const PointsItem = ({
  username,
  userEmail,
  point,
  pointsType,
  CouponId,
  orderAmount,
  createdBy,
  createdDate,
  status,
}) => {
  return (
    <>
      <div className="points-item">
        <div className="points-item-row">
          <div className="points-options">
            <div
              className={
                "status " +
                (status === 1 ? "In" : status === 2 ? "Out" : "Pending")
              }
            >
              {status === 1 ? "In" : status === 2 ? "Out" : "Pending"}
            </div>
            {/* <div className="action-dropdown dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item">
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                <button type="button" className="dropdown-item">
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                  Draft
                </button>
                <button type="button" className="dropdown-item standOut">
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="points-content">
          <div className="points-label">Name</div>
          <div className="points-title">{username}</div>
          <div className="points-label mt-4">E-mail</div>
          <div className="points-text">{userEmail}</div>
        </div>
        <div className="points-metas">
          {point && (
            <div className="meta-item">
              <div className="meta-content">
                <div className="meta-label">Points</div>
                <div className="meta-data">{point}</div>
              </div>
            </div>
          )}
          {orderAmount && (
            <div className="meta-item">
              <div className="meta-content">
                <div className="meta-label">Amount</div>
                <div className="meta-data">{orderAmount.toFixed(2)}</div>
              </div>
            </div>
          )}
        </div>

        {(pointsType || CouponId || createdBy || createdDate) && (
          <div className="points-item-row border-t">
            <div className="points-info">
              {pointsType && (
                <div className="info">
                  <div className="info-label">Points Type</div>
                  <div className="info-data">{pointsType}</div>
                </div>
              )}
              {CouponId && (
                <div className="info">
                  <div className="info-label">Coupon ID</div>
                  <div className="info-data">{CouponId}</div>
                </div>
              )}
            </div>
            <div className="points-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PointsItem;
