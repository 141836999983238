import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Search from "../../components/Form/Search";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { detailsOrders, listOrder } from "../../helpers/api_helper";
import moment from "moment";
import SidebarDetails from "../../components/Elements/SidebarDetails";
import OrderDetails from "../../components/Modals/OrderDetails";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Loading } from "../../components/Elements/Loading";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import Button from "../../components/Form/Button";
import Pagination from "../../components/Elements/Pagination";
import { SocketContext } from "../../socket/socketio";
import AllOrderItem from "../../components/Items/AllOrderItem";

const OrderList = () => {
  // Query Client instance
  const queryClient = useQueryClient();
  const fp = useRef(null);
  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status] = useState("");
  const [date, setDate] = useState(null);
  const [dateRange, setDateRenge] = useState("");
  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");
  const socket = useContext(SocketContext);



  // for date range search
  useEffect(() => {
    if (date && date.e && date.e[0] && date.e[1]) {
      setDateRenge({
        start: date.e[0],
        end: date.e[1],
      });
    }
  }, [date]);

  // Dynamic query key for Orders 🗝️
  const queryOrders = useMemo(() => ["new_orders", keyword, status, limit, offset, dateRange], [keyword, status, limit, offset, dateRange]);


  useEffect(() => {
    if (socket?.io) {
      socket.on('orderNotificationResponse', async (data) => {
        if (data) {
          queryClient.invalidateQueries(queryOrders);
          queryClient.removeQueries({
            queryKey: ["new_orders"],
            type: "inactive",
          });

        }
      });

      return () => {
        socket.off("orderNotificationResponse", () => { })
      };
    }
  }, [socket, socket?.io, queryClient, queryOrders]);

  // For fetching details of item
  const {
    data: details,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsOrders({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);

  // Order List Component
  function OrderList() {
    // Custom hook for fetching order list
    function useOrder() {
      return useQuery({
        queryKey: queryOrders,
        queryFn: () =>
          listOrder({
            keyword: keyword,
            limit: limit,
            offset: offset,
            sortByField: "created_at",
            sortByValue: -1,
            order_status_id: "632aac71482ae592baf10614",
            dateRange: dateRange,
          }),
        keepPreviousData: true,
      });
    }

    const { data: orders, error, isFetching } = useOrder();


    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (orders.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {orders.data.map((item) => (
            <AllOrderItem
              key={item._id}
              item_id={item._id}
              orderId={item?.order_id}
              order_status={item?.order_status?.name}
              order_details={item?.order_details}
              product_info={item?.order_details?.map((item) => {
                return item.product_info;
              })}
              createdDate={moment(new Date(item?.created_at)).format(
                "hh:mm A, Do MMM YY"
              )}
              price={item?.order_total_amount}
              payment_info={item?.payment_method}
              payment_status={item?.payment_status}
              shipping_info={item?.shipping_info}
              delivery_type={item?.order_type}
              coupon_info={item?.coupon_info}
              customer_info={item?.user_info}
              account_type={item?.user_account_type}
              query={queryOrders}
              detailsFetch={refetch}
              setEditId={setEditId}
              functions={addModal}
            />
          ))}
          <Pagination count={orders.count} limit={limit} offset={offset} setOffset={setOffset} />
        </>
      );
    }
  }


  const addModal = () => {
    setIsAddModal(true);
  };

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>
          {/* <div className="input-items">
            <SelectTag
              data={categoryDropdown}
              placeholder={"Search By Delivery Rider"}
            />
          </div> */}
          <div className="input-items">
            <Flatpickr
              ref={fp}
              className="form-control"
              id="datepicker-publish-input"
              placeholder="Select a date"
              onChange={(e) => {
                setDate({ e });
              }}
              options={{
                altInput: true,
                altFormat: "F j, Y",
                mode: "range",
                dateFormat: "d.m.y",
              }}
            />

          </div>
          <div className="input-items">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                setKeyword("");
                setDateRenge("");
                fp.current.flatpickr.clear();
                queryClient.invalidateQueries(queryOrders);
                queryClient.removeQueries({
                  queryKey: ["new_orders"],
                  type: "inactive",
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="orders-list">
        <OrderList functions={addModal} />
      </div>
      <SidebarDetails
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <OrderDetails
          details={details}
          isFetching={isFetching}
          query={queryOrders}
          modalClose={() => setIsAddModal(false)}
        />
      </SidebarDetails>
    </>
  );
};

export default OrderList;
