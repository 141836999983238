import React from 'react';

const PageItem = ({
  title,
  description,
  subTitle,
  slug,
  createdBy,
  createdDate,
  status,
  functions,
  id
}) => {
  return (
    <>
      <div className="pages-item">
        <div className="pages-item-row">
          <div className="pages-options">
            {/* <div className={"status " + ((status === 1)?'published':'draft')}>{(status === 1)?'published':'draft'}</div> */}
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={()=>functions({slug,id})}><i className="fa-regular fa-fw fa-pen-to-square"></i>Edit</button>
                {/* <button type="button" className="dropdown-item"><i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>Draft</button> */}
                {/* <button type="button" className="dropdown-item standOut" onClick={()=>functions(id)}><i className="fa-regular fa-fw fa-trash-can"></i>Delete</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="pages-content">
          
          <div className="pages-title">{title}</div>
          <div className="pages-label mt-2">{subTitle}</div>
          <div className="pages-label mt-4">Slug</div>
          <div className="pages-text">{slug}</div>
        </div>
 
       
        {( createdBy || createdDate) &&
        <div className="pages-item-row border-t">
        <div className="pages-info">
            {createdBy &&
            <div className="info">
              <div className="info-label">Created By</div>
              <div className="info-data">{createdBy}</div>
            </div>
            }
          </div>
         
          <div className="pages-info">
            {createdDate &&
            <div className="info">
              <div className="info-label">Created Date</div>
              <div className="info-data">{createdDate}</div>
            </div>
            }
          </div>
        </div>
        }
      </div>
    </>
  );
};

export default PageItem;