import React, { useEffect, useState } from "react";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import AddDeliverySettings from "../../components/Modals/AddDeliverySettings";
import { deleteDeliverySettings, detailsDeliverySettings, listDeliverySettings } from "../../helpers/api_helper";
import Pagination from "../../components/Elements/Pagination";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Confirm from "../../components/Elements/Confirm";
import { toast } from "react-toastify";

const DeliverySettings = () => {
  const queryClient = useQueryClient();


  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [editId, setEditId] = useState("");
  const [isEditModal, setIsEditModal] = useState(false);


  const [isAddModal, setIsAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const addModal = () => {
    setIsAddModal(true);
  };
  const deleteItem = () => {
    setIsOpenConfirm(true);
  }



  const queryDeli = ["delivery_settings", keyword, limit, offset];

  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsDeliverySettings({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "" && isEditModal) {
      refetch();
    }
  }, [editId, isEditModal, refetch]);


  const deleteProduct = useMutation(
    (delProductId) => deleteDeliverySettings(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryDeli);
          queryClient.removeQueries({
            queryKey: ["delivery_settings"],
            type: "inactive",
          });
          setDeleteModal(false);
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  function DietList() {
    // Custom hook for fetching diet list
    function useDiet() {
      return useQuery({
        queryKey: queryDeli,
        queryFn: () =>
          listDeliverySettings({
            keyword: keyword,
            // status: true,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: diets, error, isFetching } = useDiet();

    //console.log(diets);
    // Handling loading state 😑
    if (isFetching) {
      return <Loading />
    }

    // Handling error state 🥴
    if (error) {
      return <Error />
    }

    // Check if their is no data 🤨
    else if (diets.data.length === 0) {
      return <NoData />
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          <div>
            {diets.data.map((item) => (
              <div className="item-data-row" key={item._id}>
                <div className="postal-code">{item.zip_code}</div>
                <div className="min-order-value">{item.minimum_order_amount}</div>
                <div className="delivery-costs">{item.delivery_charge}</div>
                <div className="action-dropdown dropdown">
                  <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button type="button" className="dropdown-item"
                      onClick={() => {
                        setIsEditModal(true);
                        setEditId(item._id);
                        // detailsFetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>


                    {/* <button type="button" className="dropdown-item">
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{'draft'}
                </button> */}


                    <button type="button" className="dropdown-item standOut" onClick={() => {
                      setDeleteModal(true);
                      setEditId(item._id);
                    }}>
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Pagination count={diets.count} limit={limit} offset={offset} setOffset={setOffset} />
        </>
      );
    }
  }

  // console.log(isEditModal, isFetching);


  return (
    <>

      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            <Search placeholder={"Search..."} state={keyword} setState={setKeyword} />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button label={""} labelClasses={""} classes={"px-2"} icon={"fa-regular fa-arrow-rotate-right"} iconPosition={"left"} functions={() => {
              queryClient.invalidateQueries(queryDeli);
              queryClient.removeQueries({
                queryKey: ["delivery_settings"],
                type: "inactive",
              });
            }} />
            <Button label={"Delivery"} labelClasses={""} classes={""} icon={"fa-regular fa-plus"} iconPosition={"left"} functions={addModal} />
          </div>
        </div>

      </div>
      <div className="delivery-settings-list">
        <div className="table-inner">
          <div className="delivery-title">Delivery Settings</div>
          <div className="table-data">

            <div className="item-data-row header-row">
              <div className="postal-code">Post Code <span className="sortbale"></span></div>
              <div className="min-order-value">minimum order Amount</div>
              <div className="delivery-costs">Delivery Costs</div>
            </div>
            <div className="table-body-part">
              <DietList />
            </div>
          </div>
        </div>

      </div>


      <Modal title={"Add Delivery Setting"} size={"sm"} isOpen={isAddModal} modalClose={() => setIsAddModal(!isAddModal)}>
        <AddDeliverySettings modalClose={() => setIsAddModal(false)} query={queryDeli} />
      </Modal>

      <Modal title={"Edit Delivery Setting"} size={"sm"} isOpen={isEditModal} modalClose={() => setIsEditModal(false)}>
        <AddDeliverySettings modalClose={() => setIsEditModal(false)} query={queryDeli} details={details} isFetching={isFetching} />
      </Modal>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: editId });
        }}
      />



    </>
  );
};

export default DeliverySettings;
