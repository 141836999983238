const BannerItem = ({ image, name, page_type, createdBy, createdDate, updatedDate, functions,bannerId,setDeleteModal,setDeleteId }) => {

  const remove = () => {
    setDeleteModal(true)
    setDeleteId(bannerId)
  }

  return (
    <>
      <div className="banner-item">
        <div className="banner-item-row">
          <div className="banner-content">
            <div className="banner-image">
              <img src={image} />
            </div>
            <div className="banner-details">
              <span>Title</span>
              <div className="banner-title">{name}</div>
              <div className="banner-descriptions">
                <span>Page</span>
                <div className="des">{page_type?.slug}</div>
              </div>
            </div>
          </div>
          <div className="banner-options">
            {/* <div className={"status " + (status === 1 ? "published" : "draft")}>{status === 1 ? "published" : "draft"}</div> */}
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={()=>functions(bannerId)}>
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                {/* <button type="button" className="dropdown-item">
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>Draft
                </button> */}
                <button type="button" className="dropdown-item standOut" onClick={remove}>
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {(createdBy || createdDate || updatedDate) && (
          <div className="banner-item-row border-t">
            <div className="banner-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="banner-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BannerItem;
