import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { jobAppliesDelete } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const JobsApplicationItem = ({
  name,
  orderItems,
  cv_upload,
  applicantName,
  phone,
  email,
  comment,
  applicationDate,
  motivation,
  id,
  editData,
  updatedDate,
  query

}) => {

  const [deleteModal, setDeleteModal] = useState(false);
  const queryClient=useQueryClient();
  const deleteProduct = useMutation(
    (delProductId) => jobAppliesDelete(delProductId),
    {
      onSuccess: (res) => {
       
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["application"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );
  return (
    <>
   
        <div className="applications-item">
        <div className="applications-row" style={{
          display: "flex",
          flexDirection: "row-reverse"
        }}>
          <div className="jobs-options">
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item standOut" onClick={() => {
                  setDeleteModal(true)
                }}><i className="fa-regular fa-fw fa-trash-can"></i>Delete</button>
              </div>
            </div>
          </div>
        </div>
          <div className="applications-content">
            
            <div className="order-info">
              <div className="applications-title">{name}</div>
              <div className="applications-metas">
                {orderItems && (
                  <div className="meta-item">
                    <div className="meta-content">
                      <div className="meta-label">Items</div>
                      <div className="meta-data">
                        {orderItems.length > 0
                          ? orderItems.map((item) => (
                              <span key={item._id}>{item.name}</span>
                            ))
                          : "No"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="applications-metas">
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Comment </div>
                    <div className="meta-data">{comment}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="order-customer-info ps-4">
              <div className="title">Applicant Details</div>
              <div className="info-customer">
                <div className="name">{applicantName}</div>
                <div className="other-data">{phone}</div>
                <div className="other-data">{email}</div>
              </div>
            </div>
            <div className="order-customer-info ps-4">
              <div className="title">CV/Motivation</div>

              <div className="info-customer">
                {
                  cv_upload&&(
                    <div className="other-data type">
                    CV
                    <a href={cv_upload} target={"_blank"}>
                      <i class="fa-regular fa-file"></i>
                    </a>
                  </div>
                  )
                }
                
                {
                  motivation && (
                    <div className="other-data type">
                    Motivation
                  
                    <a href={motivation} target={"_blank"}>
                      <div className="fa fa-file"></div>
                    </a>
  
                  </div>
                  )
                 }
              
              </div>
            </div>
          </div>

          <div className="applications-item-row border-t">
            <div className="applications-info">
              {applicationDate && (
                <div className="info">
                  <div className="info-label">Application Date</div>
                  <div className="info-data">{applicationDate}</div>
                </div>
              )}
            </div>
            <div className="applications-info">
              {applicationDate && (
                <div className="info">
                  <div className="info-label">Updated Date</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: id });
        }}></Confirm>
  
    </>
  );
};

export default JobsApplicationItem;
