import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { jobListRemove, jobListStatusChange } from '../../helpers/api_helper';
import Confirm from '../Elements/Confirm';

const JobsItem = ({
  name,
  index,
  id,
  description,
  jobType,
  jobLocation,
  cvRequired,
  motivationRequired,
  createdBy,
  createdDate,
  status,
  query,
  setEditId,
  setEditModal,
  required_cv
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  console.log(jobType)


  const deleteProduct = useMutation(
    (delProductId) => jobListRemove(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobs"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  const statusChange = useMutation(
    (changeSts) => jobListStatusChange(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobs"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );
  return (
    <>
    <Draggable draggableId={id} index={index} key={id}>
    {(provided) => (
      <div className="jobs-item mb-3" 
        key={id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="jobs-item-row">
          <div className="jobs-options">
            <div className={"status " + ( status ?'published':'draft')}>{ status ?'published':'draft'}</div>
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={() => {
                    setEditModal(true);
                    setEditId(id);
                  }}><i className="fa-regular fa-fw fa-pen-to-square"></i>Edit</button>
                <button type="button" className="dropdown-item" onClick={() => statusChange.mutate({
                  id: id,
                  status: status ? false : true,
                })}><i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{status ? 'Draft' : 'Publish'}</button>
                <button type="button" className="dropdown-item standOut" onClick={() => setDeleteModal(true)}><i className="fa-regular fa-fw fa-trash-can"></i>Delete</button>
              </div>
            </div>
          </div>
        </div>
        <div className="jobs-content">
          <div className="jobs-label">Title</div>
          <div className="jobs-title">{name}</div>
          <div className="jobs-label mt-4">Description</div>
          <div className="jobs-text">{description}</div>
        </div>
        <div className="jobs-metas">
          {jobType &&
          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">Job Type</div>
              <div className="meta-data">{jobType}</div>
            </div>
          </div>
          }
          {jobLocation &&
          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">Job Location Type</div>
              <div className="meta-data">{jobLocation}</div>
            </div>
          </div>
          }
         
        </div>
       
        {(motivationRequired  || createdBy || createdDate) &&
        <div className="jobs-item-row border-t">
          <div className="jobs-info">
            {/* {cvRequired && */}
            <div className="info">
              <div className="info-label">CV Mandetory</div>
              <div className="info-data">{cvRequired === true ? "Yes" : "No"}</div>
            </div>
            {/* } */}
            {/* {motivationRequired  && */}
            <div className="info">
              <div className="info-label">Motivation Mandetory</div>
              <div className="info-data">{motivationRequired === true ? "Yes" : "No"}</div>
            </div>
            {/* } */}
          </div>
          <div className="jobs-info">
            {createdBy &&
            <div className="info">
              <div className="info-label">Created By</div>
              <div className="info-data">{createdBy}</div>
            </div>
            }
            {createdDate &&
            <div className="info">
              <div className="info-label">Created Date</div>
              <div className="info-data">{createdDate}</div>
            </div>
            }
          </div>
        </div>
        }
      </div>
    )}
      </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: id });
        }}
      />
    </>
  );
};

export default JobsItem;