import { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addFaq, editFaqs, listCategories } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { Loading } from "../Elements/Loading";
import Select from "react-select";
const AddFaqs = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  // const category = queryClient.getQueryData(["faq_categories_dropdown"]);
  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [faq_category_id, setFaq_category_id] = useState([]);
  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);

  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");

  function useCategory() {
    return useQuery({
      queryKey: ["faq_categories_dropdown_id"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await listCategories(
          {
            limit: 9999,
            category_type: "faqs",
          }
        );
        
        data.forEach((item) => {
          tmp.push({ value: item._id, label: item.name });
        });

        return tmp;
      },
    });
  }

  const { data: category } = useCategory();

  const { mutate: handleSubmit } = useMutation((payload) => addFaq(payload), {
    onSuccess: (res) => {
      // console.log(res);
      if (res.status === 200) {
        modalClose(!modalClose);
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["faqs"],
          type: "inactive",
        });
      
        setTitle("");
        setAnswer("");
        setStatus(1);
        setFaq_category_id([]);
        setSelectedCatOpt([])
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const { mutate: handleEdit } = useMutation((payload) => editFaqs(payload), {
    onSuccess: (res) => {
      // console.log(res);
      if (res.status === 200) {
        modalClose();
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["faqs"],
          type: "inactive",
        });
        setTitle("");
        setAnswer("");
        setStatus(1);
        setSelectedCatOpt([])
        setFaq_category_id([]);
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  useEffect(() => {
    if (details) {
      setFaq_category_id(details ? details.category?._id : "");
      setTitle(details ? details.question : "");
      setAnswer(details ? details.answer : "");
      setStatus(details ? details.status : "");
      setPublishedData([]);
      console.log(category);
      setSelectedCatOpt(
        category?.find((item) => {
          return item.value === details.category?._id;
        })
      );
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 600);
    }
  }, [details]);

  

  if (isFetching) {
    return <Loading />;
  }


  

  // console.log(status);


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Qusetion"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Veg"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col">
                <div className="mb-4">
                  <SelectTag
                    selectedOptions={selectedCatOpt}
                    setSelectedOptions={setSelectedCatOpt}
                    setStateId={setFaq_category_id}
                    data={category}
                    placeholder={"Example: Food"}
                    label={"Select Category"}
                    inputRequired={false}
                    isCheckbox={true}
                    checkboxPosition={"right"}
                  />
                </div>
              </div>
            </div> */}

            <div className="mb-3">
              <div className="input">
                <div className="input-options">
                  <div className="input-label">Categories</div>
                </div>
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={category}
                  value={selectedCatOpt || []}
                  placeholder={"Categories"}
                  onChange={(e) => {
                    // console.log(e);
                    setFaq_category_id(e.value);
                    setSelectedCatOpt(e);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <Textarea
                    state={answer}
                    setState={setAnswer}
                    label={"Answer"}
                    inputRequired={true}
                    inputPlaceholder={"Write Answer......."}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  {/* {console.log(publishedData, status)} */}
                  <RadiosLabel
                    setRadio={setStatus}
                    data={publishedData}
                    setData={setPublishedData}
                    label={"Status"}
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {details ? (
                    <Button
                      label={"Save"}
                      functions={() =>
                        handleEdit({
                          id: details._id,
                          answer: answer,
                          question: title,
                          status: status === 1 ? true : false,
                          category: selectedCatOpt.value,
                        })
                      }
                    />
                  ) : (
                    <Button
                      label={"Save"}
                      functions={() =>
                        handleSubmit({
                          question: title,
                          answer: answer,
                          status: status === 1 ? true : false,
                          category: faq_category_id,
                        })
                      }
                    />
                  )}

                  <Button
                    label={"Cancel"}
                    classes={"main-button-alt"}
                    functions={() => {
                      modalClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFaqs;
