import { useEffect, useState } from 'react';
import Input from "../Form/Input";
import Button from "../Form/Button";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminEdit } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { Loading } from '../Elements/Loading';
import ImageUploadPreview from '../Form/ImageUploadPreview';

const AddCustomers = ({
  modalClose,
  query,
  details,
  isFetching,
}) => {

  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    { _id: true, label: "Published", inputName: "publish_status", current: true },
    { _id: false, label: "Draft", inputName: "publish_status", current: false },
  ]);

  const [status, setStatus] = useState(true);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [uploadData, setUploadData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  

  const { mutate: handleEdit } = useMutation(
    (payload) => AdminEdit(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["customer"],
            type: "inactive",
          });
          setTitle("");
          setEmail("");
          setUploadData([]);
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if(details) {
      setIsDisabled(true)
      setTitle(details ? details.name : "");
      setEmail(details ? details.email : "")
    //   setStatus(details ? details.status : "");
    setPhone(details ? details.phone : "")
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
              {
                _id: true,
                label: "Published",
                inputName: "publish_status",
                current: details.status.toString() === 'true' ? true : false,
              },
              {
                _id: false,
                label: "Draft",
                inputName: "publish_status",
                current: details.status.toString() === 'false' ? true : false,
              },
            ]
            : []
        );
  
      }, 600)
    }
  }, [details]);


  if(isFetching){
    return(
      <Loading/>
    )
  }


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input 
                    state={title}
                    setState={setTitle}
                    label={"Title"} 
                    inputRequired={true} 
                    inputType={"text"} 
                    inputPlaceholder={"Example . Jane"} 
                    inputName={""} 
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input 
                    state={email}
                    setState={setEmail}
                    label={"Email"} 
                    inputRequired={true} 
                    inputType={"text"} 
                    inputPlaceholder={"Example . abc@mailinator.com"} 
                    inputName={""} 
                    isDisabled={isDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input 
                    state={phone}
                    setState={setPhone}
                    label={"Phone"} 
                    inputRequired={true} 
                    inputType={"number"} 
                    inputPlaceholder={"Example . 7003491718"} 
                    inputName={""} 
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
               
                  <RadiosLabel 
                    data={publishedData} 
                    setRadio={setStatus}
                    label={"Status"} 
                    inputRequired={true} 
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                {details ? <Button label={"Save"} 
                  functions={() => handleEdit({
                    id: details._id,
                    name: title,
                    email: email,
                    // status: status,
                    phone: phone,
                    image: uploadData && uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image,
                  })}
                  /> : ""}
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col">
                <ImageUploadPreview
                  setImage={setUploadData}
                  data={uploadData}
                  label={"Upload Image"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomers;