import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { stepsListStatusChange } from "../../helpers/api_helper";

const StepsItem = ({ name, description, createdBy, createdDate, status, updatedAt, id, query, editmodal, setListDeleteId, setDeleteModal, index }) => {


  const queryClient = useQueryClient();

  // status changing ============>

  const statusChange = useMutation(
    (payload) => stepsListStatusChange(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["diets"],
            type: "inactive",
          });
        } else {
          toast.error(res.message);
        }
      }
    }
  )

  const statusChangeHandler = () => {
    statusChange.mutate({
      id: id,
      status: status ? false : true,
    })
  }

  // ==========>

  const deleteListHandler = () => {
    setListDeleteId(id)
    setDeleteModal(true)
  }

  return (
    <>
      <Draggable draggableId={id} index={index} key={id}>
        {
          (provided) => (
            <div className="steps-item" key={id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div className="steps-item-row">
                <div className="steps-options">
                  <div className={"status " + (status === true ? "published" : "draft")}>{status === true ? "published" : "draft"}</div>
                  <div className="action-dropdown dropdown">
                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                      <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <button type="button" className="dropdown-item" onClick={() => editmodal(id)}>
                        <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                      </button>
                      <button type="button" className="dropdown-item" onClick={statusChangeHandler}>
                        <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{status ? 'Draft' : 'Published'}
                      </button>
                      <button type="button" className="dropdown-item standOut" onClick={deleteListHandler}>
                        <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps-content">
                <div className="steps-label-index">{index +1}.</div>
                <div className="steps-label">Name</div>
                <div className="steps-title">{name}</div>
                <div className="steps-label mt-4">Description</div>
                <div className="steps-text">{description}</div>
              </div>

              {(createdBy || createdDate) && (
                <div className="steps-item-row border-t">
                  <div className="steps-info">
                    {createdBy && (
                      <div className="info">
                        <div className="info-label">Created By</div>
                        <div className="info-data">{createdBy}</div>
                      </div>
                    )}
                  </div>

                  <div className="steps-info">
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">
                        {
                          moment(new Date(createdDate)).format("Do MMM YY")
                        }
                      </div>
                    </div>
                    <div className="info">
                      <div className="info-label updated-date-sec">Last Updated</div>
                      <div className="info-data">
                        {
                          moment(new Date(updatedAt)).format("Do MMM YY")
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        }
      </Draggable>
    </>
  );
};

export default StepsItem;
