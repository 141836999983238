import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import Button from "../Form/Button";
import PreviewProduct from "../Elements/PreviewProduct";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { RiderAdd, RiderEdit } from "../../helpers/api_helper";
import RadiosLabel from "../Form/RadiosLabel";

const AddRider = ({ query, modalClose, details, isFetching }) => {
  const [isPreview, setIspreview] = useState(false);

  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    {
      _id: true,
      label: "Available",
      inputName: "publish_status",
      current: true,
    },
    {
      _id: false,
      label: "Not Available",
      inputName: "publish_status",
      current: false,
    },
  ]);
  const [status, setStatus] = useState(true);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [uploadData, setUploadData] = useState([]);

  const { mutate: handleSubmit } = useMutation((payload) => RiderAdd(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["drivers"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["rider_dropdown"],
          type: "inactive",
        });
        setTitle("");
        setEmail("");
        setPhone("");
        setDescription("");
        setUploadData([]);
        setStatus(1);
        modalClose();
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const { mutate: handleEdit } = useMutation((payload) => RiderEdit(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["drivers"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["rider_dropdown"],
          type: "inactive",
        });
        setTitle("");
        setEmail("");
        setPhone("");
        setDescription("");
        setUploadData([]);
        setStatus(1);
        modalClose();
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  useEffect(() => {
    if (details) {
      setTitle(details ? details.name : "");
      setEmail(details ? details.email : "");
      setPhone(details ? details.phone : "");
      setDescription(details ? details.description : "")
      setStatus(details ? details.available : "");
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: true,
                  label: "Available",
                  inputName: "publish_status",
                  current: details.available === true? true : false,
                },
                {
                  _id: false,
                  label: "Not Available",
                  inputName: "publish_status",
                  current: details.available === false ? true : false,
                },
              ]
            : []
        );
      }, 600);
    }
  }, [details]);

  // console.log(status);

  if (isFetching) {
    return <div>Loading...</div>;
  }
  const previewData = [
    {
      image: "images/product.png",
    },
  ];
  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="mb-3">
                <Input
                  state={title}
                  setState={setTitle}
                  label={"Name"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Name"}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input
                  state={email}
                  setState={setEmail}
                  label={"Email"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Email"}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input
                  state={phone}
                  setState={setPhone}
                  label={"Phone Number"}
                  inputRequired={true}
                  inputType={"Number"}
                  inputPlaceholder={"Phone Number"}
                  inputName={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3">
                <Textarea
                  state={description}
                  setState={setDescription}
                  label={"Description"}
                  inputRequired={true}
                  inputPlaceholder={"Write description......."}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
               
                  <RadiosLabel 
                    data={publishedData}
                    setData={setPublishedData}
                    setRadio={setStatus}
                    label={"Status"} 
                    inputRequired={true} 
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-5">
            {!isPreview ? (
              <>
                <div className="row">
                  <div className="col">
                    <ImageUploadPreview
                      setImage={setUploadData}
                      data={uploadData}
                      label={"Upload Photo"}
                      inputRequired={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              <PreviewProduct
                data={previewData}
                previewClose={() => setIspreview(!isPreview)}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              {details ? (
                <Button
                  label={"Save"}
                  functions={() =>
                    handleEdit({
                      id: details._id,
                      name: title,
                      email: email,
                      phone: phone,
                      description: description,
                      status: status,
                      available: status,
                      image:
                        uploadData && uploadData[0]?.image.includes("https")
                          ? null
                          : uploadData[0]?.image,
                    })
                  }
                />
              ) : (
                <Button
                  label={"Save"}
                  functions={() =>
                    handleSubmit({
                      name: title,
                      email: email,
                      phone: phone,
                      description: description,
                      status: status,
                      available: status,
                      image: uploadData ? uploadData[0]?.image : null,
                    })
                  }
                />
              )}
              <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRider;
