import React, { useState } from "react";
import Button from "../Form/Button";
import { ReasonList, rejectLiveOrder, stsChngLiveOrder } from "../../helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Confirm from "../Elements/Confirm";
import Select from "react-select";
import { toast } from "react-toastify";

const OrderReject = ({ currId, modalClose, currOrderId,rejectStatus }) => {
  const queryClient = useQueryClient();

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteModal = () => {
    setIsDeleteModal(true);
  };

  function useRiders() {
    return useQuery({
      queryKey: ["reason_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await ReasonList();
        
        data.forEach((item) => {
          tmp.push({ value: item._id, label: item.name });
        });

       
        return tmp;
      },
    });
  };

  const { data: reasons } = useRiders();

  const [selectedRsn, setSelectedRsn] = useState([]);
  const [rsnId, setRsnId] = useState('');

  const {mutate: reject} = useMutation(
    (payload) => rejectLiveOrder(payload),
    {
      onSuccess: (res) => {

        if (res.status === 200) {
          toast.success(res.message);
          modalClose();
          stsChngLiveOrder({id:currId, type_id: 5 });
          queryClient.invalidateQueries(['live_order']);
          queryClient.removeQueries({
            queryKey: ["live_order"],
            type: "inactive",
          });
         
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );



  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <div className="order-info">ORDER ID <span>{currOrderId}</span> </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
              {reasons && 
              <div className="input">

                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={reasons}
                  placeholder={"Select Reason"}
                  value={selectedRsn}
                  onChange={(e) => {
                    setRsnId(e.value);
                    setSelectedRsn([e]);
                  }}
                  />
           </div>}
               
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button label={"Submit"} functions={() => {reject({
                  id: currId,
                  order_id: currOrderId.slice(1),
                  order_cancel_reason_id: rsnId
              });
              queryClient.invalidateQueries({
                queryKey: ['new_orders'],
                exact: false
              })
              }}/>
              <Button label={"Cancel"} classes={"main-button-alt"} functions={modalClose} />
            </div>
          </div>
        </div>
      </div>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Reject"}
        text={"Are you sure want to reject this order?"}
        isOpen={isDeleteModal}
        confirmSubmit={() => {}}
        confirmClose={() => setIsDeleteModal(false)}
      />
    </>
  );
};

export default OrderReject;
