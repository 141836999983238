import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import AuthLayout from './layouts/AuthLayout';
import DefaultLayout from './layouts/DefaultLayout';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import MenuPage from "./pages/MenuPage/MenuPage";
import Blogs from './pages/Blogs/Blogs';
import Faqs from './pages/Faqs/Faqs';
import Orders from './pages/Orders/Orders';
import Jobs from './pages/Jobs/Jobs';
import Points from './pages/Points/Points';
import Organizations from './pages/Organizations/Organizations';
import Cms from './pages/Cms/CmsPage';
import User from './pages/UserManagement/User';
import ProtectedLayout from './layouts/ProtectedLayout';



const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<AuthLayout/>}>
            <Route path='/' element={<Login/>} />
          </Route>
          <Route path='/dashboard' element={<DefaultLayout/>}>
            <Route path='/dashboard' element={<ProtectedLayout><Dashboard/></ProtectedLayout>} />
          </Route>
          <Route path='/menu' element={<DefaultLayout/>}>
            <Route path='/menu' element={<ProtectedLayout><MenuPage/></ProtectedLayout>} />
          </Route>
          <Route path='/blogs' element={<DefaultLayout/>}>
            <Route path='/blogs' element={<ProtectedLayout><Blogs/></ProtectedLayout>} />
          </Route>
          <Route path='/orders' element={<DefaultLayout/>}>
            <Route path='/orders' element={<ProtectedLayout><Orders/></ProtectedLayout>} />
          </Route>
          <Route path='/jobs' element={<DefaultLayout/>}>
            <Route path='/jobs' element={<ProtectedLayout><Jobs/></ProtectedLayout>} />
          </Route>
          <Route path='/points' element={<DefaultLayout/>}>
            <Route path='/points' element={<ProtectedLayout><Points/></ProtectedLayout>} />
          </Route>
          <Route path='/organizations' element={<DefaultLayout/>}>
            <Route path='/organizations' element={<ProtectedLayout><Organizations/></ProtectedLayout>} />
          </Route>
          <Route path='/faqs' element={<DefaultLayout/>}>
            <Route path='/faqs' element={<ProtectedLayout><Faqs/></ProtectedLayout>} />
          </Route>
          <Route path='/user' element={<DefaultLayout/>}>
            <Route path='/user' element={<ProtectedLayout><User/></ProtectedLayout>} />
          </Route>
          <Route path='/cms' element={<DefaultLayout/>}>
            <Route path='/cms' element={<ProtectedLayout><Cms/></ProtectedLayout>} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;