import { useEffect, useRef, useState } from "react";
import Search from "../../components/Form/Search";
import SelectTag from "../../components/Form/SelectTag";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { detailsOrders, listOrder, orderData, orderStatusList, RiderList } from "../../helpers/api_helper";
import AllOrderItem from "../../components/Items/AllOrderItem";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import OrderDetails from "../../components/Modals/OrderDetails";
import SidebarDetails from "../../components/Elements/SidebarDetails";
import RadioGroup from "../../components/Form/RadioGroup";
import Pagination from "../../components/Elements/Pagination";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import Button from "../../components/Form/Button";
import Select from "react-select";

const AllOrderList = () => {
  // Query Client instance
  const queryClient = useQueryClient();
  const fp = useRef(null);

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  //Data
  const [dataList, setDataList] = useState([]);
  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [order_status_id, setOrder_status_id] = useState("");
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [riderId, setRiderId] = useState('');
  // To fetch Details of specific items when it needs to be edited 
  const [editId, setEditId] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  // For fetching details of item
  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsOrders({ id: editId }),
    enabled: false,
  });


  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "" && isAddModal) {
      refetch();
    }
  }, [isAddModal, editId, refetch]);

  // for date range search
  useEffect(() => {
    if (date && date.e && date.e[0] && date.e[1]) {
      setStartDate(date.e[0]);
      setEndDate(date.e[1]);
    }
  }, [date, startDate, endDate]);

  // Dynamic query key for Orders 🗝️
  const queryOrders = [
    "orders",
    keyword,
    status,
    order_type,
    order_status_id,
    riderId,
    limit,
    offset,
    startDate,
    endDate,
  ];

  // Order List Component
  function AllOrderList() {
    // Custom hook for fetching order list
    function useAllOrder() {
      return useQuery({
        queryKey: queryOrders,
        queryFn: () =>
          listOrder({
            keyword: keyword,
            limit: limit,
            offset: offset,
            order_status_id: order_status_id,
            order_type: order_type,
            rider_id: riderId,
            dateRange: {
              start: startDate,
              end: endDate,
            },
          }),
        keepPreviousData: true,
      });
    }

    const { data: orders, error, isFetching } = useAllOrder();

    useEffect(() => {
      if (orders?.data) {
        setDataList(orders?.data);
      }
    }, [orders?.data]);

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (orders.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {orders.data.map((item) => (
            <AllOrderItem
              key={item._id}
              item_id={item._id}
              orderId={item?.order_id}
              order_status={item?.order_status?.name}
              order_status_id={item?.order_status?._id}
              order_details={item?.order_details}
              product_info={item?.order_details?.map((item) => {
                return item.product_info;
              })}
              createdDate={moment(new Date(item?.created_at)).format(
                "hh:mm A, Do MMM YY"
              )}
              rider={item?.user_rider}
              price={item?.order_total_amount}
              payment_info={item?.payment_method}
              payment_status={item?.payment_status}
              shipping_info={item?.shipping_info}
              delivery_type={item?.order_type}
              coupon_info={item?.coupon_info}
              customer_info={item?.user_info}
              account_type={item?.user_account_type}
              query={queryOrders}
              detailsFetch={refetch}
              setEditId={setEditId}
              setEditModal={setIsEditModal}
              functions={addModal}
            />
          ))}
          <Pagination count={orders.count} limit={limit} offset={offset} setOffset={setOffset} />
        </>
      );
    }
  }


  const [statData, setStatData] = useState([
    {
      _id: '1',
      data: '83',
      icon: <i className="fa-light fa-box"></i>,
      label: 'Total orders',
      metaData: [],
    },
    {
      _id: '2',
      data: '250',
      icon: <i className="fa-light fa-box-circle-check"></i>,
      label: 'Accepted orders',
      metaData: [
        { label: 'Pickup', data: '150' },
        { label: 'Delivery', data: '100' },
      ],
    },
    {
      _id: '3',
      data: '€ 158',
      icon: <i className="fa-light fa-filter-circle-dollar"></i>,
      label: 'Gross revenue',
      metaData: [],
    },
    {
      _id: '4',
      data: '€ 158',
      icon: <i className="fa-light fa-chart-mixed"></i>,
      label: 'Average order value',
      metaData: [],
    }

  ]);

  const queryOrderData = ['order_data', startDate, endDate]

  function useOrderData() {
    return useQuery({
      queryKey: queryOrderData,
      queryFn: () =>
        orderData({
          store_id: "",
          dateRange: {
            start: startDate,
            end: endDate,
          },
        }),
      keepPreviousData: true,
    });
  }

  const { data: orderDatas } = useOrderData();
  // console.log(orderDatas);


  useEffect(() => {
    setStatData(orderDatas && [
      {
        _id: '1',
        data: orderDatas.total_orders,
        icon: <i className="fa-light fa-box"></i>,
        label: 'Total orders',
        metaData: [],
      },
      {
        _id: '2',
        data: orderDatas.total_accept_orders,
        icon: <i className="fa-light fa-box-circle-check"></i>,
        label: 'Accepted orders',
        metaData: [
          { label: 'Pickup', data: orderDatas.total_pickup_order_count },
          { label: 'Delivery', data: orderDatas.total_delivery_order_count },
        ],
      },
      {
        _id: '3',
        data: '€ ' + orderDatas.gross_amount,
        icon: <i className="fa-light fa-filter-circle-dollar"></i>,
        label: 'Gross revenue',
        metaData: [],
      },
      {
        _id: '4',
        data: '€ ' + orderDatas.average_order_amount,
        icon: <i className="fa-light fa-chart-mixed"></i>,
        label: 'Average order value',
        metaData: [],
      }

    ])
  }, [orderDatas]);

  const [orderStatus, setOrderStatus] = useState([
    { _id: 1, name: "Pending", color: "warning", current: true },
    { _id: 2, name: "Rejected", color: "danger", current: false },
    { _id: 3, name: "Confirmed", color: "success", current: false },
  ]);
  const type = [
    { _id: "", name: "All", color: "success", current: true },
    { _id: 2, name: "Pick Up", color: "success", current: false },
    { _id: 1, name: "Delivery", color: "warning", current: false },
  ];


  const addModal = () => {
    setIsAddModal(true);
  };

    function useDiet() {
      return useQuery({
        queryKey: ["order_status_list"],
        keepPreviousData: true,
        queryFn: async () => {
          let tmp = [];
          const { data } = await orderStatusList(
            {
              limit: 9999,
            });
          tmp.push({ _id: "", name: "All", color: "success", current: true });
          data.forEach((item) => {
            if (item.name === "Pending") {
              tmp.push({
                _id: item._id,
                name: item.name,
                color: "warning",
                current: false,
              });
            }
            if (item.name === "Accepted") {
              tmp.push({
                _id: item._id,
                name: item.name,
                color: "success",
                current: false,
              });
            } 
            if (item.name === "Canceled") {
              tmp.push({
                _id: item._id,
                name: item.name,
                color: "danger",
                current: false,
              });
            }
            if (item.name === "Out for delivery") {
              tmp.push({
                _id: item._id,
                name: item.name,
                color: "success",
                current: false,
              });
            }
            if (item.name === "Delivered") {
              tmp.push({
                _id: item._id,
                name: item.name,
                color: "success",
                current: false,
              });
            } 
          if (item.name === "Kitchen") {
            tmp.push({
              _id: item._id,
              name: item.name,
              color: "success",
              current: false,
            });
          } 
        });
  
          return tmp;
        },
      });
    }
  
    const { data: diets } = useDiet();

    useEffect(() => {
      setOrderStatus(diets);
    }, [diets]);


    function useRiders() {
      return useQuery({
        queryKey: ["rider_dropdown"],
        queryFn: async () => {
          let tmp = [];
          const { data } = await RiderList({available:true});
          
          data.forEach((item) => {
            tmp.push({ value: item._id, label: item.name });
          });
  
          return tmp;
        },
      });
    }

    const { data: riders } = useRiders();

    const [selectedRider, setSelectedRider] = useState([]);
    
  


  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>
          <div className="input-items">
          <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={riders}
                  placeholder={"Rider Name"}
                  value={selectedRider || []}
                  onChange={(e) => {
                    setRiderId(e.value);
                    setSelectedRider(e);
                  }}
                />
          </div>

          <div className="input-items">
            <Flatpickr
              ref={fp}
              className="form-control date-picker"
              id="datepicker-publish-input"
              placeholder="Select a date"
              onChange={(e) => {
                setDate({ e });
              }}
              options={{
                altInput: true,
                altFormat: "F j, Y",
                mode: "range",
                dateFormat: "d.m.y",
              }}
            />
          </div>
          <div className="input-items">
            
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                setKeyword("");
                setDate(null);
                setStartDate("");
                setEndDate("");
                setSelectedRider('');
                setRiderId(''); //
                queryClient.invalidateQueries(queryOrders);
                queryClient.invalidateQueries(queryOrderData);
                queryClient.removeQueries({
                  queryKey: ["orders"],
                  type: "inactive",
                });
                queryClient.removeQueries({
                  queryKey: ["order_data"],
                  type: "inactive",
                });
              }}
            />

          </div>
        </div>
        <div className="radio-filters">
          <RadioGroup data={orderStatus} inputName={"status"} setData={setOrder_status_id} />
          <RadioGroup data={type} inputName={"type"} setData={setOrder_type} />
        </div>

        
      </div>
      



      <div className="order-stat">
        {statData && statData.map((item) => (
          <div className="stat-item" key={item._id}>
            <div className="icon">{item.icon}</div>
            <div className="stat-content">
              <div className="number">{item.data}</div>
              <div className="title">{item.label}</div>
              {item.metaData.length > 0 &&
                <div className="other-data">
                  {item.metaData.map((metaItem, index) => (
                    <div className="info-other" style={{ fontSize: "13px" }} key={index}>{metaItem.label}: {metaItem.data}</div>
                  ))}
                </div>
              }
            </div>
          </div>
        ))}
      </div>

      <div className="orders-list">
        <AllOrderList functions={addModal} />
      </div>
      <SidebarDetails
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <OrderDetails
          details={details}
          isFetching={isFetching}
          query={queryOrders}
          modalClose={() => setIsAddModal(false)}
        />
      </SidebarDetails>
    </>
  );
};

export default AllOrderList;
