import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import { addMisson } from "../../helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { missionEdit,missionDetails} from "../../helpers/api_helper";





const AddMission = ({setIsAddModal,setEditModel,showToastMessage,isOpen,editMode=false,query,itemId}) => {
 
  const [title,setTitle]=useState("")
  const [description,setDescription]=useState("")
  const [radiodata,setRadioData]=useState(1)
  const queryClient = useQueryClient();
  const { mutate:add } = useMutation(addMisson, {
    onSuccess: (data) => {  
      if(data.status===200){
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["mission"],
          type: "inactive",
        });

        setTitle("")
        setDescription("")
        setRadioData(true)

    
        setIsAddModal(false)
        showToastMessage({status:200,message:data.message})
      }else{
        showToastMessage({status:400,message:data.message})
    
      }
    },
  });



  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", itemId],
    queryFn: () => missionDetails({ id: itemId,type:2 }),
    enabled: false,
  });

  
  const { mutate: EditData } = useMutation(missionEdit, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setEditModel(false);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["mission"],
          type: "inactive",
        });
        showToastMessage({
          status: 200,
          message: data.message,
        });
        setDescription("");
        setTitle("");
        setRadioData(true);
        setPublishedData([
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: true,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: false,
                },
              ]);
      } else {
        showToastMessage({
          status: 400,
          message: data.message,
        });
      }
    },
  });


  useEffect(()=>{
    
    if(isOpen){
      refetch()
    }
  },[isOpen])

  useEffect(() => {
    if (details) {
      setTitle(details?.title ? details.title : "");
      setDescription(details?.description ? details.description : "");
      setPublishedData([]);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 50);
    }
  }, [details]);


  
  const [publishedData,setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  
  
  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <Input label={"Title"} inputRequired={true} inputType={"text"} inputPlaceholder={"Title"} inputName={""} setState={setTitle} state={title}/>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Textarea label={"Description"} inputRequired={true} inputPlaceholder={"Write description......."} inputName={""} setState={setDescription} state={description} />
              </div>
            </div>
           
            <div className="row">
              <div className="mb-4">
                <RadiosLabel data={publishedData} setData={setPublishedData} label={"Status"} inputRequired={true}  setRadio={setRadioData}/>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button label={"Save"}  functions={()=>{

                editMode?EditData({
                    id:itemId,
                    title,
                    description,
                    status: radiodata === 1 ? true : false,
                    type:2

                })
                :
                add({
                  title,
                  description,
                  status: radiodata === 1 ? true : false,
                  type:2
                })
              }}/>
              <Button label={"Cancel"} classes={"main-button-alt"}  functions={()=>{
                editMode?setEditModel(false):setIsAddModal(false)
              }}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMission;
