import React, { useEffect, useState } from "react";
import LiveOrderItem from "../../components/Items/LiveOrderItem";
import OrderReject from "../../components/Modals/OrderReject";
import Modal from "../../components/Elements/Modal";
import Confirm from "../../components/Elements/Confirm";
import AssignRider from "../../components/Modals/AssignRider";
import { listLiveOrder } from "../../helpers/api_helper";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";

const LiveOrders = () => {
  const [isAddModal, setIsAddModal] = useState(false);
  const [currTypId, setCurrTypId] = useState('');

  const addModal = () => {
    setIsAddModal(true);
  };
  const [isAssignModal, setIsAssignModal] = useState(false);

  const assignModal = () => {
    setIsAssignModal(true);
  };
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteModal = () => {
    setIsDeleteModal(true);
  };

  
  const [liveData, setLiveData] = useState([
    {
      _id: 1,
      title:'Accepted',
      icon:'fa-circle-check',
      orderData:[
        {_id: 'order1' , orderId:'#12580', orderIcon:'fa-bicycle' , streetName :'Olympiakade 67' , postCode:'1023 AX' , actions:[
          {_id:'order2Action1' , label:'Move To Kitchen' , className:'btn-action' , functions:addModal},
          {_id:'order2Action2' , label:'Reject' , className:'btn-action alt' , functions:deleteModal, }
          ]},
        {_id: 'order2' ,  orderId:'#12580', orderIcon:'fa-bag-shopping' , streetName :'Olympiakade 67' , postCode:'1023 AX' , actions:[
          {_id:'order2Action1' , label:'Move To Kitchen' , className:'btn-action' , functions:''},
          {_id:'order2Action2' , label:'Reject' , className:'btn-action alt' , functions:''}
          ]}
      ]
    },
    {
      _id: 2,
      title:'Kitchen',
      icon:'fa-fork-knife',
       orderData:[
        {_id: 'order1' , orderId:'#12580', orderIcon:'fa-bicycle' , streetName :'Olympiakade 67' , postCode:'1023 AX' , },
        {_id: 'order2' ,  orderId:'#12580', orderIcon:'fa-bicycle' , streetName :'Olympiakade 67' , postCode:'1023 AX',}
      ]
    },
    {
      _id: 3,
      title:'Ready',
      icon: "fa-bicycle",
       orderData:[
        {_id: 'order1' , orderId:'#12580', orderIcon:'fa-bag-shopping' , streetName :'Olympiakade 67' , postCode:'1023 AX'},
        {_id: 'order2' ,  orderId:'#12580', orderIcon:'fa-bicycle' , streetName :'Olympiakade 67' , postCode:'1023 AX' , actions:[
          {_id:'order2Action1' , label:'Assign Rider' , className:'btn-action' , functions:assignModal}
          ]}
      ]
    },
    {
      _id: 4,
      title:'Out for delivery',
      icon: "fa-moped",
       orderData:[
        {_id: 'order1' , orderId:'#12580', orderIcon:'fa-bag-shopping' , streetName :'Olympiakade 67' , postCode:'1023 AX'},
        {_id: 'order2' ,  orderId:'#12580', orderIcon:'fa-bicycle' , streetName :'Olympiakade 67' , postCode:'1023 AX'}
      ]
    },
  ]);


  
  
    // Custom hook for fetching order list
    function useAllOrder() {
      return useQuery({
        queryKey: ['live_order'],
        cacheTime: 6000 * 10,
        refetchInterval: 6000 * 10,
        refetchOnWindowFocus: true,
        queryFn: async () => {
          const { data } = await listLiveOrder({
            
          });

          return data;
        },
        
      });

    }

    const { data: orders, error, isFetching } = useAllOrder();
    

    useEffect(() => {
      let tmpAccept = []
      let tmpKitchen = []
      let tmpOut_for_del = []
      let tmpReady = []

      //tmp = orders && orders.accepted.list
      //console.log(tmp);

      orders && orders.accepted.list.forEach((item) => {
        if(!(item.cancel_request)) {
        tmpAccept.push({_id: item._id , type_id: 9, orderId:'#'+ item.order_id,  time:item.time, expected_delivery_time: item?.expected_delivery_time, updated_at: item?.updated_at, orderIcon: item.order_type === 1 ? 'fa-bicycle' : 'fa-bag-shopping', streetName : item.shipping_info?.address, postCode: item.shipping_info?.zipcode , actions:[
          {_id:'order2Action1' , label:'Move To Kitchen' , className:'btn-action' , functions: ""},
          {_id:'order2Action2' , label:'Reject' , className:'btn-action alt' , functions:deleteModal, }
          ]})
        }
      })
      

      orders && orders.kitchen.list.forEach((item) => {
        if(!(item.cancel_request)) {
          if(!(item.cancel_request)) {
          tmpKitchen.push({_id: item._id , type_id: 10, orderId:'#'+ item.order_id,time:item.time, expected_delivery_time: item?.expected_delivery_time, updated_at: item?.updated_at, orderIcon: item.order_type === 1 ? 'fa-bicycle' : 'fa-bag-shopping', streetName : item.shipping_info?.address, postCode: item.shipping_info?.zipcode , actions:[
            {_id:'order2Action1' , label:'Move To Ready' , className:'btn-action' , functions:""},
            {_id:'order2Action2' , label:'Reject' , className:'btn-action alt' , functions:deleteModal, }
            ]})
          }
          }
      })

      orders && orders.out_for_delivery.list.forEach((item) => {
        if(!(item.cancel_request)) {
        tmpOut_for_del.push({_id: item._id ,type_id: 4, orderId:'#'+ item.order_id,time:item.time, expected_delivery_time: item?.expected_delivery_time, updated_at: item?.updated_at, orderIcon: item.order_type === 1 ? 'fa-bicycle' : 'fa-bag-shopping', streetName : item.shipping_info?.address, postCode: item.shipping_info?.zipcode })
        }
      })

      orders && orders.ready.list.forEach((item) => {
        tmpReady.push({_id: item._id ,type_id: 11,time:item.time, expected_delivery_time: item?.expected_delivery_time, updated_at: item?.updated_at, orderId:'#'+ item.order_id, orderIcon: item.order_type === 1 ? 'fa-bicycle' : 'fa-bag-shopping', streetName : item.shipping_info?.address, postCode: item.shipping_info?.zipcode , actions:[
          {_id:'order2Action1' , label:'Assign rider' , className:'btn-action' , functions:assignModal},
          {_id:'order2Action2' , label:'Reject' , className:'btn-action alt' , functions:deleteModal, }
          ]})
      })

      let a = [...liveData];

      a[0].orderData = tmpAccept;
      a[1].orderData = tmpKitchen;
      a[2].orderData = tmpReady;
      a[3].orderData = tmpOut_for_del;

      // console.log(a);
      setLiveData(a);

    }, [orders]);


  //   {
  //     "id":"6350fab79092d0ae15ea9c9b",
     
  //     "order_status_id":"636a5124d856cc52a7810782"
  // }
    


if(isFetching && orders === undefined){
  return <Loading />
}

if(error){
  return <Error />
}

  return (
    <>
      <div className="live-order-part">
        {liveData && liveData?.map((item) => (
          <LiveOrderItem
            key={item._id}
            title={item.title}
            icon={item.icon}
            orderData={item.orderData}
            setTypId={setCurrTypId}
          />
        ))}
      </div>
      <Modal title={"Reject Reason"} size={"sm"} isOpen={isAddModal} modalClose={() => setIsAddModal(!isAddModal)}>
        <OrderReject />
      </Modal>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this product?"}
        isOpen={isDeleteModal}
        confirmSubmit={() => {}}
        confirmClose={() => setIsDeleteModal(false)}
      />

     
    </>
  );
};

export default LiveOrders;
