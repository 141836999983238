import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  futureTeamDelete,
  futureTeamDetails,
  futureTeamStatusChange,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { Draggable } from "react-beautiful-dnd";
import Confirm from "../Elements/Confirm";

const FutureItem = ({
  name,
  type,
  featured,
  createdBy,
  createdDate,
  updatedDate,
  status,
  isDraft = false,
  id,
  editId,
  setEditModel,
  query,
  index,
}) => {
  const [deleteModel, setDeleteModel] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: statusChange } = useMutation(futureTeamStatusChange, {
    onSuccess: (res) => {
      if (res.status === 200) {
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["futureTeam"],
          type: "inactive",
        });
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    },
  });

  const { mutate: deleteItem } = useMutation(futureTeamDelete, {
    onSuccess: (res) => {
      if (res.status === 200) {
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["futureTeam"],
          type: "inactive",
        });
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    },
  });

  return (
    <>
      <Draggable draggableId={id} index={index} key={id} >
        {(provided) => (
          <div
            className="diets-item mb-3"
            key={id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="future-item">
              <div className="future-item-row">
                <div className="future-options">
                  <div
                    className={
                      "status " + (status === true ? "published" : "draft")
                    }
                  >
                    {status === true ? "published" : "draft"}
                  </div>
                  <div className="action-dropdown dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      onClick={() => {
                        editId(id);
                      }}
                    >
                      <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          editId(id);
                          setEditModel(true);
                        }}
                      >
                        <i className="fa-regular fa-fw fa-pen-to-square"></i>
                        Edit
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          statusChange({
                            id: id,
                            status: !status,
                            flag: "status",
                          });
                        }}
                      >
                        <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                        {isDraft ? "Draft" : "Publish"}
                      </button>
                      <button
                        type="button"
                        className="dropdown-item standOut"
                        onClick={() => {
                          setDeleteModel(true);
                        }}
                      >
                        <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="future-content">
                <div className="future-label">Name</div>
                <div className="future-title">{name}</div>
              </div>
              <div className="future-metas">
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Type</div>
                    <div className="meta-data">{type}</div>
                  </div>

                </div>
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Featured</div>
                    <div className="meta-data">{featured}</div>
                  </div>
                </div>
              </div>
              {(createdBy || createdDate) && (
                <div className="future-item-row border-t">
                  <div className="future-info">
                    {createdBy && (
                      <div className="info">
                        <div className="info-label">Created By</div>
                        <div className="info-data">{createdBy}</div>
                      </div>
                    )}
                  </div>

                  <div className="future-info">
                    {createdDate && (
                      <div className="info">
                        <div className="info-label">Created Date</div>
                        <div className="info-data">{createdDate}</div>
                      </div>
                    )}
                  </div>
                  <div className="future-info">
                    {updatedDate && (
                      <div className="info">
                        <div className="info-label">Updated Date</div>
                        <div className="info-data">{updatedDate}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

        )}
      </Draggable>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModel}
        confirmClose={() => setDeleteModel(false)}
        confirmSubmit={() =>
          deleteItem({
            id: id,
          })
        }
      />
    </>
  );
};

export default FutureItem;
