import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

const SidebarDetails = ({ isOpen, modalClose = () => {}, children}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <div className={"details-box" + (isOpen?' active':'')}>
        <div className="details-box-backdrop"></div>
        <div className="details-box-content">
          <div className="details-box-header">
            <button type="button" className="details-box-close" 
            onClick={() => {
              modalClose();
              queryClient.removeQueries({
                queryKey: ["details"],
                exact: false,
              });
            }}
            >
              <i className="fa-light fa-fw fa-xmark"></i>
            </button>
            </div>
            <div className="details-box-body scrollbar">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SidebarDetails;