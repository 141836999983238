import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import moment from "moment/moment";

const getAllDaysInMonth = (month, year) =>
  Array.from(
    {length: new Date(year, month, 0).getDate()}, // get next month, zeroth's (previous) day
    (_, i) => new Date(year, month - 1, i + 1)    // get current month (0 based index)
  );





const LineChart = ({ dateRange, graphData, type="month"}) => {
  const labelYear = ["January", "February", "March", "April", "May", "June", "August", "September", "October", "November", "December"];
  const labels = [moment().subtract(1, "quarter").startOf("quarter").format("MMMM"), moment().subtract(1, "quarter").startOf("quarter").add(1, "month").format("MMMM"),  moment().subtract(1, "quarter").startOf("quarter").add(2, "month").format("MMMM")];
  const labelWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", ];
  const labelMonth = getAllDaysInMonth(new Date(dateRange.start).getMonth()+1, new Date(dateRange.start).getYear()).map(x => x.toLocaleDateString([], { month: "short", day: "numeric" }));
  // const labelMonth1 = Array.from({length: 31}, (_, i) => i + 1)
  
  // console.log(labelMonth2);
  const data = {
    labels: type === "month" ? labelMonth : type === "week" ? labelWeek : labelYear,
    datasets: graphData,
  };
console.log(graphData);
  return (
    <div>
      <Line data={data} />
    </div>
  );
};

export default LineChart;