import React, { useState } from "react";
import RadioGroup from "../Form/RadioGroup";
import { Draggable } from "react-beautiful-dnd";
import Confirm from "../Elements/Confirm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { delTakeOff, stsChangeTakeOff } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const TakeOffTimeItem = ({ id, index, name, setEditModal, setEditId, createdBy, createdDate, updatedDate, status, query }) => {

  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);



  const deleteProduct = useMutation(
    (delProductId) => delTakeOff(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["take_off_time"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  const statusChange = useMutation(
    (changeSts) => stsChangeTakeOff(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["take_off_time"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
    <Draggable draggableId={id} index={index} key={id}>
    {(provided) => (
      <div className="take-off-item mb-3"
        key={id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="take-off-item-row">
          <div className="take-off-content">
            <div className="take-off-details">
              <span>TIME</span>
              <div className="take-off-title">{name}</div>
            </div>
          </div>
          <div className="take-off-options">
            <div className={"status " + (status ? "published" : "draft")}>{status ? "published" : "draft"}</div>
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={() => {
                    setEditModal(true);
                    setEditId(id);
                  }}>
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                <button type="button" className="dropdown-item" onClick={() => statusChange.mutate({
                  id: id,
                  status: status ? false : true,
                })}>
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{status ? "Draft" : "Published"}
                </button>
                <button type="button" className="dropdown-item standOut" onClick={() => setDeleteModal(true)}>
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {(createdBy || createdDate || updatedDate) && (
          <div className="take-off-item-row border-t">
            <div className="take-off-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="take-off-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      )}
      </Draggable>
           <Confirm
             icon={"fa-regular fa-xmark"}
             iconColorClass={"bg-danger"}
             title={"Delete"}
             text={"Are you really want to delete this?"}
             isOpen={deleteModal}
             confirmClose={() => setDeleteModal(false)}
             confirmSubmit={() => {
               deleteProduct.mutate({ id: id });
             }}
           />

    </>
  );
};

export default TakeOffTimeItem;
