import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import SelectTag from "../Form/SelectTag";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { hostUrl } from "../../config/host";
import axios from "axios";
import { toast } from "react-toastify";

const AddAddons = ({modalClose,queryProduct,setProducts,productsData,productDetail,addongroupId, combo, setCombo}) => {
  const [title, setTitle] = useState("");
  const [productId, setProductId] = useState(false);
  const [regular_price, setRegular_price] = useState("0");
  const [sale_price, setSale_price] = useState("");
  const [price_point, setPrice_point] = useState("");
  const [price_point_disble, setPrice_point_disable] = useState(false);
  const [plu_code, setPlu_code] = useState(Date.now());
  const [description, setDescription] = useState("");
  const [featured, setFeatured] = useState(false);
  const [in_stock, setIn_stock] = useState(1);
  const [diet_id, setDiet_id] = useState("633eb0f81a0c7d11f15318c6");
  const [status, setStatus] = useState(1); //
  const [product_category_id, setProduct_category_id] = useState([]);
  const [allergie_id, setAllergie_id] = useState([]);
  const [spice_level_id, setSpice_level_id] = useState([]);
  const [addon_id, setAddon_id] = useState([]);



  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [selectedAllergieOpt, setSelectedAllergieOpt] = useState([]);
  const [selectedAddonOpt, setSelectedAddonOpt] = useState([]);
  const [selectedSpiceOpt, setSelectedSpiceOpt] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [dietData, setDietData] = useState([]);

  const [publishedData, setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);


  function useDiet() {
    return useQuery({
      queryKey: ["diets_radio"],
      keepPreviousData: true,
      queryFn: async () => {
        let tmp = [];
        const { data } = await axios.post(
          `${hostUrl}/product_ingredients/list`,
          {
            status: true,
            type: 3,
            limit: 9999,
          },
          {}
        );
        data.data.forEach((item) => {
          if (item.title === "Non veg") {
            tmp.push( {_id:item._id, label:item.title, inputName:'diet', current:true });
          } else if (item.title === "Veg") {
            tmp.push( {_id:item._id, label:item.title, inputName:'diet', current:true });
          } else if ((item.title === "Vegan")) {
            tmp.push( {_id:item._id, label:item.title, inputName:'diet', current: true });
          } else {
            tmp.push( {_id:item._id, label:item.title, inputName:'diet', current: false });
          }
        });
           
        return tmp;
      },
    });
  }
  const { data: diets } = useDiet();
 
  


  useEffect(() => {
   
    let a = [];
    diets &&
      diets.forEach((item) => {
        if (item._id !== "") {
          a.push({ _id: item._id, label: item.label, inputName: "diet" });
        }
      });
    setDietData(a);
  }, [diets]);
  const queryClient = useQueryClient();

useEffect(() => {
  if(productDetail){
      setProductId(productDetail ? productDetail._id : "");
      setTitle(productDetail ? productDetail.title : "");
      setDescription(productDetail ? productDetail.short_description : "");
      setRegular_price(productDetail ? productDetail.regular_price : "");
      setIn_stock(productDetail ? productDetail.in_stock : true);
      setUploadData(productDetail && productDetail.image ? [{ _id: 1, image: productDetail.image }] : []);
      setStatus(productDetail ? productDetail.status : 1);
      setDiet_id(productDetail && productDetail.diet_details? productDetail.diet_details?._id : "")
      let tmpCat = []
      let tmpCatID = []

      productDetail && productDetail.category_list?.forEach((item) => {
        tmpCatID.push(item._id);
        tmpCat.push({label: item.title, value: item._id});
      });

      
      
      let tmpSpice = []
      let tmpSpiceID = []

      productDetail && productDetail.spice_level_list?.forEach((item) => {
        tmpSpiceID.push(item._id);
        tmpSpice.push({label: item.title, value: item._id});
      });

      let tmpAllergie = []
      let tmpAllergieID = []

      productDetail && productDetail.allergie_list?.forEach((item) => {
        tmpAllergieID.push(item._id);
        tmpAllergie.push({label: item.title, value: item._id});
      });
      setSelectedCatOpt(tmpCat);
      setProduct_category_id(tmpCatID);
      
      setSelectedSpiceOpt(tmpSpice);
      setSpice_level_id(tmpSpiceID);

      setSelectedAllergieOpt(tmpAllergie);
      setAllergie_id(tmpAllergieID);
      let a = [];
      diets &&
        diets.forEach((item) => {
          if (item._id !== "") {
            a.push({ _id: item?._id, label: item?.label, inputName: "diet", current: productDetail?.diet_details?.title === item.label ? true : false });
          }
        });
        console.log(a);
        setDietData(a);
        console.log(a);
        setTimeout(() => {
          if(productDetail !== undefined){
          setPublishedData(
            productDetail
              ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: productDetail && productDetail.status === 1 ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: productDetail && productDetail.status === 2 ? true : false,
                },
              ]
              : []
          );
            }
        }, 600)

  }
  
}, [productDetail]);

 
  function useAllergy() {
    return useQuery({
      queryKey: ["allergy_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await axios.post(
          `${hostUrl}/product_ingredients/list`,
          {
            status: true,
            type: 1,
            limit: 9999,
          },
          {}
        );

        data.data.forEach((item) => {
          tmp.push({ value: item._id, label: item.title });
        });

        return tmp;
      },
    });
  }
  const { data: allergies } = useAllergy();
 
  const allergy = allergies;
  function useSpice() {
    return useQuery({
      queryKey: ["spice_level_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await axios.post(
          `${hostUrl}/product_ingredients/list`,
          {
            status: true,
            type: 2,
            limit: 9999,
          },
          {}
        );

        data.data.forEach((item) => {
          tmp.push({ value: item._id, label: item.title });
        });

        return tmp;
      },
    });
  }

  const { data: spices } = useSpice();
  const spiceLvl = spices;

  function generatePlu_code() {
    setPlu_code(Date.now());
  }
  let token = localStorage.getItem('access_token');
  const config = {

    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    },
  };

  const submitProduct = useMutation(
    (newProduct) =>
      axios
        .post(
          `${hostUrl}/v2/products/create`,
          newProduct,
          config
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
        
          const push = [{ "_id" : res.data._id,"title":res.data.title}];
          setProducts([...productsData,...push]);
          setTitle("");
          setRegular_price("");
          setSale_price("");
          setPrice_point("");
          setPlu_code(Date.now());
          setDescription("");
          setFeatured(false);
          setIn_stock(1);
          setDiet_id("");
          setStatus(1);
          setProduct_category_id([]);
          setAllergie_id([]);
          setSpice_level_id([]);
          setAddon_id([]);
          setUploadData([])
          setSelectedAllergieOpt([])
          setSelectedSpiceOpt([])
          modalClose()
          toast.success(res.message);
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const submitEditedProduct = useMutation(
    (editProduct) =>
      axios
        .post(
          `${hostUrl}/v2/products/edit`,
          editProduct,
          config
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          let updateData = productsData.map(u => u._id !== res.data._id ? u :  res.data);
         
          setProducts(updateData);
          setProductId(false)
          setTitle("");
          setRegular_price("");
          setUploadData([])
          setSale_price("");
          setPrice_point("");
          setPlu_code(Date.now());
          setDescription("");
          setFeatured(false);
          setIn_stock(1);
          setDiet_id("");
          setStatus(1);
          setProduct_category_id([]);
          setAllergie_id([]);
          setSelectedSpiceOpt([])
          setSpice_level_id([]);
          setAddon_id([]);
          setSelectedAllergieOpt([])
          modalClose();
          toast.success(res.message);
        }else{
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">

            <div className="row">
              <div className="col-md-7">
                <div className="mb-3">
                <Input
                      state={title}
                      setState={setTitle}
                      label={"Product Name"}
                      inputRequired={true}
                      inputType={"text"}
                      inputPlaceholder={"Example . Pizza"}
                      inputName={""}
                    />
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                <Input
                      state={plu_code}
                      setState={setPlu_code}
                      label={"PLU Code"}
                      inputRequired={true}
                      inputType={"text"}
                      inputPlaceholder={"1667303409038"}
                      inputName={""}
                      labelButtonName={"Generate"}
                      labelButtonFunction={generatePlu_code}
                    />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                <Input
                      state={regular_price}
                      setState={setRegular_price}
                      label={"Regular Price"}
                      inputRequired={true}
                      inputType={"number"}
                      inputPlaceholder={"€ 250"}
                      inputName={""}
                      isDisabled={combo}
                    />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                {dietData && <RadiosLabel
                      setRadio={setDiet_id}
                      data={dietData}
                      label={"Diet"}
                      inputRequired={false}
                    />}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                <Textarea
                      state={description}
                      setState={setDescription}
                      label={"Description"}
                      inputRequired={true}
                      inputPlaceholder={"Write description......."}
                      inputName={""}
                    />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-4">
                {publishedData && (
                      <RadiosLabel
                        setRadio={setStatus}
                        data={publishedData}
                        setData={setPublishedData}
                        label={"Status"}
                        inputRequired={true}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                {productId && <Button label={'Update'}  functions={() =>
                        submitEditedProduct.mutate({
                          id: productId,
                          title: title,
                          product_type: 2,
                          plu_code: plu_code,
                          regular_price: parseInt(regular_price),
                          short_description: description,
                          featured: featured,
                          status: status,
                          addon_group_id:addongroupId,
                          in_stock: 1,
                          image: uploadData && uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image,
                          allergie_id: allergie_id ? allergie_id : null,
                          spice_level_id: spice_level_id
                            ? spice_level_id
                            : null
                           })
                      }/>}
                  {!productId && <Button label={'Save'}  functions={() =>
                        submitProduct.mutate({
                          title: title,
                          product_type: 2,
                          plu_code: plu_code,
                          regular_price: parseInt(regular_price),
                          short_description: description,
                          featured: featured,
                          status: status,
                          addon_group_id:addongroupId,
                          in_stock: 1,
                          image: uploadData ? uploadData[0]?.image : null,
                          diet_id: diet_id ? diet_id : null,
                          allergie_id: allergie_id ? allergie_id : null,
                          spice_level_id: spice_level_id
                            ? spice_level_id
                            : null
                           })
                      }/>}
                  <Button label={'Cancel'} functions={()=>modalClose()} classes={'main-button-alt'}/>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-5">

            <div className="row">
              <div className="col">
              <ImageUploadPreview
                        setImage={setUploadData}
                        data={uploadData}
                        label={"Upload Image"}
                        inputRequired={false}
                      />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-4">
                <SelectTag
                          selectedOptions={selectedAllergieOpt}
                          setSelectedOptions={setSelectedAllergieOpt}
                          setStateId={setAllergie_id}
                          data={allergy}
                          placeholder={"Example: Allergies"}
                          label={"Allergies"}
                          inputRequired={false}
                          isCheckbox={false}
                          checkboxPosition={"right"}
                        />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/* <div className="mb-4">
                <SelectTag
                        selectedOptions={selectedSpiceOpt}
                        setSelectedOptions={setSelectedSpiceOpt}
                          setStateId={setSpice_level_id}
                          data={spiceLvl}
                          placeholder={"Example: Spice Level"}
                          label={"Spice Level"}
                          inputRequired={false}
                          isCheckbox={false}
                          checkboxPosition={"right"}
                        />
                </div> */}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAddons;