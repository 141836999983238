import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import TopNavigation from "../../components/Navigation/TopNavigation";
import { setTitle } from '../../helpers/MetaTag';
import AllOrders from "./AllOrders";
import LiveOrders from "./LiveOrders";
import OrderList from "./OrdersList";
import TakeOffTime from "./TakeOffTime";
import DeliverySettings from "./DeliverySettings";
const Orders = () => {
  setTitle('Dabbawala | Orders');
  const [currentLocation, setCurrentLocation] = useState();

  const location = useLocation();
  const tabData = [
    {_id:1, label:'New Order', link:'/orders', search:'?tab=list' , component: <OrderList/>},
    {_id:2, label:'All Orders', link:'/orders', search:'?tab=all' , component: <AllOrders/>},
    {_id:3, label:'Live Orders', link:'/orders', search:'?tab=live' , component: <LiveOrders/>},
    {_id:4, label:'Delivery Settings', link:'/orders', search:'?tab=settings' , component: <DeliverySettings/>},
    {_id:5, label:'Take of Time', link:'/orders', search:'?tab=take-of-time', component: <TakeOffTime/>},
  ]

  useEffect(()=>{
    let index = tabData.findIndex((tab) => tab.search === location.search)
    if(index !== -1){
      setCurrentLocation(tabData[index]);
    }
  },[location]);

  return (
    <>
     <TopNavigation data={tabData}/>
      <div className="page-content">
      {currentLocation &&
          currentLocation.component
        }
      </div>
    
    </>
  );
};

export default Orders;
