import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { setTitle } from '../../helpers/MetaTag';
import Image from "../../components/Elements/Image";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import { login } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

const Login = () => {
  setTitle('Dabbawala | Signin');

  const navigate = useNavigate();

  const [isForgot, setIsForgot] = useState(false);
  const [email, setEmail] = useState('admin@dabbawala.com');
  const [password, setPassword] = useState('admin@123');

  

  async function handleSubmit(e) {
    e.preventDefault();
    login({
      email: email,
      password: password
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        localStorage.setItem('access_token', res.data.access_token);
        //navigate('/dashboard');
        window.location.href = '/dashboard?tab=revenue'
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    })
  }

  // const { mutate: handleSubmit } = useMutation(
  //   (payload) => login(payload),
  //   {
  //     onSuccess: (res) => {
  //       console.log(res.data.access_token);
  //       localStorage.setItem('access_token', res.data.access_token);
  //       window.location.reload();
  //       if (res.status === 200) {
  //         toast.success(res.data.access_token);
  //         navigate('/dashboard')
  //       }
  //       if (res.status === 400) {
  //         toast.error(res.message);
  //       }
  //     },
  //     onError: (res) => {
  //       console.log(res);
  //     },
  //   }
  // );

  const forgotPassword = () =>{
    setIsForgot(!isForgot);
  }


  return (
    <>
      <div className="authentication-content">
        <div className="brand">
          <Link to="/" className="logo">
            <Image src={'./logo.svg'} alt={'Dabbawala'} width={'100%'} height={'100%'} effect={'blur'} />
          </Link>
        </div>
        {isForgot ? (
          <>
            <div className="authentication-inner">
              <div className="authentication-title">Forgot Password?</div>
              <div className="authentication-text">Enter your email address to get reset link!</div>
              <form className="mt-5">
                <div className="mb-3">
                  <Input
                    state={email}
                    setState={setEmail}
                    label={'Email'}
                    inputRequired={true}
                    inputType={'email'}
                    inputPlaceholder={'Example: admin@dabbawala.com'}
                    inputName={''}
                  />
                </div>
                <div className="mb-3">
                  <Button 
                    label={'Reset Password'} 
                    classes={'w-100'} 
                    icon={'fa-light fa-key'} 
                    iconPosition={'left'} 
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button label={'Signin'} classes={'main-button-link text-color-02'} labelClasses={'text-sm'} functions={forgotPassword}/>
                </div>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className="authentication-inner">
              <div className="authentication-title">Welcome Back</div>
              <div className="authentication-text">Signin with your credentials to continue</div>
              <form className="mt-5">
                <div className="mb-3">
                  <Input
                    state={email}
                    setState={setEmail}
                    label={'Email'}
                    inputRequired={true}
                    inputType={'email'}
                    inputPlaceholder={'Example: admin@dabbawala.com'}
                    inputName={''}
                  />
                </div>
                <div className="mb-3">
                  <Input
                    state={password}
                    setState={setPassword}
                    label={'Password'}
                    inputRequired={true}
                    inputType={'password'}
                    inputPlaceholder={'**********'}
                    inputName={''}
                  />
                </div>
                <div className="mb-3">
                  <Button
                    functions={handleSubmit}
                    label={'Signin'} 
                    classes={'w-100'} 
                    icon={'fa-light fa-arrow-right-to-arc'} 
                    iconPosition={'left'} 
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button 
                  label={'Forgot Password'} 
                  classes={'main-button-link text-color-02'} 
                  labelClasses={'text-sm'} 
                  functions={forgotPassword}
                />
                </div>
              </form>
            </div>
          </>
        )}

      </div>
      
    </>
  );
};

export default Login;