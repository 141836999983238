import React, { useState, useEffect } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTakeOff, editTakeOff } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const AddTakeOffTime = ({
  modalClose,
  query,
  details,
  isFetching
}) => {

  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    { _id: true, label: "Published", inputName: "publish_status", current: true },
    { _id: false, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(true)
  const [title, setTitle] = useState("");
  const [uploadData, setUploadData] = useState([]);

  const { mutate: handleSubmit } = useMutation(
    (payload) => addTakeOff(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["diets"],
            type: "inactive",
          });
          setTitle("");
          setStatus(true);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => editTakeOff(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["take_off_time"],
            type: "inactive",
          });
          setTitle("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if(details) {
      setTitle(details ? details.time : "");
      setStatus(details ? details.status : "");
      setPublishedData([]);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
              {
                _id: true,
                label: "Published",
                inputName: "publish_status",
                current: details.status.toString() === 'true' ? true : false,
              },
              {
                _id: false,
                label: "Draft",
                inputName: "publish_status",
                current: details.status.toString() === 'false' ? true : false,
              },
            ]
            : []
        );
  
      }, 300)
    }
  }, [details]);


  //console.log(typeof title, status);


  if(isFetching){
    return(
      <div>Loading...</div>
    )
  }
  

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input label={"Time"} inputRequired={true} inputType={"time"} inputPlaceholder={"Example . 15:30 PM"} inputName={""} 
                    state={title}
                    setState={setTitle}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel data={publishedData} setData={setPublishedData} setRadio={setStatus} label={"Status"} inputRequired={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {details ? <Button label={"Save"} 
                    functions={() => handleEdit({
                      id: details._id,
                      time: title,
                      status: status,
                    })}
                  /> : <Button label={"Save"} 
                    functions={() => handleSubmit({
                      time: title,
                      status: status,
                    })}
                  />}
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={modalClose} />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AddTakeOffTime;
