import { useEffect, useState } from "react";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import CategoryItems from "../../components/Items/CategoryItems";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import AddCategory from "../../components/Modals/AddCategory";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import { toast } from "react-toastify";
import Pagination from "../../components/Elements/Pagination";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ordering, ProductdetailsCategories, ProductlistCategories } from "../../helpers/api_helper";
import Select from "react-select";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";

const Categories = () => {
 // Query Client instance
 const queryClient = useQueryClient();

 // For Managing Modals
 const [isAddModal, setIsAddModal] = useState(false);
 const [isEditModal, setIsEditModal] = useState(false);

 const [isOne, setDataOne] = useState(false);

 // For pagination
 const [perPages, setPerPages] = useState([
   { label: "10 (Default)", value: 10 },
   { label: 20, value: 20 },
   { label: 50, value: 50 },
   { label: 100, value: 100 },
   { label: "All", value: 999999 },
 ]);
 const [selectedPageOpt, setSelectedPageOpt] = useState([]);

 //Data
 const [dataList, setDataList] = useState([]);
 
 // For Filtering Data 🔍
 const [keyword, setKeyword] = useState("");
 const [limit, setLimit] = useState(10);
 const [offset, setOffset] = useState(0);
 const [status, setStatus] = useState("");

 // To fetch Details of specific items when it needs to be edited
 const [editId, setEditId] = useState("");

 // Static status Radio buttons
 const [statusStatic, setStatusStatic] = useState([
   { _id: "", name: "All", color: "success", current: true },
   { _id: true, name: "Published", color: "success", current: false },
   { _id: false, name: "Draft", color: "warning", current: false },
 ]);


 // Dynamic query key for product categories 🗝️
 const queryCategory = ["categories", keyword, status, limit, offset];

 // For opening Add Modal althogh not mandetory
 const addModal = () => {
   setIsAddModal(true);
 };

 // For fetching details of item
 const {
   data: details,
   error,
   refetch,
   isFetching,
 } = useQuery({
   queryKey: ["details", editId],
   queryFn: async () => ProductdetailsCategories({ id: editId }),
   enabled: false,
 });

 // Refetch when editId updates
 useEffect(() => {
   if (editId !== "" && isEditModal) {
     refetch();
   }
 }, [editId, refetch, isEditModal]);

 // Category List Component
 function CategoryList() {
   // Custom hook for fetching Category list
   function useCategory() {
     return useQuery({
       queryKey: queryCategory,
       queryFn: () =>
       ProductlistCategories({
           keyword: keyword,
           sortByField: "ordering",
           sortByValue: 1,
           status: status,
           limit: limit,
           offset: offset,
         }),
       keepPreviousData: true,
     });
   }

   const { data: categories, error, isFetching } = useCategory();

   useEffect(() => {
     if (!isOne) {
       setDataList(categories?.data);
     }
   }, [isOne, categories?.data]);

   const handleDragEnd = (e) => {
     //console.log(e);
     if (!e.destination) return;
     let tempData = Array.from(dataList);
     let [source_data] = tempData.splice(e.source.index, 1);
     tempData.splice(e.destination.index, 0, source_data);
     // console.log(tempData);
     setDataList([...tempData]);
     let dataArray = [];
     for (let i = 0; i < tempData.length; i++) {
       dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
     }
     // setOrderingData(dataArray);
     onClickSaveOrdering(dataArray);
     setDataOne(true);
   };
   const onClickSaveOrdering = async (orderingData) => {
     const saveOrdering = await ordering({
       orderingData: orderingData,
       tableName: "product_category",
     });

     if (saveOrdering.status === 200) {
       setDataOne(false);
       queryClient.invalidateQueries(queryCategory);
       queryClient.removeQueries({
         queryKey: ["categories"],
         type: "inactive",
       });
       toast.success(saveOrdering.message);
     } else {
       toast.error(saveOrdering.message);
     }
   };


    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error />;
    } else if (categories.data.length === 0) {
      return <NoData />;
    } else {
      return (
        <>
       
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={"div"}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {!isOne && dataList &&
                    dataList.length > 0 &&
                    dataList.map((item, index) => (
                      <CategoryItems
                        key={item._id}
                        index={index}
                        categoryId={item._id}
                        image={item.image}
                        name={item.title}
                        descriptions={item.description}
                        createdDate={moment(new Date(item.created_at)).format(
                          "Do MMM YY"
                        )}
                        status={item.status}
                        queryCategory={queryCategory}
                        detailsFetch={fetch}
                        setEditModal={setIsEditModal}
                        setEditId={setEditId}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          
          <Pagination
            count={categories.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>

          <div className="input-items">
            <Search
              placeholder={"Search..."}
              state={keyword}
              setState={setKeyword}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                setKeyword("");
                setLimit(10);
                setSelectedPageOpt([])
                queryClient.invalidateQueries(queryCategory);
                queryClient.removeQueries({
                  queryKey: ["categories"],
                  type: "inactive",
                });
              }}
            />
            <Button
              label={"Add"}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="radio-filters">
          <RadioGroup
            data={statusStatic}
            setData={setStatus}
            inputName={"status"}
          />
        </div>
      </div>

      <div className="category-list">
        <CategoryList/>
      </div>

      <Modal
        title={"Add Category"}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(false)}
      >
        <AddCategory
          modalClose={() => setIsAddModal(false)} query={queryCategory}
        />
      </Modal>
      <Modal
        title={"Edit Category"}
        size={"xl"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(false)}
      >
        <AddCategory
           modalClose={() => setIsEditModal(false)}
           query={queryCategory}
           details={details}
           isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default Categories;
