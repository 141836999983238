import React, { useEffect, useState } from 'react';
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select from 'react-select';
import { addCoupon, editCoupon } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

const AddCoupon = ({
  modalClose,
  query,
  details,
  isFetching,
}) => {

  const queryClient = useQueryClient();


  const users = queryClient.getQueryData(["user_dropdown"]);

  const [user_id, setUser_id] = useState("");
  const [code, setCode] = useState("");
  const [discount_type, setDiscount_type] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [min_spend_amount, setMin_spend_amount] = useState("");
  const [max_spend_amount, setMax_spend_amount] = useState("");
  const [use_limit, setUse_limit] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [status, setStatus] = useState(1);

  const [selectedUserOpt, setSelectedUserOpt] = useState([]);
  const [userId_arr, setUserId_arr] = useState("");


  const [publishedData, setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);

  const [discount_typeOpt, setDiscount_typeOpt] = useState([
    { label: "%", value: 1 },
    { label: "€", value: 2 },
  ]);

  const [selTyp, setSelTyp] = useState([]);

  const submitCoupon = useMutation(
    (payload) => addCoupon(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["coupons"],
            type: "inactive",
          });
          modalClose();
          setCode("");
          setDescription("");
          setAmount("");
          setStatus(true);
          setSelectedUserOpt([]);
          setStart_date("");
          setEnd_date("");
          setUse_limit("");
        }

        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const submitEditedCoupon = useMutation(
    (payload) => editCoupon(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["coupons"],
            type: "inactive",
          });
          setCode("");
          setDescription("");
          setSelectedUserOpt([]);
          setAmount("");
          setStatus(true);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  useEffect(() => {
    if (details) {
      setUser_id(details ? details.user_id : "");
      setCode(details ? details.code : "");
      setStatus(details ? details.status ? 1 : 2 : 1);
      setStart_date(details ? details.start_date.substring(0, 10) : "");
      setEnd_date(details ? details.end_date.substring(0, 10) : "");
      setDescription(details ? details.description : "");
      setUse_limit(details ? details.use_limit : "");
      setMax_spend_amount(details ? details.max_spend_amount : "");
      setMin_spend_amount(details ? details.min_spend_amount : "");
      setAmount(details ? details.amount : "");
      setDiscount_type(details ? details.discount_type : "");
      setPublishedData([]);

      setSelectedUserOpt(users.find((item) => {
        return item.value === details.user_id
      }))

      setTimeout(() => {
        setPublishedData(
          details
            ? [
              {
                _id: 1,
                label: "Published",
                inputName: "publish_status",
                current: details.status.toString() === 'true' ? true : false,
              },
              {
                _id: 2,
                label: "Draft",
                inputName: "publish_status",
                current: details.status.toString() === 'false' ? true : false,
              },
            ]
            : []
        );

        setSelTyp(
          details ? details.discount_type === 1 ? [{ label: "%", value: 1 }] : [{ label: "€", value: 2 }]
            : []
        );

      }, 600)
    }



  }, [details])


  if (isFetching) {
    return (
      <div>Loading...</div>
    )
  }

  console.log(user_id);

  return (
    <>
      <div className="px-2">
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">

              <div className="mb-3">
                <div className="input">
                  <div className="input-options">
                    <div
                      className="input-label">
                      Users
                    </div>
                  </div>
                  <Select
                    className="select-tag"
                    isMulti={false}
                    isClearable={true}
                    options={users}
                    value={selectedUserOpt || []}
                    placeholder={'All User'}
                    onChange={(e) => {
                      console.log(e);
                      setUser_id(e.value);
                      setSelectedUserOpt(e);
                    }}
                  />
                </div>
              </div>
              {/* <Input
                state={user_id}
                setState={setUser_id}
                label={'User ID'}
                inputRequired={true}
                inputType={'text'}
                inputPlaceholder={'User ID'}
                inputName={''}
              /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <Input
                state={code}
                setState={setCode}
                label={'Coupon Code'}
                inputRequired={true}
                inputType={'text'}
                inputPlaceholder={'Coupon Code'}
                inputName={''}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mb-3">
              <div className="input">
                <div className="input-options">
                  <div
                    className="input-label">
                    Discount Type
                  </div>
                </div>
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={discount_typeOpt}
                  value={selTyp || []}
                  placeholder={'€ or %'}
                  onChange={(e) => {
                    console.log(e);
                    setDiscount_type(e.value);
                    setSelTyp(e);
                  }}
                />
              </div>
              {/* <Input
                state={discount_type}
                setState={setDiscount_type}
                label={'Discount Type'}
                inputRequired={true}
                inputType={'text'}
                inputPlaceholder={'€ or %'}
                inputName={''}
              /> */}
            </div>
          </div>
          <div className="col-lg-9">
            <div className="mb-3">
              <Input
                state={amount}
                setState={setAmount}
                label={'Amount'}
                inputRequired={true}
                inputType={'number'}
                inputPlaceholder={'€ 150'}
                inputName={''}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mb-3">
              <Input
                state={min_spend_amount}
                setState={setMin_spend_amount}
                label={'Min Spent Amount'}
                inputRequired={false}
                inputType={'number'}
                inputPlaceholder={''}
                inputName={''}
              />
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="mb-3">
              <Input
                state={max_spend_amount}
                setState={setMax_spend_amount}
                label={'Max Spent Amount'}
                inputRequired={true}
                inputType={'number'}
                inputPlaceholder={''}
                inputName={''}
              />
            </div>
          </div> */}
          <div className="col-lg-4">
            <div className="mb-3">
              <Input
                state={use_limit}
                setState={setUse_limit}
                label={'Limit'}
                inputRequired={true}
                inputType={'number'}
                inputPlaceholder={''}
                inputName={''}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <Input
                state={start_date}
                setState={setStart_date}
                label={'Valid From'}
                inputRequired={true}
                inputType={'date'}
                inputPlaceholder={''}
                inputName={''}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <Input
                state={end_date}
                setState={setEnd_date}
                label={'Valid Through'}
                inputRequired={true}
                inputType={'date'}
                inputPlaceholder={''}
                inputName={''}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mb-3">
              <Textarea
                state={description}
                setState={setDescription}
                label={'Coupon Description'}
                inputRequired={false}
                inputPlaceholder={'Write description.......'}
                inputName={''}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mb-4">
              <RadiosLabel setRadio={setStatus} data={publishedData} setData={setPublishedData} label={'Status'} inputRequired={true} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              {details ? <Button label={'Save'}
                functions={() => {
                  submitEditedCoupon.mutate({
                    id: details._id,
                    code: code,
                    description: description,
                    discount_type: parseInt(discount_type),
                    amount: amount,
                    start_date: new Date(start_date),
                    end_date: new Date(end_date),
                    user_id: user_id,
                    min_spend_amount: parseInt(min_spend_amount),
                    //max_spend_amount: parseInt(max_spend_amount),
                    use_limit: use_limit,
                    status: status === 1 ? true : false
                  })
                }}
              /> : <Button label={'Save'}
                functions={() => {
                  submitCoupon.mutate({
                    code: code,
                    description: description,
                    discount_type: parseInt(discount_type),
                    amount: amount,
                    start_date: new Date(start_date),
                    end_date: new Date(end_date),
                    user_id: user_id === "" ? null : user_id,
                    min_spend_amount: parseInt(min_spend_amount),
                    //max_spend_amount: parseInt(max_spend_amount),
                    use_limit: use_limit,
                    status: status === 1 ? true : false
                  })
                }}
              />}
              <Button label={'Cancel'} classes={'main-button-alt'} functions={modalClose} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCoupon;