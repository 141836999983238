import { useEffect, useState } from 'react';
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import CouponItem from "../../components/Items/CouponItem";
import AddCoupon from "../../components/Modals/AddCoupon";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment/moment';

import Pagination from '../../components/Elements/Pagination';
import { detailsCoupon, listCoupon, userList } from '../../helpers/api_helper';
import Select from 'react-select';
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";



const Coupon = () => {

  const queryClient = useQueryClient();
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [selectedUserOpt, setSelectedUserOpt] = useState([]);
  const [userId_arr, setUserId_arr] = useState("");

  const [editId, setEditId] = useState("");


  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  const addModal = () => {
    setIsAddModal(true);
  }

  const queryCoupon = [
    "coupons",
    keyword, 
    limit, 
    offset, 
    status,
    userId_arr
  ];

  const { data: details, error, refetch, isFetching } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsCoupon({
      id: editId,
    }),
    enabled: false,
  });

  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);

  function useUsers() {
    return useQuery({
      queryKey: ["user_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await userList({ limit: 99999, role: "user", });
        tmp.push({ value: "", label: "All User" });
        data.forEach((item) => {
          tmp.push({ value: item._id, label: item.name });
        });

        return tmp;
      },
    });
  }


  const [perPages, setPerPages] = useState([
    { label:"10 (Default)",value:10 },
    { label:20,value:20 },
    { label:50,value:50 },
    { label:100,value:100 },
    { label:"All",value:999999  },
 ]);
 
  const { data: users } = useUsers();

  console.log(users);

  function Coupons() {
    function useCoupon() {
      return useQuery({
  
        queryKey: queryCoupon,
        queryFn: async () => listCoupon({
          keyword: keyword,
          type: 1,
          status: status,
          limit: limit,
          offset: offset,
          user_id: userId_arr
        }),
      });
    }
  
    const { data: coupons, error, isFetching } = useCoupon();

    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error />;
    } else if (coupons.data.length === 0) {
      return <NoData />;
    } else {
      return (
        <>
          {coupons.data.map((item) => (
              <CouponItem
                key={item?._id}
                couponId={item._id}
                name={item.code}
                description={item.description}
                addons={item?.addons}
                validFrom={moment(new Date(item.start_date)).format(
                  "Do MMM YY"
                )}
                validThrough={moment(new Date(item.start_date)).format(
                  "Do MMM YY"
                )}
                limit={item.use_limit}
                minSpent={item.min_spend_amount}
                maxSpent={item.max_spend_amount}
                createdDate={moment(new Date(item.start_date)).format(
                  "Do MMM YY"
                )}
                queryData={queryCoupon}
                status={item?.status}
                detailsFetch={refetch}
                setEditId={setEditId}
                setEditModal={setIsEditModal}
              />
            ))}
          <Pagination count={coupons.count} limit={limit} offset={offset} setOffset={setOffset} />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
        <div className="input-items">
              {perPages && 
              <div className="input">

                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={'Per Page'}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                 />
           </div>
             }
              </div>
              
          <div className="input-items">
            <Search 
              state={keyword}
              setState={setKeyword}
              placeholder={'Search...'}
            />

           
          </div>
          <div className="input-items">
              {users && 
              <div className="input">

                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={users}
                  value={selectedUserOpt || []}
                  placeholder={'All User'}
                  onChange={(e) => {
                    setUserId_arr(e.value);
                    setSelectedUserOpt(e);
                  }}
                 />
           </div>
              
              }
              </div>
         
          <div className="input-items items-end gap-3 ms-auto">
            <Button classes={'px-2'} icon={'fa-regular fa-arrow-rotate-right'} iconPosition={'left'}
              functions={() => {
                queryClient.invalidateQueries(queryCoupon);
                queryClient.removeQueries({
                  queryKey: ["coupons"],
                  type: "inactive",
                });
                setOffset(0);
                setKeyword("");
                setStatus("");
                setStatusStatic([]);
                setSelectedUserOpt([]);
                setLimit(10);
                setSelectedPageOpt([]);
                setTimeout(() => {
                  setStatusStatic([
                    { _id: "", name: "All", color: "success", current: true },
                    { _id: true, name: "Published", color: "success", current: false },
                    { _id: false, name: "Draft", color: "warning", current: false },
                  ]);
                }, 300);
              }}
            />
            <Button label={'Coupon'} icon={'fa-regular fa-plus'} iconPosition={'left'} functions={addModal}/>
          </div>
        </div>
        <div className="input-items">
            <RadioGroup setData={setStatus} data={statusStatic} inputName={'status'}/>
          </div>
      </div>
      <div className="coupon-list">
        <Coupons
          keyword={keyword}
          status={status}
          limit={limit}
          offset={offset}
          queryData={queryCoupon}
          setLimit={setLimit}
          setOffset={setOffset}
          refetch={refetch}
          setEditId={setEditId}
          setEditModal={setIsEditModal}
        />
      </div>
      <Modal title={'Create Coupon'} size={'sm'} isOpen={isAddModal} modalClose={() => setIsAddModal(false)}>
        <AddCoupon modalClose={() => setIsAddModal(!isAddModal)} query={queryCoupon}/>
      </Modal>

      <Modal title={'Edit Coupon'} size={'sm'} isOpen={isEditModal} modalClose={() => setIsEditModal(false)}>
        <AddCoupon 
          modalClose={() => setIsEditModal(false)} 
          query={queryCoupon}
          details={details}
          isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default Coupon;