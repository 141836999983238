import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import SelectTag from "../../components/Form/SelectTag";
import PreviewProduct from "../Elements/PreviewProduct";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BannersAdd, BannersEdit, pageList } from "../../helpers/api_helper";
import Select from "react-select";
import { toast } from "react-toastify";

const AddBanner = ({ data, status, modalClose,detailsFetch,query }) => {
  const categoryDropdown = [
    { value: 1, label: "Part Time" },
    { value: 2, label: "Full Time" },
  ];

  const [isPreview, setIspreview] = useState(false);
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')
  const [button_url, setButton_url] = useState('')
  const [editId, setEditId] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([]);

  // ===============================>

  const queryClient = useQueryClient();

  const publishedData = [
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ];
  const [previewData, setPreviewData] = useState([])

  useEffect(() => {
    if (status === false) {
      setEditId(data?._id)
      setPreviewData([{ image: data?.image }])
      setTitle(status === true ? '' : data?.title || '')
      setDescription(status === true ? '' : data?.description || '')
      setButton_url(status === true ? '' : data?.button_url || '')
      setOptions([{ label: data?.type, value: data?._id }])
    } else {
      setPreviewData([])
      setEditId('')
      setTitle('')
      setDescription('')
      setButton_url('')
      setOptions([])
    }
  }, [data,status])



  // page list call===================>

  function usePageListTitle() {
    return useQuery({
      queryKey: ['pagelistKey'],
      queryFn: async () => {
        let tmp = [];
        const { data } = await pageList({
          status: true,
          limit: 9999,
        });
        data?.forEach((item) => {
          tmp.push({ label: item.title, value: item._id });
        });
        return tmp;
      },
    });
  }

  const { data: pageTitle } = usePageListTitle();

  // add banner function===============>

  const addBanner = useMutation(
    (payLoad) => BannersAdd(payLoad),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          modalClose(!modalClose)
          setLoading(false)
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["banners"],
            type: "inactive",
          })
        } else {
          setLoading(false)
          toast.error(res.message);
        }
      }
    }
  )
  // edit banner function===============>
  const editBanner = useMutation(
    (payLoad) => BannersEdit(payLoad),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          modalClose(!modalClose)
          setLoading(false)
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["banners"],
            type: "inactive",
          })
        } else {
          setLoading(false)
          toast.error(res.message);
        }
      }
    }
  )

  // details save=========>

  const saveHandler = () => {
    setLoading(true)
    if (status) {
      addBanner.mutate({ title, description, button_url, image: previewData[0]?.image.includes('https') ? null : previewData[0]?.image, type: options.label, type_id: options.value })
    } else {
      editBanner.mutate({ id: editId, title, description, button_url, image: previewData[0]?.image.includes('https') ? null : previewData[0]?.image, type: options.label, type_id: options.value })
    }
  }

  // cancel section==========>

  const cancel = () => {
    modalClose(!modalClose)
  }

  if(detailsFetch){
    return <>loading...</>
  }


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="mb-3">
                <Input state={title} setState={setTitle} label={"Title"} inputRequired={true} inputType={"text"} inputPlaceholder={"Title"} inputName={""} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input state={button_url} setState={setButton_url} label={"Link"} inputRequired={true} inputType={"text"} inputPlaceholder={"Link"} inputName={""} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <div className={"input-label"}>
                  Select Status
                </div>
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={pageTitle}
                  value={options}
                  placeholder={"Select Page"}
                  onChange={(e) => {
                    setOptions(e)
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Textarea state={description} setState={setDescription} label={"Description"} inputRequired={true} inputPlaceholder={"Write description......."} inputName={""} />
              </div>
            </div>
            {/* <div className="row">
              <div className="mb-4">
                <RadiosLabel data={publishedData} label={"Status"} inputRequired={true} />
              </div>
            </div> */}
          </div>
          <div className="col-lg-5">
            {!isPreview ? (
              <>
                <div className="row">
                  <div className="col">
                    <ImageUploadPreview data={previewData} setImage={setPreviewData} label={'Upload Image'} inputRequired={true} />
                  </div>
                </div>

              </>
            ) : (
              <PreviewProduct data={previewData} previewClose={() => setIspreview(!isPreview)} />
            )}

          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button label={"Save"} loading={loading} functions={saveHandler} />
              <Button label={"Cancel"} classes={"main-button-alt"} functions={cancel} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBanner;