export const Loading = () => {
    return(
        <div className="noresult">
            <div className="text-center">
                <hr style={{ marginBottom: "40px", color: "red"}} />
                    {/* <div className="fas fa-yin-yang fa-spin fa-2x"> */}
                <div className="fad fa-spinner-third fa-spin" style={{color: "red", fontSize: "2.3em", animationDuration: "1s"}}>
                </div>
            </div>
        </div>
    )
}