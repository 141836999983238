import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Button from "../Form/Button";
import Textarea from "../../components/Form/Textarea";
import { addOrganization, editOrganization } from "../../helpers/api_helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const AddOrganization = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const { mutate: handleSubmit } = useMutation(
    (payload) => addOrganization(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["organization"],
            type: "inactive",
          });
          setTitle("");
          setDescription("");
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => editOrganization(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["organization"],
            type: "inactive",
          });
          setTitle("");
          setDescription("");
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (details) {
      setTitle(details ? details.title : "");
      setDescription(details ? details.description : "");

      // setTimeout(() => {
      //   setPublishedData(
      //     details
      //       ? [
      //         {
      //           _id: true,
      //           label: "Published",
      //           inputName: "publish_status",
      //           current: details.status.toString() === 'true' ? true : false,
      //         },
      //         {
      //           _id: false,
      //           label: "Draft",
      //           inputName: "publish_status",
      //           current: details.status.toString() === 'false' ? true : false,
      //         },
      //       ]
      //       : []
      //   );

      // }, 600)
    }
  }, [details]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Organization Name"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . ABC Organization"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <Textarea
                  state={description}
                  setState={setDescription}
                    label={"Description"}
                    inputRequired={true}
                    inputPlaceholder={"Write description......."}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel
                    data={publishedData}
                    label={"Status"}
                    inputRequired={true}
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                {details ? <Button label={"Save"} 
                  functions={() => handleEdit({
                    id: details._id,
                    type: 3,
                    title: title,
                    description: description,
                    // image: uploadData && uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image,
                  })}
                  /> : <Button label={"Save"} 
                  functions={() => handleSubmit({
                    type: 3,
                    title: title,
                    description: description,
                    // image: uploadData ? uploadData[0]?.image : null,
                  })}
                  />}
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrganization;
