import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/Form/Button";
import DashboardStatItem from "../../components/Elements/DashboardStatItem";
import DashboardNavigation from "../../components/Navigation/DashboardNavigation";
import RevenueChart from "./RevenueChart";
import { customerDashboardData, deliveryPickup, orderData, pointSection, postCodeData, productSection, riderData, userData } from "../../helpers/api_helper";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

const Dashboard = () => {
  setTitle("Dabbawala | Dashboard");
  const qureryClient = useQueryClient()

  const [currentLocation, setCurrentLocation] = useState();
  const [totalCancelled, setTotalCancelled] = useState({ total: "0", tooBusy: "0", lowDeliveryStuff: "0", customer: "0" });
  const [totalPoint, setTotalPoint] = useState({  total: "0", spendCharity: "0", spendFood: "0", spendMerch: "0", spendCoupon: "0"});
  const [sorting, setSorting] = useState("");
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("week").format("YYYY-MM-DD"),
    end: moment().endOf("week").format("YYYY-MM-DD"),
  });
  const [active,setActive] = useState(true);

  const location = useLocation();
  const tabData = [
    { _id: 1, label: 'Revenue', link: '/dashboard', search: '?tab=revenue', component: <RevenueChart /> },
    { _id: 2, label: 'Number of orders', link: '/dashboard', search: '?tab=number-of-orders', component: <RevenueChart /> },
    { _id: 3, label: 'Average order value', link: '/dashboard', search: '?tab=avaerage-order', component: <RevenueChart /> },

  ]

  useEffect(() => {
    let index = tabData.findIndex((tab) => tab.search === location.search)
    if (index !== -1) {
      setCurrentLocation(tabData[index]);
    }
  }, [location]);




  const [statData, setStatData] = useState([
    {
      _id: "1",
      data: "83",
      icon: <i className="fa-light fa-box"></i>,
      label: "Total orders",
      metaData: [],
    },
    {
      _id: "2",
      data: "250",
      icon: <i className="fa-light fa-box-circle-check"></i>,
      label: "Accepted orders",
      metaData: [
        { label: "Pickup", data: "150" },
        { label: "Delivery", data: "100" },
      ],
    },
    {
      _id: "3",
      data: "€ 158",
      icon: <i className="fa-light fa-filter-circle-dollar"></i>,
      label: "Gross revenue",
      metaData: [],
    },
    {
      _id: "4",
      data: "€ 158",
      icon: <i className="fa-light fa-chart-mixed"></i>,
      label: "Average order value",
      metaData: [],
    },
  ]);


  const queryOrderData = ['order_data', dateRange]
  const queryPostCodeData = ['postcode_data', dateRange]
  const queryUserData = ['user_data', dateRange]
  const queryRiderData = ['rider_data', dateRange]
  const queryProductData = ['product_data', dateRange]
  const queryCustomerData = ['customer_data', dateRange]


  function useOrderData() {
    return useQuery({
      queryKey: queryOrderData,
      queryFn: () =>
        orderData({
          store_id: "",
          dateRange
        }),
      keepPreviousData: true,
    });
  }

  function usePostData() {
    return useQuery({
      queryKey: queryPostCodeData,
      queryFn: () =>
      postCodeData({
          dateRange
        }),
      keepPreviousData: true,
    });
  }

  function useUserData() {
    return useQuery({
      queryKey: queryUserData,
      queryFn: () =>
      userData({
        limit: 10,
          dateRange
        }),
      keepPreviousData: true,
    });
  }

  function useRiderData() {
    return useQuery({
      queryKey: queryRiderData,
      queryFn: () =>
      riderData({
          dateRange
        }),
      keepPreviousData: true,
    });
  }

  function useCustomerData() {
    return useQuery({
      queryKey: queryCustomerData,
      queryFn: () =>
      customerDashboardData({
          dateRange
        }),
      keepPreviousData: true,
    });
  }

  function useProductData() {
    return useQuery({
      queryKey: queryProductData,
      queryFn: () =>
      productSection({
        limit: 10,
        offset: 0,
        sortKey: "total_number_sold",
        sortValue: -1,
        dateRange
        }),
      keepPreviousData: true,
    });
  }


  const { data: orderDatas } = useOrderData();
  const { data: userDatas } = useUserData();
  const { data: riderDatas } = useRiderData();
  const { data: postDatas } =  usePostData();
  const { data: productDatas } =  useProductData();
  const { data: customerDatas } =  useCustomerData();
  //console.log(customerDatas);// userData useUserData useCustomerData

  useEffect(() => {
    async function loadData() {
      const data = await deliveryPickup({
        store_id: "",
        dateRange
      })

      const pointData = await pointSection({dateRange});

      // const productSec = await productSection({limit: 10, offset: 0 ,dateRange});

      // console.log(productSec);
      setTotalPoint({
        total: pointData.total_number_of_point, spendCharity: pointData.total_number_of_spend_on_charity, spendFood: pointData.total_number_of_spend_on_food, spendMerch: pointData.total_number_of_spend_on_merchandise, spendCoupon: pointData.spend_on_coupon_codes
      })
      

      let a = (((data?.total_delivery_order_count) / (data?.total_delivery_order_count + data?.total_pickup_order_count)) * 100).toFixed(2);
      let b = (((data?.total_pickup_order_count) / (data?.total_delivery_order_count + data?.total_pickup_order_count)) * 100).toFixed(2);

      setStatDelivery([
        {
          _id: 1,
          image: 'images/pick-up.svg',
          text: 'Delivery',
          orderData: `${data.total_delivery_order_count} (${a !== "NaN" ? a : '0.00'}%)` ,
          revenueData: `€  ${data.delevery_gross_amount.toFixed(2)}`,
        },
        {
          _id: 2,
          image: 'images/delivery.svg',
          text: 'Pick Up',
          orderData: `${data.total_pickup_order_count } (${b !== "NaN" ? b :"0.00"}%)` ,
          revenueData: `€  ${data.pickup_gross_amount.toFixed(2)}`,
        },
      ])
    }
    loadData();
  }, [dateRange])

  useEffect(() => {
    setStatData(orderDatas && [
      {
        _id: '1',
        data: orderDatas.total_orders,
        icon: <i className="fa-light fa-box"></i>,
        label: 'Total orders',
        metaData: [],
      },
      {
        _id: '2',
        data: orderDatas.total_accept_orders,
        icon: <i className="fa-light fa-box-circle-check"></i>,
        label: 'Accepted orders',
        metaData: [
          { label: 'Pickup', data: orderDatas.total_pickup_order_count },
          { label: 'Delivery', data: orderDatas.total_delivery_order_count },
        ],
      },
      {
        _id: '3',
        data: `€ ${orderDatas && orderDatas?.gross_amount}`,
        icon: <i className="fa-light fa-filter-circle-dollar"></i>,
        label: 'Gross revenue',
        metaData: [],
      },
      {
        _id: '4',
        data: `€ ${orderDatas && orderDatas?.average_order_amount}`,
        icon: <i className="fa-light fa-chart-mixed"></i>,
        label: 'Average order value',
        metaData: [],
      }

    ])
    setTotalCancelled({total: orderDatas && orderDatas?.cancel_order_count.toString(), tooBusy: orderDatas && orderDatas?.too_busy_count.toString(), lowDeliveryStuff: orderDatas && orderDatas?.low_delivery_staff.toString(), customer: orderDatas && orderDatas?.customer_cancelled_order.toString()})
  }, [orderDatas]);

  const customerData = [
    {
      _id: 1,
      mainTitle: 'Customers',
      number: (customerDatas?.total_number_of_customers && customerDatas?.total_lead_member) ? (customerDatas?.total_number_of_customers + customerDatas?.total_lead_member).toString() :'0',
      statotherdata: [
        { _id: 'otherData1', color: '#F14E2D', title: 'DDW Members', dataNumber: customerDatas?.total_number_of_customers ? customerDatas?.total_number_of_customers.toString() : "0" },
        { _id: 'otherData2', color: '#F29032', title: 'Leads', dataNumber: customerDatas?.total_lead_member ? customerDatas?.total_lead_member.toString() : "0"  },
      ],

    }
  ]
  const pointsData = [
    {
      _id: 1,
      mainTitle: 'Total Points',
      number: totalPoint.total,
      statotherdata: [
        { _id: 'otherData1', color: '#F14E2D', title: 'Spend on merchandise', dataNumber: totalPoint.spendMerch },
        { _id: 'otherData2', color: '#F29032', title: 'Spend on food', dataNumber: totalPoint.spendFood },
        { _id: 'otherData3', color: '#BF1E36', title: 'Spend on charity', dataNumber: totalPoint.spendCharity },
        { _id: 'otherData4', color: '#282C3F', title: 'Spend on coupon codes', dataNumber: totalPoint.spendCoupon },
      ],

    }
  ]

  const cancelledData = [
    {
      _id: 1,
      mainTitle: 'Cancelled orders',
      number: totalCancelled.total,
      statotherdata: [
        { _id: 'otherData1', color: '#F14E2D', title: ' For Too busy', dataNumber: totalCancelled.tooBusy },
        { _id: 'otherData2', color: '#F29032', title: 'For Low delivery staff', dataNumber: totalCancelled.lowDeliveryStuff },
        { _id: 'otherData3', color: '#BF1E36', title: 'Customer cancelled order', dataNumber: totalCancelled.customer },
      ],

    }
  ]
  
  const [statDelivery, setStatDelivery] = useState([
    
  ]);

// console.log(moment(new Date()).startOf('day').format("YYYY-MM-DD h:mm:ss a"));
  const [optionsStock, setOptionsStock] = useState([
    {
      value: {
        start: moment().startOf("day").format("YYYY-MM-DD h:mm:ss a"),
        end: moment().endOf("day").format("YYYY-MM-DD h:mm:ss a"),
      },
      label: "Today (" + moment().format("DD MMM") + ")",
    },
    {
      value: {
        start: moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD h:mm:ss a"),
        end: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD h:mm:ss a"),
      },
      label:
        "Yesterday (" +
        moment().subtract(1, "day").startOf("day").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("week").format("YYYY-MM-DD"),
        end: moment().endOf("week").format("YYYY-MM-DD"),
      },
      label:
        "This Week (" +
        moment().startOf("week").format("DD MMM") +
        " - " +
        moment().endOf('week').format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
      },
      label:
        "Last Week (" +
        moment().subtract(1, "week").startOf("week").format("DD MMM") +
        " - " +
        moment().subtract(1, "week").endOf("week").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
      },
      label:
        "This Month (" +
        moment().startOf("month").format("DD MMM") +
        " - " +
        moment().endOf("month").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "month").add(1, "day").endOf("month").format("YYYY-MM-DD h:mm:ss a"),
      },
      label:
        "Last Month (" +
        moment().subtract(1, "month").startOf("month").format("DD MMM") +
        " - " +
        moment().subtract(1, "month").endOf("month").local().format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "quarter")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Current Quarter (" +
        moment().startOf("quarter").format("DD MMM") +
        " - " +
        moment().endOf("quarter").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().endOf("year").format("YYYY-MM-DD"),
      },
      label:
        "Current Year (" +
        moment().startOf("year").format("DD MMM") +
        " - " +
        moment().endOf("year").format("DD MMM") +
        ")",
    },
  ]);

  function handleDateRange(e) {
    // console.log(e);
    if (active) {
      setDateRange({
        start: e.value.start,
        end: e.value.end,
      });
    } else if (e[0] && e[1]) {
      setDateRange({
        start: moment(new Date(e[0])).format("YYYY-MM-DD"),
        end: moment(new Date(e[1])).format("YYYY-MM-DD"),
      });
    }
  }


  return (
    <>
      <div className="page-content">
        <div className="page-filter mb-3">
          <div className="input-filters d-flex justify-content-between">
            {/* <div className="input-items">
              <Search placeholder={"Select delivery region"} />
            </div> */}
            {optionsStock.length !== 0 && <div className="input-items">
              Date Search
             {/* <Select
              options={optionsStock}
              defaultValue={optionsStock[2]}
              name="choices-category-input"
              onChange={(e) => handleDateRange(e)}
            /> */}

                      {active ? (
                          <Select
                            options={optionsStock}
                            defaultValue={optionsStock[2]}
                            name="choices-category-input"
                            onChange={(e) => handleDateRange(e)}
                          />
                        ) : (
                          <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              defaultDate: [moment().format("DD MMM YYYY")],
                            }}
                            onChange={(e) => handleDateRange(e)}
                          />
                        )}


            </div>}
            <Button label={""} labelClasses={""} classes={"px-2"} icon={"fa-regular fa-calendar"} iconPosition={"left"} functions={() => {setActive(!active); !active && setDateRange({
    start: moment().startOf("week").format("YYYY-MM-DD"),
    end: moment().endOf("week").format("YYYY-MM-DD"),
  })}
  }>
                        Switch
                      </Button>
            <div className="input-items items-end gap-3 ms-auto ">
              <Button label={""} labelClasses={""} classes={"px-2"} icon={"fa-regular fa-arrow-rotate-right"} iconPosition={"left"} functions={() => {
                setDateRange({
                  start: moment().startOf("week").format("YYYY-MM-DD"),
                  end: moment().endOf("day").format("YYYY-MM-DD"),
                });
                setOptionsStock([]);
                setActive(true);
                setTimeout(() => {
                  setOptionsStock([
    {
      value: {
        start: moment().startOf("day").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      label: "Today (" + moment().format("DD MMM") + ")",
    },
    {
      value: {
        start: moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
        end: moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Yesterday (" +
        moment().subtract(1, "day").startOf("day").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("week").format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "This Week (" +
        moment().startOf("week").format("DD MMM") +
        " - " +
        moment().format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(2, "weeks")
          .startOf("day")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "week").endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Last Week (" +
        moment().subtract(1, "week").startOf("week").format("DD MMM") +
        " - " +
        moment().subtract(1, "week").endOf("week").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "This Month (" +
        moment().startOf("month").format("DD MMM") +
        " - " +
        moment().format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      },
      label:
        "Last Month (" +
        moment().subtract(1, "month").startOf("month").format("DD MMM") +
        " - " +
        moment().subtract(1, "month").endOf("month").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "quarter")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Current Quarter (" +
        moment().subtract(1, "quarter").startOf("quarter").format("DD MMM") +
        " - " +
        moment().endOf("day").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().endOf("day").format("YYYY-MM-DD"),
      },
      label:
        "Current Year (" +
        moment().startOf("year").format("DD MMM") +
        " - " +
        moment().endOf("day").format("DD MMM") +
        ")",
    },
                  ])
                }, 100)
                qureryClient.invalidateQueries(['order_data'])
                qureryClient.invalidateQueries(['postcode_data'])
                qureryClient.invalidateQueries(['user_data'])
                qureryClient.invalidateQueries(['product_data'])
                qureryClient.invalidateQueries(['customer_data'])
              }} />
            </div>
          </div>
        </div>
        <div className="dashboard-stat">
          <div className="left-side-stat">
            <div className="order-stat">
            {statData && statData.map((item) => (
          <div className="stat-item" key={item._id}>
            <div className="icon">{item.icon}</div>
            <div className="stat-content">
              <div className="number">{item.data}</div>
              <div className="title">{item.label}</div>
              {item.metaData.length > 0 &&
                <div className="other-data">
                  {item.metaData.map((metaItem, index) => (
                    <div className="info-other" style={{ fontSize: "13px" }} key={index}>{metaItem.label}: {metaItem.data}</div>
                  ))}
                </div>
              }
            </div>
          </div>
        ))}
            </div>

            <div className='stat-tab-view'>
              <DashboardNavigation data={tabData} />
              <div className="stat-tab-content ">
                {currentLocation &&
                  currentLocation.component
                }
              </div>
            </div>

            <div className="delivery-settings-list mt-5">
              <div className="table-inner">
                <div className="delivery-title">Menu Items</div>
                <div className="table-data">
                  <div className="item-data-row header-row">
                    <div className="postal-code">Menu Item <span className="sortbale"></span></div>
                    <div className="min-order-value">Total Number Sold<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "i" ? "j" : "i")}}></button></div>
                    <div className="delivery-costs">Total Sales<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "k" ? "l" : "k")}}></button></div>
                  </div>
                  {productDatas && productDatas?.data?.length !== 0 && <div className="table-body-part">
                    {productDatas?.data?.sort((a,b)=>
                    sorting === "i" ? parseInt(a.total_number_sold)-parseInt(b.total_number_sold) : 
                    sorting === "j" ? parseInt(b.total_number_sold)-parseInt(a.total_number_sold) : 
                    sorting === "k" ? parseInt(b.total_sales)-parseInt(a.total_sales) : 
                    sorting === "l" ? parseInt(a.total_sales)-parseInt(b.total_sales) : null)?.map((item) => (
                      <div className="item-data-row" key={item._id}>
                        <div className="postal-code">{item.product_name}</div>
                        <div className="min-order-value">{item.total_number_sold}</div>
                        <div className="delivery-costs">€ {item.total_sales ? item.total_sales?.toFixed(2) : "0"}</div>
                      </div>
                    ))}
                  </div>}
                </div>
              </div>
            </div>

            {/* <div className='stat-tab-view mt-5'>
              <DashboardNavigation data={tabData} />
              <div className="stat-tab-content ">
                {currentLocation &&
                  currentLocation.component
                }
              </div>
            </div> */}

            <div className="delivery-settings-list mt-5">
              <div className="table-inner">
                <div className="delivery-title">Regional Data</div>
                <div className="table-data">
                  <div className="item-data-row header-row">
                    <div className="postal-code">Post Code <span className="sortbale"></span></div>
                    <div className="min-order-value">Number of Orders<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "g" ? "h" : "g")}}></button></div>
                    <div className="delivery-costs">Avg. Order Value<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "e" ? "f" : "e")}}></button></div>
                    <div className="delivery-costs">Total Order Amount<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "c" ? "d" : "c")}}></button></div>
                  </div>
                  {postDatas && postDatas.length !== 0 && <div className="table-body-part">
                    {postDatas?.sort((a,b)=>
                    sorting === "c" ? parseInt(a.total_order_amount)-parseInt(b.total_order_amount) : 
                    sorting === "d" ? parseInt(b.total_order_amount)-parseInt(a.total_order_amount) : 
                    sorting === "e" ? parseInt(b.average_order_value)-parseInt(a.average_order_value) : 
                    sorting === "f" ? parseInt(a.average_order_value)-parseInt(b.average_order_value) : 
                    sorting === "g" ? parseInt(a.number_of_order)-parseInt(b.number_of_order) : 
                    sorting === "h" ? parseInt(b.number_of_order)-parseInt(a.number_of_order) : null 

                    )?.map((item) => 
                      {if (item.zip_code !== "") {
                        return(
                          <div className="item-data-row" key={item._id}>
                            <div className="postal-code">{item.zip_code}</div>
                            <div className="min-order-value">{item.number_of_order}</div>
                            <div className="delivery-costs">€ {item.average_order_value ? item.average_order_value?.toFixed(2) : "0"}</div>
                            <div className="delivery-costs">€ {item.total_order_amount ? item.total_order_amount?.toFixed(2) : "0"}</div>
                          </div>
                        )
                      }
                    }
                    )}
                  </div>}
                </div>
              </div>
            </div>

            <div className="delivery-settings-list mt-5">
              <div className="table-inner">
                <div className="delivery-title">Top users</div>
                <div className="table-data">
                  <div className="item-data-row header-row">
                    <div className="postal-code">Image <span className="sortbale"></span></div>
                    <div className="min-order-value">Name</div>
                    <div className="delivery-costs">Total order Count<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "a" ? "b" : "a")}}></button></div>
                  </div>
                  <div className="table-body-part">
                    {userDatas?.sort((a,b)=>sorting === "a" ? parseInt(a.total_order_count)-parseInt(b.total_order_count) : sorting === "b" ? parseInt(b.total_order_count)-parseInt(a.total_order_count) : null)?.map((item) => (
                      <div className="item-data-row" key={item._id}>
                        <div className="postal-code" >
                          <img src={item?.user_status_details?.image} style={{ height: "60px", width: "60px", borderRadius: "50%" }}/>
                          </div>
                        <div className="min-order-value">{item.user_status_details?.name}</div>
                        <div className="delivery-costs">{item.total_order_count}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="delivery-settings-list mt-5">
              <div className="table-inner">
                <div className="delivery-title">Riders</div>
                <div className="table-data">
                  <div className="item-data-row header-row">
                    <div className="postal-code">Image <span className="sortbale"></span></div>
                    <div className="min-order-value">Name</div>
                    <div className="delivery-costs">Total order Count<button className="fa-duotone fa-sort-up" onClick={()=>{setSorting(prev => prev === "m" ? "n" : "m")}}></button></div>
                  </div>
                  <div className="table-body-part">
                    {Array.isArray(riderDatas) && riderDatas?.sort((a,b)=>sorting === "m" ? parseInt(a.order_count)-parseInt(b.order_count) : sorting === "n" ? parseInt(b.order_count)-parseInt(a.order_count) : null)?.map((item) => (
                      <div className="item-data-row" key={item._id}>
                        <div className="postal-code" >
                          <img src={item?.image} style={{ height: "60px", width: "60px", borderRadius: "10%", objectFit: "contain" }} />
                          </div>
                        <div className="min-order-value">{item?.name}</div>
                        <div className="delivery-costs">{item.order_count}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="right-side-stat">
          {statDelivery !== [] && <div className="delivery-stat">
              {
                 statDelivery.map((item) => (
                  <div className="delivery-item" key={item._id}>
                    <div className="icon">
                      <img src={item.image} />
                    </div>
                    <div className="delivery-content">
                      <div className="text">{item.text}</div>
                      <div className="order-data-row">
                        <div className="order-row">
                          <div className="sub-text">Order</div>
                          <div className="data">{item.orderData}</div>
                        </div>
                        <div className="order-row">
                          <div className="sub-text">Revenue</div>
                          <div className="data">{item.revenueData}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>}
            <div className='customer-stat'>
              {
                customerData.map((item) => (
                  <DashboardStatItem
                    key={item._id}
                    mainTitle={item.mainTitle}
                    number={item.number}
                    statotherdata={item.statotherdata}
                  />
                ))
              }
            </div>
            {pointsData && pointsData.length !== 0 && <div className='customer-stat'>
              {
                pointsData.map((item) => (
                  <DashboardStatItem
                    key={item._id}
                    mainTitle={item.mainTitle}
                    number={item.number}
                    statotherdata={item.statotherdata}
                  />
                ))
              }
            </div>}
            <div className='customer-stat'>
              {
                cancelledData.map((item) => (
                  <DashboardStatItem
                    key={item._id}
                    mainTitle={item.mainTitle}
                    number={item.number}
                    statotherdata={item.statotherdata}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
