import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { delService, stsChangeService } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const ServicesItem = ({
  ServiceId,
  image,
  name,
  index,
  descriptions,
  createdBy,
  createdDate,
  updatedDate,
  status,
  query,
  detailsFetch,
  setEditId,
  setEditModal,
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProduct = useMutation(
    (delProductId) => delService(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["services"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation((changeSts) => stsChangeService(changeSts), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["services"],
          type: "inactive",
        });
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  let sta = 3;
  if(status === 1){
    sta = 3;
  }else if(status === 3){
    sta = 1;
  }else if(status === 2){
    sta = 3;
  }

  return (
    <>
      <Draggable draggableId={ServiceId} index={index} key={ServiceId}>
        {(provided) => (
          <div
            className="services-item mb-3"
            key={ServiceId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="services-item-row">
              <div className="services-content">
                <div className="services-image">
                  <img src={image} />
                </div>
                <div className="services-details">
                  <span>Title</span>
                  <div className="services-title">{name}</div>
                  <div className="services-descriptions">
                    <span>Descriptions</span>
                    <div className="des">{descriptions}</div>
                  </div>
                </div>
              </div>
              <div className="services-options">
                <div
                  className={
                    "status " +
                    (status === 1
                      ? "published"
                      : status === 2
                      ? "coming Soon"
                      : "draft")
                  }
                >
                  {status === 1
                    ? "published"
                    : status === 2
                    ? "coming Soon"
                    : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditId(ServiceId);
                        detailsFetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                  
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChange.mutate({
                          id: ServiceId,
                          // status: status ? false : true,
                          status: sta,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status === 1
                        ? "draft"
                        : status === 3
                        ? "publish"
                        : status === 2
                        ? "draft"
                        : ""}
                    </button>
                    {status !== 2 && (
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() =>
                          statusChange.mutate({
                            id: ServiceId,
                            status: 2,
                          })
                        }
                      >
                        <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                        coming soon
                      </button>
                    )}

                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {(createdBy || createdDate || updatedDate) && (
              <div className="services-item-row border-t">
                <div className="services-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                </div>
                <div className="services-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Last Updated</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
      {/* <div className="services-item"></div> */}
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: ServiceId });
        }}
      />
    </>
  );
};

export default ServicesItem;
