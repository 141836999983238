import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { delOrganization, stsChangeOrganization } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const OrganizationItem = ({
  name,
  organizationId,
  description,
  createdBy,
  createdDate,
  status,
  query,
  detailsFetch,
  setEditId,
  setEditModal,
  setDeleteModal,
  setDeleteId
}) => {

  const queryClient = useQueryClient();


  const remove = () =>{
    setDeleteModal(true)
    setDeleteId(organizationId)
  }


  const statusChange = useMutation(
    (changeSts) => stsChangeOrganization(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["organization"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      <div className="points-item">
        <div className="points-item-row">
          <div className="points-options">
            {/* <div className={"status " + (status ? "published" : "draft")}>
              {status ? "published" : "draft"}
            </div> */}
            <div className="action-dropdown dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item"  onClick={() => {
                    setEditModal(true);
                    setEditId(organizationId);
                    detailsFetch();
                  }}>
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                {/* <button type="button" className="dropdown-item">
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                  Draft
                </button> */}
                <button type="button" className="dropdown-item standOut" onClick={remove}>
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="points-content">
          <div className="points-label">Organization Title</div>
          <div className="points-title">{name}</div>
          <div className="points-label mt-4">Description</div>
          <div className="points-text">{description}</div>
        </div>

        {(createdBy || createdDate) && (
          <div className="points-item-row border-t">
            <div className="points-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: organizationId });
        }}
      /> */}
    </>
  );
};

export default OrganizationItem;
