import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RiderDelete, RiderStatusChange } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";
import RadioGroup from "../Form/RadioGroup";

const DriverItem = ({
  image,
  name,
  email,
  phone,
  descriptions,
  createdDate,
  updatedDate,
  driverId,
  query,
  index,
  status,
  setEditId,
  setEditModal,
  detailsFetch,
}) => {
 
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);
  const [availablilty, setAvailablilty] = useState(status);

  const staticStatusArr = [
    {
      _id: true,
      name: "Available",
      color: "success",
      current: availablilty === true ? true : "",
    },
    {
      _id:  false,
      name: "Not Available",
      color: "warning",
      current: availablilty === false ? true : "",
    },
  ];

  


  const deleteProduct = useMutation(
    (delProductId) => RiderDelete(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["riders"],
            type: "inactive",
          });
          queryClient.removeQueries({
            queryKey: ["rider_dropdown"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (status !== availablilty) {
      statusChange.mutate({
        id: driverId,
        available: availablilty,
      });
    }
  }, [availablilty]);

  const statusChange = useMutation(
    (changeSts) => RiderStatusChange(changeSts),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["riders"],
            type: "inactive",
          });
          queryClient.removeQueries({
            queryKey: ["rider_dropdown"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      
          <div
            className="driver-item mb-3"
           
          >
            <div className="driver-item-row">
              <div className="driver-options">
                <div className="menu-stock">
                <RadioGroup
                  data={staticStatusArr}
                  setData={setAvailablilty}
                  inputName={"item_stock" + driverId}
                  labelClass={"bg-shades-04"}
                />
              </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditId(driverId);
                  
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>

                    {/* <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChange.mutate({
                          id: driverId,
                          status: status ? false : true,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status ? "not available" : "available"}
                    </button> */}

                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="driver-content">
              <div className="driver-image">
                <img src={image} />
              </div>
              <div className="driver-details">
                <span>Name</span>
                <div className="driver-title">{name}</div>
                <div className="driver-metas">
                  <div className="meta-item">
                    <div className="meta-content">
                      <div className="meta-label">E-mail</div>
                      <div className="meta-data">{email}</div>
                    </div>
                  </div>
                  {phone && (
                    <div className="meta-item">
                      <div className="meta-content">
                        <div className="meta-label">Phone</div>
                        <div className="meta-data">{phone}</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="driver-descriptions">
                  <span>Notes</span>
                  <div className="des">{descriptions}</div>
                </div>
                {/* <div className="radio-filters mt-3">
                  {status && (
                  <RadioGroup
                    data={status}
                    inputName={"item_stock" + driverId}
                    labelClass={"bg-shades-04"}
                  />
                   )}
                </div> */}
              </div>
            </div>

            {(createdDate || updatedDate) && (
              <div className="driver-item-row border-t">
                {/* <div className="driver-info">
              {emailVerify && (
                <div className="info">
                  <div className="info-label">Email Verified</div>
                  <div className="info-data">{emailVerify}</div>
                </div>
              )}
            </div> */}
                <div className="driver-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Last Updated</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        {/* )} */}
      {/* </Draggable> */}
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: driverId });
        }}
      />
    </>
  );
};

export default DriverItem;
