import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addCat, editCat } from "../../helpers/api_helper";
import { Loading } from "../Elements/Loading";

const AddCategory = ({ details, isFetching, modalClose, queryCategory }) => {
  const queryClient = useQueryClient();

  const [catId, setCatId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  const [uploadData, setUploadData] = useState([]);

  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);

  const { mutate: submitCategory } = useMutation(
    (payload) => addCat(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryCategory);
          queryClient.removeQueries({
            queryKey: ["categories"],
            type: "inactive",
          });
          setTitle("");
          setDescription("");
          setUploadData([]);
          setStatus("");
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: submitEditedCategory } = useMutation(
    (payload) => editCat(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryCategory);
          queryClient.removeQueries({
            queryKey: ["categories"],
            type: "inactive",
          });
          setTitle("");
          setDescription("");
          setUploadData([]);
          setStatus("");
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (details) {
      setTitle(details ? details.title : "");
      setStatus(details ? details.status : "");
      setDescription(details ? details.description : "");
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);

      setCatId(details ? details._id : "");

      console.log(details.status === true ? true : false);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 600);
    }
  }, [details]);

  if (isFetching) {
    return <Loading />;
  }

  // if(details) {
  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    label={"Title"}
                    state={title}
                    setState={setTitle}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Indian Drinks"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <Textarea
                    label={"Description"}
                    state={description}
                    setState={setDescription}
                    inputRequired={true}
                    inputPlaceholder={"Write description......."}
                    inputName={""}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  {publishedData && (
                    <RadiosLabel
                      setRadio={setStatus}
                      data={publishedData}
                      setData={setPublishedData}
                      label={"Status"}
                      inputRequired={true}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {details ? (
                    <Button
                      label={"Save"}
                      functions={() =>
                        submitEditedCategory({
                          id: details?._id,
                          title: title,
                          description: description,
                          image:
                            uploadData && uploadData[0]?.image.includes("https")
                              ? null
                              : uploadData[0]?.image,
                          status: status === 1 ? true : false,
                        })
                      }
                    />
                  ) : (
                    <Button
                      label={"Save"}
                      functions={() =>
                        submitCategory({
                          title: title,
                          description: description,
                          image: uploadData[0]?.image
                            ? uploadData[0].image
                            : null,
                          status: status === 1 ? true : false,
                        })
                      }
                    />
                  )}
                  <Button
                    label={"Cancel"}
                    classes={"main-button-alt"}
                    functions={() => {
                      modalClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col">
                <ImageUploadPreview
                  data={uploadData}
                  setImage={setUploadData}
                  label={"Upload Image"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
