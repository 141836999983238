const Radio = ({
  id,
  label,
  inputName,
  classes='',
  current,
  inputRequired,
  setCurrent,
  data,
  setData,
}) => {
  return (
    <>
      <div className={"radio" + (" " + classes)}>
        <input
          type="radio"
          value={id}
          name={inputName}
          className="radio-input"
          checked={current}
          onChange={(e) => {
            setCurrent(id);
            let tmp = data;
            let arr = [];
            tmp.forEach((item) => {
              console.log(item._id, e.target.value);
              if (item._id == e.target.value) {
              arr.push({_id: item._id, label: item.label, inputName: item.inputName, current: e.target.checked} )
              } else {
                arr.push({_id: item._id, label: item.label, inputName: item.inputName, current: false} )
              }
            })

            setData(arr);
        }}
        />
        <div className={"radio-icon" + (current?" active":"")}></div>
        {label && (
          <div className={"radio-label" + (inputRequired ? " required" : "")}>
            {label}
          </div>
        )}
      </div>
    </>
  );
};

export default Radio;
