import { useState } from "react";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import PageItem from "../../components/Items/PageItem";
import AddPage from "../../components/Modals/AddPage";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  pageList, pageListDetails
} from "../../helpers/api_helper";
import moment from "moment/moment";
import Select from "react-select";
import Pagination from "../../components/Elements/Pagination";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";

const Page = () => {

  // Query Client instance
  const queryClient = useQueryClient();
  const [isAddModal, setIsAddModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [editId, setEditId] = useState("");
  const [editSlug, setEditSlug] = useState("");
  const [status, setStatus] = useState(true);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);


  // For pagination======================>
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);

  // Page List =======================>

  const pageListKey = ["pagelists", keyword, selectedPageOpt, limit, offset];

  const {
    data: pageListData,
    isLoading: pageListLoading,
    error: pageListDataError,
  } = useQuery({
    queryKey: pageListKey,
    queryFn: async () =>
      pageList({
        keyword: keyword,
        limit: limit,
        offset: offset,
      }),
  });

  // Page List details=======================>

  const detailsPageListKey = ["pagelists_details", editId, editSlug];

  const { data: pageListDetailsData, isFetching: detailsLoading } = useQuery({
    queryKey: detailsPageListKey,
    queryFn: async () => pageListDetails({ slug: editSlug }),
  });

  const editHandler = (e) => {
    setEditSlug(e.slug);
    setEditId(e.id);
    setIsAddModal(true);
    setStatus(false);
  };

  // reset functions================>

  // const reset = () => {
  //   setOffset(0);
  //   setLimit(10);
  //   setSelectedPageOpt([]);
  //   setKeyword("");
  // };

  const addModal = () => {
    setIsAddModal(true);
    setStatus(true);
  };

  const closeModal = () => {
    setStatus(true);
    setIsAddModal(!isAddModal);
  };

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(pageListKey);
                queryClient.removeQueries({
                  queryKey: ["pagelists"],
                  type: "inactive",
                });
                setOffset(0);
                setLimit(10);
                setSelectedPageOpt([]);
                setKeyword("");
              }}
            />
            <Button
              label={"Add Page"}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        {/* <div className="input-items">
            <RadioGroup data={status} inputName={'status'}/>
          </div> */}
      </div>
      <div className="pages-list">
        {pageListData?.data?.length === 0 && <NoData />}
        {pageListDataError && <Error />}
        {pageListLoading ? (
          <Loading />
        ) : (
          pageListData?.data?.map((item) => (
            <PageItem
              key={item._id}
              id={item._id}
              title={item.title}
              subTitle={item.subtitle}
              slug={item.slug}
              createdBy={item.createdBy}
              createdDate={moment(new Date(item.created_at)).format(
                "MMM Do YY"
              )}
              status={item.status}
              functions={editHandler}
            />
          ))
        )}
      </div>
      <Pagination
        count={pageListData?.count}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />
      <Modal
        title={status ? "Add Page" : "Edit Page"}
        query={pageListKey}
        size={"lg"}
        isOpen={isAddModal}
        modalClose={closeModal}
      >
        <AddPage
          detailsFetch={detailsLoading}
          status={status}
          setStatus={setStatus}
          id={editId}
          editData={pageListDetailsData?.data}
          setIsAddModal={setIsAddModal}
        />
      </Modal>
    </>
  );
};

export default Page;
