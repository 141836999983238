import { useEffect, useState } from "react";
import Search from "../../components/Form/Search";
import AllergiesItem from "../../components/Items/AllergiesItem";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import AddAllergies from "../../components/Modals/AddAllergies";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import RadioGroup from "../../components/Form/RadioGroup";
import {
  detailsProductIngredient,
  listProductIngredient,
  ordering,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Select from 'react-select';
import Pagination from "../../components/Elements/Pagination";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";

const Allergy = () => {
  const queryClient = useQueryClient();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  const [editId, setEditId] = useState("");
  const [isOne, setDataOne] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  
   
   const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  const addModal = () => {
    setIsAddModal(true);
  };

  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () =>
      detailsProductIngredient({
        id: editId,
      }),
    enabled: !!editId,
  });

  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);

  const queryData = ["ingredients", keyword, limit, offset, status];

  function Allergies() {
    function useAllergy() {
      return useQuery({
        queryKey: queryData,
        queryFn: async () =>
          listProductIngredient({
            keyword: keyword,
            type: 1,
            status: status,
            limit: limit,
            offset: offset,
            sortByField: "ordering",
            sortByValue: 1,
          }),
      });
    }

    const { data: allergies, error, isFetching } = useAllergy();

    const handleDragEnd = (e) => {
      setDataOne(true);
      if (!e.destination) return;
      let tempData = Array.from(dataList);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
      // console.log(tempData);
      setDataList([...tempData]);
      let dataArray = [];
      for (let i = 0; i < tempData.length; i++) {
        dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
      }
      // setOrderingData(dataArray);

      onClickSaveOrdering(dataArray);
    };
    const onClickSaveOrdering = async (orderingData) => {
      const saveOrdering = await ordering({
        orderingData: orderingData,
        tableName: "product_ingredient",
      });

      if (saveOrdering.status === 200) {
        setDataOne(false);
        queryClient.invalidateQueries(queryData);
        queryClient.removeQueries({
          queryKey: ["ingredients"],
          type: "inactive",
        });
        toast.success(saveOrdering.message);
      } else {
        toast.error(saveOrdering.message);
      }
    };

    useEffect(() => {
      if (!isOne && allergies !== undefined) {
        setDataList(allergies?.data);
      }
    }, [isOne, allergies?.data]);

    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error />;
    } else if (allergies.data.length === 0) {
      return <NoData />;
    } else {
      return (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {dataList &&
                    dataList.length > 0 &&
                    dataList.map((item,index) => (
                      <AllergiesItem
                        key={item?._id}
                        index={index}
                        AllergiesId={item._id}
                        image={item?.image}
                        name={item?.title}
                        descriptions={item?.description}
                        addons={item?.addons}
                        createdDate={moment(new Date(item?.created_at)).format(
                          "Do MMM YY"
                        )}
                        updatedDate={moment(new Date(item?.updated_at)).format(
                          "Do MMM YY"
                        )}
                        queryKeyProduct={queryData}
                        status={item?.status}
                        detailsFetch={refetch}
                        setEditId={setEditId}
                        setEditModal={setIsEditModal}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Pagination count={allergies.count} limit={limit} offset={offset} setOffset={setOffset} />

        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
        <div className="input-items">
              {perPages && 
              <div className="input">

                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={'Per Page'}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                 />
           </div>
             }
              </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryData);
                queryClient.removeQueries({
                  queryKey: ["ingredients"],
                  type: "inactive",
                });
                setOffset(0);
                setLimit(10);
                setSelectedPageOpt([]);
                setKeyword("");
                setStatus("");
                setStatusStatic([]);
                setTimeout(() => {
                  setStatusStatic([
                    { _id: "", name: "All", color: "success", current: true },
                    {
                      _id: true,
                      name: "Published",
                      color: "success",
                      current: false,
                    },
                    {
                      _id: false,
                      name: "Draft",
                      color: "warning",
                      current: false,
                    },
                  ]);
                }, 300);
              }}
            />
            <Button
              label={"Allergies"}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="radio-filters">
          <RadioGroup
            data={statusStatic}
            setData={setStatus}
            inputName={"status"}
          />
        </div>
      </div>

      <div className="allergies-list">
        <Allergies
          queryData={queryData}
          keyword={keyword}
          limit={limit}
          offset={offset}
          status={status}
          setLimit={setLimit}
          setOffset={setOffset}
          refetch={refetch}
          setEditId={setEditId}
          setEditModal={setIsEditModal}
        />
      </div>

      <Modal
        title={"Add Allergies"}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(false)}
      >
        <AddAllergies
          modalClose={() => setIsAddModal(false)}
          query={queryData}
        />
      </Modal>

      <Modal
        title={"Edit Allergies"}
        size={"md"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(false)}
      >
        <AddAllergies
          modalClose={() => setIsEditModal(false)}
          query={queryData}
          details={details}
          isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default Allergy;