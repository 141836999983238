import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import {
  delProductIngredient,
  stsChangeProductIngredient,
} from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const AllergiesItem = ({
  index,
  image,
  name,
  createdBy,
  createdDate,
  updatedDate,
  status,
  AllergiesId,
  queryData,
  detailsFetch,
  setEditId,
  setEditModal,
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProduct = useMutation(
    (payload) => delProductIngredient(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryData);
          queryClient.removeQueries({
            queryKey: ["ingredients"],
            type: "inactive",
          });
        }

        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation(
    (payload) => stsChangeProductIngredient(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryData);
          queryClient.removeQueries({
            queryKey: ["ingredients"],
            type: "inactive",
          });
        }

        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      <Draggable draggableId={AllergiesId} index={index} key={AllergiesId}>
        {(provided) => (
          <div
            className="allergies-item mb-3"
            key={AllergiesId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="allergies-item-row">
              <div className="allergies-content">
                <div className="allergies-image">
                  <img src={image} />
                </div>
                <div className="allergies-details">
                  <div className="allergies-title">{name}</div>
                </div>
              </div>
              <div className="allergies-options">
                <div className={"status " + (status ? "published" : "draft")}>
                  {status ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditId(AllergiesId);
                        detailsFetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChange.mutate({
                          id: AllergiesId,
                          status: status ? false : true,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status ? "draft" : "publish"}
                    </button>
                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {(createdBy || createdDate || updatedDate) && (
              <div className="allergies-item-row border-t">
                <div className="allergies-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                </div>
                <div className="allergies-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Last Updated</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: AllergiesId });
        }}
      />
    </>
  );
};

export default AllergiesItem;
