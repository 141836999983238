import { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import TopNavigation from "../../components/Navigation/TopNavigation";
import { setTitle } from '../../helpers/MetaTag';
import Admin from "./Admin";
import Customers from "./Customers";
import Drivers from "./Drivers";

const User = () => {
  setTitle('Dabbawala | User Management');
  const [currentLocation, setCurrentLocation] = useState();

  const location = useLocation();
  const tabData = useMemo(() => [
    {_id:1, label:'Admins', link:'/user', search:'?tab=admin' , component:<Admin/>},
    {_id:2, label:'Customers', link:'/user', search:'?tab=user' , component:<Customers/>},
    {_id:3, label:'Delivery Riders', link:'/user', search:'?tab=drivers' , component:<Drivers/>},
  ], []) 

  useEffect(()=>{
    let index = tabData.findIndex((tab) => tab.search === location.search)
    if(index !== -1){
      setCurrentLocation(tabData[index]);
    }
  },[location, tabData]);

  return (
    <>
     <TopNavigation data={tabData}/>
      <div className="page-content">
      {currentLocation &&
          currentLocation.component
        }
      </div>
    
    </>
  );
};

export default User;
