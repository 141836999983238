import { useEffect, useState } from "react";
import Button from "../../components/Form/Button";
import Search from "../../components/Form/Search";
import SelectTag from "../../components/Form/SelectTag";
import RadioGroup from "../../components/Form/RadioGroup";
import AddonItem from "../../components/Items/AddonItem";
import Modal from "../../components/Elements/Modal";
import AddonGroup from "../../components/Modals/AddonGroup";
import AddAddons from "../../components/Modals/AddAddons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Select from 'react-select';
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";
import Pagination from "../../components/Elements/Pagination";
import { addOnGroupDetails, addOnGroupList, listProduct, ProductlistCategories } from "../../helpers/api_helper";

function AddonList({
  keyword,
  status,
  limit,
  offset,
  categoryArray,
  productArray,
  setLimit,
  setOffset,
  queryKey,
  refetch,
  setIsAddonEditGroupModal,
  setAddonGrpId,
  setIsError,
  setIsSuccess
}) {




  function useAddons() {
    return useQuery({
      queryKey: queryKey,
      queryFn: async () => addOnGroupList(
        {
          keyword: keyword,
          limit: limit,
          product_category_id: categoryArray,
          addon_product_id: productArray,
          offset: offset,
          status: status
        }
      ),
      keepPreviousData: true
    });
  }
  
  const { status: sts, data:addons, error, isFetching } = useAddons();
  if (isFetching) {
    return <Loading />
  }

  if (error) {
    return <Error />;
  } else if (addons.data.length === 0) {
    return <NoData />;
  } else {
    return (
      <>
        {addons.data.map((item) => (
            <AddonItem
            key={item?._id}
            id={item?._id}
            name={item.title}
            category={item.product_category_list}
            product={item.product_list}
            combo={item.combo_product_list}
            spiceLevel={item.spice_level_list}
            allergies={item.allergie_list}
            addon_list={item?.addon_list}
            minQuantity={item.min_buy_qty}
            maxQuantity={item.max_buy_qty}
            createdDate={item.createdDate}
            query={queryKey}
            status={item.status}
            priceLower={item?.min_price}
            priceUpper={item?.max_price}
            setIsAddonEditGroupModal={setIsAddonEditGroupModal}
            setAddonGrpId={setAddonGrpId}
            refetch={refetch}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
          ))}
       <Pagination count={addons.count} limit={limit} offset={offset} setOffset={setOffset} />
      </>
    );
  }
}

// Component for Product Category Dropdown
function Category({ selectedCatOpt, setSelectedCatOpt, setCatArr }) {
  function useCategory() {
    return useQuery({
      queryKey: ["categories_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await ProductlistCategories(
          {
            limit: 9999,
          }
        );

        data.forEach((item) => {
          tmp.push({ value: item._id, label: item.title });
        });

        return tmp;
      },
    });
  }

  const { data: categories } = useCategory();

  return (
    <div className="input-items">
      <SelectTag
        data={categories}
        selectedOptions={selectedCatOpt}
        setSelectedOptions={setSelectedCatOpt}
        setStateId={setCatArr}
        placeholder={"Category"}
      />
    </div>
  );
}

//Product
function Product({ selectedProOpt, setSelectedProOpt, setProArr }) {
  function useProducts() {
    return useQuery({
      queryKey: ["addonproducts"],
      keepPreviousData: true,
      queryFn: async () => {
        let tmp = [];
        const { data } = await listProduct(
          {
            status: 1,
            product_type: 2,
            limit: 9999,
          }
        );
        data.forEach((item) => {
          tmp.push({ value: item._id, label: item.title });
         
        });
           
        return tmp;
      },
    });
  }
  const { data: products } = useProducts();

 return (
    <div className="input-items">
      <SelectTag
        data={products}
        selectedOptions={selectedProOpt}
        setSelectedOptions={setSelectedProOpt}
        setStateId={setProArr}
        placeholder={"Addons"}
      />
    </div>
  );
}

const Addons = () => {
  const queryClient = useQueryClient();

  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

 const [isAddonGroupModal, setIsAddonGroupModal] = useState(false);
 const [isAddonEditGroupModal, setIsEditAddonGroupModal] = useState(false);
  const [isAddonModal, setIsAddonModal] = useState(false);
  const [isEditAddonModal, setEditIsAddonModal] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [productsData, setProducts] = useState([]);
  const [productDetail, setProductDetails] = useState([]);
  const [addongroupId, setAddonGrpId] = useState(false);
  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  
  const [selectedProOpt, setSelectedProOpt] = useState([]);
 const [selectedPro, setSelectedPro] = useState([]);
 const [isError, setIsError] = useState(false);
 const [isSuccess, setIsSuccess] = useState(false);

 const [combo, setCombo] = useState(false);


 const [perPages, setPerPages] = useState([
  { label: "10 (Default)", value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
  { label: "All", value: 999999 },
]);

 
 const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  const { data: details, refetch,isFetching:isFetch } = useQuery({
    queryKey: ["details", addongroupId],
    queryFn: async () => addOnGroupDetails({ id: addongroupId }),
    enabled: false,
  });



  useEffect(() => {
    if (addongroupId) {
      refetch();
    }
  }, [addongroupId, refetch]);


  useEffect(() => {
    if(isError){
      toast.error(isError);
      setIsError(false)
    }

  }, [isError]);

  useEffect(() => {
    if(isSuccess){
      toast.error(isSuccess);
      setIsSuccess(false)
    }
   
  }, [isSuccess]);

 






 

  const addonGroupModal = () => {
    setIsAddonGroupModal(true);
  };

  const deleteItem = () => {
    setIsOpenConfirm(true);
  };

  const queryAddons = [
    "addons",
    keyword,
    selectedCat,
    selectedPro,
    status,
    limit,
    offset
  ];
  

  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);
 
    return (
      <>
        <div className="page-filter mb-3">
          <div className="input-filters">
          <div className="input-items">
              {perPages && 
              <div className="input">

                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={'Per Page'}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                 />
           </div>
             }
              </div>
            <div className="input-items">
              <Search placeholder={"Search..."}  state={keyword}
              setState={setKeyword} />
            </div>
            <Category
            selectedCatOpt={selectedCatOpt}
            setSelectedCatOpt={setSelectedCatOpt}
            setCatArr={setSelectedCat}
          />
             <Product
            selectedProOpt={selectedProOpt}
            setSelectedProOpt={setSelectedProOpt}
            setProArr={setSelectedPro}
          />
            
            <div className="input-items items-end gap-3">
              <Button
                classes={"px-2"}
                icon={"fa-regular fa-arrow-rotate-right"}
                iconPosition={"left"}
                functions={() => {
                  queryClient.invalidateQueries(queryAddons);
                  queryClient.removeQueries({
                    queryKey: ["addons"],
                    type: "inactive",
                  });
                  setOffset(0);
                  setKeyword("");
                  setStatus("");
                  setStatusStatic([]);
                  setSelectedCatOpt([]);
                  setSelectedProOpt([]);
                  setLimit(10);
                  setSelectedPageOpt([])
                  setTimeout(() => {
                    setStatusStatic([
                      { _id: "", name: "All", color: "success", current: true },
                      { _id: true, name: "Published", color: "success", current: false },
                      { _id: false, name: "Draft", color: "warning", current: false },
                    ]);
                  }, 300);
                }}
              />
              <Button
                label={"Add"}
                icon={"fa-regular fa-plus"}
                iconPosition={"left"}
                functions={addonGroupModal}
              />
            </div>
          </div>
          <div className="radio-filters">
            {/* <RadioGroup data={type} inputName={"type"} /> */}
            <RadioGroup data={statusStatic}  setData={setStatus} inputName={"status"} />
          </div>
        </div>
        
        <div className="addons-list">
        <AddonList  keyword={keyword}
          limit={limit}
          offset={offset}
          categoryArray={selectedCat}
          productArray={selectedPro}
          status={status}
          queryKey={queryAddons}
          setLimit={setLimit}
          setOffset={setOffset}
          refetch={refetch}
          setAddonGrpId={setAddonGrpId}
          setIsAddonEditGroupModal={setIsEditAddonGroupModal}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
          />
        </div>
       
       
        <Modal
          title={'Add Addon Group'}
          size={"md"}
          isOpen={isAddonGroupModal}
          modalClose={() => setIsAddonGroupModal(!isAddonGroupModal)}
        >
          <AddonGroup setProducts={setProducts} setCombo={setCombo} isOpen={isAddonModal} modalClose={() => setIsAddonGroupModal(!isAddonGroupModal)} setIsOpen={setIsAddonModal}  isFetch={isFetch} setProductDetails={setProductDetails}/>
        </Modal>

        <Modal
          title={'Edit Addon Group'}
          size={"md"}
          isOpen={isAddonEditGroupModal}
          modalClose={() => setIsEditAddonGroupModal(!isAddonEditGroupModal)}
        >
          <AddonGroup setProducts={setProducts} setCombo={setCombo} isOpen={isAddonModal} modalClose={() => setIsEditAddonGroupModal(!isAddonEditGroupModal)} setIsOpen={setIsAddonModal} productsData={productsData} setProductDetails={setProductDetails} groupData={details} isFetch={isFetch} setEditIsAddonModal={setEditIsAddonModal}/>
        </Modal>
  

     
        <Modal
          title={"Add Addon"}
          size={"xl"}
          isOpen={isAddonModal}
          modalClose={() => setIsAddonModal(!isAddonModal)}
        >
          <AddAddons modalClose={() => setIsAddonModal(false)} combo={combo} setCombo={setCombo} setProducts={setProducts} productsData={productsData} addongroupId={addongroupId}/>
        </Modal>

        <Modal
          title={"Edit Addon"}
          size={"xl"}
          isOpen={isEditAddonModal}
          modalClose={() => setEditIsAddonModal(!isEditAddonModal)}
        >
          <AddAddons modalClose={() => setEditIsAddonModal(false)} setProducts={setProducts} combo={combo} setCombo={setCombo} productsData={productsData} productDetail={productDetail} addongroupId={addongroupId} />
        </Modal>
        
  
        {/* <Confirm
          icon={"fa-regular fa-xmark"}
          iconColorClass={"bg-danger"}
          title={"Delete"}
          text={"Are you really want to delete item?"}
          isOpen={deleteModal}
          // confirmClose={() => setIsOpenConfirm(false)}
          confirmClose={() => setDeleteModal(false)}
        /> */}
      </>
    );

   

  
};

export default Addons;
