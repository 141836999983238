import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { missionStatusChange,missionDelete } from '../../helpers/api_helper';
import Confirm from '../Elements/Confirm';

const MissionItem = ({
  title,
  descriptions,
  createdBy,
  createdDate,
  updatedDate,
  status,
  isDraft=false,
  id=null,
  setEditModel,
  editId,
  query,index
}) => {
  const [deleteModal,setDeleteModal]=useState(false)
 
  const queryClient = useQueryClient();
  const { mutate:statusChange } = useMutation( missionStatusChange, {
    onSuccess: (data) => {
        if(data.status===200){
          toast.success(data.message)
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["mission"],
            type: "inactive",
          });
          

        }else{
          toast.error(data.message)
        }
          
    },
  });


  const { mutate:deleteItem } = useMutation(missionDelete, {
    onSuccess: (data) => {
        
        if(data.status===200){
          toast.success(data.message)
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["mission"],
            type: "inactive",
          });
          

        }else{
          toast.error(data.message)
        }
          
    },
  });
  return (
    <>
     <Draggable draggableId={id} index={index} key={id} >

     {(provided) => (
      <div className="mission-item mb-3"   
      key={id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      >
      

        <div className="mission-item-row">
          <div className="mission-options">
            <div className={"status " + ((status === 1)?'published':'draft')}>{(status === 1)?'published':'draft'}</div>
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={()=>{
                  setEditModel(true)
                  editId(id)
                }}><i className="fa-regular fa-fw fa-pen-to-square"></i>Edit</button>

                <button type="button" className="dropdown-item" onClick={()=>{
                 statusChange({
                  id:id,
                  status:!isDraft,
                  type:2

                 })
                }}><i className="fa-regular fa-fw fa-arrow-up-from-dotted-line" ></i>{isDraft?"Draft":"Publish"}</button>
                <button type="button" className="dropdown-item standOut" onClick={()=>{
                   setDeleteModal(true)
                }}><i className="fa-regular fa-fw fa-trash-can"></i>Delete</button>
              </div>
            </div>
          </div>
        </div>
        <div className="mission-content">
          <div className="mission-label">Title</div>
          <div className="mission-title">{title}</div>
          <div className="mission-label mt-4">Description</div>
          <div className="mission-text">{descriptions}</div>
        </div>
 
       
        {( createdBy || createdDate) &&
        <div className="mission-item-row border-t">
        <div className="mission-info">
            {createdBy &&
            <div className="info">
              <div className="info-label">Created By</div>
              <div className="info-data">{createdBy}</div>
            </div>
            }
          </div>
         
          <div className="mission-info">
            {createdDate &&
            <div className="info">
              <div className="info-label">Created Date</div>
              <div className="info-data">{createdDate}</div>
            </div>
            }
            {updatedDate &&
            <div className="info">
              <div className="info-label">Created Date</div>
              <div className="info-data">{updatedDate}</div>
            </div>
            }
          </div>
        </div>
        }
    
      </div>
       )}
      </Draggable>


        <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() =>
          deleteItem({
            id: id,
          })
        }
      />

    </>
  );
};

export default MissionItem;