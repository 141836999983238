import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { delProduct, stsChangeProduct } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";
import Image from "../Elements/Image";
import RadioGroup from "../Form/RadioGroup";

const MerchandiseItem = ({
  productId,
  index,
  name,
  image,
  points,
  stock,
  createdBy,
  createdDate,
  updatedDate,
  status,
  queryMerch,
  setEditId,
  refetch,
  setEditModal,
}) => {
  const queryClient = useQueryClient();

  const [deleteModal, setDeleteModal] = useState(false);

  const [in_stock, setIn_stock] = useState(stock);

  const staticStockArr = [
    {
      _id: 1,
      name: "In stock",
      color: "success",
      current: stock === 1 ? true : false,
    },
    {
      _id: 2,
      name: "Out of stock",
      color: "danger",
      current: stock === 2 ? true : false,
    },
  ];

  const deleteMerch = useMutation((payload) => delProduct(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(queryMerch);
        queryClient.removeQueries({
          queryKey: ["merchandise"],
          type: "inactive",
        });
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const statusChangeMerch = useMutation(
    (payload) => stsChangeProduct(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryMerch);
          queryClient.removeQueries({
            queryKey: ["merchandise"],
            type: "inactive",
          });
        }

        if (res.status === 400) {
          toast.success(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const stockChangeMerch = useMutation((payload) => stsChangeProduct(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.removeQueries({
          queryKey: ["products"],
          type: "inactive",
        });
      }

      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  useEffect(() => {
    if (in_stock !== stock) {
      stockChangeMerch.mutate({
        id: productId,
        status: status,
        in_stock: in_stock,
      });
    }
  }, [in_stock]);

  return (
    <>
      <Draggable draggableId={productId} index={index} key={productId}>
        {(provided) => (
          <div
            className="category-item mb-3"
            key={productId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="merchandise-item-row">
              <div className="merchandise-options">
                <div
                  className={"status " + (status === 1 ? "published" : "draft")}
                >
                  {status === 1 ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditId(productId);
                        refetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChangeMerch.mutate({
                          id: productId,
                          status: status === 1 ? 3 : 1,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status === 1 ? "draft" : "publish"}
                    </button>
                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="merchandise-item-row unset-align">
              <div className="merchandise-image">
                <Image
                  src={image}
                  alt={name}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                />
              </div>
              <div className="merchandise-content">
                <div className="merchandise-title">{name}</div>
                <div className="merchandise-metas">
                  {points >= 0 && (
                    <div className="meta-item">
                      <div className="meta-icon">
                        <i className="fa-light fa-fw fa-coins"></i>
                      </div>
                      <div className="meta-content">
                        <div className="meta-label">Points</div>
                        <div className="meta-data">{points}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="merchandise-item-row mt-3">
              <div className="merchandise-stock">
                {staticStockArr.length && (
                  <RadioGroup
                    setData={setIn_stock}
                    data={staticStockArr}
                    inputName={"item_stock" + productId}
                    labelClass={"bg-shades-04"}
                  />
                )}
              </div>
            </div>
            {(createdBy || createdDate || updatedDate) && (
              <div className="merchandise-item-row border-t">
                <div className="merchandise-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                </div>
                <div className="merchandise-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Last Updated</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this ?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteMerch.mutate({ id: productId });
        }}
      />
    </>
  );
};

export default MerchandiseItem;
