import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { AdminDelete } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const CustomerItem = ({
  image,
  name,
  phone,
  email,
  email_verified,
  createdDate,
  updatedDate,
  customerId,
  query,
  detailsFetch,
  setEditId,
  setEditModal,
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProduct = useMutation(
    (delProductId) => AdminDelete(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["customer"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      <div className="customer-item">
        <div className="customer-item-row">
          <div className="customer-options">
            <div className="action-dropdown dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    setEditModal(true);
                    setEditId(customerId);
                    detailsFetch();
                  }}
                >
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                <button
                  type="button"
                  className="dropdown-item standOut"
                  onClick={() => setDeleteModal(true)}
                >
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="customer-content">
          <div className="customer-image">
            <img src={image} />
          </div>
          <div className="customer-details">
            <span>Name</span>
            <div className="customer-title">{name}</div>
            <div className="customer-metas">
              <div className="meta-item">
                <div className="meta-content">
                  <div className="meta-label">E-mail</div>
                  <div className="meta-data">{email}</div>
                </div>
              </div>
              {phone && (
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Phone</div>
                    <div className="meta-data">{phone}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {(createdDate || updatedDate) && (
          <div className="customer-item-row border-t">
            <div className="customer-info">
              {/* {emailVerify && ( */}
              <div className="info">
                <div className="info-label">Email Verified</div>
                <div className="info-data">
                  {email_verified === true
                    ? "YES"
                    : email_verified === false
                    ? "NO"
                    : ""}
                </div>
              </div>
              {/* )} */}
            </div>
            <div className="customer-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: customerId });
        }}
      />
    </>
  );
};

export default CustomerItem;
