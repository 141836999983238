import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { delBlog, stsChangeBlog } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const BlogsItem = ({
  index,
  BlogId,
  name,
  author,
  createdDate,
  query,
  status,
  detailsFetch,
  setEditId,
  setEditModal,
  updatedDate,
  createdBy,
  alias,
  setalias
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProduct = useMutation((delProductId) => delBlog(delProductId), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["Blogs"],
          type: "inactive",
        });
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const statusChange = useMutation((changeSts) => stsChangeBlog(changeSts), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["Blogs"],
          type: "inactive",
        });
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  return (
    <>
      <Draggable draggableId={BlogId} index={index} key={BlogId}>
        {(provided) => (
          <div
            className="blogs-item"
            key={BlogId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="blogs-item-row">
              <div className="blogs-options">
                <div className={"status " + (status ? "published" : "draft")}>
                  {status ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setalias(alias);
                        detailsFetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChange.mutate({
                          id: BlogId,
                          status: status ? false : true,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status ? "draft" : "publish"}
                    </button>
                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="blogs-content">
              <div className="blogs-label">Blog title</div>
              <div className="blogs-title">{name}</div>
            </div>
            <div className="blogs-metas">
              {/* <div className="meta-item">
                <div className="meta-content">
                  <div className="meta-label">Blog Views</div>
                  <div className="meta-data">{views}</div>
                </div>
              </div> */}
              <div className="meta-item">
                <div className="meta-content">
                  <div className="meta-label">Author</div>
                  <div className="meta-data">{author}</div>
                </div>
              </div>
            </div>

            
            {(createdBy || createdDate || updatedDate) && (
          <div className="blogs-item-row border-t">
            <div className="blogs-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="blogs-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
          </div>
        )}
      </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: BlogId });
        }}
      />
    </>
  );
};

export default BlogsItem;
