import { useEffect, useState } from "react";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Textarea from "../../components/Form/Textarea";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  siteSettingsDetails,
  siteSettingsEdit,
} from "../../helpers/api_helper";
import ImageUploadPreview from "../../components/Form/ImageUploadPreview";
import { toast } from "react-toastify";

const PageSettings = () => {

  const queryClient = useQueryClient();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adddress, setAddress] = useState("");
  const [copyright, setCopyright] = useState("");
  const [facebook_link, setFacebook_link] = useState("");
  const [twitter_link, setTwitter_link] = useState("");
  const [whatsapp_number, setWhatsapp_number] = useState("");
  const [instagram_link, setInstagram_link] = useState("");
  const [linkedin_link, setLinkedin_link] = useState("");
  const [snapchat_link, setSnapchat_link] = useState("");
  const [tiktok_link, setTiktok_link] = useState("");
  const [point_per_euro, setPoint_per_euro] = useState("");
  const [logo, setLogo] = useState([]);

  const querySettings = ["sitesettings"];

  // site setting details
  const { data: siteSettings } = useQuery({
    queryKey: querySettings,
    queryFn: async () => siteSettingsDetails({}),
  });

  const { mutate: handleEdit } = useMutation(
    (payload) => siteSettingsEdit(payload),
    {
      onSuccess: (res) => {
       
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(querySettings);
          queryClient.removeQueries({
            queryKey: ["sitesettings"],
            type: "inactive",
          });
          setTitle("");
          setDescription("");
          setEmail("");
          setPhone("");
          setAddress("");
          setCopyright("");
          setFacebook_link("");
          setTwitter_link("");
          setWhatsapp_number("");
          setInstagram_link("");
          setLinkedin_link("");
          setSnapchat_link("");
          setTiktok_link("");
          setPoint_per_euro("");
          setLogo([]);
         
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  // console.log(siteSettings);

  useEffect(() => {
    if (siteSettings) {
      setTitle(siteSettings ? siteSettings.title : "");
      setDescription(siteSettings ? siteSettings.description : "");
      setEmail(siteSettings ? siteSettings.email : "");
      setPhone(siteSettings ? siteSettings.phone : "");
      setAddress(siteSettings ? siteSettings.address : "");
      setCopyright(siteSettings ? siteSettings.copyright : "");
      setFacebook_link(siteSettings ? siteSettings.facebook_link : "");
      setTwitter_link(siteSettings ? siteSettings.twitter_link : "");
      setWhatsapp_number(siteSettings ? siteSettings.whatsapp_number : "");
      setInstagram_link(siteSettings ? siteSettings.instagram_link : "");
      setLinkedin_link(siteSettings ? siteSettings.linkedin_link : "");
      setSnapchat_link(siteSettings ? siteSettings.snapchat_link : "");
      setTiktok_link(siteSettings ? siteSettings.tiktok_link : "");
      setPoint_per_euro(siteSettings ? siteSettings.point_per_euro : "");
      setLogo(siteSettings ? [{ _id: 1, image: siteSettings.logo }] : []);
    }
  }, [siteSettings]);

  return (
    <>
      <div className="page-settings">
        <div className="title">Site Settings</div>
        <div className="page-settings-items">
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                state={title}
                setState={setTitle}
                label={"Title"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"Dabbawala"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
            <div className="col-md-6">
              <Input
                state={email}
                setState={setEmail}
                label={"Email"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"Dabbawala@gmail.com"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                state={phone}
                setState={setPhone}
                label={"Phone No"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"+91 9087654321"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
            <div className="col-md-6">
              <Input
                state={whatsapp_number}
                setState={setWhatsapp_number}
                label={"Whatsapp No"}
                inputRequired={false}
                inputType={"text"}
                inputValue={"12345678923"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                state={adddress}
                setState={setAddress}
                label={"Address"}
                inputRequired={true}
                inputType={"text"}
                inputValue={
                  "Akshya Naga 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016"
                }
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
            <div className="col-md-6">
              <Input
                state={copyright}
                setState={setCopyright}
                label={"Copyright"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"Dabbawala © 2022 All the rights reserved."}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                state={facebook_link}
                setState={setFacebook_link}
                label={"Facebook Link"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"https://www.facebook.com/DabbawaladotCom"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
            <div className="col-md-6">
              <Input
                state={twitter_link}
                setState={setTwitter_link}
                label={"Twitter Link"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"https://twitter.com/Dabbawaladotcom"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                state={linkedin_link}
                setState={setLinkedin_link}
                label={"LinkedIn Link"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"https://www.linkedin.com/dabbawala"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
            <div className="col-md-6">
              <Input
                state={instagram_link}
                setState={setInstagram_link}
                label={"Instagram Link"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"https://www.instagram.com/dabbawala"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                state={tiktok_link}
                setState={setTiktok_link}
                label={"Tiktok Link"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"https://www.tiktok.com/dabbawala"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
            <div className="col-md-6">
              <Input
                state={instagram_link}
                setState={setInstagram_link}
                label={"Instagram Link"}
                inputRequired={true}
                inputType={"text"}
                inputValue={"https://www.snapchat.com/dabbawala"}
                inputPlaceholder={""}
                inputName={""}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <Textarea
                state={description}
                setState={setDescription}
                label={"Description"}
                labelClasses={""}
                inputClasses={""}
                inputPlaceholder={""}
                inputValue={""}
                inputName={""}
                inputRequired={false}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <>
                <div className="row">
                  <div className="col">
                    <ImageUploadPreview
                      setImage={setLogo}
                      data={logo}
                      label={"Upload Logo"}
                      inputRequired={true}
                    />
                  </div>
                </div>
              </>
            </div>
          </div>
          <Button
            label={"Update"}
            labelClasses={""}
            classes={""}
            functions={() =>
              handleEdit({
                id: siteSettings?._id,
                logo: logo && logo[0]?.image.includes('https') ? null : logo[0]?.image,
                title: title,
                description: description,
                email: email,
                phone: phone,
                adddress: adddress,
                copyright: copyright,
                facebook_link: facebook_link,
                twitter_link: twitter_link,
                tiktok_link: tiktok_link,
                whatsapp_number: whatsapp_number,
                instagram_link: instagram_link,
                linkedin_link: linkedin_link,
                snapchat_link: snapchat_link,
                point_per_euro: point_per_euro,
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default PageSettings;
