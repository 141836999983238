import Confirm from '../Elements/Confirm';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from 'react-toastify';
import { delCategories, stsChangeCategories } from '../../helpers/api_helper';
import { Draggable } from "react-beautiful-dnd";
const DietItem = ({
  index,
  categoryId,
  image,
  name,
  createdBy,
  createdDate,
  updatedDate,
  status,
  query,
  detailsFetch,
  setEditId,
  setEditModal,

}) => {

  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);



  const deleteProduct = useMutation(
    (delProductId) => delCategories(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["Blogcategories"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  const statusChange = useMutation(
    (changeSts) => stsChangeCategories(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["Blogcategories"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  return (
    <>
    <Draggable draggableId={categoryId} index={index} key={categoryId}>
    {(provided) => (
      <div className="diets-item mb-3"
        key={categoryId}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
     
        <div className="diets-item-row">
          <div className="diets-content">
            {/* <div className="diets-image"><img src={image} /></div> */}
            <div className='diets-details'>
              <div className="diets-title">{name}</div>
            </div>
          </div>
          <div className="diets-options">
            <div className={"status " + (status ? 'published' : 'draft')}>{status ? 'published' : 'draft'}</div>
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item"
                  onClick={() => {
                    setEditModal(true);
                    setEditId(categoryId);
                    detailsFetch();
                  }}
                >
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>


                <button type="button" className="dropdown-item" onClick={() => statusChange.mutate({
                  id: categoryId,
                  status: status ? false : true,
                })}
                >
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{status ? 'draft' : 'publish'}
                </button>


                <button type="button" className="dropdown-item standOut" onClick={() => setDeleteModal(true)}>
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>

        </div>



        {(createdBy || createdDate || updatedDate) &&
        <div className="diets-item-row border-t">
          <div className="diets-info">
            {createdBy &&
            <div className="info">
              <div className="info-label">Created By</div>
              <div className="info-data">{createdBy}</div>
            </div>
            }
          
          </div>
          <div className="diets-info">
            {createdDate &&
            <div className="info">
              <div className="info-label">Created Date</div>
              <div className="info-data">{createdDate}</div>
            </div>
            }
            {updatedDate &&
            <div className="info">
              <div className="info-label">Last Updated</div>
              <div className="info-data">{updatedDate}</div>
            </div>
            }
          </div>
        </div>
        }
      </div>
 )}
 </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: categoryId });
        }}
      />
    </>
  );
};

export default DietItem;