import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { funFactsDelete, funFactsStatusChange } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";
import { toast } from "react-toastify";
import { Draggable } from "react-beautiful-dnd";

const FunFactsItem = ({
  title,
  description,
  createdBy,
  createdDate,
  updatedDate,
  status,
  isDraft = false,
  setEditModel,
  id = null,
  editId,
  draftId,
  query,
  index,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: deleteItem } = useMutation(funFactsDelete, {
    onSuccess: (data) => {
      if (data.status === 200) {
        toast.success(data.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["fanFacts"],
          type: "inactive",
        });
      } else {
        toast.error(data.message);
      }
    },
  });

  const { mutate: statusChange } = useMutation(funFactsStatusChange, {
    onSuccess: (data) => {
      if (data.status === 200) {
        toast.success(data.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["fanFacts"],
          type: "inactive",
        });
      } else {
        toast.error(data.message);
      }
    },
  });

  return (
    <>
      <Draggable draggableId={id} index={index} key={id}>
        {(provided) => (
          <div
            className="funfacts-item mb-3"
            key={id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="funfacts-item-row">
              <div className="funfacts-options">
                <div
                  className={"status " + (status === 1 ? "published" : "draft")}
                >
                  {status === 1 ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModel(true);
                        editId(id);
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        statusChange({
                          id: id,
                          status: !isDraft,
                        });
                      }}
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {isDraft ? "Draft" : "publish"}
                    </button>
                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="funfacts-content">
              <div className="funfacts-label">Title</div>
              <div className="funfacts-title">{title}</div>
              <div className="funfacts-label mt-4">Description</div>
              <div className="funfacts-text">{description}</div>
            </div>

            {(createdBy || createdDate) && (
              <div className="funfacts-item-row border-t">
                <div className="funfacts-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                </div>

                <div className="funfacts-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}

                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Updated Date</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() =>
          deleteItem({
            id: id,
          })
        }
      />
    </>
  );
};

export default FunFactsItem;
