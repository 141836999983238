import Input from "../Form/Input";
import Button from "../Form/Button";
import SelectTag from "../Form/SelectTag";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { hostUrl } from "../../config/host";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AddonGroup = ({setProducts,isOpen, modalClose,setIsOpen,productsData,setProductDetails,groupData,isFetch,setEditIsAddonModal, setCombo}) => {
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [productId, setProductId] = useState("");
  const [minQty, setMinQty] = useState("");
  const [maxQty, setMaxQty] = useState("");
  const [status, setStatus] = useState(true);
  const queryClient = useQueryClient();
  const categoryDropdown = queryClient.getQueryData(["categories_dropdown"]);
  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [selectedProductOpt, setSelectedProductOpt] = useState([]);
  const [editProductId, setEditProductId] = useState("");
  const [grpId, setGrpId] = useState(false);
  const [isCombo, setIsCombo] = useState(false);

  useEffect(()=>{
    if(productsData){
      let ids = [];
      productsData.map((it)=>{
        ids.push(it._id)
      })
      let marge = [...productId,...ids];
     const unique = [...new Set(marge)]
      setProductId(unique)
      setProducts(productsData)
    }
  },[productsData])


  useEffect(()=>{
if(groupData){
  console.log(groupData);
  setGrpId(groupData ? groupData._id : false);
  setTitle(groupData ? groupData.title : "");
  setMinQty(groupData ? groupData.min_buy_qty : "");
  setMaxQty(groupData ? groupData.max_buy_qty : "");
  
  let tmpCat = []
  let tmpCatID = []

  groupData && groupData.category_list?.forEach((item) => {
    tmpCatID.push(item._id);
    tmpCat.push({label: item.title, value: item._id});
  });
  setSelectedCatOpt(tmpCat);
  setCategoryId(tmpCatID);

  let tmpProduct = []
  let tmpProductID = []

  groupData && groupData.product_list?.forEach((item) => {
    tmpProductID.push(item._id);
    tmpProduct.push({label: item.title, value: item._id});
  });

  groupData && groupData.combo_product_list?.forEach((item) => {
    tmpProductID.push(item._id);
    tmpProduct.push({label: item.title, value: item._id});
  });
  setSelectedProductOpt(tmpProduct);
  setProductId(tmpProductID);

  setIsCombo(groupData?.combo_product_list?.length > 0);
  setCombo(groupData?.combo_product_list?.length > 0);
  // console.log(isCombo);

  let tmpAddons = []
   groupData && groupData.addon_list?.forEach((item) => {
    tmpAddons.push({_id: item._id, title: item.title});
  });
  setProducts(tmpAddons)

}
  },[groupData])

  function useProducts() {
    return useQuery({
      queryKey: ["products"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await axios.post(
          `${hostUrl}/v2/products/list`,
          {
            product_type: 1,
            limit: 9999,
          },
          {}
        );
  
        data.data.forEach((item) => {
          tmp.push({ value: item._id, label: item.title });
        });
  
        return tmp;
      },
    });
  }
  const { data: productDropdown } = useProducts();

  function useCombos() {
    return useQuery({
      queryKey: ["combos_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await axios.post(
          `${hostUrl}/v2/products/list`,
          {
            product_type: 4,
            limit: 9999,
          },
          {}
        );
  
        data.data.forEach((item) => {
          tmp.push({ value: item._id, label: item.title });
        });
  
        return tmp;
      },
    });
  }
  const { data: comboDropdown } = useCombos();

 
  console.log(comboDropdown);

  const addAddons = () => {
    setIsOpen(!isOpen);
  }


  const config = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    },
  };
  const submitAddons = useMutation(
    (newProduct) =>
      axios
        .post(
          `${hostUrl}/addon-group/create`,
          newProduct,
          config
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          queryClient.invalidateQueries('addons');
          queryClient.removeQueries({
            queryKey: ["addons"],
            type: "inactive",
          });
          toast.error(res.success);
          setTitle("");
          setMinQty("");
          setMaxQty("");
          
          setSelectedCatOpt([]);
          setSelectedProductOpt([]);
          
          modalClose();
        }else{
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { data: details, error, refetch, isFetching } = useQuery({
    queryKey: ["details", editProductId],
    queryFn: async () => {
      const { data } = await axios.post(
        `${hostUrl}/v2/products/details`,
        {
          id: editProductId,
        },
        {}
      );
      return data.data;
    },
    enabled: false,
  });
 

  useEffect(() => {
    if (editProductId !== "") {
      refetch();
    }
  }, [editProductId, refetch]);

  useEffect(() => {
    setProductDetails(details)
  }, [details]);


  const deleteProduct = useMutation(
    (delProductId) =>
      axios
        .post(
          `${hostUrl}/v2/products/remove`,
          delProductId,
          config
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          const element = document.getElementById('modification'+res.data._id);
          element.remove();
        }else{
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const submitEditAddons = useMutation(
    (editProduct) =>
      axios
        .post(
          `${hostUrl}/addon-group/edit`,
          editProduct,
          config
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          queryClient.invalidateQueries('addons');
          queryClient.removeQueries({
            queryKey: ["addons"],
            type: "inactive",
          });
          setGrpId(false);
          setTitle("");
          setMinQty("");
          setMaxQty("");
          setSelectedCatOpt([]);
          setCategoryId([]);
          setSelectedProductOpt([]);
          setProductId([]);
          modalClose();
          toast.success(res.message);
        }else{
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  
  if(isFetch){
    return(
      <div>Loading...</div>
    )
  }
  return (
    <>
      <div className="px-2">
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">
              <Input 
                label={'Name'}
                state={title}
                setState={setTitle}
                inputRequired={true}
                inputType={'text'}
                inputPlaceholder={'Example: Drinks'}
                inputName={''}
              />
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-3">
                  <Input 
                    label={'Min'}
                    state={minQty}
                    setState={setMinQty}
                    inputRequired={true}
                    inputType={'text'}
                    inputPlaceholder={'Min quantity'}
                    inputName={''}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <Input 
                    label={'Max'}
                    state={maxQty}
                    setState={setMaxQty}
                    inputRequired={true}
                    inputType={'text'}
                    inputPlaceholder={'Max quantity'}
                    inputName={''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
          <div className="checkbox mb-3">
            <input
              type="checkbox"
              name={"Combo"}
              className="checkbox-input"
              defaultChecked={isCombo ? true : false}
              checked={isCombo ? true : false}
              onChange={(e) => {setIsCombo(e.target.checked); setSelectedProductOpt([]);}}
            />
            <div className="checkbox-icon col-lg-6"></div>
              {isCombo ? <SelectTag 
                selectedOptions={selectedProductOpt}
                setSelectedOptions={setSelectedProductOpt}
                setStateId={setProductId}
                data={comboDropdown}
                placeholder={'Example: Biriyani, Chicken'}
                inputRequired={false}
                label={'Combo Products'}
              /> : <SelectTag 
              selectedOptions={selectedProductOpt}
              setSelectedOptions={setSelectedProductOpt}
              setStateId={setProductId}
              data={productDropdown}
              placeholder={'Example: Biriyani, Chicken'}
              inputRequired={false}
              label={'Combo Products'}
            />}
            </div>
            <div className="mb-3">
              {isCombo ? null : <SelectTag 
               selectedOptions={selectedCatOpt}
               setSelectedOptions={setSelectedCatOpt}
                setStateId={setCategoryId}
                data={categoryDropdown}
                placeholder={'Example: Categories'}
                label={'Category'}
                inputRequired={false}
                isCheckbox={false}
              />}
            </div>
            
          </div>
          {/* <div className="col-lg-12">
            <div className="mb-4">
              <RadiosLabel  setRadio={setStatus} data={publishedData} label={'Status'} inputRequired={true}/>
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="modification">
            {grpId && <Button label={'New Add-on'} classes={'main-button-link text-color-02'} functions={addAddons}/>} 
              <div className="modification-list" >
                {productsData && productsData.length > 0 && productsData.map((item) => (
                <div className="item" key={item._id} id={"modification"+item._id}>
                  <div className="item-title">{item.title}</div>
                  <div className="item-action">
                    <Button classes={'main-button-icon-sm text-shades-05'} icon={'fa-regular fa-pen-to-square'} iconPosition={'left'}  functions={() => {
                    setEditProductId(item._id);
                    refetch();
                    setEditIsAddonModal(true);
                  }}/>
                    <Button classes={'main-button-icon-sm text-color-02'} icon={'fa-regular fa-trash-can'} iconPosition={'left'} functions={() =>
                        deleteProduct.mutate({
                          id: item._id,
                        })
                      }/>
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="d-flex flex-wrap gap-3">
            {grpId &&  <Button label={'Update'}  functions={() =>
                        submitEditAddons.mutate({
                          id:grpId,
                          title: title,
                          product_category_id: categoryId,
                          product_id: productId,
                          min_buy_qty: minQty,
                          max_buy_qty: maxQty,
                          status:status 
                        })
                      }/>}
            {!grpId &&  <Button label={'Save'}  functions={() => {
                        submitAddons.mutate({
                          title: title,
                          product_category_id: categoryId,
                          product_id: productId,
                          min_buy_qty: minQty,
                          max_buy_qty: maxQty,
                          status:status 
                        })
                      }
                      }/>}
             
              <Button label={'Cancel'} functions={() => {
                   
                   modalClose();
                  }} classes={'main-button-alt'}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddonGroup;