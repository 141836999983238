import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import PreviewProduct from "../Elements/PreviewProduct";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { createStepsList, stepsListEdit } from "../../helpers/api_helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const AddSteps = ({ query, setModalClose, details, edit, detailsListLoading }) => {


  const [listTitle, setListTitle] = useState("")
  const [listdescription, setListDescription] = useState("")
  const [editStatus, setEditStatus] = useState(true)
  const [status, setStatus] = useState(true)
  const [editId, setEditId] = useState('')
  const [image, setImage] = useState([])
  const [isPreview, setIspreview] = useState(false);
  const [loading, setLoading] = useState(false)
  

  // ========================================>
  const queryClient = useQueryClient();





  useEffect(() => {
    if (details) {
      setImage(details ? [{ _id: 1, image: details.image }] : []);
      setListTitle(details?.title);
      setListDescription(details?.description);
      setEditId(details?._id);
      setPublishedData([]);
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 600);
    }
  }, [details])

  const [publishedData, setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);

  // =======================>




  // add step lists data values==================>

  const stepAddData = {
    title: listTitle,
    description: listdescription,
    status: status,
    image: image ? image[0]?.image : null
  }
  // ======================================>

  const categoryDropdown = [
    { value: 1, label: "Part Time" },
    { value: 2, label: "Full Time" },
  ];


  const previewData = [
    {
      image: 'images/product.png',

    }
  ]

  // gatting form data =======================>

  const titleHandler = (event) => {
    setListTitle(event)
  }
  const descriptionHandler = (event) => {
    setListDescription(event)
  }

  const radioValue = (event) => {
    if (event === 1) {
      setStatus(true)
    } else {
      setStatus(false)
    }
    if (event === 1) {
      setEditStatus(true)
    } else {
      setEditStatus(false)
    }
  }

  // data add onSubmit in database===============>

  const addStepList = useMutation(
    (payload) => createStepsList(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setLoading(false)
          setListTitle('')
          setListDescription('')
          setModalClose(!setModalClose)
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["stepsList"],
            type: "inactive",
          })
        } else {
          setLoading(false)
          toast.error(res.message);
        }
      }
    }
  )

  // edit step List ================>

  const editStepList = useMutation(
    (payload) => stepsListEdit(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setLoading(false)
          setListTitle('')
          setListDescription('')
          setModalClose(!setModalClose)
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["stepsList"],
            type: "inactive",
          })
        } else {
          setLoading(false)
          toast.error(res.message);
        }
      }
    }
  )

  const handleSubmit = (formData) => {
    setLoading(true)
    if (edit === false) {
      addStepList.mutate(formData)
    } else {
      editStepList.mutate({
        title: listTitle,
        description: listdescription,
        image: image && image[0]?.image.includes("https") ? null: image[0]?.image,
        status: editStatus,
        id: editId
      })
    }
  }

  if (detailsListLoading) {
    return <>loading...</>
  }


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="mb-3">
                <Input state={listTitle} setState={titleHandler} label={"Title"} inputRequired={true} inputType={"text"} inputPlaceholder={"Title"} inputName={"title"} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Textarea state={listdescription} setState={descriptionHandler} label={"Job Description"} inputRequired={true} inputPlaceholder={"Write description......."} inputName={""} />
              </div>
            </div>
            <div className="row">
              <div className="mb-4">
                <RadiosLabel setRadio={radioValue} data={publishedData} setData={setPublishedData} label={"Status"} inputRequired={true} />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            {!isPreview ? (
              <>
                <div className="row">
                  <div className="col">
                    <ImageUploadPreview setImage={setImage} data={image} label={'Upload Icon'} inputRequired={true} />
                  </div>
                </div>

              </>
            ) : (
              <PreviewProduct data={previewData} previewClose={() => setIspreview(!isPreview)} />
            )}

          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button
                functions={() => handleSubmit(stepAddData)}
                label={"Save"}
                loading={loading}
              />
              <Button label={"Cancel"} classes={"main-button-alt"} functions={() => setModalClose()} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSteps;
