import { useEffect, useState } from "react";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import AddFood from "../../components/Modals/AddFood";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import MenuItems from "../../components/Items/MenuItem";
import moment from "moment/moment";
import { detailsProduct, listProduct, listProductIngredient } from "../../helpers/api_helper";
import Pagination from "../../components/Elements/Pagination";
import Select from 'react-select';
import CatWiseModal from "../../components/Modals/CatWiseModal";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";




// Component for Diet Dropdown
function Diet({ setDiet }) {
  function useDiet() {
    return useQuery({
      queryKey: ["diets"],
      keepPreviousData: true,
      queryFn: async () => {
        let tmp = [];
        const { data } = await listProductIngredient(
          {
            status: true,
            type: 3,
            limit: 9999,
          });

        tmp.push({ _id: "", name: "All", color: "success", current: true });
        data.forEach((item) => {
          if (item.title === "Non veg") {
            tmp.push({
              _id: item._id,
              name: item.title,
              color: "danger",
              current: false,
            });
          } else {
            tmp.push({
              _id: item._id,
              name: item.title,
              color: "success",
              current: false,
            });
          }
        });

        return tmp;
      },
    });
  }

  const { data: diets } = useDiet();

  return <RadioGroup data={diets} setData={setDiet} inputName={"type"} />;
}

// Product section component with filtering and Pagination
const ComboList = () => {
  // For filtering data
  const [keyword, setKeyword] = useState("");
  const [selectedCat, setSelectedCat] = useState([]);
  const [selectedSpice, setSelectedSpice] = useState([]);
  const [selectedAllergy, setSelectedAllergy] = useState([]);
  const [diet, setDiet] = useState("");
  const [stock, setStock] = useState("");
  const [popular, setPopular] = useState("");
  const [status, setStatus] = useState("");
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  
  // For pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [productCount, setProductCount] = useState(0);

  // For selected options in dropdown
  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [selectedSpiceOpt, setSelectedSpiceOpt] = useState([]);
  const [selectedAllergyOpt, setSelectedAllergyOpt] = useState([]);
  const [editProductId, setEditProductId] = useState("");
  const [isOne, setDataOne] = useState(false);
 const [dataList, setDataList] = useState([]);
  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editProductId],
    queryFn: async () => detailsProduct({ id: editProductId }),
    enabled: false,
  });

  const queryProduct = [
    "combo-deals",
    keyword,
    selectedCat,
    selectedSpice,
    selectedAllergy,
    [diet],
    stock,
    popular,
    status,
    limit,
    offset,
  ];

  //console.log(selectedAllergy, queryProduct);

  const queryClient = useQueryClient();

  const [isAddModal, setIsAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isCatWise, setCatWiseModal] = useState(false);

  const [staticStockArr, setStaticStockArr] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: 1, name: "In stock", color: "success", current: false },
    { _id: 2, name: "Out of stock", color: "danger", current: false },
    { _id: 3, name: "Sold out for today", color: "warning", current: false },
  ]);

  const type = [
    { _id: 1, name: "All", color: "success", current: true },
    { _id: 2, name: "Veg", color: "success", current: false },
    { _id: 3, name: "Non Veg", color: "danger", current: false },
  ];

  const [popularity, setPopularity] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Popular", color: "warning", current: false },
    { _id: false, name: "Non Popular", color: "", current: false },
  ]);

  const [statusStatic, setStatusStaic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: 1, name: "Published", color: "success", current: false },
    { _id: 3, name: "Draft", color: "warning", current: false },
  ]);

  const [perPages, setPerPages] = useState([
     { label:"10 (Default)",value:10 },
     { label:20,value:20 },
     { label:50,value:50 },
     { label:100,value:100 },
     { label:"All",value:999999  },
  ]);

  
  const addModal = () => {
    setIsAddModal(true);
  };

  const addCatWise = () => {
    setCatWiseModal(true);
  };

  useEffect(() => {
    if (editProductId !== "" && editModal) {
      refetch();
    }
  }, [editModal,editProductId, refetch]);

// Product Items Component
function Product({
  
}) {
  function useProducts() {
    return useQuery({
      queryKey: queryProduct,
      queryFn: async () => listProduct({
        keyword: keyword,
        product_type: 4,
        product_category_id: selectedCat,
        status: status,
        spice_level_id: selectedSpice,
        admin_allergie_id: selectedAllergy,
        in_stock: stock,
        diet_id: diet === "" ? [] : [diet],
        featured: popular,
        addon_id: [],
        limit: limit,
        offset: offset,
        sortByField: "ordering",
        sortByValue: 1,
      }),
      keepPreviousData: true,
    });
  }

  const { status: sts, data: products, error, isFetching } = useProducts();
  
  setProductCount(products ? products.count : 0);



  useEffect(() => {
    //console.log(isOne);
    if (!isOne && products !== undefined) {
      setDataList(products?.data);
    }
  }, [isOne, products?.data]);

  if (isFetching) {
    return <Loading />
  }

  if (error) {
    return <Error />
  } else if (products.data.length === 0) {
    return <NoData/>
  } else {
    return (
      <>  
        {dataList && dataList.length > 0 && dataList.map((item,index) => (
          <MenuItems
            key={item._id}
            index={index}
            productId={item._id}
            name={item.title}
            type={item?.diet_list?.title}
            category={item.category_list}
            isPopular={item.featured}
            points={item.price_point}
            spiceLevel={item.spice_level_list}
            stock={item.in_stock}
            createdBy={item?.created_by_name?.name}
            createdDate={moment(new Date(item.created_at)).format("Do MMM YY")}
            updatedDate={moment(new Date(item.updated_at)).format("Do MMM YY")}
            plu_code={item.plu_code}
            status={item.status}
            price={item.regular_price}
            discountPercentage={item.sale_price}
            queryKeyProduct={queryProduct}
            detailsFetch={fetch}
            setEditProductId={setEditProductId}
            setEditModal={setEditModal}
          />
        ))}

        <Pagination count={products.count} limit={limit} offset={offset} setOffset={setOffset} />
      </>
    );
  }
}

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
        <div className="input-items">
              {perPages && 
              <div className="input">

                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={'Per Page'}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                 />
           </div>
             }
              </div>

          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>
          {/* <Category
            selectedCatOpt={selectedCatOpt}
            setSelectedCatOpt={setSelectedCatOpt}
            setCatArr={setSelectedCat}
          /> */}

          {/* <SpiceLvl
            selectedSpiceOpt={selectedSpiceOpt}
            setSelectedSpiceOpt={setSelectedSpiceOpt}
            setSpiceArr={setSelectedSpice}
          /> */}

          {/* <Allergy
            selectedAllergyOpt={selectedAllergyOpt}
            setSelectedAllergyOpt={setSelectedAllergyOpt}
            setAllergyArr={setSelectedAllergy}
          /> */}


          <div className="input-items items-end gap-3 ms-auto">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                setOffset(0);
                setKeyword("");
                setSelectedCat([]);
                setSelectedSpice([]);
                setSelectedAllergy([]);
                setDiet("");
                setStock("");
                setPopular("");
                setStatus("");
                setStaticStockArr([]);
                setPopularity([]);
                setStatusStaic([]);
                setSelectedCatOpt([]);
                setSelectedAllergyOpt([]);
                setSelectedSpiceOpt([]);
                setLimit(10);
                setLimit(10);
                setSelectedPageOpt([])
                setTimeout(() => {
                  setStaticStockArr([
                    { _id: "", name: "All", color: "success", current: true },
                    {
                      _id: 1,
                      name: "In stock",
                      color: "success",
                      current: false,
                    },
                    {
                      _id: 2,
                      name: "Out of stock",
                      color: "danger",
                      current: false,
                    },
                    {
                      _id: 3,
                      name: "Sold out for today",
                      color: "warning",
                      current: false,
                    },
                  ]);
                  setPopularity([
                    { _id: "", name: "All", color: "success", current: true },
                    {
                      _id: true,
                      name: "Popular",
                      color: "warning",
                      current: false,
                    },
                    {
                      _id: false,
                      name: "Non Popular",
                      color: "",
                      current: false,
                    },
                  ]);
                  setStatusStaic([
                    { _id: "", name: "All", color: "success", current: false },
                    {
                      _id: 1,
                      name: "Published",
                      color: "success",
                      current: true,
                    },
                    { _id: 3, name: "Draft", color: "warning", current: false },
                  ]);
                }, 1000);
                queryClient.invalidateQueries(queryProduct);
                queryClient.removeQueries({
                  queryKey: ["products"],
                  type: "inactive",
                });
              }}
            />
            <Button
              label={"Combo"}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="radio-filters">
          {staticStockArr && (
            <RadioGroup
              data={staticStockArr}
              setData={setStock}
              inputName={"stock"}
            />
          )}
          {staticStockArr && <Diet setDiet={setDiet} />}
          {popularity && (
            <RadioGroup
              data={popularity}
              setData={setPopular}
              inputName={"popularity"}
            />
          )}
          <RadioGroup
            data={statusStatic}
            setData={setStatus}
            inputName={"status"}
          />

          </div>

      </div>
      <div className="menu-list">
        <Product
          keyword={keyword}
          categoryArray={selectedCat}
          spiceLevelArray={selectedSpice}
          allergyArray={selectedAllergy}
          diet={diet}
          stock={stock}
          popular={popular}
          status={status}
          limit={limit}
          offset={offset}
          setLimit={setLimit}
          setOffset={setOffset}
          queryProduct={queryProduct}
          detailsFetch={refetch}
          setEditProductId={setEditProductId}
          setEditModal={setEditModal}
          setProductCount={setProductCount}
        />
      </div>
      <Modal
        title={"Add Combo"}
        size={"xl"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <AddFood isCombo={true} modalClose={() => setIsAddModal(!isAddModal)} />
      </Modal>

      <Modal
        title={"Edit Combo"}
        size={"xl"}
        isOpen={editModal}
        modalClose={() => {
          setEditModal(false);
          queryClient.removeQueries({
            queryKey: ["details"],
            exact: false
          });
        }} 
      >


        <AddFood isCombo={true} details={details} isFetching={isFetching} queryProduct={queryProduct} modalClose={() => setEditModal(false)} />

      </Modal>


      <Modal
        title={ selectedCatOpt[0]?.label + " Ordering"}
        size={"sm"}
        isOpen={isCatWise}
        modalClose={() => setCatWiseModal(!isCatWise)}
      >
        <CatWiseModal modalClose={() => {
          setCatWiseModal(!isCatWise);
        }}
           selectedCatOpt={selectedCatOpt} />
      </Modal>
    </>
  );
};

export default ComboList;
