import React, { useState, useEffect } from "react";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import AddVision from "../../components/Modals/AddVision";
import VisionItem from "../../components/Items/VisionItem";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  visionList,
  ordering,
} from "../../helpers/api_helper";
import moment from "moment/moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Pagination from "../../components/Elements/Pagination";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";

const Vision = () => {
  const [isAddModal, setIsAddModal] = useState(false);
  const [radioData, setRadioData] = useState(1);
  const [list, setList] = useState();
  const [editItemId, setEditItemId] = useState("");
  const [editModel, setEditModel] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isOne, setDataOne] = useState(false);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 99999999 },
  ]);

  

  const showToastMessage = (data) => {
    if (data.status === 200) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  const queryClient = useQueryClient();


  useEffect(()=>{
    setOffset(0)
    setLimit(10)
 
  },[keyword,radioData])
  const visionKey = ["vision", keyword, radioData, limit, offset];

  const status = [
    { _id: 1, name: "All", color: "success", current: true },
    { _id: 2, name: "Published", color: "success", current: false },
    { _id: 3, name: "Draft", color: "warning", current: false },
  ];

  function VisionList() {
    function useVision() {
      return useQuery({
        queryKey: visionKey,
        queryFn: () =>
          visionList({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: radioData === 1 ? "" : radioData === 2 ? true : false,
            limit: limit,
            offset: offset,
            type:1
          }),
        keepPreviousData: true,
      });
    }
   
    
    const { data: listData, error, isFetching } = useVision();

    useEffect(() => {
      
      if (!isOne) {
        setList(listData?.data);
      }
    }, [isOne, listData?.data]);

    const handleDragEnd = (e) => {
      
      if (!e.destination) return;
      let tempData = Array.from(list);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);

      setList([...tempData]);
      let dataArray = [];
      for (let i = 0; i < tempData.length; i++) {
        dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
      }

      onClickSaveOrdering(dataArray);
      setDataOne(true);
    };

    const onClickSaveOrdering = async (orderingData) => {
      const saveOrdering = await ordering({
        orderingData: orderingData,
        tableName: "mission_vision",
      });

      if (saveOrdering.status === 200) {
        setDataOne(false);
        queryClient.invalidateQueries(visionKey);
        queryClient.removeQueries({
          queryKey: ["vision"],
          type: "inactive",
        });
        toast.success(saveOrdering.message);
      } else {
        toast.error(saveOrdering.message);
      }
    };

    if (isFetching) {
      return <Loading />;
    } else if (error) {
      return <Error />;
    } else if (listData?.data?.length === 0) {
      return <NoData />;
    } else {
      return (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {Array.isArray(list) &&
                    list.map((item,index) => (
                      <VisionItem
                        key={item._id}
                        title={item.title}
                        id={item._id}
                        index={index}
                        serviceImage={item.image}
                        descriptions={item.description}
                        createdBy={item.created_by}
                        createdDate={moment(new Date(item?.created_at)).format(
                          "Do MMM YY"
                        )}
                        updatedDate={moment(new Date(item?.updated_at)).format(
                          "Do MMM YY"
                        )}
                        status={item.status === false ? 3 : 1}
                        isDraft={item.status}
                        setEditModel={setEditModel}
                        query={visionKey}
                        setEditItemId={setEditItemId}
                      />
                    ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Pagination
            count={listData?.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  const addModal = () => {
    setIsAddModal(true);
  };


  const handelReload = () => {

    queryClient.invalidateQueries(visionKey);
    queryClient.removeQueries({
      queryKey: ["visionKey"],
      type: "inactive",
    });

    setKeyword("");
    setOffset(0);
    setLimit(10);
    setRadioData(1);
    setSelectedPageOpt([]);
  };

  // const handelEdit = (id) => {
  //   setEditItemId(id);

  // };

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            <div className="input">
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={perPages}
                value={selectedPageOpt || []}
                placeholder={"Per Page"}
                onChange={(e) => {
                  setLimit(e.value)
                  setSelectedPageOpt(e);
                }}
              />
            </div>
          </div>

          <div className="input-items">
            <Search
              placeholder={"Search..."}
              setState={setKeyword}
              state={keyword}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto ">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={handelReload}
            />
            <Button
              label={"Add "}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="radio-filters">
          <RadioGroup
            data={status}
            inputName={"status"}
            setData={setRadioData}
          />
        </div>
      </div>

      <div className="vision-list">
      <VisionList></VisionList>
      </div>

      <Modal
        title={"Add"}
        size={"lg"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <AddVision
          showToastMessage={showToastMessage}
          setIsAddModal={setIsAddModal}
          query={visionKey}
        />
      </Modal>

      {/* edit  */}

      <Modal title={"Edit"} size={"lg"} isOpen={editModel} modalClose={() => setEditModel(false)}>
        <AddVision  showToastMessage={showToastMessage} setEditModel={setEditModel} itemId={editItemId} editMode={true} isOpen={editModel}  />
      </Modal>

      
    </>
  );
};

export default Vision;
