import { useState, useEffect } from 'react';
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addProduct, editProduct } from '../../helpers/api_helper';
import { toast } from 'react-toastify';

const AddMerchandise = ({ details, isFetching, query, modalClose}) => {

  const queryClient = useQueryClient();

  const [title, setTitle] = useState("");
  const [productId, setProductId] = useState("");
  const [price_point, setPrice_point] = useState("");
  const [price_point_disble, setPrice_point_disable] = useState(false);
  const [plu_code, setPlu_code] = useState(Date.now());
  const [description, setDescription] = useState("");
  const [in_stock, setIn_stock] = useState(1);
  const [status, setStatus] = useState(1); 

  const [uploadData, setUploadData] = useState([]);

  const [stockManageData, setStockManageData] = useState([
    {_id:1, label:'In Stock', inputName:'stockManage', current:true },
    {_id:2, label:'Out of stock', inputName:'stockManage', current:false },
  ])


  const [publishedData, setPublishedData] = useState([
    {_id:1, label:'Published', inputName:'publish_status', current:true },
    {_id:3, label:'Draft', inputName:'publish_status', current:false },
  ]);


  const submitMerch = useMutation(
    (payload) => addProduct(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["merchandise"],
            type: "inactive",
          });
          setTitle("");
          setPrice_point("");
          setPlu_code(Date.now());
          setDescription("");
          setIn_stock(1);
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const submitEditedMerch = useMutation(
    (payload) => editProduct(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["merchandise"],
            type: "inactive",
          });
          setTitle("");
          setPrice_point("");
          setPlu_code(Date.now());
          setDescription("");
          setIn_stock(1);
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    setPlu_code(Date.now());
  }, []);

  function generatePlu_code() {
    setPlu_code(Date.now());
  }

  useEffect(() => {
    if(details){
      setProductId(details ? details._id : "");
      setTitle(details ? details.title : "");
      setDescription(details ? details.short_description : "");
      setPrice_point(details ? details.price_point : "");
      setPlu_code(details ? details.plu_code : "");
      setIn_stock(details ? details.in_stock : true);
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setStatus(details ? details.status : "");

      setPublishedData([]);
      setStockManageData([]);



      setTimeout(() => {


      setPublishedData(
        details
          ? [
            {
              _id: 1,
              label: "Published",
              inputName: "publish_status",
              current: details.status === 1 ? true : false,
            },
            {
              _id: 3,
              label: "Draft",
              inputName: "publish_status",
              current: details.status === 3 ? true : false,
            },
          ]
          : []
      );

      setStockManageData(
        details
          ? [
            {
              _id: 1,
              label: "In Stock",
              inputName: "stockManage",
              current: details.in_stock === 1 ? true : false,
            },
            {
              _id:2,
              label:'Out of stock',
              inputName:'stockManage',
              current:details.in_stock === 2 ? true : false,
             },
          ]
          : []
      );
    }, 700)}
  }, [details])

  

  if(isFetching){
    return(
      <div>Loading...</div>
    )
  }

  if(details) { 
    return (
      <>
        <div className="px-2">
          <div className="row divide-x">
            <div className="col-lg-8">
  
              <div className="row">
                <div className="col-md-7">
                  <div className="mb-3">
                    <Input
                      state={title}
                      setState={setTitle}
                      label={'Product Name'}
                      inputRequired={true}
                      inputType={'text'}
                      inputPlaceholder={'Example . Tshirt'}
                      inputName={''}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="mb-3">
                    <Input
                      state={plu_code}
                      setState={setPlu_code}
                      label={'PLU Code'}
                      inputRequired={true}
                      inputType={'text'}
                      inputPlaceholder={'1667303409038'}
                      inputName={''}
                      labelButtonName={'Generate'}
                      labelButtonFunction={generatePlu_code}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <Input
                      state={price_point}
                      setState={setPrice_point}
                      label={'Point Price'}
                      inputRequired={true}
                      inputType={'number'}
                      inputPlaceholder={'€ 250'}
                      inputName={''}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="mb-4">
                    <RadiosLabel
                      data={stockManageData}
                      setData={setStockManageData}
                      setRadio={setIn_stock}
                      label={'Stock Manage'} 
                      inputRequired={true}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <Textarea
                      state={description}
                      setState={setDescription}
                      label={'Description'}
                      inputRequired={true}
                      inputPlaceholder={'Write description.......'}
                      inputName={''}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-4">
                    <RadiosLabel
                      setRadio={setStatus}
                      data={publishedData} 
                      setData={setPublishedData}
                      label={'Status'} 
                      inputRequired={true}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="d-flex flex-wrap gap-3">
                    <Button label={'Save'} functions={() =>
                        submitEditedMerch.mutate({
                          id: productId,
                          title: title,
                          product_type: 3,
                          plu_code: plu_code,
                          price_point: parseInt(price_point),
                          short_description: description,
                          in_stock: in_stock,
                          status: status,
                          image: uploadData && uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image,
                        })}/>
                    <Button 
                      label={'Cancel'} 
                      classes={'main-button-alt'} 
                      functions={modalClose} 
                    />
                  </div>
                </div>
              </div>
  
            </div>
            <div className="col-lg-4">
  
              <div className="row">
                <div className="col">
                  <ImageUploadPreview 
                    data={uploadData}
                    setImage={setUploadData}
                    label={'Upload Image'} 
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );  
  }else {
    return (
      <>
        <div className="px-2">
          <div className="row divide-x">
            <div className="col-lg-8">
  
              <div className="row">
                <div className="col-md-7">
                  <div className="mb-3">
                    <Input
                      state={title}
                      setState={setTitle}
                      label={'Product Name'}
                      inputRequired={true}
                      inputType={'text'}
                      inputPlaceholder={'Example . Pizza'}
                      inputName={''}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="mb-3">
                    <Input 
                      state={plu_code}
                      setState={setPlu_code}
                      label={'PLU Code'}
                      inputRequired={true}
                      inputType={'text'}
                      inputPlaceholder={'1667303409038'}
                      inputName={''}
                      labelButtonName={'Generate'}
                      labelButtonFunction={generatePlu_code}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="mb-3">
                    <Input 
                      state={price_point}
                      setState={setPrice_point}
                      isDisabled={price_point_disble}
                      current={price_point_disble}
                      setCurrent={setPrice_point_disable}
                      label={'Point Price'}
                      inputRequired={true}
                      inputType={'number'}
                      inputPlaceholder={'€ 250'}
                      inputName={''}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="mb-4">
                    <RadiosLabel
                      data={stockManageData}
                      setData={setStockManageData}
                      setRadio={setIn_stock}
                      label={'Stock Manage'} 
                      inputRequired={true}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <Textarea
                      state={description}
                      setState={setDescription}
                      label={'Description'}
                      inputRequired={true}
                      inputPlaceholder={'Write description.......'}
                      inputName={''}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-4">
                    <RadiosLabel 
                      data={publishedData}
                      setData={setPublishedData}
                      setRadio={setStatus}
                      label={'Status'} 
                      inputRequired={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="d-flex flex-wrap gap-3">
                    <Button label={'Save'} functions={() =>
                        submitMerch.mutate({
                          title: title,
                          product_type: 3,
                          plu_code: plu_code,
                          price_point: parseInt(price_point),
                          short_description: description,
                          in_stock: in_stock,
                          status: status,
                          image: uploadData ? uploadData[0]?.image : null,
                        })}/>
                    <Button label={'Cancel'} classes={'main-button-alt'} functions={modalClose}/>
                  </div>
                </div>
              </div>
  
            </div>
            <div className="col-lg-4">
  
              <div className="row">
                <div className="col">
                  <ImageUploadPreview 
                    data={uploadData}
                    setImage={setUploadData}
                    label={'Upload Image'} 
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );  
  }
  
};

export default AddMerchandise;