import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import PreviewProduct from "../Elements/PreviewProduct";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { pageListAdd, pageListEdit } from "../../helpers/api_helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import slugify from 'slugify'

const AddPage = ({ query, setIsAddModal, editData, status, setStatus, id,detailsFetch }) => {

  const [isPreview, setIspreview] = useState(false);
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [slug, setSlug] = useState('')
  const [description, setDescription] = useState('')
  const [radio, setRadio] = useState('')
  const [loading, setLoading] = useState(false)
  const [uploadData, setUploadData] = useState([''])

  // ======================================================>

  const queryClient = useQueryClient()

  useEffect(() => {
    slugCreate()
  }, [title])


  useEffect(() => {
    if (status === false) {
      editField()
    } else {
      setTitle('')
      setSubTitle('')
      setSlug('')
      setDescription('')
      setUploadData('')
    }
  }, [editData, status])


  const publishedData = [
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ];

  const previewData = [
    {
      image: 'images/product.png',

    }
  ]

  // add page list data============>

  const addPageList = useMutation(
    (payload) => pageListAdd(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setTitle('')
          setSubTitle('')
          setSlug('')
          setDescription('')
          setIsAddModal(!setIsAddModal);
          setLoading(false);
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["pagelists"],
            type: "inactive",
          })
        } else {
          setLoading(false)
          toast.error(res.message);
        }
      }
    }
  )

  // edit page list data====================>

  const editPageList = useMutation(
    (payload) => pageListEdit(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setTitle('')
          setSubTitle('')
          setSlug('')
          setDescription('')
          setUploadData('')
          setIsAddModal(!setIsAddModal);
          setLoading(false);
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["pagelists"],
            type: "inactive",
          })
        } else {
          setLoading(false)
          toast.error(res.message);
        }
      }
    }
  )

  // add or edit list function when clicked in save button===================>


  const addList = () => {
    setLoading(true)
    if (status) {
      addPageList.mutate({ title, subtitle: subTitle, slug, description, image: uploadData[0]?.image })
    } else {
      editPageList.mutate({ title, subtitle: subTitle, slug, description, image: uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image, id })
    }
  }

  // slug create functions======================>

  const slugCreate = () => {
    const slugCreate = slugify((title || ''), { remove: /[*+~.()'"!:@#^]/g }).toLowerCase()
    setSlug(slugCreate)
  }

  // input fid automatic add data when clicked in edit button from list=======================>

  const editField = () => {
    setTitle(editData?.title)
    setSubTitle(editData?.subtitle)
    setSlug(editData?.slug)
    setDescription(editData?.description)
    setUploadData([{ image: editData?.image }])
  }

  // modal closed==========================>

  const closeModal = () => {
    setIsAddModal(!setIsAddModal)
    setStatus(true)
  }

  if(detailsFetch){
    return <>loading...</>
  }


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="mb-3">
                <Input state={title} setState={setTitle} label={"Title"} inputRequired={true} inputType={"text"} inputPlaceholder={"Title"} inputName={""} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input state={subTitle} setState={setSubTitle} label={"Sub Title"} inputRequired={true} inputType={"text"} inputPlaceholder={"Sub Title"} inputName={""} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input state={slug} label={"Slug"} inputRequired={true} inputType={"text"} inputPlaceholder={"Slug"} inputName={""} />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Textarea state={description} setState={setDescription} label={"Description"} inputRequired={true} inputPlaceholder={"Write description......."} inputName={""} />
              </div>
            </div>
            {/* <div className="row">
              <div className="mb-4">
                <RadiosLabel setRadio={setRadio} data={publishedData} label={"Status"} inputRequired={true} />
              </div>
            </div> */}
          </div>
          <div className="col-lg-5">
            {!isPreview ? (
              <>
                <div className="row">
                  <div className="col">
                    <ImageUploadPreview setImage={setUploadData} data={uploadData} label={'Upload Image'} inputRequired={true} />
                  </div>
                </div>

              </>
            ) : (
              <PreviewProduct data={previewData} previewClose={() => setIspreview(!isPreview)} />
            )}

          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button label={"Save"} functions={addList} loading={loading} />
              <Button label={"Cancel"} classes={"main-button-alt"} functions={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPage;
