import { useEffect, useState } from "react";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import AddBenefits from "../../components/Modals/AddBenefits";
import BenefitsItem from "../../components/Items/BenefitsItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { detailsBenefits, listBenefits, ordering } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Pagination from "../../components/Elements/Pagination";
import moment from "moment";
import Select from 'react-select';

const Benefits = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [isOne, setDataOne] = useState(false);

  //Data
  const [dataList, setDataList] = useState([]);

  // For pagination
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  // Dynamic query key for Benefits 🗝️
  const queryBenefits = ["benefits", keyword, status, limit, offset];

  // For opening Add Modal althogh not mandetory
  const addModal = () => {
    setIsAddModal(true);
  };

  // For fetching details of item
  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsBenefits({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "" && isEditModal) {
      refetch();
    }
  }, [editId, refetch, isEditModal]);

  // Benefits List Component
  function BenefitsList() {
    // Custom hook for fetching benefits list
    function useBenefits() {
      return useQuery({
        queryKey: queryBenefits,
        queryFn: () =>
          listBenefits({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: benefits, error, isFetching } = useBenefits();

    useEffect(() => {
      if (!isOne) {
        setDataList(benefits?.data);
      }
    }, [isOne, benefits?.data]);

    const handleDragEnd = (e) => {
      //console.log(e);
      if (!e.destination) return;
      let tempData = Array.from(dataList);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
      // console.log(tempData);
      setDataList([...tempData]);
      let dataArray = [];
      for (let i = 0; i < tempData.length; i++) {
        dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
      }
      // setOrderingData(dataArray);
      onClickSaveOrdering(dataArray);
      setDataOne(true);
    };
    const onClickSaveOrdering = async (orderingData) => {
      const saveOrdering = await ordering({
        orderingData: orderingData,
        tableName: "account_benefit",
      });

      if (saveOrdering.status === 200) {
        setDataOne(false);
        queryClient.invalidateQueries(queryBenefits);
        queryClient.removeQueries({
          queryKey: ["benefits"],
          type: "inactive",
        });
        toast.success(saveOrdering.message);
      } else {
        toast.error(saveOrdering.message);
      }
    };

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (benefits.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {dataList &&
                    dataList.length > 0 &&
                    dataList.map((item, index) => (
                      <BenefitsItem
                        key={item._id}
                        index={index}
                        BenefitsId={item._id}
                        icon={item?.image}
                        title={item?.title}
                        createdDate={moment(new Date(item?.created_at)).format(
                          "Do MMM YY"
                        )}
                        updatedDate={moment(new Date(item?.updated_at)).format(
                          "Do MMM YY"
                        )}
                        query={queryBenefits}
                        status={item?.status}
                        detailsFetch={refetch}
                        setEditId={setEditId}
                        setEditModal={setIsEditModal}
                      />
                    ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Pagination
            count={benefits.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }
  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
        <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryBenefits);
                queryClient.removeQueries({
                  queryKey: ["benefits"],
                  type: "inactive",
                });
                setOffset(0);
                setKeyword("");
                setStatus("");
                setSelectedPageOpt([])
                setStatusStatic([]);
                setTimeout(() => {
                  setStatusStatic([
                    { _id: "", name: "All", color: "success", current: true },
                    {
                      _id: true,
                      name: "Published",
                      color: "success",
                      current: false,
                    },
                    {
                      _id: false,
                      name: "Draft",
                      color: "warning",
                      current: false,
                    },
                  ]);
                }, 300);
              }}
            />
            <Button
              label={"Add"}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="input-items">
          <RadioGroup
            data={statusStatic}
            setData={setStatus}
            inputName={"status"}
          />
        </div>
      </div>
      <div className="benefits-list">
        <BenefitsList/>
      </div>
      
       {/* // For add Modal */}
       <Modal
        title={"Add Benefits"}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(false)}
      >
        <AddBenefits modalClose={() => setIsAddModal(false)} query={queryBenefits} />
      </Modal>

      {/* //For Edit Modal */}
      <Modal
        title={"Edit Benefits"}
        size={"md"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(false)}
      >
        <AddBenefits
          modalClose={() => setIsEditModal(false)}
          query={queryBenefits}
          details={details}
          isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default Benefits;
