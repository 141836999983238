import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import TopNavigation from "../../components/Navigation/TopNavigation";
import {setTitle} from '../../helpers/MetaTag';
import Applications from "./Applications";
import JobsList from "./JobsList";
import JobType from "./JobType";
import Location from "./Location";

const Jobs = () => {
  setTitle('Dabbawala | Jobs');
  const [currentLocation, setCurrentLocation] = useState();

  const location = useLocation();
  const tabData = [
    {_id:1, label:'Jobs List', link:'/jobs', search:'?tab=list' , component:<JobsList/>},
    {_id:2, label:'Job Type', link:'/jobs', search:'?tab=type' , component:<JobType/>},
    {_id:3, label:'Location Type', link:'/jobs', search:'?tab=location' , component:<Location/>},
    {_id:4, label:'Job Applications', link:'/jobs', search:'?tab=applications', component:<Applications/>},
  ]

  useEffect(()=>{
    let index = tabData.findIndex((tab) => tab.search === location.search)
    if(index !== -1){
      setCurrentLocation(tabData[index]);
    }
  },[location]);

  return (
    <>
     <TopNavigation data={tabData}/>
      <div className="page-content">
      {currentLocation &&
          currentLocation.component
      }
      </div>
    
    </>
  );
};

export default Jobs;
