import React, { useState } from "react";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import DropdownCheckbox from "../Dropdowns/DropdownCheckbox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { assignRider, RiderList, stsChngLiveOrder } from "../../helpers/api_helper";
import Select from "react-select";
import SelectTag from "../Form/SelectTag";
import { toast } from "react-toastify";

const AssignRider = ({currId, currOrderId, modalClose}) => {

  const queryClient = useQueryClient();


  function useRiders() {
    return useQuery({
      queryKey: ["rider_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await RiderList({available:true});
        
        data.forEach((item) => {
          tmp.push({ value: item._id, label: item.name });
        });

        return tmp;
      },
    });
  }


  const {mutate: statusChange} = useMutation(
    (changeSts) => stsChngLiveOrder(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(['live_order']);
          queryClient.removeQueries({
            queryKey: ["live_order"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const {mutate: riderAssign} = useMutation(
    (changeSts) => assignRider(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(['live_order']);
          queryClient.removeQueries({
            queryKey: ["live_order"],
            type: "inactive",
          });
          modalClose();        
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { data: riders } = useRiders();

  const [selectedRider, setSelectedRider] = useState([]);
  const [riderId, setRiderId] = useState('');


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <div className="order-info">ORDER ID <span>{currOrderId}</span> </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
              {riders && 
              <div className="input">
                {/* <Select
          className="select-tag"
          isMulti={true}
          isClearable={true}
          options={riders}
          value={selectedRider || []}
          placeholder={"Rider Name"}
          onChange={(e) => {
            let a = [];
            e.forEach((item) => {
              a.push(item.value);
            });
            setRiderId(a);
            setSelectedRider(e);
          }}
        /> */}

                      {/* <SelectTag
                        selectedOptions={selectedRider}
                        setSelectedOptions={setSelectedRider}
                          setStateId={setRiderId}
                          data={riders}
                          placeholder={"Rider Name"}
                          // label={"Spice Level"}
                          inputRequired={true}
                          // isCheckbox={true}
                          // checkboxPosition={"right"}
                        />  */}
                       
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={riders}
                  placeholder={"Rider Name"}
                  value={selectedRider || []}
                  onChange={(e) => {
                    setRiderId(e.value);
                    setSelectedRider(e);
                  }}
                />
           </div>}
               
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              {riderId && <Button label={"Save"} functions={() => {
                riderAssign({
                  "id":currId,
                  "rider_id": riderId
              })
              statusChange({
                id: currId,
                type_id: 11
              })
              }} />}
              <Button label={"Cancel"} classes={"main-button-alt"} functions={modalClose}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignRider;
