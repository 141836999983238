import { useEffect, useState } from "react";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import BannerItem from "../../components/Items/BannerItem";
import AddBanner from "../../components/Modals/AddBanner";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BannersDelete, BannersDetails, BannersList } from "../../helpers/api_helper";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import { toast } from "react-toastify";
import Pagination from "../../components/Elements/Pagination";
import moment from "moment";
import Select from 'react-select';
import Confirm from "../../components/Elements/Confirm";

const Banner = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId,setDeleteId] = useState('')


  // For Managing Modals
  const [modalState, setModalState] = useState(true)
  const [isAddModal, setIsAddModal] = useState(false);
  // const [isEditModal, setIsEditModal] = useState(false);

  const [isOne, setDataOne] = useState(false);

  //Data
  const [dataList, setDataList] = useState([]);

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  // For pagination======================>
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);

  // Dynamic query key for Banner 🗝️
  const queryBanners = ["banners", keyword, status, limit, offset];

  // For opening Add Modal althogh not mandetory
  const addModal = () => {
    setIsAddModal(true);
    setModalState(true)
  };
  const editmodal = (id) => {
    setEditId(id)
    setIsAddModal(true);
    setModalState(false)
  };

  // For fetching details of item
  const {
    data: details,
    error,
    refetch,
    isFetching:detailsFetch,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => BannersDetails({ id: editId }),
    enabled: false,
  });


  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);


  // Banner List Component
  function BannersLists() {
    // Custom hook for fetching banner list
    function useBanners() {
      return useQuery({
        queryKey: queryBanners,
        queryFn: () =>
          BannersList({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: banners, error, isFetching } = useBanners();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (banners.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {banners.data.length > 0 &&
            banners.data.map((item, index) => {
              return (
                <BannerItem
                  key={item._id}
                  index={index}
                  bannerId={item._id}
                  image={item?.image}
                  name=<div dangerouslySetInnerHTML={{ __html: item?.title }} />
                  descriptions={item?.description}
                  type={item?.type}
                  page_type={item?.page_type}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryBanners}
                  detailsFetch={refetch}
                  setEditId={setEditId}
                  functions={editmodal}
                  setDeleteId={setDeleteId}
                  setDeleteModal={setDeleteModal}
                />
              );

            })}

          <Pagination
            count={banners.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  // delete List===========>

  const bannersListDelete = useMutation(
    (payload)=>BannersDelete(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setDeleteModal(false);
          toast.success(res.message);
          queryClient.invalidateQueries(queryBanners);
          queryClient.removeQueries({
            queryKey: ["banners"],
            type: "inactive",
          });
        } else {
          toast.error(res.message);
        }
      }
    }
  )

  const deleteBannerList = () => {
    bannersListDelete.mutate({id:deleteId}) 
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto ">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryBanners);
                queryClient.removeQueries({
                  queryKey: ["banners"],
                  type: "inactive",
                });
                setOffset(0);
                setLimit(10);
                setKeyword("");
                setSelectedPageOpt([])
              }}
            />
            <Button
              label={"Add"}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        {/* <div className="radio-filters">
          <RadioGroup data={status} inputName={"status"} />
        </div> */}
      </div>

      <div className="banner-list">
        <BannersLists />
      </div>

      {/* // For add Modal */}
      <Modal
        title={modalState === true ? "Add Banner" : "Edit Banner"}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(false)}
      >
        <AddBanner
          modalClose={setIsAddModal}
          data={details}
          status={modalState}
          detailsFetch={detailsFetch}
          query={queryBanners}
        />
      </Modal>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        setDeleteModal={setDeleteModal}
        confirmSubmit={deleteBannerList}
      />
    </>
  );
};

export default Banner;
