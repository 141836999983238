import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import { jobTypeAdd } from "../../helpers/api_helper";
import { jobTypeEdit } from "../../helpers/api_helper";
import { Loading } from "../Elements/Loading";
import { toast } from "react-toastify";

const AddJobType = ({ query, modalClose, details, isFetching }) => {

  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");

  const { mutate: handleSubmit } = useMutation(
    (payload) => jobTypeAdd(payload),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobTypes"],
            type: "inactive",
          });
          setTitle("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => jobTypeEdit(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobTypes"],
            type: "inactive",
          });
          setTitle("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (details) {
      setTitle(details ? details.type : "");
      setStatus(details ? details.status ? 1 : 2 : 1);
      setPublishedData([]);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 100);
    }
  }, [details]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Name"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Part Time"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel
                    data={publishedData} 
                    setData={setPublishedData}
                    setRadio={setStatus}
                    label={"Status"}
                    inputRequired={true}
                    
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                {details ? <Button label={"Save"} 
                  functions={() => handleEdit({
                    id: details._id,
                    type: title,
                    status: status === 1 ? true : false,
                  })}
                  /> : <Button label={"Save"} 
                  functions={() => handleSubmit({
                    type: title,
                    status: status === 1 ? true : false,
                  })}
                  />}
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddJobType;
