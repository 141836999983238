import React, { useState } from "react";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import DropdownCheckbox from "../Dropdowns/DropdownCheckbox";
import { useNavigate } from "react-router";

const OrderModal = ({modalClose,count,setNewOrderInfo}) => {
 const navigator = useNavigate();
 const closeOrder = () =>{
    modalClose();
    setNewOrderInfo([]);
    navigator('/orders?tab=list');
 }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <div className="order-info">New Order </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <p>You have received {count} new order</p>
               
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button label={"View Orders"} functions={()=>closeOrder()} />
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderModal;
