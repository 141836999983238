import { useEffect, useState } from "react";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import DriverItem from "../../components/Items/DriverItem";
import Modal from "../../components/Elements/Modal";
import AddRider from "../../components/Modals/AddRider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RiderDetails, RiderList } from "../../helpers/api_helper";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import moment from "moment";
import Pagination from "../../components/Elements/Pagination";
import RadioGroup from "../../components/Form/RadioGroup";
import Select from "react-select";

const Drivers = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);


  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");

  // For pagination
  const [perPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Available", color: "success", current: false },
    { _id: false, name: "Not Available", color: "warning", current: false },
  ]);

  // Dynamic query key for Riders 🗝️
  const queryRiders = ["riders", keyword, status, limit, offset];

  // For opening Add Modal althogh not mandetory
  const addModal = () => {
    setIsAddModal(true);
  };

  // For fetching details of item
  const {
    data: details,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => RiderDetails({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "" && isEditModal) {
      refetch();
    }
  }, [editId, refetch, isEditModal]);

  // Rider List Component
  function RidersList() {
    // Custom hook for fetching rider list
    function useRider() {
      return useQuery({
        queryKey: queryRiders,
        queryFn: () =>
          RiderList({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            available: status,
            limit: limit,
            offset: offset,
            role: "delivery-rider",
          }),
        keepPreviousData: true,
      });
    }

    const { data: riders, error, isFetching } = useRider();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (riders.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          
          {riders.data.length > 0 &&
            riders.data.map((item, index) => {
              return (
                <DriverItem
                  key={item._id}
                  index={index}
                  driverId={item._id}
                  image={item?.image}
                  name={item?.name}
                  phone={item?.phone}
                  descriptions={item?.description}
                  email={item?.email}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryRiders}
                  status={item?.available}
                  // detailsFetch={refetch}
                  setEditId={setEditId}
                  setEditModal={setIsEditModal}
                />
              );
            })}

          
          <Pagination
            count={riders.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto ">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryRiders);
                queryClient.removeQueries({
                  queryKey: ["riders"],
                  type: "inactive",
                });
                setOffset(0);
                setKeyword("");
                setStatus("");
                setStatusStatic([]);
                setSelectedPageOpt([]);
                setTimeout(() => {
                  setStatusStatic([
                    { _id: "", name: "All", color: "success", current: true },
                    {
                      _id: true,
                      name: "Available",
                      color: "success",
                      current: false,
                    },
                    {
                      _id: false,
                      name: "Not Available",
                      color: "warning",
                      current: false,
                    },
                  ]);
                }, 300);
              }}
            />
            <Button
              label={"Add"}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="radio-filters">
          {statusStatic && (
            <RadioGroup
              data={statusStatic}
              setData={setStatus}
              inputName={"status"}
            />
          )}
        </div>
      </div>

      <div className="driver-list">
        <RidersList />
      </div>
      <Modal
        title={"Add"}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <AddRider modalClose={() => setIsAddModal(false)} query={queryRiders} />
      </Modal>
      <Modal
        title={"Edit Rider"}
        size={"md"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(!isEditModal)}
      >
        <AddRider
          modalClose={() => setIsEditModal(false)}
          query={queryRiders}
          details={details}
          isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default Drivers;
