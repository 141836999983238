import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link, NavLink } from "react-router-dom";

const DashboardNavigation = ({data}) => {
  const location = useLocation();
  return (
    <>
      <ul className="statmenutabbed-list">
        {data.map((item) => (
        <li key={item._id}>
          <Link to={item.link+item.search} className={"navigation-items " + ((location.search === item.search)?'active':'')}>{item.label}</Link>
        </li>
        ))}
      </ul>
    </>
  );
};

export default DashboardNavigation;