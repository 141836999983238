import { useState } from "react";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import CommentItem from "../../components/Items/CommentItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { listComments } from "../../helpers/api_helper";
import moment from "moment";
import Pagination from "../../components/Elements/Pagination";
import Select from 'react-select';

const CommentsList = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // For pagination
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  // Dynamic query key for Comments 🗝️
  const queryComments = ["comments", keyword, status, limit, offset];

  // For opening Add Modal althogh not mandetory
  const addModal = () => {
    setIsAddModal(true);
  };

  // Comments List Component
  function CommentList() {
    // Custom hook for fetching Comment list
    function useComment() {
      return useQuery({
        queryKey: queryComments,
        queryFn: () =>
          listComments({
            keyword: keyword,
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: comments, error, isFetching } = useComment();

    // Handling loading state 😑
    if (isFetching) {
      return (
        <div className="noresult">
          <div className="text-center">
            <div className="fa-solid fa-circle-notch fa-spin"></div>
          </div>
        </div>
      );
    }

    // Handling error state 🥴
    if (error) {
      return <div>Something went wrong 🫥</div>;
    }

    // Check if their is no data 🤨
    else if (comments.data.length === 0) {
      return (
        <div className="noresult">
          <div className="text-center">
            <h5 className="mt-2">Sorry! No Result Found</h5>
          </div>
        </div>
      );
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {comments.data.length > 0 &&
            comments.data.map((item, index) => {
              return (
                <CommentItem
                  key={item._id}
                  index={index}
                  CommentId={item._id}
                  blogTitle={item?.blog_title?.title.substring(0, 15)}
                  name={item?.name}
                  email={item?.email}
                  comment={item?.comment.substring(0, 25)}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryComments}
                  status={item?.status}
                  setEditId={setEditId}
                  setEditModal={setIsEditModal}
                />
              );
            })}

          <Pagination
            count={comments.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
        <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              placeholder={"Search..."}
              state={keyword}
              setState={setKeyword}
            />
          </div>

          {/* <div className="input-items items-end ms-auto gap-3">
            <Button classes={"px-2"} icon={"fa-regular fa-arrow-rotate-right"} iconPosition={"left"} />
            <Button label={"Add Blog"} labelClasses={""} classes={""} icon={"fa-regular fa-plus"} iconPosition={"left"} functions={addModal} />
          </div> */}
        </div>
        <div className="radio-filters">
          <RadioGroup
            data={statusStatic}
            setData={setStatus}
            inputName={"status"}
          />
        </div>
      </div>
      <div className="comment-list">
        <CommentList />
      </div>
    </>
  );
};

export default CommentsList;
