import React from 'react'
import { useJwt } from "react-jwt";
import { Navigate } from 'react-router-dom';

export default function ProtectedLayout(props) {
    let token = localStorage.getItem('access_token');
    const { decodedToken, isExpired } = useJwt(token);

    if (!(decodedToken !== null ? decodedToken?.role_id === "6334278c7c01eee674d6d8eb" ?
        true : 
        false :
        false || !isExpired)
       ) {
            localStorage.removeItem("access_token");
        return (
            <Navigate replace to='/' />
        );
      }
    
      return <>{props.children}</>;
}
