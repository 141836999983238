import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import FutureItem from "../../components/Items/FutureItem";
import TopNavigation from "../../components/Navigation/TopNavigation";
import {setTitle} from '../../helpers/MetaTag';
import Banner from "./Banner";
import Benefits from "./Benefits";
import FunFacts from "./FunFacts";
import FutureTeam from "./FutureTeam";
import Highlights from "./Highlights";
import Mission from "./Mission";
import OpeningHours from "./OpeningHours";
import Page from "./Page";
import PageSettings from "./PageSettings";
import Services from "./Services";
import Steps from "./Steps";
import Team from "./Team";
import Vision from "./Vision";

const Cms = () => {
  setTitle('Dabbawala | CMS Management');
  const [currentLocation, setCurrentLocation] = useState();

  const location = useLocation();
  const tabData = [
    {_id:1, label:'Steps', link:'/cms', search:'?tab=steps' , component:<Steps/>},
    {_id:2, label:'Services', link:'/cms', search:'?tab=services' , component:<Services/>},
    {_id:3, label:'Benefits', link:'/cms', search:'?tab=benefits' , component:<Benefits/>},
    {_id:4, label:'Fun Facts', link:'/cms', search:'?tab=fun-facts', component:<FunFacts/>},
    {_id:5, label:'Teams', link:'/cms', search:'?tab=teams' ,  component:<Team/>},
    {_id:6, label:'Missions', link:'/cms', search:'?tab=missions' , component:<Mission/>},
    {_id:7, label:'Visions', link:'/cms', search:'?tab=visions' , component:<Vision/>},
    {_id:8, label:'Highlights', link:'/cms', search:'?tab=highlights', component:<Highlights/>},
    {_id:9, label:'Future Team', link:'/cms', search:'?tab=future-team' , component:<FutureTeam/>},
    {_id:10, label:'Opening Hours', link:'/cms', search:'?tab=opening-hours' , component:<OpeningHours/>},
    {_id:11, label:'Pages', link:'/cms', search:'?tab=pages' , component:<Page/>},
    {_id:12, label:'Banners', link:'/cms', search:'?tab=banners', component: <Banner/>},
    {_id:13, label:'Site Settings', link:'/cms', search:'?tab=site-settings',component:<PageSettings/>},
  ]

  useEffect(()=>{
    let index = tabData.findIndex((tab) => tab.search === location.search)
    if(index !== -1){
      setCurrentLocation(tabData[index]);
    }
  },[location]);

  return (
    <>
     <TopNavigation data={tabData}/>
      <div className="page-content">
      {currentLocation &&
          currentLocation.component
      }
      </div>
    
    </>
  );
};

export default Cms;
