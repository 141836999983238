import { useEffect, useState } from "react";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import CustomerItem from "../../components/Items/CustomerItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { detailsAdmin, listAdmin } from "../../helpers/api_helper";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import moment from "moment";
import Pagination from "../../components/Elements/Pagination";
import Select from "react-select";
import AddCustomers from "../../components/Modals/AddCustomers";
import Modal from "../../components/Elements/Modal";

const Customers = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For pagination
  const [perPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // For Managing Modals
  const [isEditModal, setIsEditModal] = useState(false);


  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status] = useState("");

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");

  // Dynamic query key for Customer 🗝️
  const queryCustomer = ["customer", keyword, status, limit, offset];


  // For fetching details of item
  const {
    data: details,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsAdmin({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);

  // Diet List Component
  function CustomerList() {
    // Custom hook for fetching diet list
    function useCustomer() {
      return useQuery({
        queryKey: queryCustomer,
        queryFn: () =>
          listAdmin({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
            role: "user",
          }),
        keepPreviousData: true,
      });
    }

    const { data: customer, error, isFetching } = useCustomer();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (customer.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {customer.data.length > 0 &&
            customer.data.map((item, index) => {
              return (
                <CustomerItem
                  key={item._id}
                  index={index}
                  customerId={item._id}
                  image={item?.image}
                  name={item?.name}
                  email={item?.email}
                  phone={item?.phone}
                  email_verified={item?.email_verified}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryCustomer}
                  status={item?.status}
                  detailsFetch={refetch}
                  setEditId={setEditId}
                  setEditModal={setIsEditModal}
                />
              );
            })}

          <Pagination
            count={customer.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto ">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryCustomer);
                queryClient.removeQueries({
                  queryKey: ["customer"],
                  type: "inactive",
                });
                setOffset(0);
                setKeyword("");
                setSelectedPageOpt([])
              }}
            />
          </div>
        </div>
      </div>

      <div className="customer-list">
        <CustomerList />
      </div>

      {/* //For Edit Modal */}
      <Modal
        title={"Edit Customers"}
        size={"md"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(false)}
      >
        <AddCustomers
          modalClose={() => setIsEditModal(false)}
          query={queryCustomer}
          details={details}
          isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default Customers;
