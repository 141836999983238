import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { visionDelete, visionStatusChange } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

import RadioGroup from "../Form/RadioGroup";

const VisionItem = ({ serviceImage, title, descriptions, createdBy, createdDate, updatedDate, status,isDraft,id=null,setEditModel,query,
  setEditItemId,index
}) => {
  const [deleteModal,setDeleteModal]=useState(false)
  const queryClient=useQueryClient()
  const { mutate:statusChange } = useMutation(visionStatusChange, {
    onSuccess: (data) => {
        if(data.status===200){
          toast.success(data.message)
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["vision"],
            type: "inactive",
          });
          

        }else{
          toast.error(data.message)
        }
          
    },
  });

  const { mutate:deleteItem } = useMutation(visionDelete, {
    onSuccess: (data) => {
        
        if(data.status===200){
          toast.success(data.message)
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["vision"],
            type: "inactive",
          });
          

        }else{
          toast.error(data.message)
        }
          
    },
  });


  return (
    <> 
     <Draggable draggableId={id} index={index} key={id} >

        {(provided) => (
      <div className="vision-item mb-3" 
      key={id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
        <div className="vision-item-row">
          <div className="vision-content">
            <div className="vision-image">
              <img src={serviceImage} />
            </div>
            <div className="vision-details">
              <span>Title</span>
              <div className="vision-title">{title}</div>
              <div className="vision-descriptions">
                <span>Descriptions</span>
                <div className="des">{descriptions}</div>
              </div>
            </div>
          </div>
          <div className="vision-options">
            <div className={"status " + (status === 1 ? "published" : "draft")}>{status === 1 ? "published" : "draft"}</div>
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={()=>{
                   setEditModel(true)
                   setEditItemId(id)
                }}>
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                <button type="button" className="dropdown-item" onClick={()=>{
                    statusChange({
                      id:id,
                      status:!isDraft
                    })
                }}>
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{isDraft?"Draft":"Publish"}
                </button>
                <button type="button" className="dropdown-item standOut" onClick={()=>{
                     setDeleteModal(true)
                }}>
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {(createdBy || createdDate || updatedDate) && (
          <div className="vision-item-row border-t">
            <div className="vision-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="vision-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      )}
      </Draggable>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() =>
          deleteItem({
            id:id,
          })
        }
      />
    </>
  );
};

export default VisionItem;
