import { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addAuthor } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const AddAuthors = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("")
  
  const { mutate: handleSubmit } = useMutation(
    (payload) => addAuthor(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["authors"],
            type: "inactive",
          });
          setTitle("");
         setDescription("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (details) {
      setTitle(details ? details.name : "");
      setStatus(details ? details.status : "");
      setDescription(details ? details.description : "");
      setPublishedData([]);
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 100);
    }
  }, [details]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Author Name"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Jane Doe"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <Textarea
                    state={description}
                    setState={setDescription}
                    label={"Description"}
                    inputRequired={true}
                    inputPlaceholder={"Write description......."}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel
                    data={publishedData}
                    setData={setPublishedData}
                    setRadio={setStatus}
                    label={"Status"}
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  <Button
                    label={"Save"}
                    functions={() =>
                      handleSubmit({
                        
                        name: title,
                        status: status === 1 ? true : false,
                        description: description
                      })
                    }
                  />
                  <Button label={"Cancel"} classes={"main-button-alt"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAuthors;
