const AllOrderItem = ({
  orderId,
  order_details,
  payment_info,
  delivery_type,
  shipping_info,
  createdDate,
  coupon_info,
  customer_info,
  account_type,
  setEditId,
  item_id,
  order_status,
  price,
  rider,
  functions = () => { },
}) => {

  // console.log(order_details);

  return (
    <>
      <div className="orders-item">
        <div className="orders-item-row">
          <div className="orders-options">
            <div className="action-dropdown dropdown">
                
            </div>
          </div>
        </div>
        <div
          className="orders-content"
          onClick={() => {
            functions();
            setEditId(item_id);
          }}
        >
          <div className="order-info">
            <div className="orders-title">
              {orderId}
              <div className="status-part">
                {order_status === "Out for delivery" && <span className="confirmed">{order_status}</span>}
                {order_status === "Canceled" && <span className="rejected">{order_status}</span>}
                {order_status === "Pending" && <span className="rejected">{order_status}</span>}
                {order_status === "Delivered" && <span className="confirmed">{order_status}</span>}
                {order_status === "Out for Delivery" && <span className="confirmed">{order_status}</span>}
                {order_status === "Kitchen" && <span className="confirmed">{order_status}</span>}
                {order_status === "Accepted" && (
                  <span className="confirmed">{order_status}</span>
                )}
              </div>
            </div>
            <div className="orders-metas">
              {order_details && (
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Items</div>
                    <div className="meta-data">
                      {order_details.length > 0
                        ? order_details.length > 3 ? order_details.slice(0,3).map((item, i) => {
                          if (i < 2) {
                            return <span key={item._id}>{item?.product_name}</span>;
                          } else{ 
                            return <span key={item._id}>...</span>;
                          }
                        }) :
                        order_details.map((item) => {
                            return <span key={item._id}>{item?.product_name}</span>;
                        }) : "No"}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="orders-metas">
              <div className="meta-item">
                <div className="meta-content">
                  <div className="meta-label">payment</div>
                  <div className="meta-data">{payment_info?.title}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-customer-info ps-4">
            <div className="title">CUSTOMER DETAILS</div>
            <div className="info-customer">
              <div className="name">{customer_info?.name}
                {account_type.account_type === 2 ? <span className="member-data guest">Guest</span> :
                <span className="member-data member">Member</span>}
              </div>
              <div className="other-data">{customer_info?.phone}</div>
              <div className="other-data">{customer_info?.email}</div>
              <div className="other-data">{shipping_info?.address}</div>
              <div className="other-data type">
                <span>Delivery Type</span>
                {delivery_type === 1
                  ? "Delivery"
                  : delivery_type === 2
                    ? "Pick up"
                    : ""}
              </div>
              {rider && rider.length !== 0 ? <div className="other-data type">
                <span>Delivery Rider</span>
                {rider[0].name}
              </div> : ''}
            </div>
          </div>
          <div className="order-customer-info ps-4">
            {/* <div className="title">ORDER ISSUE</div>
            <div className="info-customer">
              <div className="other-data type">
                <span>Reason</span>
                {reason}
              </div>
              <div className="other-data type">
                <span>Cancelled By</span>
                {cancelBy}
              </div>
            </div> */}
          </div>
          <div className="orders-price">
            <div className="price">
              <span>Net Value</span> €
              {price > 0
                ? price.toFixed(2) : '0'}
            </div>
          </div>
        </div>

        {/* {coupon_info && ( */}
        <div className="orders-item-row border-t">
          <div className="orders-info">
            {coupon_info && (
              <div className="info">
                <div className="info-label">Applied Coupon</div>
                <div className="info-data">{coupon_info?.code}</div>
              </div>
            )}
          </div>
          <div className="orders-info">
            {createdDate && (
              <div className="info">
                <div className="info-label">Order Date</div>
                <div className="info-data">{createdDate}</div>
              </div>
            )}
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default AllOrderItem;
