import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import Button from "../Form/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addDeliverySettings, editDeliverySettings } from "../../helpers/api_helper";

const AddDeliverySettings = ({ modalClose, query, details, isFetching }) => {
  const queryClient = useQueryClient();

  const [postCode, setPostCode] = useState("");
  const [cost, setCost] = useState('');
  const [minAmt, setMinAmt] = useState('');

  const { mutate: handleSubmit } = useMutation(
    (payload) => addDeliverySettings(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["delivery_settings"],
            type: "inactive",
          });
          setMinAmt("");
          setPostCode('');
          setCost('');
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => editDeliverySettings(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["delivery_settings"],
            type: "inactive",
          });
          setMinAmt("");
          setPostCode('');
          setCost('');
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  useEffect(() => {
    if(details) {
      setPostCode(details ? details.zip_code : "");
      setCost(details ? details.delivery_charge : "");
      setMinAmt(details ? details.minimum_order_amount : "");

      // setTimeout(() => {
      //   setPublishedData(
      //     details
      //       ? [
      //         {
      //           _id: true,
      //           label: "Published",
      //           inputName: "publish_status",
      //           current: details.status.toString() === 'true' ? true : false,
      //         },
      //         {
      //           _id: false,
      //           label: "Draft",
      //           inputName: "publish_status",
      //           current: details.status.toString() === 'false' ? true : false,
      //         },
      //       ]
      //       : []
      //   );
  
      // }, 600) editDeliverySettings
    }
  }, [details]);


  if(isFetching){
    return(
      <div>Loading...</div>
    )
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input state={postCode} setState={setPostCode} label={"Post Code"} inputRequired={true} inputType={"text"} inputPlaceholder={"Example . 1011"} inputName={""} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input state={cost} setState={setCost} label={"Delivery Costs"} inputRequired={true} inputType={"text"} inputPlaceholder={"Ex, £150"} inputName={""} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input state={minAmt} setState={setMinAmt} label={"Minimum Order Amount"} inputRequired={true} inputType={"text"} inputPlaceholder={"Ex, £150"} inputName={""} />
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {details ? <Button label={"Save"} functions={() => handleEdit({
                    id: details._id,
                    zip_code: postCode,
                    minimum_order_amount: parseInt(minAmt),
                    delivery_charge: parseInt(cost),
                  })}/> : <Button label={"Save"} functions={() => handleSubmit({
                    zip_code: postCode,
                    minimum_order_amount: parseInt(minAmt),
                    delivery_charge: parseInt(cost),
                  })}/>}
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={modalClose}/>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AddDeliverySettings;
