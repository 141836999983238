import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import {
  jobListAdd,
  jobListEdit,
  jobLocationList, jobTypeList
} from "../../helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../Elements/Loading";
import Select from "react-select";

const AddJob = ({ modalClose, query, details, isFetching }) => {
  const categoryDropdown = [
    { value: 1, label: "Part Time" },
    { value: 2, label: "Full Time" },
  ];
  const locationDropdown = [
    { value: 1, label: "Remote" },
    { value: 2, label: "On Site" },
  ];

  const [cvOpt, setCvOpt] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);

  const [selectedCvOpt, setSelectedCvOpt] = useState([]);
  const [selectedMotiOpt, setSelectedMotiOpt] = useState([]);
  const [motiOpt, setMotiOpt] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);

  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: false,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: true },
  ]);
  const [status, setStatus] = useState(2);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [job_type, setJob_type] = useState("");
  const [location_type, setLocation_type] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [selectedjobLocOpt, setselectedjobLocOpt] = useState([]);
  const [cat_id, setCat_id] = useState("");
  const [jobloc_id, setjobloc_id] = useState("");

  // const [cv_req, setCv_req] = useState(null);
  // const [moti_req, setMoti_req] = useState(null);

  const { mutate: handleSubmit } = useMutation(
    (payload) => jobListAdd(payload),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobs"],
            type: "inactive",
          });
          setTitle("");
          setDescription("");
          setJob_type("");
          setLocation_type("");
          setCity("");
          setState("");
          setCountry("");
          setSelectedCatOpt([]);
          setselectedjobLocOpt([]);
          setSelectedCvOpt([]);
          setSelectedMotiOpt([]);
          // setUploadData([]);
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => jobListEdit(payload),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobs"],
            type: "inactive",
          });
          setTitle("");
          // setUploadData([]);
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  // job type dropdown
  function useCategory() {
    return useQuery({
      queryKey: ["job Category"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await jobTypeList({
          status: true,
          limit: 9999,
        });
        // console.log(data)
        data.forEach((item) => {
          tmp.push({ label: item.type, value: item._id });
        });
        return tmp;
      },
    });
  }

  const { data: jobtype } = useCategory();

  // location type dropdown

  function useJobLocation() {
    return useQuery({
      queryKey: ["job location"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await jobLocationList({
          status: true,
          limit: 9999,
        });
        console.log(data);
        data.forEach((item) => {
          tmp.push({ label: item.type, value: item._id });
        });
        return tmp;
      },
    });
  }

  const { data: jobLocation } = useJobLocation();
  // function handleChangeCV(e) {
  //   setCv_req(e);
  // }

  // function handleChangeMoti(e) {
  //   setMoti_req(e);
  // }

  useEffect(() => {
    if (details) {
      setCat_id(details ? details?.job_type?._id : "");
      setjobloc_id(details ? details?.location_type?._id : "");
      setTitle(details ? details.title : "");
      setDescription(details ? details.description : "");
      setStatus(details ? details.status ? 1 : 2 : 1);
      setJob_type(details ? details.job_type : "");
      setCity(details ? details.city : "");
      setState(details ? details.state : "");
      setCountry(details ? details.country : "");
      setLocation_type(details ? details.location_type : "");
      setSelectedCatOpt(
        jobtype.find((item) => {
          return item.value === details.job_type?._id;
        })
      );
      setselectedjobLocOpt(
        jobLocation?.find((item) => {
          return item.value === details.location_type?._id;
        })
      );
      setPublishedData([]);
      setSelectedCvOpt(
        cvOpt.find((item) => {
          return item.value === details?.required_cv;
        })
      );
      setSelectedMotiOpt(
        motiOpt.find((item) => {
          return item.value === details?.required_motivation;
        })
      );

      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 100);
    }
  }, [details]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <div className="px-2">
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-3">
              <Input
                state={title}
                setState={setTitle}
                label={"Title"}
                inputRequired={true}
                inputType={"text"}
                inputPlaceholder={"Title"}
                inputName={""}
              />
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="mb-3">
              <div className="input-items">
                <SelectTag label={'Job Type'} data={categoryDropdown} placeholder={'Category'} />
              </div>
            </div>
          </div> */}

          <div className="mb-3">
            <div className="input">
              <div className="input-options">
                <div className="input-label">Job Type*</div>
              </div>
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={jobtype}
                value={selectedCatOpt || []}
                placeholder={"Job Type"}
                onChange={(e) => {
                  setCat_id(e.value);
                  setSelectedCatOpt(e);
                }}
              />
            </div>
          </div>

          {/* <div className="col-lg-4">
            <div className="mb-3">
              <div className="input-items">
                <SelectTag
                  label={"Job Location Type"}
                  data={locationDropdown}
                  placeholder={"Job Location"}
                />
              </div>
            </div>
          </div> */}

          <div className="mb-3">
            <div className="input">
              <div className="input-options">
                <div className="input-label">Job Location *</div>
              </div>
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={jobLocation}
                value={selectedjobLocOpt || []}
                placeholder={"Job Location"}
                onChange={(e) => {
                  setjobloc_id(e.value);
                  setselectedjobLocOpt(e);
                }}
              />
            </div>
          </div>

          {/* <div className="col-lg-4">
            <div className="mb-3">
              <div className="input-items">
                <SelectTag
                  label={"CV Mandetory"}
                  data={cvDropdown}
                  placeholder={"CV Requirement"}
                />
              </div>
            </div>
          </div> */}

          <div className="mb-3">
            <div className="input">
              <div className="input-options">
                <div className="input-label">CV Mandetory</div>
              </div>
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={cvOpt}
                value={selectedCvOpt}
                placeholder={"CV Requirement"}
                onChange={(e) => setSelectedCvOpt(e)}
              />
            </div>
          </div>

          <div className="mb-3">
            <div className="input">
              <div className="input-options">
                <div className="input-label">Motivation Mandetory</div>
              </div>
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={motiOpt}
                value={selectedMotiOpt}
                placeholder={"Motivation Requirement"}
                onChange={(e) => setSelectedMotiOpt(e)}
              />
            </div>
          </div>

          {/* <div className="col-lg-4">
            <div className="mb-3">
              <div className="input-items">
                <SelectTag
                  label={"Motivation Mandetory"}
                  data={motivationDropdown}
                  placeholder={"Motivation Requirement"}
                />
              </div>
            </div>
          </div> */}

          <div className="col-lg-12">
            <div className="mb-3">
              <Textarea
                label={"Job Description"}
                inputRequired={true}
                inputPlaceholder={"Write description......."}
                inputName={""}
                state={description}
                setState={setDescription}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mb-4">
              <RadiosLabel
                data={publishedData} 
                setData={setPublishedData}
                setRadio={setStatus}
                label={"Status"}
                inputRequired={true}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              {details ? (
                <Button
                  label={"Save"}
                  functions={() =>
                    handleEdit({
                      id: details._id,
                      title: title,
                      description: description,
                      job_type: job_type,
                      location_type: location_type,
                      required_cv: selectedCvOpt.value,
                      required_motivation: selectedMotiOpt.value,
                      city: city,
                      state: state,
                      status: status === 1 ? true : false,
                      country: country,
                    })
                  }
                />
              ) : (
                <Button
                  label={"Save"}
                  functions={() =>
                    handleSubmit({
                      title: title,
                      description: description,
                      job_type: selectedCatOpt.value,
                      location_type: selectedjobLocOpt.value,
                      required_cv: selectedCvOpt.value,
                      required_motivation: selectedMotiOpt.value,
                      city: city,
                      state: state,
                      status: status === 1 ? true : false,
                      country: country,
                    })
                  }
                />
              )}
              <Button
                label={"Cancel"}
                classes={"main-button-alt"}
                functions={modalClose}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddJob;
