export default function Pagination({ count, offset, setOffset, limit }) {
    if(count > limit) {
        return (
            <>
            <div className='pagination-section'>
            <button className='button-pagination'
              onClick={() => setOffset((old) => Math.max(old - limit, 0))}
              disabled={offset === 0}
            >
              Prev
            </button>
            <span className='page-number'>{Math.ceil(offset / limit) + 1}</span>
            <button className='button-pagination'
              onClick={() => setOffset((old) => (old + limit))}
              disabled={count <= (offset + limit)}
            >
              Next
            </button>
            </div>
           
            </>
      )
    }
  
}