import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { detailsOrders, stsChangeOrder, stsChngLiveOrder } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";
import Modal from "../Elements/Modal";
import Button from "../Form/Button";
import RadioGroup from "../Form/RadioGroup";
import AssignRider from "../Modals/AssignRider";
import OrderReject from "../Modals/OrderReject";
import { redirect } from "react-router-dom";
import SidebarDetails from "../Elements/SidebarDetails";
import OrderDetails from "../Modals/OrderDetails";
import moment from "moment/moment";

const LiveOrderItem = ({ title, icon, orderData, setId, setTypId }) => {
  const queryClient = useQueryClient();

  const [currId, setCurrId] = useState("");
  const [editId, setEditId] = useState("");
  const [currOrderId, setCurrOrderId] = useState("");
  const [sideBarModal, setSideBarModal] = useState(false);
  const [time_left, setTime_left] = useState('');

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime_left(moment(new Date()));
    }, 1000);
    return function () {
      clearInterval(timerId);
    };
  }, []);

  const [isAssignModal, setIsAssignModal] = useState(false);
  const assignModal = () => {
    setIsAssignModal(true);
  };

  const [isAddModal, setIsAddModal] = useState(false);

  const addModal = () => {
    setIsAddModal(true);
  };

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteModal = () => {
    setIsDeleteModal(true);
  };

  const { mutate: statusChange } = useMutation(
    (changeSts) => stsChngLiveOrder(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(["live_order"]);
          queryClient.removeQueries({
            queryKey: ["live_order"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const rejectStatus = async() =>{
    await stsChangeOrder({id:currId, type_id: 5 });
   }

     // For fetching details of item
  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsOrders({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "" && sideBarModal) {
      refetch();
    }
  }, [isAddModal, editId, sideBarModal, refetch]);
  

  return (
    <>
      <div className="live-order-list">
        <div className="live-order-row">
          <div className="live-list">
            <div className="heading-part">
              <div className="icon">
                <i className={"fa-light fa-fw " + icon}></i>
              </div>
              <div className="text">
                {title}
                <span>({orderData && orderData?.length})</span>
              </div>
            </div>
            {orderData &&
              orderData.map((item) => (
                <div className="live-item" key={item._id} style={{ padding: '8px', borderRadius: '6px', backgroundColor :item.type_id === 4 ? moment(new Date(item?.expected_delivery_time), "YYYY-MM-DD HH:mm:ss").from(item.updated_at).includes('ago') ? '#f88379' : 'lightgreen' : ''}}>
                    <div className="order-id"  onClick={() => {
                  setEditId(item._id);
                  setSideBarModal(true);
                }} style={{ cursor: 'pointer' }}>{item.orderId} {item?.expected_delivery_time && <span style={{fontSize:'12px',color:"red"}}>{moment(new Date(item?.expected_delivery_time), "YYYY-MM-DD HH:mm:ss").from(item.updated_at).includes('ago') ? '' : moment(new Date(item?.expected_delivery_time), "YYYY-MM-DD HH:mm:ss").from(time_left)}</span>} </div>
                    {item?.expected_delivery_time && <div>Expected Delivery Time: <span style={{fontSize:'12px',color:"red"}}>{moment(new Date(item?.expected_delivery_time)).format("LT")}</span></div>}
                    <div className="order-info">
                      <div className="data-info">
                        <div className="info">{item.streetName}</div>
                        <div className="info">{item.postCode}</div>
                      </div>

                      <div className="data-icon">
                        <i className={"fa-light fa-fw " + item.orderIcon}></i>
                      </div>
                    
                  </div>
                  
                  <div className="order-action">
                    {item.type_id === 9 && (
                      <Button
                        label={"Move to Kitchen"}
                        classes={"btn-action"}
                        functions={() => {
                          statusChange({
                            id: item._id,
                            type_id: item.type_id,
                          });
                        }}
                      />
                    )}
                    {item.type_id === 10 && (
                      <Button
                        label={"Move to Ready"}
                        classes={"btn-action"}
                        functions={() => {
                          statusChange({
                            id: item._id,
                            type_id: item.type_id,
                          });
                        }}
                      />
                    )}
                    {(item.type_id === 11 && item.orderIcon === 'fa-bicycle') && (
                      <Button
                        label={"Assign Rider"}
                        classes={"btn-action"}
                        functions={() => {
                          assignModal();
                          setCurrId(item._id);
                          setCurrOrderId(item.orderId);
                        }}
                      />
                    )}
                    {item.type_id === 4 && <Button
                      label={"Move to Delivered"}
                      classes={"btn-action"}
                      functions={() => {
                        statusChange({
                          id: item._id,
                          type_id: item.type_id,
                        });
                      }}
                    />}
                    {(item.type_id === 11 && item.orderIcon === 'fa-bag-shopping') && (
                    <Button
                      label={"Move to Delivery"}
                      classes={"btn-action"}
                      functions={() => {
                        statusChange({
                          id: item._id,
                          type_id: item.type_id,
                        });
                      }}
                    />
                    )}

                    {item.type_id === 9 && <Button
                      label={"Reject"}
                      classes={"btn-action alt"}
                      functions={() => {
                        deleteModal();
                        setCurrId(item._id);
                        setCurrOrderId(item.orderId);
                      }}
                    />}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal
        title={"Add Rider"}
        size={"sm"}
        isOpen={isAssignModal}
        modalClose={() => setIsAssignModal(!isAssignModal)}
      >
        <AssignRider
          currId={currId}
          currOrderId={currOrderId}
          modalClose={() => setIsAssignModal(false)}
        />
      </Modal>
      <Modal
        title={"Reject Reason"}
        size={"sm"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <OrderReject
          currId={currId}
          currOrderId={currOrderId}
          rejectStatus={rejectStatus}
          modalClose={() => setIsAddModal(false)}
        />
      </Modal>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Reject"}
        text={"Are you sure want to reject this order?"}
        isOpen={isDeleteModal}
        submitText={"Yes"}
        cancelText={"No"}
        confirmSubmit={() => {
          addModal();
          setIsDeleteModal(false);
        }}
        confirmClose={() => setIsDeleteModal(false)}
      />
      <SidebarDetails
        isOpen={sideBarModal}
        modalClose={() => {
          setSideBarModal(false);
        }}
      >
        <OrderDetails
          details={details}
          modalClose={() => {
            setSideBarModal(false);
            queryClient.removeQueries({
              queryKey: ["details"],
              exact: false,
              type: "all"
            });
          }}
        />
      </SidebarDetails>
    </>
  );
};

export default LiveOrderItem;
