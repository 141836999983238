import { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import Textarea from "../Form/Textarea";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addBlog, editBlogs } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import Select from 'react-select';

const AddBlogs = ({ query, modalClose, details, isFetching }) => {
  const [uploadData, setUploadData] = useState([]);
  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(true);
  const [title, setTitle] = useState("");
  const [selectedCatOpt, setSelectedCatOpt] = useState([]);
  const [category_id, setCategory_id] = useState("")
  const categories = queryClient.getQueryData(["blogs_categories_dropdown"]);
  const [description, setDescription] = useState("")

  const { mutate: handleSubmit } = useMutation((payload) => addBlog(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["Blogs"],
          type: "inactive",
        });
        setTitle("");
        setDescription("");
        setUploadData([]);
        setSelectedCatOpt([]);
        setStatus(1);
        modalClose();
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const { mutate: handleEdit } = useMutation((payload) => editBlogs(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["Blogs"],
          type: "inactive",
        });
        setTitle("");
        setUploadData([]);
        setDescription("");
        setSelectedCatOpt([]);
        setStatus(1);
        modalClose();
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  useEffect(() => {
    if (details) {
      setCategory_id(details ? details.category?._id : "");
      setTitle(details ? details.title : "");
      setStatus(details ? details.status : "");
      setDescription(details ? details.short_description : "");
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);
      setSelectedCatOpt(
        categories.find((item) => {
          return item.value === details.category?._id;
        })
      );
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 100);
    }
  }, [details]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Title"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Veg"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
                <div className="input">
                  <div className="input-options">
                    <div
                      className="input-label">
                      Categories
                    </div>
                  </div>
                  <Select
                    className="select-tag"
                    isMulti={false}
                    isClearable={true}
                    options={categories}
                    value={selectedCatOpt || []}
                    placeholder={'Categories'}
                    onChange={(e) => {
                      console.log(e);
                      setCategory_id(e.value);
                      setSelectedCatOpt(e);
                    }}
                  />
                </div>
              </div>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <Textarea
                    state={description}
                    setState={setDescription}
                    inputRequired={true}
                    inputPlaceholder={"Write description......."}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel
                    setRadio={setStatus}
                    data={publishedData}
                    setData={setPublishedData}
                    label={"Status"}
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {/* <Button label={"Save"} />
                  <Button label={"Cancel"} classes={"main-button-alt"} /> */}
                  {details ? (
                    <Button
                      label={"Save"}
                      functions={() =>
                        handleEdit({
                          id: details._id,
                          title: title,
                          short_description: description,
                          category_id: selectedCatOpt.value,
                          status: status === 1 ? true : false,
                          image: uploadData && uploadData[0]?.image.includes("https")
                              ? null
                              : uploadData[0]?.image,
                        })
                      }
                    />
                  ) : (
                    <Button
                      label={"Save"}
                      functions={() =>
                        handleSubmit({
                          title: title,
                          short_description: description,
                          category_id: category_id,
                          status: status === 1 ? true : false,
                          image: uploadData ? uploadData[0]?.image : null,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col">
                <ImageUploadPreview
                  setImage={setUploadData}
                  data={uploadData}
                  label={"Upload Image"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogs;
