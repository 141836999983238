import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { delFaqcategory, stsChangeFaq } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const FaqCategoryItem = ({
  name,
  createdBy,
  createdDate,
  updatedDate,
  status,
  FaqcategoriesId,
  query,
  detailsFetch,
  setEditId,
  setEditModal,
  index
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteFaqcategory = useMutation(
    (delFaqcategoryId) => delFaqcategory(delFaqcategoryId),
    {
      onSuccess: (res) => {

        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["Faqcategories"],
            type: "inactive",
          });
          queryClient.removeQueries({
            queryKey: ["faq_categories_dropdown_id"],
            type: "inactive",
          });
          queryClient.removeQueries({
            queryKey: ["faq_categories_dropdown"],
            type: "inactive",
          });

          queryClient.removeQueries({
            queryKey: ["feedback_categories_dropdown"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation((changeSts) => stsChangeFaq(changeSts), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["Faqcategories"],
          type: "inactive",
        });//
        queryClient.removeQueries({
          queryKey: ["faq_categories_dropdown_id"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["faq_categories_dropdown"],
          type: "inactive",
        });
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  return (
    <>
      <Draggable
        draggableId={FaqcategoriesId}
        index={index}
        key={FaqcategoriesId}
      >
        {(provided) => (
          <div
            className="faqs-item mb-3"
            key={FaqcategoriesId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="faqs-item-row">
              <div className="faqs-content">
                <div className="faqs-details">
                  <span>Category Name</span>
                  <div className="faqs-title">{name}</div>
                </div>
              </div>
              <div className="faqs-options">
                <div className={"status " + (status ? "published" : "draft")}>
                  {status ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditId(FaqcategoriesId);
                        detailsFetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>

                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChange.mutate({
                          id: FaqcategoriesId,
                          status: status ? false : true,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status ? "draft" : "publish"}
                    </button>

                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {(createdBy || createdDate || updatedDate) && (
              <div className="faqs-item-row border-t">
                <div className="faqs-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                </div>
                <div className="faqs-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Last Updated</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>

      

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteFaqcategory.mutate({ id: FaqcategoriesId });
        }}
      />
    </>
  );
};

export default FaqCategoryItem;
