import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { openingListEdit } from '../../helpers/api_helper'
import Button from '../Form/Button'

const OpeningHourEdit = ({ modalClose, id, query, data, detailsListLoading }) => {


  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);
  const [loading, setLoading] = useState(false)

  const day = [
    { value: 1, label: 'Sunday', },
    { value: 2, label: 'Monday', },
    { value: 3, label: 'Tuesday', },
    { value: 4, label: 'Wednesday', },
    { value: 5, label: 'Thursday', },
    { value: 6, label: 'Friday', },
    { value: 7, label: 'Saturday', },
  ]
  const status = [
    { value: 1, label: 'Close', },
    { value: 2, label: 'Open', },
  ]

  useEffect(() => {
    if (data?.day_name) {
      setSelectedOptions([day.find((item) => item.label === data.day_name)])
    }
    if (data?.is_closed) {
      setSelectedStatusOptions([status[0]])
    } else {
      setSelectedStatusOptions([status[1]])
    }
  }, [data?.day_name, data?.is_closed])

  // ======================================================>

  const queryClient = useQueryClient()



  const editOpeningHours = useMutation(
    (payload) => openingListEdit(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          modalClose(!modalClose)
          toast.success(res.message);
          setLoading(false)
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["openingList"],
            type: "inactive",
          })
        } else {
          toast.error(res.message);
          setLoading(false)
        }
      }
    }
  )


  const handleSubmit = () => {
    setLoading(true)
    editOpeningHours.mutate({
      id: id,
      day_name: selectedOptions[0]?.label,
      is_closed: selectedStatusOptions[0]?.label === 'Close' ? true : false,
    })
  }

  if (detailsListLoading) {
    return <>loading...</>
  }


  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          {/* <div className="col-lg-7"> */}
          <div className="row">
            <div className="mb-3">
              <div className={"input-label"}>
                Select Day
              </div>
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={day}
                value={selectedOptions || []}
                placeholder={'Select Status'}
                onChange={(e) => {
                  setSelectedOptions([e]);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="mb-3">
              <div className={"input-label"}>
                Select Status
              </div>
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={status}
                value={selectedStatusOptions || []}
                placeholder={'Select Status'}
                onChange={(e) => {
                  setSelectedStatusOptions([e]);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-wrap gap-3">
                <Button
                  functions={handleSubmit}
                  label={"Save"}
                  loading={loading}
                />
                <Button label={"Cancel"} classes={"main-button-alt"} functions={() => modalClose()} />
              </div>
            </div>
          </div>
          {/* </div> */}

        </div>
      </div>
    </>
  )
}

export default OpeningHourEdit