import React from "react";
import Button from "../Form/Button";
import Input from "../Form/Input";

const AcceptModal = ({ acceptStatus,setTime,time,modalClose}) => {
    
  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <div className="order-info">Delivery Time </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-2">
              <Input
                  state={time}
                  setState={setTime}
                  label={""}
                  inputRequired={true}
                  inputType={"number"}
                  inputPlaceholder={"Time Example (In minutes) : 30 "}
                  inputName={""}
                /> 
               
              </div>
              <div className="mb-2">
              
                </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button label={"Submit"}  functions={()=>acceptStatus()}/>
              <Button label={"Cancel"} functions={()=>modalClose()} classes={"main-button-alt"} />
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default AcceptModal;
