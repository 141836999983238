import { useState, useEffect } from 'react';
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import StepsItem from '../../components/Items/StepsItem';
import AddSteps from '../../components/Modals/AddSteps';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { deleteStepsList, detailsStepsList, ordering, stepsList } from '../../helpers/api_helper';
import Pagination from "../../components/Elements/Pagination";
import { toast } from 'react-toastify';
import Confirm from '../../components/Elements/Confirm';
import Select from 'react-select';
import { Loading } from '../../components/Elements/Loading';
import { NoData } from '../../components/Elements/NoData';
import { Error } from '../../components/Elements/Error';


const Steps = () => {

  const [deleteModal, setDeleteModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [listEditId, setListEditId] = useState()
  const [listDeleteId, setListDeleteId] = useState('')
  const [filter, setFilter] = useState(1)
  const [limit, setLimit] = useState(10)
  const [isOne, setDataOne] = useState(false);
  const [offset, setOffset] = useState(0);

  const [listData, setListData] = useState([])
  const [dataList, setDataList] = useState([]);


  // ===================================>





  // For pagination======================>
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);

  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  const queryClient = useQueryClient();

  // keys for step list queries===================>

  const queryStepLists = ["stepsList", keyword, filter, selectedPageOpt, offset, limit];
  const editStepLists = ["stepsListDetails", listEditId];

  // rest button

  const reset = () => {
    setKeyword('');
    setOffset(0);
    setLimit(10);
    setSelectedPageOpt([]);
    queryClient.invalidateQueries(queryStepLists);
    queryClient.removeQueries({
      queryKey: ["stepsList"],
      type: "inactive",
    });
    queryClient.removeQueries({
      queryKey: ["stepsListDetails"],
      exact: false,
    });
  }

  const addModal = () => {
    setIsAddModal(true);
  }

  const status = [
    { _id: 1, name: 'All', color: 'success', current: true },
    { _id: 2, name: 'Published', color: 'success', current: false },
    { _id: 3, name: 'Draft', color: 'warning', current: false },
  ]

  // step lists call===================>

  const { data: stepsListData, error, refetch, isFetching, isLoading } = useQuery({
    queryKey: queryStepLists,
    queryFn: async () => stepsList({
      status: filter === 1 ? '' : filter === 2,
      keyword: keyword,
      // limit: selectedPageOpt.value
      limit: limit,
      offset: offset,
      sortByField: "ordering",
      sortByValue: 1,
    }),
  });

  useEffect(() => {
    if (!isOne) {
      setListData(stepsListData?.data);
    }
  }, [isOne, stepsListData?.data]);


  // get filter value from radio button===================>

  const setData = (filterValue) => {
    setFilter(filterValue)
  }

  // searching for step list===================>

  const setKeywords = (keyword) => {
    setKeyword(keyword)
  }

  // edit step list==============>

  const { data: stepsListDetails, isFetching: detailsList } = useQuery({
    queryKey: editStepLists,
    queryFn: async () => detailsStepsList({ id: listEditId }),
  });

  const editmodal = (event) => {
    setListEditId(event)
    setIsEditModal(true);
  }

  // list deleted section=============>

  const deleteLists = useMutation(
    (payload) => deleteStepsList(payload),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setDeleteModal(false);
          toast.success(res.message);
          queryClient.invalidateQueries(queryStepLists);
          queryClient.removeQueries({
            queryKey: ["stepsList"],
            type: "inactive",
          });
        } else {
          toast.error(res.message);
        }
      }
    }
  )

  // drag and drop handling================>

  const handleDragEnd = (e) => {

    if (!e.destination) return;
    let tempData = Array.from(listData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setListData([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    onClickSaveOrdering(dataArray);
    setDataOne(true);
  };


  const onClickSaveOrdering = async (orderingData) => {
    const saveOrdering = await ordering({
      orderingData: orderingData,
      tableName: "howItWorks",
    });

    if (saveOrdering.status === 200) {
      setDataOne(false);
      queryClient.invalidateQueries(queryStepLists);
      queryClient.removeQueries({
        queryKey: ["stepsList"],
        type: "inactive",
      });

      toast.success(saveOrdering.message);
    } else {
      toast.error(saveOrdering.message);
    }
  };

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeywords}
              placeholder={'Search...'} />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button functions={reset} classes={'px-2'} icon={'fa-regular fa-arrow-rotate-right'} iconPosition={'left'} />
            <Button label={'Add Steps'} icon={'fa-regular fa-plus'} iconPosition={'left'} functions={addModal} />
          </div>
        </div>
        <div className="input-items">
          <RadioGroup
            data={status}
            setData={setData}
            inputName={"status"}
          />
        </div>
      </div>
      <div className="steps-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='stepLists'>
            {(provided) => {
              // data fetching
              if (isFetching) {
                return <Loading />
              }
              // error
              if (error) {
                return <Error />
              }
              // for no data found 
              else if (stepsListData.data.length === 0) {
                return <NoData />
              }
              // all data
              else {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      listData?.map((list, index) => {
                        return (
                          <StepsItem
                            key={list._id}
                            index={index}
                            id={list._id}
                            name={list.title}
                            description={list.description}
                            status={list.status}
                            createdBy={list.created_by}
                            createdDate={list.created_at}
                            updatedAt={list.updated_at}
                            setListDeleteId={setListDeleteId}
                            setDeleteModal={setDeleteModal}
                            editmodal={editmodal}
                          />
                        )
                      })
                    }
                    {provided.placeholder}
                  </div>
                )
              }
            }
            }
          </Droppable>
        </DragDropContext>
        <Pagination count={stepsListData?.count} limit={limit} offset={offset} setOffset={setOffset} />
      </div>

      <Modal title={'Add Steps'} size={'md'} isOpen={isAddModal} modalClose={() => setIsAddModal(!isAddModal)}>
        <AddSteps edit={false} query={queryStepLists} setModalClose={() => setIsAddModal(!isAddModal)} />
      </Modal>
      <Modal title={'Update Steps'} size={'md'} isOpen={isEditModal} modalClose={() => setIsEditModal(!isEditModal)}>
        <AddSteps edit={true} detailsListLoading={detailsList} details={stepsListDetails} setModalClose={() => setIsEditModal(!isEditModal)} />
      </Modal>


      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        setDeleteModal={setDeleteModal}
        confirmSubmit={() => {
          deleteLists.mutate({ id: listDeleteId });
        }}
      />
    </>
  );
};

export default Steps;