import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import LineChart from "../../components/Charts/LineChart";
import { avgRevenueGraph, countGraph, revenueGraph } from "../../helpers/api_helper";

const RevenueChart = () => {
  
let [searchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
    prev_start: moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    prev_end: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  });
  const [type, setType] = useState("month");
  const [data, setdata] = useState([]);
  const [rev, setRev] = useState("");
  const [prevRev, setPrevRev] = useState("");

  useEffect(() => {
    async function loadData() {
      if(searchParams.get('tab') === "revenue"){
        var a = await revenueGraph({ dateRange, type });
      } else if(searchParams.get('tab') === "number-of-orders"){ 
        var a = await countGraph({ dateRange, type });
      } else {
        var a = await avgRevenueGraph({ dateRange, type });
      }

      setdata(a.data);
      setRev(a.current_revenue_amount);
      setPrevRev(a.previous_revenue_amount);
    }
    loadData();
  }, [dateRange, searchParams.get('tab')]);

  function handleDateRange(e) {
    // console.log(e);
    // if (active) {
    let a = Intl.DateTimeFormat().resolvedOptions().timeZone
    setDateRange({
      start: e.value.start,
      end: e.value.end,
      prev_start: e.value.prev_start,
      prev_end: e.value.prev_end,
    });

    setType(e.value.type);
    // } else if (e[0] && e[1]) {
    //   setDateRange({
    //     start: new Date(e[0]).toISOString(),
    //     end: new Date(e[1]).toISOString(),
    //   });
    // }
  }

  const [optionsStock, setOptionsStock] = useState([
    {
      value: {
        start: moment().startOf("week").format("YYYY-MM-DD"),
        end: moment().endOf("week").format("YYYY-MM-DD"),
        prev_start: moment()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD h:mm:ss a"),
        prev_end: moment()
          .subtract(1, "week")
          .endOf("week")
          .format("YYYY-MM-DD h:mm:ss a"),
        type: "week",
      },
      label:
        "This Week (" +
        moment().startOf("week").format("DD MMM") +
        " - " +
        moment().endOf("week").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD"),
        end: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
        prev_start: moment()
          .subtract(2, "week")
          .startOf("week")
          .format("YYYY-MM-DD h:mm:ss a"),
        prev_end: moment()
          .subtract(2, "week")
          .endOf("week")
          .format("YYYY-MM-DD h:mm:ss a"),
        type: "week",
      },
      label:
        "Last Week (" +
        moment().subtract(1, "week").startOf("week").format("DD MMM") +
        " - " +
        moment().subtract(1, "week").endOf("week").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
        prev_start: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        prev_end: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
        type: "month",
      },
      label:
        "This Month (" +
        moment().startOf("month").format("DD MMM") +
        " - " +
        moment().endOf("month").format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD h:mm:ss a"),
        prev_start: moment()
          .subtract(2, "month")
          .startOf("month")
          .format("YYYY-MM-DD h:mm:ss a"),
        prev_end: moment()
          .subtract(2, "month")
          .endOf("month")
          .format("YYYY-MM-DD h:mm:ss a"),
        type: "month",
      },
      label:
        "Last Month (" +
        moment().subtract(1, "month").startOf("month").format("DD MMM") +
        " - " +
        moment().subtract(1, "month").endOf("month").local().format("DD MMM") +
        ")",
    },
    {
      value: {
        start: moment().startOf("year").format("YYYY-MM-DD"),
        end: moment().endOf("year").format("YYYY-MM-DD"),
        prev_start: moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD h:mm:ss a"),
        prev_end: moment()
          .subtract(1, "year")
          .endOf("year")
          .format("YYYY-MM-DD h:mm:ss a"),
        type: "year",
      },
      label:
        "Current Year (" +
        moment().subtract(1, "year").startOf("year").format("DD MMM") +
        " - " +
        moment().subtract(1, "year").endOf("year").format("DD MMM") +
        ")",
    },
  ]);

  return (
    <>
      <div className="chart-dashboard">
        <div className="chart-data-details">
          {data.length !== 0 && (
            <>
              <div className="data-row">
                <div className="main-data">
                {searchParams.get('tab') === "number-of-orders" ? null :  "€"}{rev}
                  <span
                    className={
                      (parseFloat(rev) - parseFloat(prevRev)) < 0
                        ? "danger"
                        : "ok" +
                      " percentage"
                    }
                  >
                    {parseInt(rev) - parseInt(prevRev) < 0 ? "-" : "+"}
                    {(
                      Math.abs(parseInt(rev) - parseInt(prevRev)) /
                            (parseFloat(rev) + parseFloat(prevRev) / 2)
                       * 100
                    ).toFixed(2)}
                    %
                  </span>
                </div>
                <div className="sub-text">Current Period</div>
              </div>
              <div className="data-row">
                <div className="main-data">{searchParams.get('tab') === "number-of-orders" ? null :  "€"}{prevRev}</div>
                <div className="sub-text">Previous Period</div>
              </div>
            </>
          )}
        </div>
        <div className="chart-data">
          <div className="date">
            Current Period (
            <span>
              {moment(dateRange.start).format("DD MMM")} - {moment(dateRange.end).format("DD MMM")}
            </span>
            )
          </div>
          <span className="seperator"></span>
          <div className="date previous">
            Previous Period (
            <span>
              {moment(dateRange.prev_start).format("DD MMM")} - {moment(dateRange.prev_end).format("DD MMM")}
            </span>
            )
          </div>
          <Select
            options={optionsStock}
            defaultValue={optionsStock[2]}
            name="choices-category-input"
            onChange={(e) => handleDateRange(e)}
            
          />
        </div>
        <div className="chart-view mt-4">
          <LineChart dateRange={dateRange} graphData={data} type={type} />
        </div>
      </div>
    </>
  );
};

export default RevenueChart;
