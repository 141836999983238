import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { delComments, stsChangeComments } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const CommentItem = ({
  index,
  CommentId,
  blogTitle,
  name,
  email,
  comment,
  query,
  createdBy,
  createdDate,
  updatedDate,
  status,
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProduct = useMutation(
    (delProductId) => delComments(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["comments"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation(
    (changeSts) => stsChangeComments(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["comments"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      <div className="comment-item mb-3">
        <div className="comment-item-row">
          <div className="comment-options">
            <div className={"status " + (status ? "published" : "draft")}>
              {status ? "published" : "draft"}
            </div>
            <div className="action-dropdown dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <button type="button" className="dropdown-item"><i className="fa-regular fa-fw fa-pen-to-square"></i>Edit</button> */}
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() =>
                    statusChange.mutate({
                      id: CommentId,
                      status: status ? false : true,
                    })
                  }
                >
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                  {status ? "draft" : "publish"}
                </button>
                <button
                  type="button"
                  className="dropdown-item standOut"
                  onClick={() => setDeleteModal(true)}
                >
                  <i className="fa-regular fa-fw fa-trash-can"></i>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="comment-content">
          <div className="comment-label">Blog title</div>
          <div className="comment-title">{blogTitle}</div>
        </div>
        <div className="comment-metas">
          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">Name</div>
              <div className="meta-data">{name}</div>
            </div>
          </div>

          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">EMAIL</div>
              <div className="meta-data">{email}</div>
            </div>
          </div>
        </div>
        <div className="comment-content">
          <div className="comment-label mt-4">COMMENT</div>
          <div className="comment-text">{comment}</div>
        </div>

        {(createdBy || createdDate || updatedDate) && (
          <div className="comment-item-row border-t">
            <div className="comment-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="comment-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: CommentId });
        }}
      />
    </>
  );
};

export default CommentItem;
