import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addOtherCat, editCategories } from "../../helpers/api_helper";
import { Loading } from "../Elements/Loading";

const AddFaqCategory = ({ details, isFetching, modalClose, query }) => {
  const queryClient = useQueryClient();

  const [catId, setCatId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(1);

  const [uploadData, setUploadData] = useState([]);

  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);

  const submitCategory = useMutation((newProduct) => addOtherCat(newProduct), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);//
        queryClient.removeQueries({
          queryKey: ["Faqcategories"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["faq_categories_dropdown_id"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["faq_categories_dropdown"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["feedback_categories_dropdown"],
          type: "inactive",
        });
        setTitle("");
        setDescription("");
        setUploadData([]);
        setStatus(true);
        modalClose();
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const handleEdit = useMutation((payload) => editCategories(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["details"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["faq_categories_dropdown_id"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["faq_categories_dropdown"],
          type: "inactive",
        });
        queryClient.removeQueries({
          queryKey: ["feedback_categories_dropdown"],
          type: "inactive",
        });
        setTitle("");
        setDescription("");
        setUploadData([]);
        setStatus(1);
        modalClose();
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  useEffect(() => {
    if (details) {
      setTitle(details ? details.name : "");
      setDescription(details ? details.description : "");
      setStatus(details ? details.status ? 1 : 2 : 1);
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 100);
    }
  }, [details]);

  if (isFetching) {
    return <Loading/>;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Category Title"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Indian Drinks"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <Textarea
                    label={"Description"}
                    state={description}
                    setState={setDescription}
                    inputRequired={true}
                    inputPlaceholder={"Write description......."}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel
                    setRadio={setStatus}
                    data={publishedData}
                    setData={setPublishedData}
                    label={"Status"}
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {details ? (
                    <Button
                      label={"Save"}
                      functions={() =>
                        handleEdit.mutate({
                          id: details._id,
                          category_type: "faqs",
                          name: title,
                          status: status === 1 ? true : false,
                          description: description,
                          image:
                            uploadData && uploadData[0]?.image.includes("https")
                              ? null
                              : uploadData[0]?.image,
                        })
                      }
                    />
                  ) : (
                    <Button
                      label={"Save"}
                      functions={() =>
                        submitCategory.mutate({
                          id: catId,
                          category_type: "faqs",
                          name: title,
                          description: description,
                          image: uploadData ? uploadData[0]?.image : null,
                          status: status === 1 ? true : false,
                        })
                      }
                    />
                  )}

                  <Button
                    label={"Cancel"}
                    classes={"main-button-alt"}
                    functions={() => {
                      modalClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col">
                <ImageUploadPreview
                  data={uploadData}
                  setImage={setUploadData}
                  label={"Upload Image"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFaqCategory;
