import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { delBenefits, stsChangeBenefits } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const BenefitsItem = ({
  icon,
  index,
  BenefitsId,
  title,
  createdBy,
  createdDate,
  status,
  query,
  detailsFetch,
  setEditId,
  setEditModal,
}) => {

  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProduct = useMutation(
    (delProductId) => delBenefits(delProductId),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["benefits"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation(
    (changeSts) => stsChangeBenefits(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["benefits"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      <Draggable draggableId={BenefitsId} index={index} key={BenefitsId}>
        {(provided) => (
          <div
            className="benefits-item mb-3"
            key={BenefitsId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="benefits-item-row">
              <div className="benefits-options">
                <div className={"status " + (status ? "published" : "draft")}>
                  {status ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditId(BenefitsId);
                        detailsFetch();
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChange.mutate({
                          id: BenefitsId,
                          status: status ? false : true,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status ? "draft" : "publish"}
                    </button>
                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="benefits-content">
              {/* <div className="benefits-label">ICON</div>
          <div className="benefits-title">{icon}</div> */}
              <div className="diets-image">
                <img src={icon} height={"100px"} />
              </div>
              <div className="benefits-label mt-4">Title</div>
              <div className="benefits-text">{title}</div>
            </div>

            {(createdBy || createdDate) && (
              <div className="benefits-item-row border-t">
                <div className="benefits-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                </div>

                <div className="benefits-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: BenefitsId });
        }}
      />
    </>
  );
};

export default BenefitsItem;
