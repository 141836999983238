import React, { useEffect } from 'react';

const Button = ({label, labelClasses, classes, icon, iconPosition, functions, loading}) => {
  return (
    <>
      <button type="button" className={"main-button" + (" " + classes)} disabled={loading} onClick={functions}>
        { iconPosition === 'left' &&
          <i className={"fa-fw" + (" " + icon)}></i>
        }
        
        { label &&
          <div className={"button-label" + (" " + labelClasses)}>{label}</div>
        }
        { loading &&
          <i className={"fa-solid fa-circle-notch fa-spin"}></i>
        }
        { iconPosition === 'right' &&
          <i className={"fa-fw" + (" " + icon)}></i>
        }
      </button>
    </>
  );
};

export default Button;