import { useEffect, useRef, useState } from "react";
import Button from "../../components/Form/Button";
import Search from "../../components/Form/Search";
import { setTitle } from "../../helpers/MetaTag";
import OrganizationItem from "../../components/Items/OrganizationsItem";
import AddOrganization from "../../components/Modals/AddOrganization";
import Modal from "../../components/Elements/Modal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  delOrganization,
  detailsOrganization,
  listOrganization,
} from "../../helpers/api_helper";
import Pagination from "../../components/Elements/Pagination";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import moment from "moment";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import Confirm from "../../components/Elements/Confirm";
import Select from "react-select";

const Organizations = () => {
  setTitle("Dabbawala | Organizations Management");

  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // For pagination
  const [perPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // Flatpickr
  const fp = useRef(null);
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status] = useState("");

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");


  // Dynamic query key for Organization 🗝️
  const queryOrganization = [
    "organization",
    keyword,
    status,
    limit,
    offset,
    startDate,
    endDate,
  ];

  // for date range search
  useEffect(() => {
    if (date && date.e && date.e[0] && date.e[1]) {
      console.log(date.e[0], date.e[1]);
      setStartDate(date.e[0]);
      setEndDate(date.e[1]);
    }
  }, [date, startDate, endDate]);

  // For fetching details of item
  const {
    data: details,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsOrganization({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);

  // Organization  List Component
  function OrganizationList() {
    // Custom hook for fetching Organization list
    function useOrganization() {
      return useQuery({
        queryKey: queryOrganization,
        queryFn: () =>
          listOrganization({
            keyword: keyword,
            sortByField: "created_at",
            sortByValue: 1,
            limit: limit,
            offset: offset,
            dateRange: {
              start: startDate,
              end: endDate,
            },
          }),
        keepPreviousData: true,
      });
    }

    const { data: organization, error, isFetching } = useOrganization();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (organization.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {organization.data.length > 0 &&
            organization.data.map((item) => {
              return (
                <OrganizationItem
                  key={item._id}
                  // image={item?.image}
                  organizationId={item._id}
                  name={item?.title}
                  description={item?.description}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryOrganization}
                  // status={item?.status}
                  detailsFetch={refetch}
                  setEditId={setEditId}
                  setEditModal={setIsEditModal}
                  setDeleteModal={setDeleteModal}
                  setDeleteId={setDeleteId}
                />
              );
            })}

          <Pagination
            count={organization.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  // delete organization list===============>

  const deleteProduct = useMutation(
    (delProductId) => delOrganization(delProductId),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setDeleteModal(false);
          toast.success(res.message);
          queryClient.invalidateQueries(queryOrganization);
          queryClient.removeQueries({
            queryKey: ["organization"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {},
    }
  );



  const addModal = () => {
    setIsAddModal(true);
  };
  // const deleteItem = () => {
  //   setIsOpenConfirm(true);
  // };
  return (
    <>
      <div className="page-content">
        <div className="page-filter mb-3">
          <div className="input-filters">
            <div className="input-items">
              {perPages && (
                <div className="input">
                  <Select
                    className="select-tag"
                    isMulti={false}
                    isClearable={true}
                    options={perPages}
                    value={selectedPageOpt || []}
                    placeholder={"Per Page"}
                    onChange={(e) => {
                      setLimit(e.value);
                      setSelectedPageOpt(e);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="input-items">
              <Search
                state={keyword}
                setState={setKeyword}
                placeholder={"Search..."}
              />
            </div>
            {/* <div className="input-items">
              <SelectTag data={statusDropdown} placeholder={"Select Status"} />
            </div> */}

            <div className="input-items">
              <Flatpickr
                ref={fp}
                className="form-control"
                id="datepicker-publish-input"
                placeholder="Select a date"
                onChange={(e) => {
                  setDate({ e });
                }}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  mode: "range",
                  dateFormat: "d.m.y",
                }}
              />
            </div>

            <div className="input-items items-end gap-3 ms-auto">
              <Button
                classes={"px-2"}
                icon={"fa-regular fa-arrow-rotate-right"}
                iconPosition={"left"}
                functions={() => {
                  queryClient.invalidateQueries(queryOrganization);
                  queryClient.removeQueries({
                    queryKey: ["organization"],
                    type: "inactive",
                  });
                  setOffset(0);
                  setKeyword("");
                  setStartDate("");
                  setEndDate("");
                  // setStatus("");
                  // setStatusStatic([]);
                  // setTimeout(() => {
                  //   setStatusStatic([
                  //     { _id: "", name: "All", color: "success", current: true },
                  //     {
                  //       _id: true,
                  //       name: "Published",
                  //       color: "success",
                  //       current: false,
                  //     },
                  //     {
                  //       _id: false,
                  //       name: "Draft",
                  //       color: "warning",
                  //       current: false,
                  //     },
                  //   ]);
                  // }, 300);
                }}
              />
              <Button
                label={"Add Organization"}
                labelClasses={""}
                classes={""}
                icon={"fa-regular fa-plus"}
                iconPosition={"left"}
                functions={addModal}
              />
            </div>
          </div>
          {/* <div className="input-items">
            <RadioGroup
              data={statusStatic}
              setData={setStatus}
              inputName={"status"}
            />
          </div> */}
        </div>
        <div className="points-list">
          <OrganizationList />
        </div>
      </div>

      {/* // For add Modal */}
      <Modal
        title={"Add Organization"}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(false)}
      >
        <AddOrganization
          modalClose={() => setIsAddModal(false)}
          query={queryOrganization}
        />
      </Modal>

      {/* //For Edit Modal */}
      <Modal
        title={"Edit Organization"}
        size={"md"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(false)}
      >
        <AddOrganization
          modalClose={() => setIsEditModal(false)}
          query={queryOrganization}
          details={details}
          isFetching={isFetching}
        />
      </Modal>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: deleteId });
        }}
      />
    </>
  );
};

export default Organizations;
