import React from "react";

const DashboardStatItem = ({statotherdata , mainTitle , number }) => {
  return (
    <>
      <div className="stat-overview">
        <div className="header-part">
          <div className="title">{mainTitle}</div>
          <div className="data">{number}</div>
        </div>
        <div className="statoverview-list">
          {statotherdata.map((item) => (
            <div className="overview-stat-item" key={item._id}>
              <div className="overview-title">
                <div className="line" style={{ backgroundColor: (item.color)} }></div>
                <div className="text">{item.title}</div>
              </div>
              <div className='data'>{item.dataNumber}</div>
            </div>
          ))}
          
        </div>
      </div>
    </>
  );
};

export default DashboardStatItem;
