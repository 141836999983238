import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import {
  AddVisionData,
  visionDetails,
  visionEdit,
} from "../../helpers/api_helper";
import { Loading } from "../Elements/Loading";

const AddVision = ({
  showToastMessage,
  setIsAddModal,
  itemId,
  editMode = false,
  isOpen,
  setEditModel,
  query,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [radioData, setRadioData] = useState(1);
  const [image, setImage] = useState([]);

  const queryClient = useQueryClient();

  // details

  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", itemId],
    queryFn: () => visionDetails({ id: itemId }),
    enabled: false ,
  });



  // for add
  const { mutate: addData } = useMutation(AddVisionData, {
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["vision"],
          type: "inactive",
        });
        setIsAddModal(false);
        showToastMessage({ status: 200, message: data.message });
        setTitle("");
        setDescription("");
        setImage([]);
        setRadioData(true);
      } else {
        showToastMessage({ status: 400, message: data.message });
      }
    },
  });

  // for edit

  const { mutate: edit } = useMutation(visionEdit, {
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["vision"],
          type: "inactive",
        });
        
        setTitle("");
        setDescription("");
        setImage([]);
        setRadioData(true);
        setEditModel(false);
        queryClient.removeQueries({
          queryKey: ["details"]
        });
        showToastMessage({ status: 200, message: data.message });
      } else {
        showToastMessage({ status: 400, message: data.message });
      }
    },
  });

  // for details

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [refetch, isOpen]);

  useEffect(() => {
    if (details) {
      setTitle(details?.title ? details.title : "");
      setDescription(details?.description ? details.description : "");
      setImage(details ? [{ _id: 1, image: details?.image }] : []);


      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 50);
    }
  }, [details]);

  const [isPreview, setIspreview] = useState(false);


  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const previewData = [
    {
      image: "images/product.png",
    },
  ];
  if (isFetching) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="px-2">
          <div className="row divide-x">
            <div className="col-lg-7">
              <div className="row">
                <div className="mb-3">
                  <Input
                    label={"Title"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Title"}
                    inputName={""}
                    setState={setTitle}
                    state={title}
                  />
                </div>
              </div>

              <div className="row">
                <div className="mb-3">
                  <Textarea
                    label={"Description"}
                    inputRequired={true}
                    inputPlaceholder={"Write description......."}
                    inputName={""}
                    setState={setDescription}
                    state={description}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-4">
                  <RadiosLabel
                    data={publishedData}
                    setData={setPublishedData}
                    label={"Status"}
                    inputRequired={true}
                    setRadio={setRadioData}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              {
                <>
                  <div className="row">
                    <div className="col">
                      <ImageUploadPreview
                        data={image}
                        label={"Upload Icon"}
                        inputRequired={true}
                        setImage={setImage}
                        state={image}
                      />
                    </div>
                  </div>
                </>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-wrap gap-3">
                <Button
                  label={"Save"}
                  functions={() => {
                    editMode
                      ? edit({
                          id: itemId,
                          title,
                          description,
                          status: radioData === 1 ? true : false,
                          image: image[0]?.image.includes("https")
                            ? null
                            : image[0]?.image,
                          type: 1,
                        })
                      : addData({
                          title,
                          description,
                          status: radioData === 1 ? true : false,
                          image: image ? image[0]?.image : null,
                          type: 1,
                        });
                  }}
                />
                <Button
                  label={"Cancel"}
                  classes={"main-button-alt"}
                  functions={() => {
                    editMode ? setEditModel(false) : setIsAddModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AddVision;
