import { useEffect, useState } from "react";
import Button from "../../components/Form/Button";
import Search from "../../components/Form/Search";
import RadioGroup from "../../components/Form/RadioGroup";
import MerchandiseItem from "../../components/Items/MerchandiseItem";
import Modal from "../../components/Elements/Modal";
import AddMerchandise from "../../components/Modals/AddMerchandise";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import {
  detailsProduct,
  listProduct,
  ordering,
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Select from "react-select";
import Pagination from "../../components/Elements/Pagination";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";

const Merchandise = () => {
  const queryClient = useQueryClient();

  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [editId, setEditId] = useState("");
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  const [isOne, setDataOne] = useState(false);
  const [dataList, setDataList] = useState([]);
  const queryMerch = ["merchandise", keyword, status, limit, offset];

  const [staticStatusArr, setStaticStatusArr] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: 1, name: "Published", color: "success", current: false },
    { _id: 3, name: "Draft", color: "warning", current: false },
  ]);

  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);

  const addModal = () => {
    setIsAddModal(true);
  };

  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => detailsProduct({ id: editId }),
    enabled: false,
  });

  useEffect(() => {
    if (editId !== "" && isEditModal) {
      refetch();
    }
  }, [editId, refetch, isEditModal]);

  function MerchList({}) {
    function useMerchProducts() {
      return useQuery({
        queryKey: queryMerch,
        queryFn: async () =>
          listProduct({
            keyword: keyword,
            product_type: 3,
            status: status,
            limit: limit,
            offset: offset,
            sortByField: "ordering",
            sortByValue: 1,
          }),
        keepPreviousData: true,
      });
    }

    const { status: sts, data: merch, error, isFetching } = useMerchProducts();
    // setProductCount(merch ? merch.count : 0)

    const handleDragEnd = (e) => {
      setDataOne(true);
      if (!e.destination) return;
      let tempData = Array.from(dataList);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
      // console.log(tempData);
      setDataList([...tempData]);
      let dataArray = [];
      for (let i = 0; i < tempData.length; i++) {
        dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
      }
      // setOrderingData(dataArray);

      onClickSaveOrdering(dataArray);
    };
    const onClickSaveOrdering = async (orderingData) => {
      const saveOrdering = await ordering({
        orderingData: orderingData,
        tableName: "product",
      });

      if (saveOrdering.status === 200) {
        setDataOne(false);
        queryClient.invalidateQueries(queryMerch);
        queryClient.removeQueries({
          queryKey: ["merchandise"],
          type: "inactive",
        });
        toast.success(saveOrdering.message);
      } else {
        toast.error(saveOrdering.message);
      }
    };

    useEffect(() => {
      //console.log(isOne);
      if (!isOne && merch !== undefined) {
        setDataList(merch?.data);
      }
    }, [isOne, merch?.data]);

    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error />;
    } else if (merch.data.length === 0) {
      return <NoData />;
    } else {
      return (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {dataList &&
                    dataList.length > 0 &&
                    dataList.map((item, index) => (
                      <MerchandiseItem
                        key={item._id}
                        index={index}
                        productId={item._id}
                        name={item.title}
                        image={item.image}
                        points={item.points}
                        stock={item.in_stock}
                        createdBy={item.created_by_name?.name}
                        createdDate={moment(new Date(item.created_at)).format(
                          "Do MMM YY"
                        )}
                        updatedDate={moment(new Date(item.updated_at)).format(
                          "Do MMM YY"
                        )}
                        status={item.status}
                        queryMerch={queryMerch}
                        refetch={fetch}
                        setEditModal={setIsEditModal}
                        setEditId={setEditId}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Pagination count={merch.count} limit={limit} offset={offset} setOffset={setOffset} />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              placeholder={"Search..."}
              state={keyword}
              setState={setKeyword}
            />
          </div>
          <div className="input-items items-end gap-3 ms-auto">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                setKeyword("");
                setLimit(10);
                setSelectedPageOpt([])
                queryClient.invalidateQueries(queryMerch);
                queryClient.removeQueries({
                  queryKey: ["merchandise"],
                  type: "inactive",
                });
              }}
            />
            <Button
              label={"Merchandise"}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="input-items">
          <RadioGroup
            setData={setStatus}
            data={staticStatusArr}
            inputName={"status"}
          />
        </div>
      </div>
      <div className="merchandise-list">
        <MerchList
          keyword={keyword}
          status={status}
          limit={limit}
          offset={offset}
          query={queryMerch}
          detailsFetch={refetch}
          setEditId={setEditId}
          setEditModal={setIsEditModal}
        />
      </div>
      <Modal
        title={"Add Merchandise Products"}
        size={"lg"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(false)}
      >
        <AddMerchandise modalClose={() => setIsAddModal(false)} />
      </Modal>

      <Modal
        title={"Edit Merchandise Products"}
        size={"lg"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(false)}
      >
        <AddMerchandise
          details={details}
          isFetching={isFetching}
          query={queryMerch}
          modalClose={() => setIsEditModal(false)}
        />
      </Modal>
    </>
  );
};

export default Merchandise;
