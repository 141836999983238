import React, { useState, useEffect } from "react";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import AddFutureTeam from "../../components/Modals/AddFutureTeam";
import FutureItem from "../../components/Items/FutureItem";
import moment from "moment/moment";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DragDropContext, Droppable} from "react-beautiful-dnd";
import {
  futureTeamDetails,
  futureTeamList,
ordering
} from "../../helpers/api_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Pagination from "../../components/Elements/Pagination";
const FutureTeam = () => {
  const [isAddModal, setIsAddModal] = useState(false);
  const [list, setList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [editModel, setEditModel] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 99999999 },
  ]);
  const [isOne, setDataOne] = useState(false);
  const queryClient = useQueryClient();
  useEffect(()=>{
    setOffset(0)
    setLimit(10)
 
  },[keyword,radioData])

  const queryFutureTeam = ["futureTeam", keyword, radioData, limit, offset];

  const showToastMessage = (data) => {
    if (data.status === 200) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };
 
  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editItemId],
    queryFn: () => futureTeamDetails({ id: editItemId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editItemId !== "" && editModel) {
      refetch();
    }
  }, [editItemId, editModel, refetch]);

  function FutureTeamList() {
    function useFutureTeam() {
      return useQuery({
        queryKey: queryFutureTeam,
        queryFn: () =>
          futureTeamList({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: radioData === 1 ? "" : radioData === 2 ? true : false,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: futureData, error, isFetching } = useFutureTeam();

    useEffect(() => {
      if (!isOne) {
        setList(futureData?.data);
      }
      
    }, [isOne,futureData?.data]);



    const handleDragEnd = (e) => {
      //console.log(e);
      if (!e.destination) return;
      let tempData = Array.from(list);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
     
      setList([...tempData]);
      let dataArray = [];
      for (let i = 0; i < tempData.length; i++) {
        dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
      }
       
      onClickSaveOrdering(dataArray);
      setDataOne(true);
    };

    const onClickSaveOrdering = async (orderingData) => {
      const saveOrdering = await ordering({
        orderingData: orderingData,
        tableName: "furute_team",
      });

      if (saveOrdering.status === 200) {
        setDataOne(false);
        queryClient.invalidateQueries(queryFutureTeam);
        queryClient.removeQueries({
          queryKey: ["futureTeam"],
          type: "inactive",
        });
        toast.success(saveOrdering.message);
      } else {
        toast.error(saveOrdering.message);
      }
    };


    if (isFetching) {
      return <Loading />;
    } else if (error) {
      return <Error />;
    } else if (futureData.data.length === 0) {
      return <NoData />;
    } else {
      return (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {Array.isArray(list) &&
                    list.map((item,index) => (
                      <FutureItem
                        key={item._id}
                        id={item._id}
                        index={index}
                        name={item.name}
                        type={item.type === 1? "Image" : "Video"}
                        featured={item.featured}
                        createdBy={item.createdBy}
                        createdDate={moment(new Date(item?.created_at)).format(
                          "Do MMM YY"
                        )}
                        updatedDate={moment(new Date(item?.updated_at)).format(
                          "Do MMM YY"
                        )}
                        status={item.status}
                        isDraft={item.status}
                        setEditModel={setEditModel}
                        editId={setEditItemId}
                        query={queryFutureTeam}
                      />
                    ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Pagination
            count={futureData.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }



  const addModal = () => {
    setIsAddModal(true);
  };

  const status = [
    { _id: 1, name: "All", color: "success", current: true },
    { _id: 2, name: "Published", color: "success", current: false },
    { _id: 3, name: "Draft", color: "warning", current: false },
  ];

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            <div className="input">
              <Select
                className="select-tag"
                isMulti={false}
                isClearable={true}
                options={perPages}
                value={selectedPageOpt || []}
                placeholder={"Per Page"}
                onChange={(e) => {
                  setLimit(e.value);
                  setSelectedPageOpt(e);
                }}
              />
            </div>
          </div>
          <div className="input-items">
            <Search
              placeholder={"Search..."}
              state={keyword}
              setState={setKeyword}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryFutureTeam);
                queryClient.removeQueries({
                  queryKey: ["futureTeam"],
                  type: "inactive",
                });
                setOffset(0);
                setLimit(10);
                setKeyword("");
                setRadioData(1);
                setSelectedPageOpt([]);
              }}
            />
            <Button
              label={"Add "}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="input-items">
          <RadioGroup
            data={status}
            inputName={"status"}
            setData={setRadioData}
          />
        </div>
      </div>
      <div className="future-list">
        <FutureTeamList></FutureTeamList>
      </div>

      <Modal
        title={"Add "}
        size={"md"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <AddFutureTeam
          setIsAddModal={setIsAddModal}
          showToastMessage={showToastMessage}
          query={queryFutureTeam}
        />
      </Modal>

      <Modal
        title={"Edit"}
        size={"md"}
        isOpen={editModel}
        modalClose={() => setEditModel(false)}
      >
        <AddFutureTeam
          isOpen={editModel}
          setEditModel={setEditModel}
          editItemId={editItemId}
          showToastMessage={showToastMessage}
          editMode={true}
          detailsFetch={refetch}
          isFetching={isFetching}
          details={details}
        />
      </Modal>
    </>
  );
};

export default FutureTeam;
