import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Button from "../../components/Form/Button";
import Modal from "../../components/Elements/Modal";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import JobTypeItem from "../../components/Items/JobTypeItem";
import AddJobType from "../../components/Modals/AddJobType";
import { jobTypeDetails, jobTypeList } from "../../helpers/api_helper";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import moment from "moment/moment";
import { Loading } from "../../components/Elements/Loading";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";
import Pagination from "../../components/Elements/Pagination";

const JobType = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  // For pagination
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // To fetch Details of specific items when it needs to be edited
  const [editId, setEditId] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  // Dynamic query key for job Types 🗝️
  const queryjobTypes = ["jobTypes", keyword, status, limit, offset];

  // For opening Add Modal althogh not mandetory
  const addModal = () => {
    setIsAddModal(true);
  };

  // For fetching details of item
  const {
    data: details,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["details", editId],
    queryFn: async () => jobTypeDetails({ id: editId }),
    enabled: false,
  });

  // Refetch when editId updates
  useEffect(() => {
    if (editId !== "") {
      refetch();
    }
  }, [editId, refetch]);

  // job type List Component
  function JobTypeList() {
    // Custom hook for fetching job type list
    function useJobType() {
      return useQuery({
        queryKey: queryjobTypes,
        queryFn: () =>
          jobTypeList({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: jobtypes, error, isFetching } = useJobType();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (jobtypes.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {jobtypes.data.length > 0 &&
            jobtypes.data.map((item, index) => {
              return (
                <JobTypeItem
                  key={item._id}
                  index={index}
                  jobTypeId={item._id}
                  type={item?.type}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryjobTypes}
                  status={item?.status}
                  detailsFetch={refetch}
                  setEditId={setEditId}
                  setEditModal={setIsEditModal}
                />
              );
            })}

          <Pagination
            count={jobtypes.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters d-flex justify-content-between">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryjobTypes);
                queryClient.removeQueries({
                  queryKey: ["jobTypes"],
                  type: "inactive",
                });
                setOffset(0);
                setKeyword("");
                setStatus("");
                setStatusStatic([]);
                setSelectedPageOpt([]);
                setTimeout(() => {
                  setStatusStatic([
                    { _id: "", name: "All", color: "success", current: true },
                    {
                      _id: true,
                      name: "Published",
                      color: "success",
                      current: false,
                    },
                    {
                      _id: false,
                      name: "Draft",
                      color: "warning",
                      current: false,
                    },
                  ]);
                }, 300);
              }}
            />
            <Button
              label={"Add"}
              labelClasses={""}
              classes={""}
              icon={"fa-regular fa-plus"}
              iconPosition={"left"}
              functions={addModal}
            />
          </div>
        </div>
        <div className="radio-filters">
          <RadioGroup
            data={statusStatic}
            setData={setStatus}
            inputName={"status"}
          />
        </div>
      </div>

      <div className="jobtype-list">
        <JobTypeList />
      </div>

      
{/* for add modal */}
      <Modal
        title={"Add Job Type"}
        size={"sm"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <AddJobType
          modalClose={() => setIsAddModal(false)} query={queryjobTypes}
        />
      </Modal>

      {/* edit modal */}

      <Modal
        title={"Edit Job Type"}
        size={"sm"}
        isOpen={isEditModal}
        modalClose={() => setIsEditModal(!isEditModal)}
      >
        <AddJobType
         modalClose={() => setIsEditModal(false)}
         query={queryjobTypes}
         details={details}
         isFetching={isFetching}
        />
      </Modal>
    </>
  );
};

export default JobType;
