import { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addProductIngredient, editProductIngredient } from "../../helpers/api_helper";
import { toast } from "react-toastify";



const AddAllergies = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(true)
  const [title, setTitle] = useState("");
  const [uploadData, setUploadData] = useState([]);


  const submitAllergy = useMutation(
    (payload) => addProductIngredient(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["ingredients"],
            type: "inactive",
          });
          setTitle("");
          setUploadData([]);
          setStatus(1);
          modalClose();
        }

        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const submitEditedAllergy = useMutation(
    (payload) => editProductIngredient(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["ingredients"],
            type: "inactive",
          });
          setTitle("");
          setUploadData([]);
          setStatus(1);
          modalClose();
        }
        
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  

  useEffect(() => {
    if(details) {
      setTitle(details ? details.title : "");
      setStatus(details ? details.status : "");
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);

      setTimeout(() => {
        setPublishedData(
          details
            ? [
              {
                _id: 1,
                label: "Published",
                inputName: "publish_status",
                current: details.status.toString() === 'true' ? true : false,
              },
              {
                _id: 2,
                label: "Draft",
                inputName: "publish_status",
                current: details.status.toString() === 'false' ? true : false,
              },
            ]
            : []
        );
  
      }, 400)
    }
  }, [details])


  if(isFetching){
    return(
      <div>Loading...</div>
    )
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input
                    state={title}
                    setState={setTitle}
                    label={"Title"}
                    inputRequired={true}
                    inputType={"text"}
                    inputPlaceholder={"Example . Wheat"}
                    inputName={""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="mb-4">
                  <RadiosLabel
                   setRadio={setStatus}
                    data={publishedData}
                    setData={setPublishedData}
                    label={"Status"}
                    inputRequired={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                  {details ? <Button label={"Save"} 
                  functions={() => submitEditedAllergy.mutate({
                    id: details._id,
                    type: 1,
                    title: title,
                    status: status === 1 ? true : false,
                    image: uploadData && uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image,
                  })}
                  /> : <Button label={"Save"} 
                  functions={() =>submitAllergy.mutate({
                    type: 1,
                    title: title,
                    status: status === 1 ? true : false,
                    image: uploadData ? uploadData[0]?.image : null,
                  })}
                  />}
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={modalClose} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col">
                <ImageUploadPreview
                  setImage={setUploadData}
                  data={uploadData}
                  label={"Upload Image"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAllergies;
