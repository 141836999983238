import React, { useEffect, useState } from 'react';
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import OpeningHoursItem from '../../components/Items/OpeningHoursItem';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { openingList, openingListDetails, ordering } from '../../helpers/api_helper';
import { NoData } from '../../components/Elements/NoData'
import Modal from "../../components/Elements/Modal";
import OpeningHourEdit from '../../components/Modals/OpeningHourEdit';
import { Loading } from '../../components/Elements/Loading';
import { toast } from 'react-toastify';
import { Error } from '../../components/Elements/Error';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const OpeningHours = () => {

  const [isAddModal, setIsAddModal] = useState(false);
  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState('')
  const [editId, setEditId] = useState()
  const [openinghoursData, setOpeningHoursData] = useState('')
  const [listData, setListData] = useState([])
  const [isOne, setDataOne] = useState(false);



  // ===============================================================================>(all functions are here)

  const queryClient = useQueryClient();

  // getting opening hours list=============>

  const openingListkey = ['openingList', keyword, filter]

  const { data: openingListData, error, isFetching: openingListFetching } = useQuery({
    queryKey: openingListkey,
    queryFn: async () => openingList({
      keyword: keyword,
      sortByField: "ordering",
      sortByValue: 1,
    })
  })

  useEffect(() => {
    if (!isOne) {
      setListData(openingListData);
    }
  }, [isOne, openingListData]);



  // details==========>

  const detailsKey = ['listDetails', editId]

  const { data: ListDetails, isFetching: detailsListLoading } = useQuery({
    queryKey: detailsKey,
    queryFn: async () => openingListDetails({ id: editId }),
  })


  // Modal opening==================>

  const addModal = () => {
    setIsAddModal(true);
  }

  const status = [
    { _id: 1, name: 'All', color: 'success', current: true },
    { _id: 2, name: 'Published', color: 'success', current: false },
    { _id: 3, name: 'Draft', color: 'warning', current: false },
  ]

  // drag and drop handling================>

  const handleDragEnd = (e) => {

    if (!e.destination) return;
    let tempData = Array.from(listData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setListData([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    onClickSaveOrdering(dataArray);
    setDataOne(true);
  };


  const onClickSaveOrdering = async (orderingData) => {
    const saveOrdering = await ordering({
      orderingData: orderingData,
      tableName: "open_hour",
    });

    if (saveOrdering.status === 200) {
      setDataOne(false);
      queryClient.invalidateQueries(openingListkey);
      queryClient.removeQueries({
        queryKey: ["openingList"],
        type: "inactive",
      });

      toast.success(saveOrdering.message);
    } else {
      toast.error(saveOrdering.message);
    }
  };

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword} placeholder={'Search...'} />
          </div>

          <div className="input-items items-end gap-3 ms-auto">
            <Button functions={() => setKeyword('')} classes={'px-2'} icon={'fa-regular fa-arrow-rotate-right'} iconPosition={'left'} />
          </div>
        </div>
        {/* <div className="input-items">
          <RadioGroup data={status} inputName={'status'} />
        </div> */}
      </div>

      <div className="openinghours-list">
        {
          openingListFetching ? <Loading /> :
            error ? <Error /> :
            openingListData?.length === 0 ? <NoData /> :
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId='openingLists'>
                    {(provided) => {
                      return (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {
                            listData?.map((list, index) =>
                              <OpeningHoursItem
                                key={list._id}
                                index={index}
                                id={list._id}
                                day={list.day_name}
                                closingDay={list.is_closed}
                                closingTime={list.end_time}
                                openingTime={list.start_time}
                                createdBy={list.createdBy}
                                createdDate={list.created_at}
                                updatedDate={list.updated_at}
                                setEditId={setEditId}
                                setIsAddModal={addModal}
                              // status={list.status}
                              />
                            )
                          }
                          {provided.placeholder}
                        </div>
                      )
                    }
                    }
                  </Droppable>
                </DragDropContext>
        }
      </div>

      <Modal
        title={"Edit Opening Hours"}
        size={"sm"}
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <OpeningHourEdit data={ListDetails} query={openingListkey} detailsListLoading={detailsListLoading} id={editId} modalClose={() => setIsAddModal(!isAddModal)} />
      </Modal>

    </>
  );
};

export default OpeningHours;