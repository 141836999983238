import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { delFaqs, FaqstsChange } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const FaqsItem = ({
  index,
  question,
  answer,
  createdBy,
  createdDate,
  updatedDate,
  status,
  query,
  faqsId,
  setEditModal,
  setEditId,
  detailsFetch,
  Cat_name
}) => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);


  const deleteFaqs = useMutation(
    (delFaqId) =>
    delFaqs(delFaqId),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["faqs"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation(
    (changeSts) => FaqstsChange(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["faqs"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
     <Draggable draggableId={faqsId} index={index} key={faqsId}>
        {(provided) => (
          <div
            className="faqs-item mb-3"
            key={faqsId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
        <div className="faqs-item-row">
          <div className="faqs-content">
          <div className="faqs-details">
              <span>Category</span>
              <div className="faqs-title">{Cat_name}</div>
            </div>
            <div className="faqs-details mt-2">
              <span>Question</span>
              <div className="faqs-title">{question}</div>
            </div>
            <div className="faqs-details mt-2">
              <span>Answer</span>
              <div className="faqs-title">{answer}</div>
            </div>
          </div>
          <div className="faqs-options">
            <div className={"status " + (status ? 'published' : 'draft')}>
              {status ? 'published' : 'draft'}
            </div>
            <div className="action-dropdown dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    setEditModal(true);
                    setEditId(faqsId);
                    detailsFetch();
                  }}
                >
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                <button type="button" className="dropdown-item" onClick={() => statusChange.mutate({
                  id: faqsId,
                  status: status ? false : true,
                })}>
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                  {status ? 'draft' : 'publish'}
                </button>
                <button
                  type="button"
                  className="dropdown-item standOut"
                  onClick={() => setDeleteModal(true)}
                >
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {(createdBy || createdDate || updatedDate) && (
          <div className="faqs-item-row border-t">
            <div className="faqs-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="faqs-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
 )}
 </Draggable>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteFaqs.mutate({ id: faqsId });
        }}
      />
    </>
  );
};

export default FaqsItem;
