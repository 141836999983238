import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { delCoupon, stsChangeCoupon } from '../../helpers/api_helper';
import Confirm from '../Elements/Confirm';

const CouponItem = ({
  couponId,
  name,
  description,
  validFrom,
  validThrough,
  limit,
  minSpent,
  maxSpent,
  createdBy,
  createdDate,
  status,
  price,
  queryData,
  detailsFetch,
  setEditId,
  setEditModal,
}) => {

  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);


  const deleteProduct = useMutation(
    (payload) => delCoupon(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryData);
          queryClient.removeQueries({
            queryKey: ["coupons"],
            type: "inactive",
          });
        }
        if(res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const statusChange = useMutation(
    (payload) => stsChangeCoupon(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryData);
          queryClient.removeQueries({
            queryKey: ["coupons"],
            type: "inactive",
          });
        }
        if(res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  return (
    <>
      <div className="coupon-item mb-3">
        <div className="coupon-item-row">
          <div className="coupon-options">
            <div className={"status " + (status ?'published':'draft')}>{status ?'published':'draft'}</div>
            <div className="action-dropdown dropdown">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" 
                
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button type="button" className="dropdown-item" onClick={() => {
                  setEditModal(true);
                  setEditId(couponId);
                  detailsFetch();
                }}
                >
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>


                <button type="button" className="dropdown-item" onClick={() => statusChange.mutate({
                  id: couponId,
                  status: status ? false : true,
                })}>
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>{status ? 'draft' : 'publish' }
                </button>


                <button type="button" className="dropdown-item standOut" onClick={() => setDeleteModal(true)}>
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="coupon-content">
          <div className="coupon-label">Coupon Code</div>
          <div className="coupon-title">{name}</div>
          <div className="coupon-label mt-4">Coupon Description</div>
          <div className="coupon-text">{description}</div>
        </div>
        <div className="coupon-metas">
          {validFrom &&
          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">Valid From</div>
              <div className="meta-data">{validFrom}</div>
            </div>
          </div>
          }
          {validThrough &&
          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">Valid Through</div>
              <div className="meta-data">{validThrough}</div>
            </div>
          </div>
          }
          {limit >= 0 &&
          <div className="meta-item">
            <div className="meta-content">
              <div className="meta-label">Limit</div>
              <div className="meta-data">{limit}</div>
            </div>
          </div>
          }
        </div>
        <div className="coupon-item-row mt-3">
          <div className="coupon-price">
            <div className="price">{price}</div>
          </div>
        </div>
        {(minSpent || maxSpent  || createdBy || createdDate) &&
        <div className="coupon-item-row border-t">
          <div className="coupon-info">
            {minSpent >= 0 &&
            <div className="info">
              <div className="info-label">Min Spent Amount</div>
              <div className="info-data">{minSpent}</div>
            </div>
            }
            {maxSpent >= 0  &&
            <div className="info">
              <div className="info-label">Max Spent Amount</div>
              <div className="info-data">{maxSpent }</div>
            </div>
            }
          </div>
          <div className="coupon-info">
            {createdBy &&
            <div className="info">
              <div className="info-label">Created By</div>
              <div className="info-data">{createdBy}</div>
            </div>
            }
            {createdDate &&
            <div className="info">
              <div className="info-label">Created Date</div>
              <div className="info-data">{createdDate}</div>
            </div>
            }
          </div>
        </div>
        }
      </div>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: couponId });
        }}
      />
    </>
  );
};

export default CouponItem;