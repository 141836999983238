import { useContext, useEffect, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Image from "../Elements/Image";
import { SocketContext } from '../../socket/socketio';
import moment from 'moment/moment';
import OrderDetails from '../Modals/OrderDetails';
import SidebarDetails from '../Elements/SidebarDetails';
import { detailsOrders } from '../../helpers/api_helper';
import { NotificationList } from '../../helpers/api_helper';
import OrderModal from "../Modals/OrderModal";
import Modal from "../../components/Elements/Modal";

const Notifications = () => {
  const socket = useContext(SocketContext);
  const [notifications, setNotifications] = useState([]);
  const [details, setOrderDetails] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isAddModal, setIsAddModal] = useState(false);
  const [newOrderInfo, setNewOrderInfo] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const token = localStorage.getItem('access_token');
  useEffect(()=>{
    if(socket?.io){
        socket.on('orderNotificationResponse', async(data) => {
          if(data && token){
          
              const notification = await NotificationList();
              setNotificationCount(notification.count);
              setNotifications(notification.data);
              setNewOrderInfo(newOrderInfo => [...new Set([...newOrderInfo,...[data]].map(item => item._id))]);
              setNotificationModal(true);
         }
        });
  
    return () => {
        socket.off("orderNotificationResponse", () => {})
    };
  }
  },[socket?.io]);

  useEffect(()=>{
    const getNotification = async() => {
         const notification = await NotificationList();
        setNotificationCount(notification.count);
        setNotifications(notification.data);
    }
  getNotification();
  },[])



  const clickNotification = async(id)=>{
     try {
      const viewNotification = await NotificationList({id:id});
        setNotificationCount(viewNotification.count);
        setNotifications(viewNotification.data);
    //console.log(viewNotification);

      const orderDetails =  await detailsOrders({ id: id });
      setOrderDetails(orderDetails);
     } catch (error) {
      
     }
    
     setIsAddModal(true);
  }

  const isFetching = true;

  return (
    <>
      <div className="notification dropdown">
        <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
          <i className="fa-light fa-fw fa-bullhorn fa-rotate-n30"></i>
          <motion.div className="count" whileHover={{ scale: 1.5 }} transition={{ type: "spring", stiffness: 400, damping: 10 }}>{notificationCount}</motion.div>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <div className="dropdown-header">
            <i className="fa-solid fa-fw fa-bullhorn"></i> Notifications
          </div>
          <div className="dropdown-body scrollbar">
            <AnimatePresence>
            {notifications.map((item) =>(
              <motion.div
              key={item._id}
              initial={{ opacity: 0,}}
              animate={{ opacity: 1, }}
              exit={{ opacity: 0, }}
              >
                <div style={{cursor:'pointer'}} onClick={()=>clickNotification(item._id)} className="notification-item">
                  <div className="notification-image">
                    <Image src={item.users?.image?item.users?.image:'https://images.unsplash.com/photo-1544168190-79c17527004f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fHVzZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60'} alt={"User Image"} width={'100%'} height={'100%'} effect={'blur'}/>
                  </div>
                  <div className="notification-content">
                    <div className="notification-title">{item?.users.name?item?.users?.name:''}</div>
                    <div className="notification-data">New Order : {item.order_id}</div>
                    <div className="notification-time">{moment(new Date(item?.created_at)).format(
                          "Do MMM YY"
                        )}</div>
                  </div>
                </div>
              </motion.div>
            ))}
            </AnimatePresence>
          </div>
          <div className="dropdown-footer">
            <Link to="/notifications" className="link">See all notifications</Link>
          </div>
        </div>
      </div>
      {details && <SidebarDetails
        isOpen={isAddModal}
        modalClose={() => setIsAddModal(!isAddModal)}
      >
        <OrderDetails
          details={details}
          isFetching={isFetching}
          query={''}
        />
      </SidebarDetails>
       }
  {/* {detailsOrders && */}
      <Modal
        title={"Order Notification"}
        size={"md"}
        isOpen={notificationModal}
        modalClose={() => setNotificationModal(!notificationModal)}
      >
        <OrderModal
          modalClose={() => setNotificationModal(!notificationModal)}
          count={newOrderInfo.length}
          setNewOrderInfo={setNewOrderInfo}
        />
      </Modal>
     {/* } */}
    </>
  );
};

export default Notifications;