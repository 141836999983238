import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import PreviewProduct from "../Elements/PreviewProduct";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addService, editService } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const AddServices = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    { _id: 1, label: "Published", inputName: "publish_status", current: true },
    { _id: 2, label: "Coming Soon", inputName: "publish_status", current: false },
    { _id: 3, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(1);
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [uploadData, setUploadData] = useState([]);
  const [isPreview, setIspreview] = useState(false);

  const previewData = [
    {
      image:'images/product.png',

    }
  ]

  const { mutate: handleSubmit } = useMutation(
    (payload) => addService(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["services"],
            type: "inactive",
          });
          setTitle("");
          setUploadData([]);
          setLink("");
          setDescription("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => editService(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["services"],
            type: "inactive",
          });
          setTitle("");
          setUploadData([]);
          setLink("");
          setDescription("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (details) {
      setTitle(details ? details.title : "");
      setDescription(details ? details.description : "");
      setLink(details ? details.link : "");
      setStatus(details ? details.status : "");
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status === 1 ? "publish" : "",
                },
                {
                  _id: 2,
                  label: "Comming Soon",
                  inputName: "publish_status",
                  current: details.status === 2 ? "coming soon" : "",
                },
                {
                  _id: 3,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status === 3 ? "draft" : "",
                },
              ]
            : []
        );
      }, 600);
    }
  }, [details]);

  if (isFetching) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="mb-3">
                <Input
                  state={title}
                  setState={setTitle}
                  label={"Title"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Title"}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input
                  state={link}
                  setState={setLink}
                  label={"Link"}
                  // inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Link"}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Textarea
                  state={description}
                  setState={setDescription}
                  label={"Description"}
                  inputRequired={true}
                  inputPlaceholder={"Write description......."}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-4">
                
                <RadiosLabel
                  data={publishedData}
                  setData={setPublishedData}
                  setRadio={setStatus}
                  label={"Status"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            {!isPreview ? (
              <>
                <div className="row">
                  <div className="col">
                    <ImageUploadPreview
                      setImage={setUploadData}
                      data={uploadData}
                      label={"Upload Icon"}
                      // inputRequired={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              <PreviewProduct
                data={previewData}
                previewClose={() => setIspreview(!isPreview)}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              {details ? (
                <Button
                  label={"Save"}
                  functions={() =>
                    handleEdit({
                      id: details._id,
                      description: description,
                      title: title,
                      status: status,
                      link: link,
                      image:
                        uploadData && uploadData[0]?.image.includes("https")
                          ? null
                          : uploadData[0]?.image,
                    })
                  }
                />
              ) : (
                <Button
                  label={"Save"}
                  functions={() =>
                    handleSubmit({
                      title: title,
                      description: description,
                      status: status,
                      link: link,
                      image: uploadData ? uploadData[0]?.image : null,
                    })
                  }
                />
              )}
              <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServices;
