import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { categoryWiseProduct, productOrdering } from "../../helpers/api_helper";

function CatWiseModal({ selectedCatOpt }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const productdata = await categoryWiseProduct({
          product_ordering: 1,
          status: 1,
          product_category_id: selectedCatOpt[0].value,
          limit: 9999,
        });
        // console.log(productdata);
        setData(productdata.data[0].product_list);
      } catch (error) {}
    };
    loadData();
  }, [selectedCatOpt]);

  const handleDragEnd = (e) => {
    //console.log(e);
    if (!e.destination) return;
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    // console.log(tempData);
    setData([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ product_id: tempData[i]._id, ordering: i + 1 });
    }
    // setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

  const onClickSaveOrdering = async (orderingData) => {
    const saveOrdering = await productOrdering({
      orderingData: orderingData,
      product_category_id: selectedCatOpt[0].value,
    });

    if (saveOrdering.status === 200) {
      toast.success(saveOrdering.message);
    } else {
      toast.error(saveOrdering.message);
    }
  };
  // console.log(data);
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table table-lg">
          <Droppable droppableId="div">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Draggable
                      draggableId={item._id}
                      key={item._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          key={item._id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="menu-item border rounded mb-2 px-4">
                            <div className="menu-list">
                              <div className="menu-content">
                                <div className="menu-title d-flex justify-content-between">
                                  {item.title}
                                  {item.featured && (
                                    <span className="popular">Popular</span>
                                  )}
                                  <div
                                    className={
                                      "status " +
                                      (item.status === 1
                                        ? "published"
                                        : "draft")
                                    }
                                  >
                                    {item.status === 1 ? "published" : "draft"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </>
  );
}

export default CatWiseModal;
