import { useEffect, useState } from "react";
import Input from "../Form/Input";
import Button from "../Form/Button";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import 'react-toastify/dist/ReactToastify.css';
import { AdminAdd, AdminEdit } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const AddAdmin = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
const [isDisabled, setIsDisabled] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [status, setStatus] = useState(1)

  const { mutate: handleSubmit } = useMutation(
    (payload) => AdminAdd(payload),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["admin"],
            type: "inactive",
          });
          setTitle("");
          setUploadData([]);
          setEmail("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  const { mutate: handleEdit } = useMutation(
    (payload) => AdminEdit(payload),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["admin"],
            type: "inactive",
          });
          
          setTitle("");
          setEmail("");
          setUploadData([]);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if(details) {
      setIsDisabled(true);
      setTitle(details ? details.name : "");
      setEmail(details ? details.email : "");
      setUploadData(details ? [{ _id: 1, image: details.image }] : []);
    }
  }, [details]);


  if(isFetching){
    return(
      <div>Loading...</div>
    )
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input 
                    state={title}
                    setState={setTitle}
                    label={"Title"} 
                    inputRequired={true} 
                    inputType={"text"} 
                    inputPlaceholder={"Example . Admin"} 
                    inputName={""} 
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <Input 
                    state={email}
                    setState={setEmail}
                    label={"Email"} 
                    inputRequired={true} 
                    inputType={"text"} 
                    inputPlaceholder={"Example . Admin@mailinator.com"} 
                    inputName={""} 
                    isDisabled={isDisabled}
                  />
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex flex-wrap gap-3">
                {details ? <Button label={"Save"} 
                  functions={() => handleEdit({
                    id: details._id,
                    name: title,
                    email: email,
                    image: uploadData && uploadData[0]?.image.includes('https') ? null : uploadData[0]?.image,
                  })}
                  /> : <Button label={"Save"} 
                  functions={() => handleSubmit({
                    name: title,
                    email: email,
                    image: uploadData ? uploadData[0]?.image : null,
                    email_verified: true
                  })}
                  />}
                  
                  <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col">
                <ImageUploadPreview
                  setImage={setUploadData}
                  data={uploadData}
                  label={"Upload Image"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
