import { useEffect, useRef, useState } from "react";
import Button from "../../components/Form/Button";
import RadioGroup from "../../components/Form/RadioGroup";
import Search from "../../components/Form/Search";
import { setTitle } from "../../helpers/MetaTag";
import PointsItem from "../../components/Items/PointsItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { listOrder, listPoints } from "../../helpers/api_helper";
import { Loading } from "../../components/Elements/Loading";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import moment from "moment";
import Pagination from "../../components/Elements/Pagination";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

const Points = () => {
  setTitle("Dabbawala | Points Management");

  // Query Client instance
  const queryClient = useQueryClient();

  // Flatpickr
  const fp = useRef(null);
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // For pagination
  const [perPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  const [selectedOrderOpt, setSelectedOrderOpt] = useState([]);
  const [OrderId_arr, setOrderId_arr] = useState("");

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: 1, name: "In", color: "success", current: false },
    { _id: 2, name: "Out", color: "danger", current: false },
    { _id: 3, name: "Pending", color: "warning", current: false },
  ]);

  // Dynamic query key for Points 🗝️
  const queryPoints = [
    "points",
    keyword,
    status,
    limit,
    offset,
    OrderId_arr,
    startDate,
    endDate,
  ];

  // for date range search
  useEffect(() => {
    if (date && date.e && date.e[0] && date.e[1]) {
      console.log(date.e[0], date.e[1]);
      setStartDate(date.e[0]);
      setEndDate(date.e[1]);
    }
  }, [date, startDate, endDate]);

  // For order fetching
  function useOrders() {
    return useQuery({
      queryKey: ["order_dropdown"],
      queryFn: async () => {
        let tmp = [];
        const { data } = await listOrder();
        // tmp.push({ value: "", label: "Order Id" });
        data.forEach((item) => {
          tmp.push({ value: item._id, label: item?.order_id });
          // console.log(item)
        });
        // console.log(tmp)
        return tmp;
      },
    });
  }

  const { data: orders } = useOrders();

  // Point List Component
  function PointList() {
    // Custom hook for fetching point list
    function usePoint() {
      return useQuery({
        queryKey: queryPoints,
        queryFn: () =>
          listPoints({
            keyword: keyword,
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
            order_id: OrderId_arr,
            dateRange: {
              start: startDate,
              end: endDate,
            },
          }),
        keepPreviousData: true,
      });
    }

    const { data: points, error, isFetching } = usePoint();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (points.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {points.data.length > 0 &&
            points.data.map((item) => {
              return (
                <PointsItem
                  key={item._id}
                  PointId={item._id}
                  username={item?.user_details?.name}
                  orderAmount={item?.order_amount}
                  userEmail={item?.user_details?.email}
                  point={item?.point}
                  pointsType={item?.point_type}
                  CouponId={item?.coupon_id}
                  status={item?.status}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryPoints}
                />
              );
            })}

          <Pagination
            count={points.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="page-filter mb-3">
          <div className="input-filters">
            <div className="input-items">
              {perPages && (
                <div className="input">
                  <Select
                    className="select-tag"
                    isMulti={false}
                    isClearable={true}
                    options={perPages}
                    value={selectedPageOpt || []}
                    placeholder={"Per Page"}
                    onChange={(e) => {
                      setLimit(e.value);
                      setSelectedPageOpt(e);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="input-items">
              <Search
                state={keyword}
                setState={setKeyword}
                placeholder={"Search..."}
              />
            </div>
            {/* <div className="input-items">
              <SelectTag
                data={orderIdDropdown}
                placeholder={"Select Order ID"}
              />
            </div> */}
            <div className="input-items">
              {orders && (
                <div className="input">
                  <Select
                    className="select-tag"
                    isMulti={false}
                    isClearable={true}
                    options={orders}
                    value={selectedOrderOpt || []}
                    placeholder={"Order ID"}
                    onChange={(e) => {
                      setOrderId_arr(e.value);
                      setSelectedOrderOpt(e);
                    }}
                  />
                </div>
              )}
            </div>

            <div className="input-items">
              {/* <Input
                label={""}
                inputRequired={true}
                inputType={"date"}
                inputPlaceholder={""}
                inputName={"Select date"}
              /> */}
              <Flatpickr
                ref={fp}
                className="form-control"
                id="datepicker-publish-input"
                placeholder="Select a date"
                onChange={(e) => {
                  setDate({ e });
                }}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  mode: "range",
                  dateFormat: "d.m.y",
                }}
              />
            </div>

            <div className="input-items items-end gap-3 ms-auto">
              <Button
                classes={"px-2"}
                icon={"fa-regular fa-arrow-rotate-right"}
                iconPosition={"left"}
                functions={() => {
                  queryClient.invalidateQueries(queryPoints);
                  queryClient.removeQueries({
                    queryKey: ["points"],
                    type: "inactive",
                  });
                  setOffset(0);
                  setKeyword("");
                  setStatus("");
                  setStatusStatic([]);
                  setStartDate("");
                  setEndDate("");
                  setOrderId_arr("");
                  setTimeout(() => {
                    setStatusStatic([
                      { _id: "", name: "All", color: "success", current: true },
                      {
                        _id: 1,
                        name: "In",
                        color: "success",
                        current: false,
                      },
                      {
                        _id: 2,
                        name: "Out",
                        color: "warning",
                        current: false,
                      },
                      {
                        _id: 3,
                        name: "Pending",
                        color: "warning",
                        current: false,
                      },
                    ]);
                  }, 300);
                }}
              />
            </div>
          </div>
          <div className="input-items">
            <RadioGroup
              data={statusStatic}
              setData={setStatus}
              inputName={"status"}
            />
          </div>
        </div>
        <div className="points-list">
          <PointList />
        </div>
      </div>
    </>
  );
};

export default Points;
