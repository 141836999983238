import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { jobTypeDelete, stsChangejobType } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";

const JobTypeItem = ({
  type,
  jobTypeId,
  createdBy,
  createdDate,
  updatedDate,
  status,
  query,
  detailsFetch,
  setEditId,
  setEditModal,
}) => {

  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);



  const deleteProduct = useMutation(
    (delJobTypeId) => jobTypeDelete(delJobTypeId),
    {
      onSuccess: (res) => {
        // console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobTypes"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );


  const statusChange = useMutation(
    (changeSts) => stsChangejobType(changeSts),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["jobTypes"],
            type: "inactive",
          });
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );



  return (
    
    <>
      <div className="jobtype-item mb-3">
        <div className="jobtype-item-row">
          <div className="jobtype-content">
            <div className="jobtype-details">
              <span>Name</span>
              <div className="jobtype-title">{type}</div>
            </div>
          </div>
          <div className="jobtype-options">
            <div className={"status " + (status ? 'published' : 'draft')}>
              {status ? 'published' : 'draft'}
            </div>
            <div className="action-dropdown dropdown">
              <button
                type="button"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    setEditModal(true);
                    setEditId(jobTypeId);
                    detailsFetch();
                  }}
                >
                  <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => statusChange.mutate({
                    id: jobTypeId,
                    status: status ? false : true,
                  })}
                >
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                  {status ? 'draft' : 'publish'}
                </button>
                <button
                  type="button"
                  className="dropdown-item standOut"
                  onClick={() => setDeleteModal(true)}
                >
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {(createdBy || createdDate || updatedDate) && (
          <div className="jobtype-item-row border-t">
            <div className="jobtype-info">
              {createdBy && (
                <div className="info">
                  <div className="info-label">Created By</div>
                  <div className="info-data">{createdBy}</div>
                </div>
              )}
            </div>
            <div className="jobtype-info">
              {createdDate && (
                <div className="info">
                  <div className="info-label">Created Date</div>
                  <div className="info-data">{createdDate}</div>
                </div>
              )}
              {updatedDate && (
                <div className="info">
                  <div className="info-label">Last Updated</div>
                  <div className="info-data">{updatedDate}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: jobTypeId });
        }}
      />
    </>
  );
};

export default JobTypeItem;
