import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addFutureTeam, futureTeamEdit } from "../../helpers/api_helper";
import ImageUploadPreview from "../Form/ImageUploadPreview";
import PreviewProduct from "../Elements/PreviewProduct";

const AddFutureTeam = ({
  showToastMessage,
  setIsAddModal,
  setEditModel,
  query,
  editMode = false,
  isOpen,
  detailsFetch,
  editItemId,
  details,
  isFetching,
}) => {
  const [name, setName] = useState("");
  const [type, setType] = useState(2);
  const [file, setFile] = useState("");
  const [radioData, setRadioData] = useState(1);
  const [image, setImage] = useState([]);

  const queryClient = useQueryClient();
  

  const { mutate: add } = useMutation(addFutureTeam, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setIsAddModal(false);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["futureTeam"],
          type: "inactive",
        });
        setName("");
        setFile("");
        setRadioData(true);
        setType(2);
        setImage([])

        showToastMessage({
          status: 200,
          message: data.message,
        });
      } else {
        showToastMessage({
          status: 400,
          message: data.message,
        });
      }
    },
  });

  const { mutate: edit } = useMutation(futureTeamEdit, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setName("");
        setFile("");
        setRadioData(true);
        setType(2);
        setImage([])
        setEditModel(false);
        queryClient.invalidateQueries(query);
        queryClient.removeQueries({
          queryKey: ["futureTeam"],
          type: "inactive",
        });
        showToastMessage({
          status: 200,
          message: data.message,
        });
      
      } else {
        showToastMessage({
          status: 400,
          message: data.message,
        });
      }
    },
  });

  useEffect(() => {
    if (isOpen && details) {
      setName(details?.name);
      if(details.type===2){
        setFile(details?.file);
        setType(2)
        setImage([])
      } 
      else{
        setFile("");
        setImage([{ _id: 1, image: details?.file }]);
        setType(1)
      }  
   
      setRadioData(details?.status);

      setPublishedData([]);
      setUploadData([]);

      setTimeout(() => {
        setPublishedData([
          {
            _id: 1,
            label: "Published",
            inputName: "publish_status",
            current: details.status.toString() === "true" ? true : false,
          },
          {
            _id: 2,
            label: "Draft",
            inputName: "publish_status",
            current: details.status.toString() === "false" ? true : false,
          },
        ]);

        if(details.type===1){

          setUploadData([
            {
              _id: 1,
              label: "Image",
              inputName: "upload_status",
              current: details.type === 1? true : false,
            },
          ]);
        }else{
             
          setUploadData([
            {
              _id: 2,
              label: "Video",
              inputName: "upload_status",
              current: details.type === 2? true : false,
            }
          ]);
        }

       
      }, 200);


    }



  }, [isOpen, details]);


  const [isPreview, setIspreview] = useState(false);

  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);

  const [uploadData, setUploadData] = useState([
   
    { _id: 1, label: "Image", inputName: "upload_status", current: false },
    { _id: 2, label: "Video", inputName: "upload_status", current: true },
  ]);
  const previewData = [
    {
      image: "images/product.png",
    },
  ];

  console.log(radioData);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <Input
                  label={"Title"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Title"}
                  inputName={""}
                  setState={setName}
                  state={name}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-4">
                {uploadData !== [] && (
                  <RadiosLabel
                    data={uploadData}
                    setData={setUploadData}
                    label={"Select Type"}
                    inputRequired={true}
                    setRadio={setType}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
              {
                type===1?(<>
                {!isPreview ? (
                  <>
                    <div className="row">
                      <div className="col">
                        <ImageUploadPreview
                          data={image}
                          label={"Upload Icon"}
                          inputRequired={true}
                          setImage={setImage}
                          state={image}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <PreviewProduct
                    data={previewData}
                    previewClose={() => setIspreview(!isPreview)}
                  />
                )}
                </>):(<>
                
                  <Input
                  label={"File"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Video/Image Link"}
                  inputName={""}
                  setState={setFile}
                  state={file}
                />
                
                </>)
              }
              

                
              </div>
            </div>
            <div className="row">
              <div className="mb-4">
                {publishedData !== [] && (
                  <RadiosLabel
                    data={publishedData}
                    setData={setPublishedData}
                    label={"Status"}
                    inputRequired={true}
                    setRadio={setRadioData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              <Button
                label={"Save"}
                functions={() => {

                 if(editMode){
                    if(image[0]?.image.includes('https')){
                      edit({
                        id: editItemId,
                        name: name,
                        type:type,
                        status: radioData,
                      })
                    }else{
                      
                      edit({
                        id: editItemId,
                        name: name,
                        type:type,
                        file: type===2?file:image[0]?.image.includes('https')?null:image[0]?.image,
                        status: radioData === 1 ? true : false,
                      })
                    }

                   }
                   else{ add({
                        name: name,
                        type: type,
                        file:type===2?file:image[0]?.image,
                        status: radioData === 1 ? true : false,
                      });
                }}}
              />
            
              <Button
                label={"Cancel"}
                classes={"main-button-alt"}
                functions={() => {
                  editMode? setEditModel(false):
                  setIsAddModal(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFutureTeam;
