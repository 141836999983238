import { useState } from "react";
import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import JobsApplicationItem from "../../components/Items/JobsApplicationItem";
import { jobAppliesList } from "../../helpers/api_helper";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import Select from "react-select";
import { Loading } from "../../components/Elements/Loading";
import Pagination from "../../components/Elements/Pagination";
import { Error } from "../../components/Elements/Error";
import { NoData } from "../../components/Elements/NoData";

const Applications = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");

  // For pagination
  const [perPages, setPerPages] = useState([
    { label: "10 (Default)", value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: "All", value: 999999 },
  ]);
  const [selectedPageOpt, setSelectedPageOpt] = useState([]);

  // Dynamic query key for Applications 🗝️
  const queryApplication = ["application", keyword, status, limit, offset];

  // Application List Component
  function ApplicationList() {
    // Custom hook for fetching application list
    function useApplication() {
      return useQuery({
        queryKey: queryApplication,
        queryFn: () =>
          jobAppliesList({
            keyword: keyword,
            sortByField: "ordering",
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
          }),
        keepPreviousData: true,
      });
    }

    const { data: applications, error, isFetching } = useApplication();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (applications.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
      return (
        // For Pagination
        <>
          {applications.data.length > 0 &&
            applications.data.map((item) => {
              return (
                <JobsApplicationItem
                  key={item._id}
                  id={item._id}
                  name={item.job.title}
                  applicationDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  cv_upload={item.cv_upload}
                  motivation={item?.motivation}
                  applicantName={item?.name}
                  email={item.email}
                  phone={item.phone}
                  comment={item.comment}
                  query={queryApplication}
                />
              );
            })}

          <Pagination
            count={applications.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
          <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>
          <div className="input-items items-end gap-3 ms-auto">
            <Button
              label={""}
              labelClasses={""}
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryApplication);
                queryClient.removeQueries({
                  queryKey: ["application"],
                  type: "inactive",
                });
                setOffset(0);
                setKeyword("");
                setSelectedPageOpt([]);
              }}
            />
          </div>
        </div>
      </div>
      <div className="applications-list">
        <ApplicationList />
      </div>
    </>
  );
};

export default Applications;
