import axios from 'axios';
import { hostUrl } from '../config/host';

// localStorage.getItem('access_token')

const instance = axios.create({
    baseURL: hostUrl,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    }
});



export const login = async (payload) => {

    return instance.post('/auth/login', payload).then((res) => res.data);

};

export const ReasonList = async (payload) => {

    return instance.post('/order-cancel-reason/list', payload).then((res) => res.data);

};

export const assignRider = async (payload) => {

    return instance.post('/order/assign-rider', payload).then((res) => res.data);

};

export const orderStatusList = async (payload) => {

    return instance.post('/order/status-list', payload).then((res) => res.data);

};

// Site Settings
export const siteSettingsDetails = async (payload) => {
    return instance.post('/site-setting/details', payload).then((res) => res.data.data);
};

export const siteSettingsEdit = async (payload) => {
    return instance.post('/site-setting/update', payload).then((res) => res.data);
};

// opening hours by Buddy

// cms opening hours

export const openingList = async (payload) => {
    return instance.post('/open_hour/list', payload).then((res) => res.data.data);
};

// cms opening hours details

export const openingListDetails = async (payload) => {
    return instance.post('/open_hour/details', payload).then((res) => res.data.data);
};

// cms opening hours edit

export const openingListEdit = async (payload) => {
    return instance.post('/open_hour/edit', payload).then((res) => res.data);
};

// cms page section list add

export const pageListAdd = async (payload) => {
    return instance.post('/page/add', payload).then((res) => res.data);

};











// Banners
export const BannersList= async (payload) => {
     
    return instance.post('/banner/list', payload).then((res) => res.data);

};
export const BannersAdd= async (payload) => {
     
    return instance.post('/banner/add', payload).then((res) => res.data);

};
export const BannersDetails= async (payload) => {
     
    return instance.post('/banner/details', payload).then((res) => res.data.data);

};

export const BannersEdit= async (payload) => {
     
    return instance.post('/banner/edit', payload).then((res) => res.data);

};


export const BannersDelete= async (payload) => {
     
    return instance.post('/banner/delete', payload).then((res) => res.data);

};

// Highlights

export const HighlightsList= async (payload) => {
     
    return instance.post('/our_highlight/list', payload).then((res) => res.data);

};
export const HighlightsAdd= async (payload) => {
     
    return instance.post('/our_highlight/create', payload).then((res) => res.data);

};
export const HighlightsDetails= async (payload) => {
     
    return instance.post('/our_highlight/details', payload).then((res) => res.data.data);

};

export const HighlightsEdit= async (payload) => {
     
    return instance.post('/our_highlight/edit', payload).then((res) => res.data);

};

export const HighlightsStatusChange= async (payload) => {
     
    return instance.post('/our_highlight/status-change', payload).then((res) => res.data);

};

export const HighlightsDelete= async (payload) => {
     
    return instance.post('/our_highlight/remove', payload).then((res) => res.data);

};

// Notification

export const NotificationList= async (payload) => {
     
    return instance.post('/order-view-status', payload).then((res) => res.data);

};

// Delivery Rider
export const RiderList= async (payload) => {
     
    return instance.post('/riders', payload).then((res) => res.data);

};
export const RiderAdd= async (payload) => {
     
    return instance.post('/rider/add', payload).then((res) => res.data);

};
export const RiderDetails= async (payload) => {
     
    return instance.post('/rider/details', payload).then((res) => res.data.data);

};

export const RiderEdit= async (payload) => {
     
    return instance.post('/rider/edit', payload).then((res) => res.data);

};

export const RiderStatusChange= async (payload) => {
     
    return instance.post('/rider/status-change', payload).then((res) => res.data);

};

export const RiderDelete= async (payload) => {
     
    return instance.post('/rider/delete', payload).then((res) => res.data);

};

// Teams

export const TeamsList= async (payload) => {
     
    return instance.post('/team/list', payload).then((res) => res.data);

};

export const TeamsAdd= async (payload) => {
     
    return instance.post('/team/create', payload).then((res) => res.data);

};

export const TeamsDetails= async (payload) => {
     
    return instance.post('/team/details', payload).then((res) => res.data.data);

};

export const TeamsEdit= async (payload) => {
     
    return instance.post('/team/edit', payload).then((res) => res.data);

};

export const TeamsStatusChange= async (payload) => {
     
    return instance.post('/team/status-change', payload).then((res) => res.data);

};

export const TeamsDelete= async (payload) => {
     
    return instance.post('/team/remove', payload).then((res) => res.data);

};

// Admin
export const listAdmin = async (payload) => {

    return instance.post('/user-search', payload).then((res) => res.data);

};
export const detailsAdmin = async (payload) => {

    return instance.post('/user/details', payload).then((res) => res.data.data);

};
export const AdminDelete= async (payload) => {
     
    return instance.post('/user/delete', payload).then((res) => res.data);

};
export const AdminEdit= async (payload) => {
     
    return instance.post('/user/edit', payload).then((res) => res.data);

};
export const AdminAdd= async (payload) => {
     
    return instance.post('/admin/add', payload).then((res) => res.data);

};

// Fun_fact by Ankan
export const funFactsList= async (payload) => {
     
    return instance.post('/fun-facts/list', payload).then((res) => res.data);

};

export const funFactsAdd= async (payload) => {
     
    return instance.post('/fun-facts/add', payload).then((res) => res.data);

};

export const funFactsDetails= async (payload) => {
     
    return instance.post('/fun-facts/details', payload).then((res) => res.data.data);

};

export const funFactsEdit= async (payload) => {
     
    return instance.post('/fun-facts/edit', payload).then((res) => res.data);

};

export const funFactsStatusChange= async (payload) => {
     
    return instance.post('/fun-facts/change-status', payload).then((res) => res.data);

};

export const funFactsDelete= async (payload) => {
     
    return instance.post('/fun-facts/delete', payload).then((res) => res.data);

};



// Mission by Ankan

export const missionList= async (payload) => {
     
    return instance.post('/mission-vision/list', payload).then((res) => res.data);

};
export const missionDetails= async (payload) => {
     
    return instance.post('/mission-vision/details', payload).then((res) => res.data.data);

};
export const addMisson= async (payload) => {
     
    return instance.post('mission-vision/create', payload).then((res) => res.data);

};

export const missionStatusDetails= async (payload) => {
     
    return instance.post('mission-vision/details', payload).then((res) => res.data.data);

};


export const missionStatusChange= async (payload) => {
     
    return instance.post('mission-vision/status-change', payload).then((res) => res.data);

};

export const missionDelete= async (payload) => {
     
    return instance.post('mission-vision/remove', payload).then((res) => res.data);


};

export const missionEdit= async (payload) => {
     
    return instance.post('mission-vision/edit', payload).then((res) => res.data);
    

};

// Vision by Ankan

export const visionList= async (payload) => {
     
    return instance.post('/mission-vision/list', payload).then((res) => res.data);

};
export const visionDetails= async (payload) => {
     
    return instance.post('/mission-vision/details', payload).then((res) => res.data.data);

};
export const AddVisionData= async (payload) => {
     
    return instance.post('mission-vision/create', payload).then((res) => res.data);

};

export const visionStatusDetails= async (payload) => {
     
    return instance.post('mission-vision/details', payload).then((res) => res.data.data);

};


export const visionStatusChange= async (payload) => {
     
    return instance.post('mission-vision/status-change', payload).then((res) => res.data);

};

export const visionDelete= async (payload) => {
     
    return instance.post('mission-vision/remove', payload).then((res) => res.data);


};

export const visionEdit= async (payload) => {
     
    return instance.post('mission-vision/edit', payload).then((res) => res.data);
    

};


// Future team by Ankan


export const futureTeamList= async (payload) => {
     
    return instance.post('/future-team/list', payload).then((res) => res.data);

};
export const futureTeamDetails= async (payload) => {
     
    return instance.post('/future-team/details', payload).then((res) => res.data.data);

};
export const addFutureTeam= async (payload) => {
     
    return instance.post('/future-team/create', payload).then((res) => res.data);

};

export const futureTeamStatusChange= async (payload) => {
     
    return instance.post('/future-team/status-change', payload).then((res) => res.data);

};

export const futureTeamDelete= async (payload) => {
     
    return instance.post('/future-team/remove', payload).then((res) => res.data);


};

export const futureTeamEdit= async (payload) => {
     
    return instance.post('/future-team/edit', payload).then((res) => res.data);
    

};


// cms page section list delete

export const pageListEdit = async (payload) => {
    return instance.post('/page/edit', payload).then((res) => res.data);
};

// cms page section list delete

export const pageListDetails = async (payload) => {
    return instance.post('/page/details', payload).then((res) => res.data);
};

export const pageList = async (payload) => {
    return instance.post('/page/list', payload).then((res) => res.data);
};

// cms step section by Buddy

export const stepsList = async (payload) => {

    return instance.post('/how_it_work/list', payload).then((res) => res.data);

};

// cms step section add list or create list

export const createStepsList = async (payload) => {

    return instance.post('/how_it_work/create', payload).then((res) => res.data);

};

// cms step section delete list or remove list

export const deleteStepsList = async (payload) => {

    return instance.post('/how_it_work/remove', payload).then((res) => res.data);
};

// cms step section delete list or remove list

export const stepsListStatusChange = async (payload) => {
    return instance.post('/how_it_work/status-change', payload).then((res) => res.data);
};

// cms step section delete list or remove list

export const detailsStepsList = async (payload) => {
    return instance.post('/how_it_work/details', payload).then((res) => res.data.data);
};

// cms step section delete list or remove list

export const stepsListEdit = async (payload) => {
    return instance.post('/how_it_work/edit', payload).then((res) => res.data);
};



// CRM_BENEFITS
export const listBenefits = async (payload) => {

    return instance.post('/account-benefit/list', payload).then((res) => res.data);

};
export const detailsBenefits = async (payload) => {

    return instance.post('/account-benefit/details', payload).then((res) => res.data.data);

};
export const stsChangeBenefits = async (payload) => {

    return instance.post('/account-benefit/status-change', payload).then(res => res.data);

};
export const delBenefits = async (payload) => {

    return instance.post('/account-benefit/remove', payload).then((res) => res.data);

};
export const editBenefits = async (payload) => {

    return instance.post('/account-benefit/edit', payload).then((res) => res.data);

};
export const addBenefits = async (payload) => {

    return instance.post('/account-benefit/create', payload).then((res) => res.data);

};

export const listLiveOrder = async (payload) => {

    return instance.post('/order/live_order_status/list', payload).then((res) => res.data);

};

export const stsChngLiveOrder = async (payload) => {

    return instance.post('/order/live_order_status', payload).then((res) => res.data);

};

export const rejectLiveOrder = async (payload) => {

    return instance.post('/order/order-cancel', payload).then((res) => res.data);

};

// addons

export const delAddon = async (payload) => {

    return instance.post('/addon-group/remove', payload).then((res) => res.data);

};

export const stsChangeAddon = async (payload) => {

    return instance.post('/addon-group/change-status', payload).then(res => res.data);

};


// CRM_SERVICE
export const listService = async (payload) => {

    return instance.post('/service/list', payload).then((res) => res.data);

};
export const detailsService = async (payload) => {

    return instance.post('/service/details', payload).then((res) => res.data.data);

};
export const stsChangeService = async (payload) => {

    return instance.post('/service/status-change', payload).then(res => res.data);

};
export const delService = async (payload) => {

    return instance.post('/service/remove', payload).then((res) => res.data);

};
export const editService = async (payload) => {

    return instance.post('/service/edit', payload).then((res) => res.data);

};
export const addService = async (payload) => {

    return instance.post('/service/create', payload).then((res) => res.data);

};

// Organization
export const listOrganization = async (payload) => {

    return instance.post('/organization/list', payload).then((res) => res.data);

};
export const detailsOrganization = async (payload) => {

    return instance.post('/organization/details', payload).then((res) => res.data.data);

};
export const stsChangeOrganization = async (payload) => {

    return instance.post('/organization/change-status', payload).then(res => res.data);

};
export const delOrganization = async (payload) => {

    return instance.post('/organization/remove', payload).then((res) => res.data);

};
export const editOrganization = async (payload) => {

    return instance.post('/organization/edit', payload).then((res) => res.data);

};
export const addOrganization = async (payload) => {

    return instance.post('/organization/create', payload).then((res) => res.data);

};

export const orderData = async (payload) => {

    return instance.post('/dashboard/order-section', payload).then((res) => res.data);

};

export const userData = async (payload) => {

    return instance.post('/dashboard/user-section', payload).then((res) => res.data.userList);

};

export const riderData = async (payload) => {

    return instance.post('/dashboard/rider-data', payload).then((res) => res.data.data);

};

export const customerDashboardData = async (payload) => {

    return instance.post('/dashboard/customer-data-section', payload).then((res) => res.data);

};


export const postCodeData = async (payload) => {

    return instance.post('/dashboard/postcode-section', payload).then((res) => res.data.postcodeList);

};


// Points
export const listPoints = async (payload) => {

    return instance.post('/point/list', payload).then((res) => res.data);

};

// Product Categories
export const ProductlistCategories = async (payload) => {

    return instance.post('/product_categories/list', payload).then((res) => res.data);

};

export const ProductdetailsCategories = async (payload) => {

    return instance.post('/product_categories/details', payload).then((res) => res.data.data);

};

export const ProductstsChangeCategories = async (payload) => {

    return instance.post('/product_categories/status-change', payload).then(res => res.data);

};


// Categories

export const listCategories = async (payload) => {

    return instance.post('/category/list', payload).then((res) => res.data);

};
export const detailsCategories = async (payload) => {

    return instance.post('/category/details', payload).then((res) => res.data.data);

};
export const stsChangeCategories = async (payload) => {

    return instance.post('/category/status-change', payload).then(res => res.data);

};
export const delCategories = async (payload) => {

    return instance.post('/category/delete', payload).then((res) => res.data);

};
export const editCategories = async (payload) => {

    return instance.post('/category/edit', payload).then((res) => res.data);

};
export const addCategories = async (payload) => {

    return instance.post('/category/add', payload).then((res) => res.data);

};

// Blogs

export const listBlog = async (payload) => {

    return instance.post('/blog/list', payload).then((res) => res.data);

};

export const detailsBlog = async (payload) => {

    return instance.post('/blog/details', payload).then((res) => res.data.data);

};

export const stsChangeBlog = async (payload) => {

    return instance.post('/blog/status-change', payload).then(res => res.data);

};
export const delBlog = async (payload) => {

    return instance.post('/blog/remove', payload).then((res) => res.data);


};
export const editBlogs = async (payload) => {

    return instance.post('/blog/edit', payload).then((res) => res.data);

};

export const addBlog = async (payload) => {

    return instance.post('/blog/add', payload).then((res) => res.data);

};
// Authors


export const addAuthor = async (payload) => {

    return instance.post('/author/create', payload).then((res) => res.data);

};

export const listAuthor = async (payload) => {

    return instance.post('/author/list', payload).then((res) => res.data);

};

export const detailsAuthor = async (payload) => {

    return instance.post('/author/details', payload).then((res) => res.data.data);

};

export const stsChangeAuthor = async (payload) => {

    return instance.post('/author/change-status', payload).then(res => res.data);

};
export const delAuthor = async (payload) => {

    return instance.post('/author/remove', payload).then((res) => res.data);


};

// Blog_Comments

export const listComments = async (payload) => {

    return instance.post('/blog_Comment/list', payload).then((res) => res.data);

};


export const stsChangeComments = async (payload) => {

    return instance.post('/blog_Comment/status-change', payload).then(res => res.data);

};
export const delComments = async (payload) => {

    return instance.post('/blog_Comment/remove', payload).then((res) => res.data);


};

// orders

export const stsChangeOrder = async (payload) => {

    return instance.post('/order/manage-accept-status', payload).then(res => res.data);

};

export const detailsOrders = async (payload) => {

    return instance.post('/order/details', payload).then((res) => res.data.data);

};

export const listOrder = async (payload) => {

    return instance.post('/order/list', payload).then((res) => res.data);

};

export const orderStatusDetails = async (payload) => {

    return instance.post('/order/status-details', payload).then((res) => res.data);

};

export const listOrderStatus = async (payload) => {

    return instance.post('/order/status-list', payload).then((res) => res.data);

};

// Faqs

export const delFaqcategory = async (payload) => {

    return instance.post('/category/delete', payload).then((res) => res.data);


};

export const listFaqCategories = async (payload) => {

    return instance.post('/category/list', payload).then((res) => res.data);

};

export const listFaqFeedback = async (payload) => {

    return instance.post('/faq/feedback-list', payload).then((res) => res.data);

};

export const FaqstsChange = async (payload) => {

    return instance.post('/faq/status-change', payload).then(res => res.data);

};

export const listFaqs = async (payload) => {

    return instance.post('/faq/list', payload).then((res) => res.data);

};

export const stsChangeFaq = async (payload) => {

    return instance.post('/category/status-change', payload).then(res => res.data);

};

export const detailsFaqs = async (payload) => {

    return instance.post('/faq/details', payload).then((res) => res.data.data);

};

export const delFaqs = async (payload) => {

    return instance.post('/faq/delete', payload).then((res) => res.data);

};

export const detailsFaqCategories = async (payload) => {

    return instance.post('/category/details', payload).then((res) => res.data.data);

};



export const editFaqs = async (payload) => {

    return instance.post('/faq/edit', payload).then((res) => res.data);

};



export const addFaq = async (payload) => {

    return instance.post('/faq/add', payload).then((res) => res.data);

};


// product Category

export const addCat = async (payload) => {

    return instance.post('/product_categories/create', payload).then((res) => res.data);

};

export const editCat = async (payload) => {

    return instance.post('/product_categories/edit', payload).then((res) => res.data);

};

export const delCat = async (payload) => {

    return instance.post('/product_categories/remove', payload).then((res) => res.data);

};

export const addOtherCat = async (payload) => {

    return instance.post('/category/add', payload).then((res) => res.data);

};


// Product Ingredient list 
export const listProductIngredient = async (payload) => {

    return instance.post('/product_ingredients/list', payload).then((res) => res.data);

};

// Product Ingredient add
export const addProductIngredient = async (payload) => {

    return instance.post('/product_ingredients/create', payload).then((res) => res.data);

};

// Product Ingredient edit
export const editProductIngredient = async (payload) => {

    return instance.post('/product_ingredients/edit', payload).then((res) => res.data);

};

// Product Ingredient delete
export const delProductIngredient = async (payload) => {

    return instance.post('/product_ingredients/remove', payload).then(res => res.data);

};

// Product Ingredient status change
export const stsChangeProductIngredient = async (payload) => {

    return instance.post('/product_ingredients/status-change', payload).then(res => res.data);

};

// Product Ingredient detatils
export const detailsProductIngredient = async (payload) => {

    return instance.post('/product_ingredients/details', payload).then((res) => res.data.data);

};

///Ordering 
export const ordering = async (payload) => {

    return instance.post('/common/ordering', payload).then((res) => res.data);

};

// product ordering
export const productOrdering = async (payload) => {

    return instance.post('/category-wise-product-ordering', payload).then((res) => res.data);

};

///Ordering
export const categoryWiseProduct = async (payload) => {

    return instance.post('/v2/products/category-wise-list', payload).then((res) => res.data);

};

///Ordering
export const productordering = async (payload) => {

    return instance.post('/category-wise-product-ordering', payload).then((res) => res.data);

};


// Products list
export const listProduct = async (payload) => {

    return instance.post('/v2/products/list', payload).then((res) => res.data);

};

// Product details
export const detailsProduct = async (payload) => {

    return instance.post('/v2/products/details', payload).then((res) => res.data.data);

};

// Product delete
export const delProduct = async (payload) => {

    return instance.post('/v2/products/remove', payload).then(res => res.data);

};

// Product status change
export const stsChangeProduct = async (payload) => {
    return instance.post('/v2/products/status-change', payload).then(res => res.data); 
};

// Product edit
export const editProduct = async (payload) => {
    return instance.post('/v2/products/edit', payload).then((res) => res.data);
};

// Product add
export const addProduct = async (payload) => {
    return instance.post('/v2/products/create', payload).then((res) => res.data);
};





// Coupon add
export const addCoupon = async (payload) => {
    return instance.post('/coupon/create', payload).then((res) => res.data);
};


// Coupons list
export const listCoupon = async (payload) => {

    return instance.post('/coupon/list', payload).then((res) => res.data);

};

// Coupon details
export const detailsCoupon = async (payload) => {

    return instance.post('/coupon/details', payload).then((res) => res.data.data);

};

// Coupon delete
export const delCoupon = async (payload) => {

    return instance.post('/coupon/remove', payload).then(res => res.data);

};

// Coupon status change
export const stsChangeCoupon = async (payload) => {
    return instance.post('/coupon/change-status', payload).then(res => res.data);
};

// Coupon edit
export const editCoupon = async (payload) => {
    return instance.post('/coupon/edit', payload).then((res) => res.data);
};

// User List
export const userList = async (payload) => {

    return instance.post('/user-search', payload).then((res) => res.data);

};


// job Added by Ankan

export const jobAppliesList = async (payload) => {
    

    return instance.post('/job/list-apply', payload).then((res) => res.data);

};

export const jobTypeList = async (payload) => {
    

    return instance.post('job/type/list', payload).then((res) => res.data);
    

};

export const addJobType = async (payload) => {
    
        
    return instance.post('job/type/add', payload).then((res) => res.data);

};


export const jobLocationList = async (payload) => {
    
        
    return instance.post('/job/location-type/list', payload).then((res) => res.data);

};


export const jobList = async (payload) => {
    
        
    return instance.post('job/list', payload).then((res) => res.data);

};

export const addJobLoactionType = async (payload) => {
    
        
    return instance.post('job/location-type/add', payload).then((res) => res.data);

};


export const searchJobApplies= async (payload) => {

    
    return instance.post('/job/list-apply', payload).then((res) => res.data.data);

};

export const jobAppliesDelete= async (payload) => {

    
    return instance.post('/job/remove-apply', payload).then((res) => res.data);

};


export const jobTypeDetails= async (payload) => {

    
    return instance.post('job/type/details', payload).then((res) => res.data.data);

};

export const jobTypeEdit= async (payload) => {

    
    return instance.post('/job/type/edit', payload).then((res) => res.data);

};

export const jobTypeAdd= async (payload) => {

    
    return instance.post('/job/type/add', payload).then((res) => res.data);

};

export const stsChangejobType = async (payload) => {
    return instance.post('/job/type/status-change', payload).then(res => res.data);
};


export const jobListAdd= async (payload) => {

    
    return instance.post('/job/add', payload).then((res) => res.data);

};

export const jobTypeDelete= async (payload) => {

    
    return instance.post('/job/type/delete', payload).then((res) => res.data);

};


export const jobLocationDetails= async (payload) => {
     

    
    return instance.post('/job/location-type/details', payload).then((res) => res.data.data);

};

export const jobLocationEdit= async (payload) => {
     

    
    return instance.post('/job/location-type/edit', payload).then((res) => res.data);

};
export const jobLocationDelete= async (payload) => {
     

    
    return instance.post('job/location-type/delete', payload).then((res) => res.data);

};

export const jobLocationStatusChange= async (payload) => {
     
    return instance.post('/job/location-type/status-change', payload).then((res) => res.data);

};

export const jobListdetails= async (payload) => {
     
    return instance.post('/job/details', payload).then((res) => res.data.data);

};

export const jobListEdit= async (payload) => {
     
    return instance.post('/job/edit', payload).then((res) => res.data);

};

export const jobListStatusChange= async (payload) => {
     
    return instance.post('/job/status-change', payload).then((res) => res.data);

};

export const jobListRemove= async (payload) => {
     
    return instance.post('/job/remove', payload).then((res) => res.data);

};



// TakeOff add
export const addTakeOff = async (payload) => {
    return instance.post('/take-of-time/create', payload).then((res) => res.data);
};


// TakeOffs list
export const listTakeOff = async (payload) => {

    return instance.post('/take-of-time/list', payload).then((res) => res.data);

};

// TakeOff details
export const detailsTakeOff = async (payload) => {

    return instance.post('/take-of-time/details', payload).then((res) => res.data.data);

};

// TakeOff delete
export const delTakeOff = async (payload) => {

    return instance.post('/take-of-time/remove', payload).then(res => res.data);

};

// TakeOff status change
export const stsChangeTakeOff = async (payload) => {
    return instance.post('/take-of-time/change-status', payload).then(res => res.data);
};

// TakeOff edit
export const editTakeOff = async (payload) => {
    return instance.post('/take-of-time/edit', payload).then((res) => res.data);
};


// Delivery add
export const addDeliverySettings = async (payload) => {
    return instance.post('/delivery-zipcode/create', payload).then((res) => res.data);
};


// Deliverys list
export const listDeliverySettings = async (payload) => {

    return instance.post('/delivery-zipcode/list', payload).then((res) => res.data);

};

// Deliverys details
export const detailsDeliverySettings = async (payload) => {

    return instance.post('/delivery-zipcode/details', payload).then((res) => res.data.data);

};

export const editDeliverySettings = async (payload) => {

    return instance.post('/delivery-zipcode/edit', payload).then((res) => res.data);

};


export const deleteDeliverySettings = async (payload) => {

    return instance.post('/delivery-zipcode/remove', payload).then((res) => res.data);

};

export const userOrderCount = async (payload) => {

    return instance.post('/dashboard/order-section/total-number-of-order', payload).then((res) => res.data.data);

};

export const deliveryPickup = async (payload) => {

    return instance.post('/dashboard/delivery-pickup', payload).then((res) => res.data);

};

//dashboard/point-section /dashboard/product-section  

export const pointSection = async (payload) => {

    return instance.post('/dashboard/point-section', payload).then((res) => res.data);

};

export const productSection = async (payload) => {

    return instance.post('/dashboard/product-section', payload).then((res) => res.data);

};

export const addOnGroupDetails = async (payload) => {

    return instance.post('/addon-group/details', payload).then((res) => res.data.data);

};

export const addOnGroupList = async (payload) => {

    return instance.post('/addon-group/list', payload).then((res) => res.data);

};

export const revenueGraph = async (payload) => {

    return instance.post('/dashboard/order-revenue-period', payload).then((res) => res.data);

};

export const avgRevenueGraph = async (payload) => {

    return instance.post('/dashboard/order-avgrev-period', payload).then((res) => res.data);

};

export const countGraph = async (payload) => {

    return instance.post('/dashboard/order-count-period', payload).then((res) => res.data);

};