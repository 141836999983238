import { useEffect, useState } from "react";
import Input from "../Form/Input";
import RadiosLabel from "../Form/RadiosLabel";
import Button from "../Form/Button";
import { HighlightsAdd, HighlightsEdit } from "../../helpers/api_helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const AddHighlights = ({ query, modalClose, details, isFetching }) => {
  const queryClient = useQueryClient();
  const [publishedData, setPublishedData] = useState([
    {
      _id: 1,
      label: "Published",
      inputName: "publish_status",
      current: true,
    },
    { _id: 2, label: "Draft", inputName: "publish_status", current: false },
  ]);
  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [video_url, setVideo_url] = useState("");
  // const [uploadData, setUploadData] = useState([]);

  const { mutate: handleSubmit } = useMutation(
    (payload) => HighlightsAdd(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["highlights"],
            type: "inactive",
          });
          setTitle("");
          setVideo_url("");
          // setUploadData([]);
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const { mutate: handleEdit } = useMutation(
    (payload) => HighlightsEdit(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(query);
          queryClient.removeQueries({
            queryKey: ["highlights"],
            type: "inactive",
          });
          setTitle("");
          // setUploadData([]);
          setVideo_url("");
          setStatus(1);
          modalClose();
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (details) {
      setTitle(details ? details.name : "");
      setStatus(details ? details.status : "");
      setVideo_url(details ? details.video_url : "");
      // setUploadData(details ? [{ _id: 1, image: details.image }] : []);
      setPublishedData([]);
      setTimeout(() => {
        setPublishedData(
          details
            ? [
                {
                  _id: 1,
                  label: "Published",
                  inputName: "publish_status",
                  current: details.status.toString() === "true" ? true : false,
                },
                {
                  _id: 2,
                  label: "Draft",
                  inputName: "publish_status",
                  current: details.status.toString() === "false" ? true : false,
                },
              ]
            : []
        );
      }, 50);
    }
  }, [details]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="mb-3">
                <Input
                  state={title}
                  setState={setTitle}
                  label={"Name"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Name"}
                  inputName={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Input
                  state={video_url}
                  setState={setVideo_url}
                  label={"Video URLs"}
                  inputRequired={true}
                  inputType={"text"}
                  inputPlaceholder={"Video Urls"}
                  inputName={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-4">
                <RadiosLabel
                  data={publishedData}
                  setData={setPublishedData}
                  setRadio={setStatus}
                  label={"Status"}
                  inputRequired={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-3">
              {details ? (
                <Button
                  label={"Save"}
                  functions={() =>{
                    if (!video_url.includes('https')) {
                      toast.error('Please enter a valid video url')
                    }else{
                      handleEdit({
                        id: details._id,
                        name: title,
                        status: status === 1 ? true : false,
                        video_url: video_url
                      })
                    }
                  }
                  }
                />
              ) : (
                <Button
                  label={"Save"}
                  functions={() =>{
                    if (!video_url.includes('https')) {
                      toast.error('Please enter a valid video url')
                    }else{
                      handleSubmit({
                        name: title,
                        status: status === 1 ? true : false,
                        video_url: video_url
                      })
                    }
                  }
                  }
                />
              )}
              <Button label={"Cancel"} classes={"main-button-alt"} functions={() => {
                    modalClose();
                  }}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHighlights;
