import moment from "moment";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const OpeningHoursItem = ({ day, closingDay, openingTime, closingTime, createdBy, createdDate, updatedDate, status, id, setEditId, setIsAddModal ,index }) => {

  const editModal = () => {
    setEditId(id)
    setIsAddModal()
  }

  return (
    <>
      <Draggable draggableId={id} index={index} key={id}>
        {
          (provided) => (
            <div className="openinghours-item" key={id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div className="openinghours-item-row">
                <div className="openinghours-options">
                  {/* <div className={"status " + (status === 1 ? "published" : "draft")}>{status === 1 ? "published" : "draft"}</div> */}
                  <div className="action-dropdown dropdown">
                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                      <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                      <button type="button" className="dropdown-item" onClick={editModal}>
                        <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                      </button>
                      {/* <button type="button" className="dropdown-item">
                  <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>Draft
                </button> */}
                      {/* <button type="button" className="dropdown-item standOut">
                  <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="openinghours-content">
                <div className="openinghours-label">Day</div>
                <div className="openinghours-title">{day}</div>
              </div>
              <div className="openinghours-metas">
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Closing Day</div>
                    <div className="meta-data">
                      {
                        closingDay ? 'Yes' : 'No'
                      }
                    </div>
                  </div>
                </div>
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Opening Time</div>
                    <div className="meta-data">
                      {
                        moment(new Date(openingTime)).format("h:mm:ss a")
                      }
                    </div>
                  </div>
                </div>
                <div className="meta-item">
                  <div className="meta-content">
                    <div className="meta-label">Closing Time</div>
                    <div className="meta-data">
                      {
                        moment(new Date(closingTime)).format("h:mm:ss a")
                      }
                    </div>
                  </div>
                </div>

              </div>
              {(createdBy || createdDate) && (
                <div className="openinghours-item-row border-t">
                  <div className="openinghours-info">
                    {createdBy && (
                      <div className="info">
                        <div className="info-label">Created By</div>
                        <div className="info-data">{createdBy}</div>
                      </div>
                    )}
                  </div>

                  <div className="openinghours-info">
                    {createdDate && (
                      <div className="info">
                        <div className="info-label">Created Date</div>
                        <div className="info-data">
                          {
                            moment(new Date(createdDate)).format("Do MMM YY")
                          }
                        </div>
                      </div>
                    )}
                    {updatedDate && (
                      <div className="info">
                        <div className="info-label">Updated Date</div>
                        <div className="info-data">
                          {
                            moment(new Date(updatedDate)).format("Do MMM YY")
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )
        }
      </Draggable>
    </>
  );
};

export default OpeningHoursItem;
