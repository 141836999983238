import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { listOrderStatus, orderStatusDetails, stsChangeOrder, userOrderCount } from "../../helpers/api_helper";

import { Loading } from "../Elements/Loading";
import Confirm from "../Elements/Confirm";
import Modal from "../../components/Elements/Modal";
import OrderReject from "./OrderReject";
import AcceptModal from "./AcceptModal";
import { toast } from "react-toastify";

const OrderDetails = ({ details, isFetching, query, modalClose }) => {
  const queryClient = useQueryClient();

  const [customerName, setCustomerName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orderCount, setOrderCount] = useState("");
  const [deliveryType, setDeliverytype] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [spiceLevel, setSpiceLevel] = useState("");
  const [statusDetails, setStatusDetails] = useState("");
  const [listStatus, setListStatus] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [rejectModal, setRejectModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isAcceptModal, setIsAcceptModal] = useState(false);
  const [time, setTime] = useState('');
  const [currTime, setCurrTime] = useState('');

  useEffect(() => {
    if (details) {
      setLoading(true);
      setCustomerName(details ? details.user_info?.name : "");
      setOrderId(details ? details.order_id : "");
      setDeliverytype(
        details.order_type === 1
          ? "Delivery"
          : details.order_type === 2
          ? "Pick up"
          : ""
      );

      setTime(details && details.pickup_time !== null ?  moment(new Date(details.pickup_time).toISOString()).diff(moment(new Date().toISOString()), 'minutes') > 0 ? moment(new Date(details.pickup_time).toISOString()).diff(moment(new Date().toISOString()), 'minutes') : '' : '')

      setOrderDate(details?.created_at);
      setSpiceLevel(
        details && details?.order_detail.length > 0
          ? details?.order_detail.map((item) => {
              return item?.spice_level_info?.title;
            })
          : "N/A"
      );

      const getOrderDetails = async () => {
        try {
          const getData = await orderStatusDetails({ order_id: details._id });
          const a = await userOrderCount({ user_id: details.user_info?._id });
          setOrderCount(a.order_count);
          setStatusDetails(getData.data);
          let type_ids = [1,2,5];
          getData && getData.data.map((it,i)=>{
            type_ids.push(it.type_id);
          })
      
          const listStatus = await listOrderStatus({allowed_types:type_ids,sortByField:'ordering',sortByValue:1});
        
          setListStatus(listStatus.data);  
        } catch (error) {}
        setLoading(false);
      };
     
      getOrderDetails();

      return () => {
        setLoading(true);
        console.log('unmounted');
      }
    }
  }, [details]);


  const acceptStatus = async() =>{
   
    if(time === ''){
      toast.error('Delivery time field is required');
      return false;
    }
    const getData = await stsChangeOrder({id:details._id, type_id: 8,time:time });
    const listStatus = await listOrderStatus({allowed_types:[1,2,5,8,6],sortByField:'ordering',sortByValue:1});
     const resData = {
      "_id": getData.data._id,
      "name": getData.data.name,
      "type_id": getData.data.type_id,
      "color": getData.data.color,
      "description": getData.data.description +' ('+time + 'mins)',
      "created_at": getData.data.created_at,
      "icon": getData.data.icon
  };
    setListStatus(listStatus.data);  
    setStatusDetails([...statusDetails,resData]);
    setRejectModal(false)
    setIsAcceptModal(false);
    modalClose();
    queryClient.invalidateQueries(["live_order"]);
    queryClient.removeQueries({
      queryKey: ["live_order"],
      type: "inactive",
    });
    queryClient.invalidateQueries(["orders"]);
    queryClient.removeQueries({
      queryKey: ["orders"],
      type: "inactive",
    });
    queryClient.invalidateQueries({
      queryKey: ["new_orders"],
      exact: false
    });
    queryClient.removeQueries({
      queryKey: ["new_orders"],
      type: "inactive",
    });
  }

  const rejectStatus = async() =>{
    const getData = await stsChangeOrder({id:details._id, type_id: 5 });
    const listStatus = await listOrderStatus({allowed_types:[1,5],sortByField:'ordering',sortByValue:1});
    setListStatus(listStatus.data);  
    setStatusDetails([...statusDetails,getData.data]);
    setRejectModal(false);
    modalClose();
  }
  
  const findAccept = statusDetails && statusDetails.find((item)=>item?.type_id === 8);
  const findCancel = statusDetails && statusDetails.find((item)=>item?.type_id === 5);



  if(isLoading){
    return (<><div className="order-details-content px-2"><Loading /></div></>);
  }
  return (
    <>
      <div className="order-details-content px-2">
        <div className="row divide-x">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-12">
                <div className="customer-details">
                  <div className="name">
                    {customerName}{" "}
                    <span className="total-order">
                      {details?.order_detail.length}{" "}
                      {details?.order_detail.length === 1 ? "Item" : "Items"} 
                    </span>
                  </div>
                  
                  <div className="data-item">
                    <span>ORDER ID</span>
                    {orderId} 
                  </div>
                  <div className="data-item mb-3">
                    <span>DELIVERY TYPE</span>
                    {deliveryType}{"  "}{details?.order_type === 1 ? <i className="fa-light fa-bicycle"></i> : <i className="fa-light fa-bag-shopping"></i>}
                  </div>
                  <div className="data-item mb-2">
                    <span>Total Orders from <b>{customerName}</b> </span>{orderCount}
                  </div>
                  {!findCancel && 
                  <div className="data-item">
                    
                  {!findAccept &&  <button className="btn btn-success" onClick={()=> setIsAcceptModal(true)}>Accept</button>} &nbsp;
                   {(details?.order_status?.type_id === 8 || details?.order_status?.type_id === 6) && <button className="btn btn-danger" onClick={()=>setRejectModal(true)}>Reject</button>}
                  </div>
                }
                
                </div>
              </div>
            </div>
            {statusDetails.length > 0 && (
              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="item-title">Order Details</div>
                  <div className="order-step-progress">
                    {statusDetails.length > 0 &&
                      statusDetails.map((item, index) => {
                        const icon = item.type_id === 5?' canceled':'';
                        const checkAccept = item.type_id === 8?details?.time:'';
                        return (
                          <React.Fragment key={item._id}>
                            <div className="order-step active" key={index}>
                              <div className={"icon" + icon}>
                                <i className={item.icon}></i>
                              </div>
                              <div className={"order-content"+icon}>
                                <div className="title">{item.description} {checkAccept && '('+checkAccept+')'}</div>
                                <div className="date">
                                  {moment(new Date(item.updated_at)).format(
                                    "Do MMM YY HH:mm:ss"
                                  )}
                                </div>
                              </div>
                            </div>
                            </React.Fragment>
                        );
                      })}

                      {!findCancel && 
                       listStatus.length > 0 &&
                       listStatus.map((item, i) => {
                          return (
                            <React.Fragment  key={item._id}>
                              <div className="order-step">
                                <div className="icon">
                                  <i className={item.icon}></i>
                                </div>
                                <div className="order-content">
                                  <div className="title">{item.description}</div>
                                  {/* <div className="date">
                                    {moment(new Date(item.updated_at)).format(
                                      "Do MMM YY hh:mm:ss"
                                    )}
                                  </div> */}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })
                      }
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-5">
              <div className="col-md-12">
                <div className="item-title">Items</div>
                <div className="order-list">
                  <div className="order-item">
                    <div className="item-details">
                      {/* {details &&
                        details?.order_detail.length > 0 &&
                        details?.order_detail.map((item) => {
                          return item?.product_info.map((it) => {
                            return (
                              <React.Fragment key={it._id}>
                                <div className="order">{it?.title}</div>
                              </React.Fragment>
                            );
                          });
                        })} */}
                      {/* <div className="sub-text">{products}</div>
                      <div className="order">{products}</div> */}

                      {/* <div className="other-data">
                        <span>SPICE LEVEL:</span> {spiceLevel}
                      </div> */}
                    </div>
                    {/* {details &&
                      details?.order_detail.length > 0 &&
                      details?.order_detail.map((item) => {
                        return (
                          <React.Fragment key={item._id}>
                            <div className="item-price">
                              <div className="other-data">
                                <span>QTY:</span>
                                {item?.quantity}
                              </div>
                              <div className="other-data">
                                <span>ITEM PRICE: </span>€{item?.price}
                              </div>
                              <div className="other-data">
                                <span>TOTAL PRICE: </span>€
                                {parseFloat(item?.price) *
                                  parseFloat(item?.quantity)}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })} */}
                  </div>
                  {details &&
                        details?.order_detail.length > 0 &&
                        details?.order_detail.map((item) => {
                          return(
                        <div className="order-item">
                          <div className="item-details">
                            <div className="order">{item?.product_info[0].title}</div>
                            <div className="other-data">
                            {item?.spice_level_info ? <span>SPICE LEVEL: {item?.spice_level_info.title}</span> : null}
                            </div>
                          </div>
                          <div className="item-price">
                            <div className="other-data">
                              <span>QTY: {item?.quantity}</span>
                            </div>
                            <div className="other-data">
                              <span>ITEM PRICE: </span>€{item?.price}
                            </div>
                            <div className="other-data">
                              <span>TOTAL PRICE: </span>€{item?.total_amount}
                            </div>
                          </div>
                        </div>
                        );
                        })}
                </div>
              </div>
            </div>
            <div className="row mt-5 justify-content-end">
              <div className="col-md-8">
                <div className="order-price-history">
                  <div className="price-info">
                    {/* {details &&
                      details?.order_detail.length > 0 &&
                      details?.order_detail.map((item) => {
                        return item?.product_info.map((it) => {
                          return (
                            <>
                              <div className="price-row">
                                <div className="label">Food Sales :</div>
                                <div className="data">€{it?.sale_price}</div>
                              </div>
                            </>
                          );
                        });
                      })} */}
                    <div className="price-row">
                      <div className="label">Food Sales :</div>
                      <div className="data">
                        €
                        {parseFloat(details?.cart_amount) -
                          parseFloat(details?.cart_discount_amount)}
                      </div>
                    </div>

                    <div className="price-row">
                      <div className="label">Discount :</div>
                      <div className="data color-danger">
                        -€{details?.cart_discount_amount}
                      </div>
                    </div>
                    <div className="price-row">
                      <div className="label">Delivery Fee :</div>
                      <div className="data color-success">
                        +€
                        {details?.shipping_charge_info?.delivery_charge
                          ? details?.shipping_charge_info?.delivery_charge
                          : "0"}
                      </div>
                    </div>
                  </div>
                  <div className="price-total">
                    <div className="price-row">
                      <div className="label">Net Value</div>
                      <div className="data">
                        €
                        {details?.order_total_amount
                          ? details?.order_total_amount
                          : "0"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="shipping-details">
              {details?.order_cancel_reasones_detail && <div className="shipping-note">
                  <div className="sub-text">Canceled Reason:</div>
                  <div className="note">{details?.order_cancel_reasones_detail?.name}</div>
                 </div>}
                <div className="shipping-note">
                  {details?.rider_note && <div className="sub-text">Delivery Note:</div>}
                  <div className="note">{details?.rider_note}</div>
                  <div className="sub-text mt-3">Kitchen Note:</div>
                  <div className="note">{details?.kitchen_note}</div>
                </div>
                <div className="shipping-other-details">
                  <div className="sub-text">Shipping Details</div>
                  <div className="other-data">
                    <div className="name">{customerName}</div>
                    <div className="data-row">
                      <div className="data-item">
                        <span>Address</span>
                        {details?.shipping_info?.address}
                      </div>
                      <div className="data-item">
                        <span>Contact Number</span>
                        {details?.user_info?.phone_code} {""}{" "}
                        {details?.user_info?.phone}
                      </div>
                      <div className="data-item">
                        <span>Email Address</span>
                        {details?.user_info?.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Cancel"}
        text={"Are you sure you want to cancel this order?"}
        isOpen={rejectModal}
        submitText={'Yes'}
        cancelText={'No'}
        confirmClose={() => setRejectModal(false)}
        confirmSubmit={() => {
          //rejectStatus();
          setIsAddModal(true)
          setRejectModal(false)
        }}
      />

     {details && <Modal title={"Reject Reason"} size={"sm"} isOpen={isAddModal} modalClose={() => setIsAddModal(!isAddModal)}>
        <OrderReject currId={details?._id} modalClose={() => setIsAddModal(!isAddModal)} currOrderId={details?.order_id} rejectStatus={() => rejectStatus()} />
      </Modal>}

      {details && <Modal title={"Delivery Time"} size={"sm"} isOpen={isAcceptModal} modalClose={() => setIsAcceptModal(!isAcceptModal)}>
        <AcceptModal acceptStatus={acceptStatus} setTime={setTime} time={time} modalClose={() => setIsAcceptModal(!isAcceptModal)}/>
      </Modal>}


    </>
  );
};

export default OrderDetails;
