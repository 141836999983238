import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { delProduct, stsChangeProduct } from "../../helpers/api_helper";
import Confirm from "../Elements/Confirm";
import RadioGroup from "../Form/RadioGroup";

const MenuItems = ({
  index,
  productId,
  name,
  type,
  category,
  isPopular,
  points,
  spiceLevel,
  allergies,
  addons,
  stock,
  createdBy,
  createdDate,
  updatedDate,
  plu_code,
  status,
  price,
  discountPercentage,
  queryKeyProduct,
  detailsFetch,
  setEditProductId,
  setEditModal,
}) => {
  const queryClient = useQueryClient();

  const [deleteModal, setDeleteModal] = useState(false);

  const [in_stock, setIn_stock] = useState(stock);

  const staticStockArr = [
    {
      _id: 1,
      name: "In stock",
      color: "success",
      current: stock === 1 ? true : false,
    },
    {
      _id: 2,
      name: "Out of stock",
      color: "danger",
      current: stock === 2 ? true : false,
    },
    {
      _id: 3,
      name: "Sold out for today",
      color: "warning",
      current: stock === 3 ? true : false,
    },
  ];

  const deleteProduct = useMutation((payload) => delProduct(payload), {
    onSuccess: (res) => {
      console.log(res);
      if (res.status === 200) {
        toast.success(res.message);
        queryClient.invalidateQueries(queryKeyProduct);
        queryClient.removeQueries({
          queryKey: ["products"],
          type: "inactive",
        });
      }
      if (res.status === 400) {
        toast.error(res.message);
      }
    },
    onError: (res) => {
      console.log(res);
    },
  });

  const statusChangeProduct = useMutation(
    (payload) => stsChangeProduct(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryKeyProduct);
          queryClient.removeQueries({
            queryKey: ["products"],
            type: "inactive",
          });
        }
        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  const stockChangeProduct = useMutation(
    (payload) => stsChangeProduct(payload),
    {
      onSuccess: (res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.message);
          queryClient.invalidateQueries(queryKeyProduct);
          queryClient.removeQueries({
            queryKey: ["products"],
            type: "inactive",
          });
        }

        if (res.status === 400) {
          toast.error(res.message);
        }
      },
      onError: (res) => {
        console.log(res);
      },
    }
  );

  useEffect(() => {
    if (stock !== in_stock) {
      stockChangeProduct.mutate({
        id: productId,
        status: status,
        in_stock: in_stock,
      });
    }
  }, [in_stock]);
  return (
    <>

          <div className="menu-item mb-3" >
            <div className="menu-item-row">
              <div className="menu-type">
                {type && (
                  <div
                    className={"type " + ((type === "Veg") || (type === "Vegan") ? "veg" : "non-veg")}
                  >
                    <div className="type-icon"></div>
                    <div className="type-title">
                      {type === "Veg" ? "veg" : (type === "Vegan") ? "vegan" : "non-veg"}
                    </div>
                  </div>
                )}
              </div>
              <div className="menu-options">
                <div
                  className={"status " + (status === 1 ? "published" : "draft")}
                >
                  {status === 1 ? "published" : "draft"}
                </div>
                <div className="action-dropdown dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        setEditModal(true);
                        setEditProductId(productId);
                      }}
                    >
                      <i className="fa-regular fa-fw fa-pen-to-square"></i>Edit
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() =>
                        statusChangeProduct.mutate({
                          id: productId,
                          status: status === 1 ? 3 : 1,
                        })
                      }
                    >
                      <i className="fa-regular fa-fw fa-arrow-up-from-dotted-line"></i>
                      {status === 1 ? "Draft" : "Publish"}
                    </button>
                    <button
                      type="button"
                      className="dropdown-item standOut"
                      onClick={() => setDeleteModal(true)}
                      // onClick={() => deleteProduct.mutate({ id: productId })}
                    >
                      <i className="fa-regular fa-fw fa-trash-can"></i>Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-content">
              <div className="menu-title">
                {name}
                {isPopular && <span className="popular">Popular</span>}
              </div>
              <div className="menu-categories">
                {category.map((item) => (
                  <span key={item._id}>{item.title}</span>
                ))}
              </div>
            </div>
            <div className="menu-metas">
              {(typeof points === 'number' && points > 0) && (
                <div className="meta-item">
                  <div className="meta-icon">
                    <i className="fa-light fa-fw fa-coins"></i>
                  </div>
                  <div className="meta-content">
                    <div className="meta-label">Points</div>
                    <div className="meta-data">{points}</div>
                  </div>
                </div>
              )}
              {spiceLevel && (
                <div className="meta-item">
                  <div className="meta-icon">
                    <i className="fa-light fa-fw fa-pepper-hot"></i>
                  </div>
                  <div className="meta-content">
                    <div className="meta-label">Spice Level</div>
                    <div className="meta-data">
                      {spiceLevel.length > 0
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                </div>
              )}
              {allergies && (
                <div className="meta-item">
                  <div className="meta-icon">
                    <i className="fa-light fa-fw fa-person-dots-from-line"></i>
                  </div>
                  <div className="meta-content">
                    <div className="meta-label">Allergies</div>
                    <div className="meta-data">
                      {allergies.length > 0
                        ? allergies.map((item) => (
                            <span key={item._id}>{item.title}</span>
                          ))
                        : "No"}
                    </div>
                  </div>
                </div>
              )}
              {addons && (
                <div className="meta-item">
                  <div className="meta-icon">
                    <i className="fa-light fa-fw fa-grid-2-plus"></i>
                  </div>
                  <div className="meta-content">
                    <div className="meta-label">Add on</div>
                    <div className="meta-data">
                      {addons.length > 0
                        ? 'Yes'
                        : "No"}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="menu-item-row mt-3">
              <div className="menu-stock">
                <RadioGroup
                  data={staticStockArr}
                  setData={setIn_stock}
                  inputName={"item_stock" + productId}
                  labelClass={"bg-shades-04"}
                />
              </div>
              <div className="menu-price">
                {(price > discountPercentage) && (discountPercentage !== 0) ? (
                  <>
                    <div className="price strike">€{price}</div>
                    <div className="price">€{discountPercentage}</div>
                  </>
                ) : (
                  <>
                    <div className="price">{price === 0 ? 'Free' : '€'+price}</div>
                  </>
                )}
              </div>
            </div>
            {(createdBy || plu_code || createdDate || updatedDate) && (
              <div className="menu-item-row border-t">
                <div className="menu-info">
                  {createdBy && (
                    <div className="info">
                      <div className="info-label">Created By</div>
                      <div className="info-data">{createdBy}</div>
                    </div>
                  )}
                  {plu_code && (
                    <div className="info">
                      <div className="info-label">PLU Code</div>
                      <div className="info-data">{plu_code}</div>
                    </div>
                  )}
                </div>
                <div className="menu-info">
                  {createdDate && (
                    <div className="info">
                      <div className="info-label">Created Date</div>
                      <div className="info-data">{createdDate}</div>
                    </div>
                  )}
                  {updatedDate && (
                    <div className="info">
                      <div className="info-label">Last Updated</div>
                      <div className="info-data">{updatedDate}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
     
      <Confirm
        icon={"fa-regular fa-xmark"}
        iconColorClass={"bg-danger"}
        title={"Delete"}
        text={"Are you really want to delete this product?"}
        isOpen={deleteModal}
        confirmClose={() => setDeleteModal(false)}
        confirmSubmit={() => {
          deleteProduct.mutate({ id: productId });
        }}
      />
    </>
  );
};

export default MenuItems;
