import Search from "../../components/Form/Search";
import Button from "../../components/Form/Button";
import FaqFeedbackItem from "../../components/Items/FaqFeedbackItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { listCategories, listFaqFeedback } from "../../helpers/api_helper";
import Pagination from "../../components/Elements/Pagination";
import { NoData } from "../../components/Elements/NoData";
import { Error } from "../../components/Elements/Error";
import { Loading } from "../../components/Elements/Loading";
import { useState } from "react";
import moment from "moment";
import Select from 'react-select';

const FaqFeedback = () => {
  // Query Client instance
  const queryClient = useQueryClient();

  // For Managing Modals
//   const [isAddModal, setIsAddModal] = useState(false);
//   const [isEditModal, setIsEditModal] = useState(false);
const [selectedCat, setSelectedCat] = useState([]);
const [category_id, setCategory_id] = useState("");

 
// For pagination
const [perPages, setPerPages] = useState([
  { label: "10 (Default)", value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
  { label: "All", value: 999999 },
]);
const [selectedPageOpt, setSelectedPageOpt] = useState([]);
 

  // For Filtering Data 🔍
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [selectedCatOpt, setSelectedCatOpt] = useState([
  ]);


  // To fetch Details of specific items when it needs to be edited
//   const [editId, setEditId] = useState("");

  // Static status Radio buttons
  const [statusStatic, setStatusStatic] = useState([
    { _id: "", name: "All", color: "success", current: true },
    { _id: true, name: "Published", color: "success", current: false },
    { _id: false, name: "Draft", color: "warning", current: false },
  ]);

  // Dynamic query key for Diets 🗝️
  const queryFeedback = ["feedback", keyword, status, limit, offset,category_id];

  // For opening Add Modal althogh not mandetory
//   const addModal = () => {
//     setIsAddModal(true);
//   };

function useCategory() {
  return useQuery({
    queryKey: ["feedback_categories_dropdown"],
    queryFn: async () => {
      let tmp = [];
      const { data } = await listCategories(
        {
          limit: 9999,
          category_type: "faqs",
        }
      );

      data.forEach((item) => {
        tmp.push({ value: item._id, label: item.name });
      });

      return tmp;
    },
  });
}

const { data: categories } = useCategory();



  function FeedbackList() {
    // Custom hook for fetching diet list
    function useFeedback() {
      return useQuery({
        queryKey: queryFeedback,
        queryFn: () =>
          listFaqFeedback({
            keyword: keyword,
            sortByValue: 1,
            status: status,
            limit: limit,
            offset: offset,
            category_id: category_id,
          }),
        keepPreviousData: true,
      });
    }

    const { data: feedbacks, error, isFetching } = useFeedback();

    // Handling loading state 😑
    if (isFetching) {
      return <Loading />;
    }

    // Handling error state 🥴
    if (error) {
      return <Error />;
    }

    // Check if their is no data 🤨
    else if (feedbacks.data.length === 0) {
      return <NoData />;
    }

    // Renders the list
    else {
    
      return (
        // For Pagination
        <>
          {feedbacks.data.length > 0 &&
            feedbacks.data.map((item) => {
              return (
                <FaqFeedbackItem
                  key={item._id}
                  //ipAddress={item?.user_ip}
                  name={item?.faq_details?.question}
                  helpful={item?.count_feedback_true}
                  not_helpful={item?.count_feedback_false}
                  createdDate={moment(new Date(item?.created_at)).format(
                    "Do MMM YY"
                  )}
                  updatedDate={moment(new Date(item?.updated_at)).format(
                    "Do MMM YY"
                  )}
                  query={queryFeedback}
                />
              );
            })}

          <Pagination
            count={feedbacks.count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="page-filter mb-3">
        <div className="input-filters">
        <div className="input-items">
            {perPages && (
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={perPages}
                  value={selectedPageOpt || []}
                  placeholder={"Per Page"}
                  onChange={(e) => {
                    setLimit(e.value);
                    setSelectedPageOpt(e);
                  }}
                />
              </div>
            )}
          </div>
          <div className="input-items">
            <Search
              state={keyword}
              setState={setKeyword}
              placeholder={"Search..."}
            />
          </div>
          <div className="input-items">
              <div className="input">
                <Select
                  className="select-tag"
                  isMulti={false}
                  isClearable={true}
                  options={categories?categories:[]}
                  value={selectedCatOpt || []}
                  placeholder={"Categories"}
                  onChange={(e) => {
                    setCategory_id(e.value);
                    setSelectedCatOpt(e);
                  }}
                />
              </div>
          </div>

          {/* <div className="input-items">
            <SelectTag data={categoryDropdown} placeholder={"Category"} />
          </div> */}
          <div className="input-items items-end ms-auto gap-3">
            <Button
              classes={"px-2"}
              icon={"fa-regular fa-arrow-rotate-right"}
              iconPosition={"left"}
              functions={() => {
                queryClient.invalidateQueries(queryFeedback);
                queryClient.removeQueries({
                  queryKey: ["feedback"],
                  type: "inactive",
                });
                setOffset(0);
                setSelectedPageOpt([]);
                setKeyword("");
                setSelectedCatOpt([]);
              }}
            />
            {/* <Button label={"Add Blog"} labelClasses={""} classes={""} icon={"fa-regular fa-plus"} iconPosition={"left"} functions={addModal} /> */}
          </div>
        </div>
        {/* <div className="radio-filters">
          <RadioGroup data={status} inputName={"status"} />
        </div> */}
      </div>
      <div className="blogs-list">
        <FeedbackList />
      </div>
    </>
  );
};

export default FaqFeedback;
